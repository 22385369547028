<template>
  <div>
    <h4 class="text-center my-4">新規登録</h4>
    <div class="d-flex justify-content-center">
      <div class="col-md-5">
        <b-alert :show="registered" variant="success">
          <h5 class="alert-heading text-center mt-3">※ご登録はまだ完了しておりません</h5>
          <div>
            <p class="text-center">
              この度は「土地作図くん」に新規登録いただき誠にありがとうございます。<br>
              登録いただきましたメールアドレスへ、認証メールを送信いたしました。<br>
              メールをご確認いただき、以降の手順へお進みください。
            </p>
          </div>
        </b-alert>
        <div v-show="registered">
          <h6 class="mt-5 text-center">数時間お待ちいただいてもメールが届かない場合</h6>
          <p class="small">
            メールアドレスをご確認いただき、再度登録をお願いいたします。<br>
            それでも届かない場合は、お手数をおかけいたしますが、
            <a href="https://sakuzukun.com/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b/" target="_blank">
              お問い合わせページ
            </a>
            よりご連絡ください。
          </p>
        </div>
        <b-card v-show="!registered">
          <p class="text-danger" v-if="errors.non_field_errors">{{ errors.non_field_errors[0] }}</p>

          <b-form @submit.prevent="onSubmit">
            <b-form-group label="氏名" label-for="manager_name">
              <b-form-input id="manager_name" v-model="form.manager_name" required :state="isValid(['non_field_errors', 'manager_name'])" autofocus />
              <b-form-invalid-feedback v-if="errors.manager_name" :state="isValid(['non_field_errors', 'manager_name'])">
                {{ errors.manager_name[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="会社名" label-for="company_name">
              <b-form-input id="company_name" v-model="form.company_name" :state="isValid(['non_field_errors', 'company_name'])" />
              <b-form-invalid-feedback v-if="errors.company_name" :state="isValid(['non_field_errors', 'company_name'])">
                {{ errors.company_name[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="電話番号" label-for="tel">
              <b-form-input id="tel" v-model="form.tel" required type="text" maxlength="15" oninput="value = value.replace(/[^0-9]+/i,'');" :state="isValid(['non_field_errors', 'tel'])" />
              <b-form-invalid-feedback v-if="errors.tel" :state="isValid(['non_field_errors', 'tel'])">
                {{ errors.tel[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="ユーザID" label-for="username">
              <b-form-input id="username" v-model="form.username" required :state="isValid(['non_field_errors', 'username'])" />
              <b-form-invalid-feedback v-if="errors.username" :state="isValid(['non_field_errors', 'username'])">
                {{ errors.username[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="メールアドレス" label-for="email">
              <b-form-input v-model="form.email" required :state="isValid(['non_field_errors', 'email'])" />
              <b-form-invalid-feedback v-if="errors.email" :state="isValid(['non_field_errors', 'email'])">
                {{ errors.email[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="パスワード" label-for="password">
              <b-form-input id="password" v-model="form.password" required type="password" :state="isValid(['non_field_errors', 'confirmed_password'])" />
              <b-form-invalid-feedback v-if="errors.confirmed_password" :state="isValid(['non_field_errors', 'confirmed_password'])">{{ errors.confirmed_password[0] }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="パスワード（確認用）" label-for="confirmed_password">
              <b-form-input id="confirmed_password" v-model="form.confirmed_password" required type="password" :state="isValid(['non_field_errors', 'confirmed_password'])" />
              <b-form-invalid-feedback v-if="errors.confirmed_password" :state="isValid(['non_field_errors', 'confirmed_password'])">{{ errors.confirmed_password[0] }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="text-center">
              <b-form-checkbox v-model="agreed" value="on" unchecked-value="off"><a href="#" v-b-modal="'terms-of-service'">利用規約</a>に同意する</b-form-checkbox>
              <b-modal id="terms-of-service" class="modal-notice-text px-3" title="利用規約" size="xl" ok-only>
                <terms-of-service v-model="title"></terms-of-service>
              </b-modal>
            </b-form-group>

            <div class="text-center mb-3">
              <b-button type="submit" variant="primary" pill class="px-5" :disabled="agreed !== 'on'">登録</b-button>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import validation from '../../mixins/validation'
import constants from '../../constants'
import TermsOfService from '../../components/TermsOfService'

export default {
  name: 'SignupForm',
  mixins: [validation],
  components: { TermsOfService },
  data: () => ({
    registered: false,
    agreed: 'off',
    form: {
      manager_name: '',
      company_name: '',
      tel: '',
      username: '',
      email: '',
      password: '',
      confirmed_password: ''
    }
  }),
  methods: {
    onSubmit () {
      this.$emit('loading', true)
      this.axios.post('/api/account/signup', this.form).then(res => {
        this.$emit('loading', false)
        this.registered = true
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.$emit('loading', false)
          this.validated(error.response.data)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
