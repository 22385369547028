const state = {
  variant: '',
  message: '',
  shown: false
}

const mutations = {
  setAll (state, value) {
    state.variant = value.variant
    state.message = value.message
    state.shown = false
  },
  updateShown (state) {
    state.shown = true
  }
}
const getters = {
  showing: state => {
    return !!state.message
  },
  all: state => {
    return state
  }
}

const actions = {
  show ({ commit }, value) {
    commit('setAll', value)
  },
  close ({ commit }) {
    commit('setAll', { variant: '', message: '' })
  },
  stateUpdate ({ dispatch, commit, state }) {
    if (!state.shown) {
      commit('updateShown')
    } else {
      dispatch('close')
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
