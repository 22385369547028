export default {
  watch: {
    needPlayVideo () {
      if (this.needPlayVideo) {
        setTimeout(() => {
          document.getElementById('play-video-btn').click()
        }, 1000)
      }
    }
  }
}
