<template>
  <b-container fluid>
    <div id="full-width-section" class="align-items-center d-flex justify-content-between mb-4 row">
      <change-title v-model="title" @change-title-error="validated"></change-title>

      <div class="ml-5 col-md-6">
        <ul class="stepFlow">
          <li class="completed">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 1</p>トリミング</span>
          </li>
          <li class="completed">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 2</p>評価対象地の測定</span>
          </li>
          <li class="completed">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 3</p>評価対象地の編集</span>
          </li>
          <li class="active">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 4</p>路線価の設定</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 5</p>測定結果</span>
          </li>
        </ul>
      </div>

      <div class="text-right">
        <b-btn variant="outline-secondary" class="px-5 mr-3" @click="back">戻る</b-btn>
        <b-btn variant="primary" class="px-3" @click="next">
          <div class="btn-2rows text-left">路線価の設定を完了し<br>結果を表示する</div>
        </b-btn>
      </div>
    </div>

    <div>
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>

    <div class="align-items-center d-flex mb-4">
      <b-button v-if="helpUrl != ''" v-b-toggle.description variant="secondary" @click="openInNewTab(helpUrl)" class="align-items-center d-flex mr-2">
        <span class="bg-white d-flex align-items-center mr-2 rounded-pill" style="width: 18px; height: 12px;">
          <b-icon-book-fill variant="info"/>
        </span>
        詳細説明を見る
      </b-button>
      <play-video-btn v-if="helpUrlVideo"></play-video-btn>

      <b-dropdown variant="secondary" class="mx-2" v-if="showPdfDropdown">
        <template #button-content><b-icon-files class="mr-1" />PDFの確認</template>
        <b-dropdown-item-button @click="closeCanvasPDF" class="small">なし</b-dropdown-item-button>
        <b-dropdown-item-button v-for="(pdf, index) in pdfFiles" :key="index" @click="openCanvasPDF(index)" class="small">{{pdf.filename}} ({{pdf.page}}ページ)</b-dropdown-item-button>
      </b-dropdown>
    </div>

    <div class="row">
      <b-form inline class="pl-3 mb-4" style="width: 100%;">
        <b-form-radio-group v-model="bgReversed">
          <b-form-radio :value="true" class="mr-5">ダークモード</b-form-radio>
          <b-form-radio :value="false" class="mr-4">ライトモード</b-form-radio>
        </b-form-radio-group>
        <label class="mr-3">｜&nbsp;土地画像：</label>
        <b-form-radio-group v-model="isImageDisplay">
          <b-form-radio :value="true" class="mr-5">表示</b-form-radio>
          <b-form-radio :value="false" class="mr-5">非表示</b-form-radio>
        </b-form-radio-group>
      </b-form>

      <div class="col-xl-8 col-lg-6 col-12">
        <!-- キャンバス -->
        <div ref="box">
          <div class="d-flex">
            <canvas
              id="canvasPDF"
              ref="canvasPDF"
              v-show="showPdfCanvas"
              :width="canvasWidth"
              :height="canvasHeight"
              @mouseup="mouseUpPDF"
              @mousedown="mouseDownPDF"
              @mouseout="mouseUpPDF"
              @mousemove="mouseMovePDF"
              @wheel.prevent="changeScalePDF"
              />
            <canvas
              id="canvas"
              ref="canvas"
              :width="canvasWidth"
              :height="canvasHeight"
              @mouseup="mouseUp"
              @mousedown="mouseDown"
              @mousemove="mouseMove"
              @wheel.prevent="changeScale"
              />
          </div>
        </div>

        <!-- 測定情報表示欄 -->
        <div v-show="showRangeInfo" class="justify-content-between align-items-center section-panel p-3">
          <div class="border-bottom">測定範囲の情報</div>
          <table class="mt-2">
            <tr>
              <td>面積：{{showRangeInfo.area}}</td>
              <td>縮尺：1/{{showRangeInfo.scale}}</td>
            </tr>
          </table>
          <template v-if="openingPassageway.isPassagewayOpened">
            <div class="border-bottom mt-3">通路開設情報</div>
            <ul class="mt-2 px-0">
              <li v-for="v in openingPassageway.passagewayVertexes" :key="v" class="d-inline-block pl-1">
                {{v.name}}
              </li>
            </ul>
          </template>
        </div>
      </div>

      <div class="col-xl-4 col-lg-6 col-12">
        <template>
          <div v-if="ranges.length == 0" style="display:block; height:40px;"></div> <!-- タブが0個の時の高さ調整用div -->
          <b-tabs content-class="mt-3" v-model="activeTabIndex">

            <b-tab v-for="(range, index) in ranges" :key="index" :title="`測定範囲${(index + 1)}`">
              <div class="justify-content-between align-items-center section-panel p-3">
                <span class="text-left">路線価</span>
                <div class="float-right">
                  <div class="text-right">
                    <b-button class="px-4" size="sm" variant="primary" v-b-modal="`route-price-modal-${index}`" @click="setCheckMenu()">追加</b-button>
                  </div>

                  <!-- 路線価追加モーダル -->
                  <b-modal :ref="`route-price-modal-${index}`" :id="`route-price-modal-${index}`" title="路線価の追加" hide-footer>
                    <div>
                      <table class="mb-3">
                        <tr>
                          <th colspan="3">地区区分を選択</th>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <b-form inline class="input-group mb-3">
                              <b-form-select
                                id="district-division"
                                v-model="recordRLP.info.RLP.districtDivision"
                                :options="districtDivisionOptions"
                                @change="changeDistrictDivision"
                                required
                              >
                              </b-form-select>
                            </b-form>
                          </td>
                        </tr>
                        <!-- 無道路地の設定 -->
                        <tr v-if="openingPassageway.isPassagewayOpened">
                          <th colspan="3">無道路地の正面路線を設定してください</th>
                        </tr>
                        <tr v-if="openingPassageway.isPassagewayOpened">
                          <td colspan="3">
                            <b-form-select class="input-group mb-3" v-model="recordRLP.noneAdjoining.routeIndex" :options="getTouchlessRouteOptions(drawing)" />
                          </td>
                        </tr>
                        <!-- 普通／屈折路兼用の頂点指定欄 -->
                        <tr>
                          <th v-if="!openingPassageway.isPassagewayOpened">始点</th>
                          <th v-if="!openingPassageway.isPassagewayOpened">終点</th>
                          <th>路線価<small>(千円)</small></th>
                        </tr>
                        <tr>
                          <td v-if="!recordRLP.noneAdjoining.activate">
                            <b-form-select v-if="!recordRLP.refracting.activate" v-model="recordRLP.info.RLP.frontageVtx.start" :options="getRoutePriceVertexOptions(range.vertexes)" @change="vertexChanged" />
                            <b-form-select v-if="recordRLP.refracting.activate" v-model="refractVtxesBuffer[0].startVertex" :options="getRoutePriceVertexOptions(range.vertexes)" />
                          </td>
                          <td v-if="!recordRLP.noneAdjoining.activate">
                            <b-form-select v-if="!recordRLP.refracting.activate" v-model="recordRLP.info.RLP.frontageVtx.end" :options="getRoutePriceVertexOptions(range.vertexes)" @change="vertexChanged" />
                            <b-form-select v-if="recordRLP.refracting.activate" v-model="refractVtxesBuffer[0].endVertex" :options="getRoutePriceVertexOptions(range.vertexes)" />
                          </td>
                          <td>
                            <b-form-input v-if="!recordRLP.refracting.activate" type="number" v-model="recordRLP.info.RLP.inputRLP" />
                            <b-form-input v-if="recordRLP.refracting.activate" type="number" v-model="refractVtxesBuffer[0].priceInput" />
                          </td>
                        </tr>
                      </table>
                      <div class="d-flex justify-content-start">
                        <div v-b-tooltip.hover :title="tooltipTitle">
                          <b-form-checkbox
                            class="mb-3 ml-2 icon-btn"
                            v-if="!recordRLP.noneAdjoining.activate"
                            v-model="recordRLP.refracting.activate"
                            :disabled="recordRLP.cornerCompletion.activate || recordRLP.noneAdjoining.activate || isExpiration"
                            :style="{ 'pointer-events': isExpiration ? 'none' : 'auto' }"
                            >
                            屈折路の追加
                            <b-icon-award-fill v-if="isExpiration" class="freemium-icon icon" font-scale="1.5" />
                          </b-form-checkbox>
                        </div>
                        <div class="ml-3">
                          <b-form-radio-group v-if="recordRLP.refracting.activate" v-model="recordRLP.refracting.innerOrOuter">
                            <b-form-radio :value="true">内接</b-form-radio>
                            <b-form-radio :value="false">外接</b-form-radio>
                          </b-form-radio-group>
                        </div>
                      </div>
                      <!-- 屈折の回数を追加／削除 -->
                      <div class="mb-2 ml-2" v-if="recordRLP.refracting.activate">
                        <b-btn class="mr-1" variant="info" v-b-tooltip.hover title="屈折点の追加" @click="addRefractPoint"><b-icon-plus /></b-btn>
                        <b-btn variant="danger" v-b-tooltip.hover title="屈折点の削除" @click="removeRefractPoint"><b-icon-dash /></b-btn>
                      </div>
                      <!-- 屈折路用の頂点指定欄 -->
                      <div v-if="recordRLP.refracting.activate">
                        <div v-for="num in recordRLP.refracting.refractNumber" :key="num"><!-- 屈折路の回数分For回す -->
                          <table class="mb-3">
                            <tr>
                              <th v-if="!recordRLP.noneAdjoining.activate">始点</th>
                              <th v-if="!recordRLP.noneAdjoining.activate">終点</th>
                              <th>路線価<small>(千円)</small></th>
                            </tr>
                            <tr>
                              <td>
                                <b-form-select v-model="refractVtxesBuffer[num-1].endVertex" disabled :options="getRoutePriceVertexOptions(range.vertexes)" />
                              </td>
                              <td>
                                <b-form-select v-model="refractVtxesBuffer[num].endVertex" :options="getRoutePriceVertexOptions(range.vertexes)" />
                              </td>
                              <td>
                                <b-form-input type="number" v-model="refractVtxesBuffer[num].priceInput" />
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <table v-bind:class="isExpiration ? 'mb-3 text-muted' : 'mb-3'">
                        <tr>
                          <th class="icon-btn">
                            評価対象地の面積<small>(㎡)</small>を変更<b-icon-award-fill v-if="isExpiration" class="freemium-icon icon" font-scale="1.5" />
                            <b-button v-bind:class="isExpiration ? 'ml-4' : 'ml-3'" size="sm" @click="$bvModal.show(`description-${index}`);">!</b-button><br />
                            <p class="small" style="margin-bottom: 0.25rem; padding-left: 1em; text-indent: -1em;">
                              ※STEP2で確定した面積が自動転記されております。<br>
                              面積に変更がなければ入力しないでこのまま進んでください。
                            </p>
                          </th>
                        </tr>
                        <tr>
                          <td v-b-tooltip.hover :title="tooltipTitle">
                            <b-form-input :disabled="isExpiration" type="number" step="0.01" v-model="recordRLP.info.irregularLand.inputArea" :style="{ 'pointer-events': isExpiration ? 'none' : 'auto' }" />
                          </td>
                        </tr>
                      </table>
                    </div>
                    <!-- OK／キャンセルボタン欄 -->
                    <div class="text-right d-flex justify-content-end">
                      <div>
                        <b-button class="mt-3 mr-2" @click="$bvModal.hide(`route-price-modal-${index}`);clearRecord4RLP();hideModal(index);">キャンセル</b-button>
                      </div>
                      <div v-b-tooltip.hover :title="tooltipTitle" v-if="!recordRLP.refracting.activate">
                        <b-button :disabled="isExpiration" class="mt-3 mr-2 icon-btn" variant="primary" :style="{ 'pointer-events': isExpiration ? 'none' : 'auto' }"  @click="showOptionModal(index);hideModal(index)">
                          オプション
                          <b-icon-award-fill v-if="isExpiration" class="freemium-icon icon" style="top:-10px" font-scale="1"/>
                        </b-button>
                      </div>
                      <div>
                        <b-button :disabled="!isSelectedDistrictDivision" class="mt-3 mr-2" variant="primary" @click="addPrice(range);hideModal(index);$bvModal.hide(`route-price-modal-${index}`)">
                          <template v-if="recordRLP.refracting.activate">屈折路の追加</template>
                          <template v-else-if="recordRLP.noneAdjoining.activate">無道路地の追加</template>
                          <template v-else>追加</template>
                        </b-button>
                      </div>
                    </div>
                  </b-modal>

                  <!-- オプションモーダル -->
                  <b-modal :ref="`route-price-option-modal-${index}`" :id="`route-price-option-modal-${index}`" title="オプション設定" hide-footer>
                    <!-- 詳細設定ボタン -->
                    <div>角切の設定がある場合は以下より設定してください。</div>
                    <!-- 角切設定 -->
                    <div class="justify-content-between align-items-center section-panel p-3">
                      <div class="d-flex justify-content-between">
                        <span>角切の設定</span>
                        <span>
                          <b-btn
                            class="mb-2 px-4"
                            size="sm"
                            v-bind:variant="isShowCutoff ? 'dark' : 'primary'"
                            @click="isShowCutoff=!isShowCutoff"
                          >
                            設定
                          </b-btn>
                          <!-- :disabled="recordRLP.noneAdjoining.activate" -->
                        </span>
                      </div>
                      <div v-show="isShowCutoff==true" class="mt-3">
                        <span class="modalTitle">正面路線</span>
                        <table class="mb-3">
                          <tr>
                            <th>始点</th>
                            <th>終点</th>
                          </tr>
                          <tr>
                            <td>
                              <b-form-select v-model="recordRLP.cornerCompletion.targetVtx.start.index" :options="getCutoffVertexOptions(range.vertexes)" @change="cornerVertexChanged" />
                            </td>
                            <td>
                              <b-form-select v-model="recordRLP.cornerCompletion.targetVtx.end.index" :options="getCutoffVertexOptions(range.vertexes)" @change="cornerVertexChanged" />
                            </td>
                          </tr>
                        </table>
                        <span class="modalTitle">側面路線1</span>
                        <table class="mb-3">
                          <tr>
                            <th>始点</th>
                            <th>終点</th>
                          </tr>
                          <tr>
                            <td>
                              <b-form-select v-model="recordRLP.cornerCompletion.sideVtx1.start.index" :options="getCutoffVertexOptions(range.vertexes)" />
                            </td>
                            <td>
                              <b-form-select v-model="recordRLP.cornerCompletion.sideVtx1.end.index" :options="getCutoffVertexOptions(range.vertexes)" />
                            </td>
                          </tr>
                        </table>
                        <span class="modalTitle">側面路線2</span>
                        <table class="mb-3">
                          <tr>
                            <th>始点</th>
                            <th>終点</th>
                          </tr>
                          <tr>
                            <td>
                              <b-form-select v-model="recordRLP.cornerCompletion.sideVtx2.start.index" :options="getCutoffVertexOptions(range.vertexes)" />
                            </td>
                            <td>
                              <b-form-select v-model="recordRLP.cornerCompletion.sideVtx2.end.index" :options="getCutoffVertexOptions(range.vertexes)" />
                            </td>
                          </tr>
                        </table>
                        <div class="text-right">
                          <b-btn class="px-4 mr-3" size="sm" variant="primary" @click="addCutoffConfig(range)">OK</b-btn>
                          <b-btn class="px-4" size="sm" variant="danger" v-b-modal="'delete-confirm-cutoff'">キャンセル</b-btn>
                          <b-modal id="delete-confirm-cutoff" hide-header @ok="cancelCutoffConfig" ok-variant="danger">
                            <div>角切の削除操作は元に戻せません。本当によろしいですか？</div>
                          </b-modal>
                        </div>
                      </div>
                    </div>

                    <!-- 設定ボタン/次に進む -->
                    <div class="text-right">
                      <b-button class="mt-3 mr-2" @click="clearRecord4RLP();$bvModal.hide(`route-price-option-modal-${index}`)">キャンセル</b-button>
                      <b-button class="mt-3 mr-2" variant="primary" @click="showModal(index);$bvModal.hide(`route-price-option-modal-${index}`);">OK</b-button>
                    </div>
                  </b-modal>
                  <b-modal size="lg" :id="`description-${index}`" hide-header ok-only>
                    次のような場合には、こちらの面積変更ツールをご利用ください。<br>
                    <span v-if="isExpiration">※面積変更ツールは有料版でご利用になれます<br></span>
                    <ul>
                      <li>STEP2の測定方法（面積をもとに測定・距離をもとに測定・縮尺をもとに測定）の際に面積をもとに測定を選択しなかった場合</li>
                      <li>STEP2の測定後に補正機能により対象地の面積を補正しなかった場合</li>
                      <li>STEP3において複数の測量図を合成した後に、頂点削除等により対象地の面積が変更された場合</li>
                      <li>STEP3において測定範囲の追加を行って対象地の面積が変更された場合</li>
                    </ul>
                    仕様上の留意点は次の通りです。<br>
                    <ol>
                      <li>こちらで評価対象地の面積を変更した場合でも図形の形状は変更されません。</li>
                      <li>こちらで変更した評価対象地の面積は次の数値に反映されます。<br>
                        <ul>
                          <li>計算上の奥行距離＝変更後の評価対象地面積÷間口</li>
                          <li>PDFダウンロード後の評価対象地の面積が変更後の評価対象地の面積になります</li>
                          <li>かげ地割合＝変更後の評価対象地の面積÷想定整形地の面積</li>
                        </ul>
                      </li>
                    </ol>
                  </b-modal>
                </div>
                <!-- 路線価表示欄 -->
                <div class="clearfix pt-3">
                  <table>
                    <tr>
                      <th>地区区分</th>
                      <th>路線種別</th>
                      <th>始点</th>
                      <th>終点</th>
                      <th>路線価<small>(千円)</small></th>
                      <th style="width: 30px"></th>
                      <th style="width: 30px"></th>
                    </tr>
                    <template v-if="listMR[index].listRLP.length > 0">
                    <tr v-for="(routePrice, rIndex) in range.routePrices" :key="`${index}-${rIndex}`">
                      <td>
                        <div v-if="listMR[index].listRLP[rIndex].info.RLP.districtDivision === undefined"
                          v-b-tooltip.hover.lefttop
                          :title="`${districtDivisionOptions[districtDivision - 1].text}`">
                          {{ (districtDivisionOptions[districtDivision - 1].text).substr(0, 3) }}
                        </div>
                        <div v-else
                          v-b-tooltip.hover.lefttop
                          :title="`${districtDivisionOptions[listMR[index].listRLP[rIndex].info.RLP.districtDivision - 1].text}`">
                          {{ (districtDivisionOptions[listMR[index].listRLP[rIndex].info.RLP.districtDivision - 1].text).substr(0, 3) }}
                        </div>
                      </td>
                      <td class="input-group pr-2">
                        <b-form-select style="width: 90%" v-model="routePrice.routeOption" :options="routeOptions[index]" @change="changeRouteOptions(range.routePrices, rIndex)"/>
                      </td>
                      <td>
                        {{ listMR[index].listRLP[rIndex].info.RLP.frontageVtx.start.name }}
                      </td>
                      <td>
                        {{ listMR[index].listRLP[rIndex].info.RLP.frontageVtx.end.name }}
                      </td>
                      <td>
                        {{ Math.round(listMR[index].listRLP[rIndex].info.RLP.calculatedRLP * 100) / 100 }}
                      </td>
                      <td>
                        <b-btn style="width: 110%" size="sm" variant="outline-primary" @click="$bvModal.show(`confirm-result-${index}-${rIndex}`),setIndexes4RLPinMR(index, rIndex)">!</b-btn>
                        <b-modal size="xl" :id="`confirm-result-${index}-${rIndex}`" @ok="changeAssumedRect" ok-only @shown="makeModalDraggable">
                          <!-- 屈折路の場合の路線価情報モーダル -->
                          <td v-if="listMR[index].listRLP[rIndex].refracting.activate">
                            <div class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;">想定整形地のパターン</div>
                            <div>想定整形地面積が最も小さいものが採用されますが、ユーザーが選択できます。</div>
                            <b-form-select v-model="refractingRlpIndex" :options="getRefractingRLPOptions(index, rIndex)" class="mb-3">
                            </b-form-select>
                            <div class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;">間口情報</div>
                            <tr>
                              <th>不整形地の間口</th>
                              <th style="width: 60px"></th>
                              <th>想定整形地の間口</th>
                              <th style="width: 60px"></th>
                              <th>間口</th>
                            </tr>
                            <tr>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.frontage * 100) / 100}}</td>
                              <td
                                v-if="listMR[index].listRLP[rIndex].info.irregularLand.frontage < listMR[index].listRLP[rIndex].info.assumedLand.frontage"
                                style="font-size: 18px; font-weight: bold; text-align: center"
                              >＜</td>
                              <td v-else style="font-size: 18px; font-weight: bold; text-align: center">＞</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.assumedLand.frontage * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>
                                {{Math.round(listMR[index].listRLP[rIndex].info.RLP.frontage * 100) / 100}}
                              </td>
                            </tr>
                            <div class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;">奥行情報</div>
                            <tr>
                              <th>不整形地の地積</th>
                              <th style="width: 60px"></th>
                              <th>間口</th>
                              <th style="width: 60px"></th>
                              <th>計算上の奥行</th>
                              <th style="width: 60px"></th>
                              <th>想定整形地の奥行</th>
                              <th style="width: 60px"></th>
                              <th>奥行</th>
                              <th style="width: 60px"></th>
                              <th>奥行価格補正率</th>
                            </tr>
                            <tr>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.area * 100) / 100}}</td>
                              <td class="pb-1" style="font-size: 25px; font-weight: bold; text-align: center">÷</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.frontage * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.calculatedDepth * 100) / 100}}</td>
                              <td
                                v-if="listMR[index].listRLP[rIndex].info.irregularLand.calculatedDepth < listMR[index].listRLP[rIndex].info.assumedLand.depth"
                                style="font-size: 18px; font-weight: bold; text-align: center">＜</td>
                              <td v-else style="font-size: 18px; font-weight: bold; text-align: center">＞</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.assumedLand.depth * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.depth * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">→</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.depthPriceCorrectionRate * 100) / 100}}</td>
                            </tr>
                            <div class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;">路線価情報</div>
                            <tr>
                              <th>路線価</th>
                              <th style="width: 60px"></th>
                              <th>奥行価格補正率</th>
                              <th style="width: 60px"></th>
                              <th>路線価の計算結果</th>
                            </tr>
                            <tr>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.inputRLP * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">×</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.depthPriceCorrectionRate * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.calculatedRLP * 100) / 100}}</td>
                            </tr>
                          </td>
                          <!-- 無道路地の場合の路線価情報モーダル -->
                          <td v-else-if="listMR[index].listRLP[rIndex].noneAdjoining.activate">
                            <div class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;">間口情報</div>
                            <tr>
                              <th>不整形地の間口</th>
                              <th style="width: 60px"></th>
                              <th>想定整形地の間口</th>
                              <th style="width: 60px"></th>
                              <th>間口</th>
                            </tr>
                            <tr>
                              <td v-if="isNaN(listMR[index].listRLP[rIndex].info.irregularLand.frontage)">{{listMR[index].listRLP[rIndex].info.irregularLand.frontage}}</td>
                              <td v-else>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.frontage * 100) / 100}}</td>
                              <td style="font-size: 18px; font-weight: bold; text-align: center">＜</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.assumedLand.frontage * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td v-if="isNaN(listMR[index].listRLP[rIndex].info.irregularLand.frontage)">{{listMR[index].listRLP[rIndex].info.irregularLand.frontage}}</td>
                              <td v-else>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.frontage * 100) / 100}}</td>
                            </tr>
                            <div class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;">奥行情報</div>
                            <tr>
                              <th>不整形地の地積</th>
                              <th style="width: 60px"></th>
                              <th>間口</th>
                              <th style="width: 60px"></th>
                              <th>計算上の奥行</th>
                              <th style="width: 60px"></th>
                              <th>想定整形地の奥行</th>
                              <th style="width: 60px"></th>
                              <th>奥行</th>
                              <th style="width: 60px"></th>
                              <th>奥行価格補正率</th>
                            </tr>
                            <tr>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.area * 100) / 100}}</td>
                              <td class="pb-1" style="font-size: 25px; font-weight: bold; text-align: center">÷</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.frontage * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.calculatedDepth * 100) / 100}}</td>
                              <td style="font-size: 18px; font-weight: bold; text-align: center">＜</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.assumedLand.depth * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.assumedLand.depth * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">→</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.depthPriceCorrectionRate * 100) / 100}}</td>
                            </tr>
                            <div class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;">路線価情報</div>
                            <tr>
                              <th>路線価</th>
                              <th style="width: 60px"></th>
                              <th>奥行価格補正率</th>
                              <th style="width: 60px"></th>
                              <th>路線価の計算結果</th>
                            </tr>
                            <tr>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.inputRLP * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">×</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.depthPriceCorrectionRate * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.calculatedRLP * 100) / 100}}</td>
                            </tr>
                          </td>
                          <!-- 普通の場合の路線価情報モーダル -->
                          <td v-else>
                            <div v-show="isShowCutoff === true" class="mt-3">
                              <div style="font-size: 20px; font-weight: bold;">角切情報</div>
                              <span class="modalTitle">正面路線</span>
                              <table class="mb-3">
                                <tr>
                                  <th>始点</th>
                                  <th>終点</th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                                <tr>
                                  <td>頂点{{listMR[index].listRLP[rIndex].cornerCompletion.targetVtx.start.index + 1}}</td>
                                  <td>頂点{{listMR[index].listRLP[rIndex].cornerCompletion.targetVtx.end.index + 1}}</td>
                                </tr>
                              </table>
                              <span class="modalTitle">側面路線1</span>
                              <table class="mb-3">
                                <tr>
                                  <th>始点</th>
                                  <th>終点</th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                                <tr>
                                  <td>頂点{{listMR[index].listRLP[rIndex].cornerCompletion.sideVtx1.start.index + 1}}</td>
                                  <td>頂点{{listMR[index].listRLP[rIndex].cornerCompletion.sideVtx1.end.index + 1}}</td>
                                </tr>
                              </table>
                              <span class="modalTitle">側面路線2</span>
                              <table class="mb-3">
                                <tr>
                                  <th>始点</th>
                                  <th>終点</th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                                <tr>
                                  <td v-if="listMR[index].listRLP[rIndex].cornerCompletion.sideVtx2.start.index !== ''">頂点{{listMR[index].listRLP[rIndex].cornerCompletion.sideVtx2.start.index + 1}}</td>
                                  <td v-else>-</td>
                                  <td v-if="listMR[index].listRLP[rIndex].cornerCompletion.sideVtx2.end.index !== ''">頂点{{listMR[index].listRLP[rIndex].cornerCompletion.sideVtx2.end.index + 1}}</td>
                                  <td v-else>-</td>
                                </tr>
                              </table>
                            </div>
                            <div class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;">間口情報</div>
                            <tr>
                              <th>不整形地の間口</th>
                              <th style="width: 60px"></th>
                              <th>想定整形地の間口</th>
                              <th style="width: 60px"></th>
                              <th>間口</th>
                            </tr>
                            <tr>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.frontage * 100) / 100}}</td>
                              <td
                                v-if="listMR[index].listRLP[rIndex].info.irregularLand.frontage < listMR[index].listRLP[rIndex].info.assumedLand.frontage"
                                style="font-size: 18px; font-weight: bold; text-align: center"
                              >＜</td>
                              <td v-else style="font-size: 18px; font-weight: bold; text-align: center">＞</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.assumedLand.frontage * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>
                                {{Math.round(listMR[index].listRLP[rIndex].info.RLP.frontage * 100) / 100}}
                              </td>
                            </tr>
                            <div class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;">奥行情報</div>
                            <tr>
                              <th>不整形地の地積</th>
                              <th style="width: 60px"></th>
                              <th>間口</th>
                              <th style="width: 60px"></th>
                              <th>計算上の奥行</th>
                              <th style="width: 60px"></th>
                              <th>想定整形地の奥行</th>
                              <th style="width: 60px"></th>
                              <th>奥行</th>
                              <th style="width: 60px"></th>
                              <th>奥行価格補正率</th>
                            </tr>
                            <tr>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.area * 100) / 100}}</td>
                              <td class="pb-1" style="font-size: 25px; font-weight: bold; text-align: center">÷</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.frontage * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.irregularLand.calculatedDepth * 100) / 100}}</td>
                              <td
                                v-if="listMR[index].listRLP[rIndex].info.irregularLand.calculatedDepth < listMR[index].listRLP[rIndex].info.assumedLand.depth"
                                style="font-size: 18px; font-weight: bold; text-align: center">＜</td>
                              <td v-else style="font-size: 18px; font-weight: bold; text-align: center">＞</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.assumedLand.depth * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.depth * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">→</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.depthPriceCorrectionRate * 100) / 100}}</td>
                            </tr>
                            <div class="mt-3 mb-3" style="font-size: 20px; font-weight: bold;">路線価情報</div>
                            <tr>
                              <th>路線価</th>
                              <th style="width: 60px"></th>
                              <th>奥行価格補正率</th>
                              <th style="width: 60px"></th>
                              <th>路線価の計算結果</th>
                            </tr>
                            <tr>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.inputRLP * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">×</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.depthPriceCorrectionRate * 100) / 100}}</td>
                              <td style="font-size: 20px; font-weight: bold; text-align: center">＝</td>
                              <td>{{Math.round(listMR[index].listRLP[rIndex].info.RLP.calculatedRLP * 100) / 100}}</td>
                            </tr>
                          </td>
                          <div class="text-right">
                            <b-button style="margin-left: auto;" class="mt-3 mr-2" @click="$bvModal.show(`depth-price-relationtable`)">補正率を確認する</b-button>
                            <b-button class="mt-3 mr-2" variant="primary" @click="downloadPDF(index, rIndex)">PDFをダウンロード</b-button>
                          </div>
                        </b-modal>
                      </td>
                      <td>
                        <b-btn size="sm" variant="danger" @click="$bvModal.show(`confirm-delete-${rIndex}`)">×</b-btn>
                        <b-modal :id="`confirm-delete-${rIndex}`" hide-header ok-variant="danger" @ok="deleteRoutePrice(rIndex)">
                          <div>路線価を削除します。よろしいですか？</div>
                        </b-modal>
                      </td>
                    </tr>
                    </template>
                  </table>
                </div>
              </div>

              <!-- セットバックの設定 -->
              <div class="justify-content-between align-items-center section-panel p-3">
                <span class="text-left">特定部分の面積測定</span>
                <div class="float-right">
                  <div class="text-right">
                    <b-button class="px-4" size="sm" variant="primary" @click="setCheckMenu();" v-b-modal="`setback-modal-${index}`">追加</b-button>
                  </div>
                  <b-modal :id="`setback-modal-${index}`" title="特定部分の面積測定の追加" hide-footer>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                      <span>面積測定の範囲を選択してください。</span>
                    </div>
                    <b-checkbox-group class="mb-3" v-model="setBackVtxs" @change="setback(setBackVtxs)">
                      <b-checkbox v-for="(v, i) in range.vertexes" :key="i" :value="v">{{ v.name }}</b-checkbox>
                    </b-checkbox-group>
                    <div class="text-right d-flex justify-content-end">
                      <div>
                        <b-button class="mt-3 mr-2" @click="$bvModal.hide(`setback-modal-${index}`)">キャンセル</b-button>
                      </div>
                      <div>
                        <b-button class="mt-3 mr-2" variant="primary" @click="setbackCheck(range); $bvModal.hide(`setback-modal-${index}`)">追加</b-button>
                      </div>
                    </div>
                  </b-modal>
                </div>

                <br clear="all">

                <p class="small" style="margin: 0.5rem 0; padding-left: 1em; text-indent: -1em;">
                  ※頂点を指定することにより、対象地の一部の面積を測定することができます。<br>
                  セットバック、がけ地等の一部の面積を測定する場合にお使いください。
                </p>

                <div class="clearfix pt-3">
                  <span class="text-left">面積<small>(㎡)</small></span>
                  <div v-for="(setback, sIndex) in range.setbacks" :key="`${index}-${sIndex}`">
                    <b-btn class="align-items-center d-flex justify-content-between my-1 rounded-0 w-100" @click="clickSetback(sIndex)">
                      面積{{ sIndex + 1 }}: {{ Math.round(setback.area * 100) / 100 }} ㎡
                      <b-btn size="sm" variant="danger" @click="deleteSetback(sIndex)">×</b-btn>
                    </b-btn>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </template>
      </div>
    </div>
    <div ref="dummyMeter" style="width: 100cm;"></div>
    <b-modal size="xl" :id="`depth-price-relationtable`" ok-only @shown="makeModalDraggable">
      <!-- 奥行き価格補正率表のモーダル -->
      <table border="1" style="border-collapse: collapse">
        <tbody>
          <tr>
            <th valign="top">
              <div align="right">地区区分<br></div>
              <br>
              <div align="left">奥行距離<br>(メートル)&emsp;</div>
            </th>
            <th scope="col"> &emsp;ビル街</th>
            <th scope="col"> &emsp;高度商業&emsp;</th>
            <th scope="col"> &emsp;繁華街&emsp;</th>
            <th scope="col"> &emsp;普通商業<br>&emsp;併用住宅</th>
            <th scope="col"> &emsp;普通住宅&emsp;</th>
            <th scope="col"> &emsp;中小工場&emsp;</th>
            <th scope="col"> &emsp;大工場</th>
          </tr>
          <tr>
            <th align="left" scope="row" valign="top"> &emsp;  4未満</th>
            <td align="center" nowrap="nowrap" rowspan="2" valign="top"> 0.80</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.90</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.90</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.90</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.90</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.85</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.85</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;  4以上6未満</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.92</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.92</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.92</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.92</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.90</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.90</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;  6 〃 &emsp;  8 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.84</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.94</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.95</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.95</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.95</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.93</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.93</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;  8 〃 &emsp;10 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.88</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.96</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.95</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.95</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;10 〃 &emsp;12 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.90</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.98</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.99</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.99</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="5" valign="top"> 1.00</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.96</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.96</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;12 〃 &emsp;14 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.91</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.99</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="5" valign="top"> 1.00</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="6" valign="top"> 1.00</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;14 〃 &emsp;16 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.92</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="9" valign="top"> 1.00</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.98</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.98</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;16 〃 &emsp;20 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.93</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.99</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.99</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;20 〃 &emsp;24 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.94</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="10" valign="top"> 1.00</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="21" valign="top"> 1.00</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;24 〃 &emsp;28 〃</th>
            <td align="center" colspan="1" nowrap="nowrap" valign="top"> 0.95</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;28 〃 &emsp;32 〃</th>
            <td align="center" colspan="1" nowrap="nowrap" valign="top"> 0.96</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.98</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.95</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;32 〃 &emsp;36 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.96</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.93</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;36 〃 &emsp;40 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.98</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.94</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.95</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.92</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;40 〃 &emsp;44 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.99</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.92</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.93</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.91</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;44 〃 &emsp;48 〃</th>
            <td align="center" nowrap="nowrap" rowspan="12" valign="top">1.00</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.90</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.91</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.90</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;48 〃 &emsp;52 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.99</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.88</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.89</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.89</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;52 〃 &emsp;56 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.98</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.87</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.88</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.88</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;56 〃 &emsp;60 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.86</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.87</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.87</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;60 〃 &emsp;64 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.96</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.85</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.86</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.86</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.99</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;64 〃 &emsp;68 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.95</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.84</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.85</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.85</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.98</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;68 〃 &emsp;72 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.94</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.83</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.84</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.84</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;72 〃 &emsp;76 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.93</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.82</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.83</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="2" valign="top"> 0.83</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="2" valign="top"> 0.96</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;76 〃 &emsp;80 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.92</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.81</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.82</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;80 〃 &emsp;84 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.90</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="6" valign="top"> 0.80</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.81</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="2" valign="top"> 0.82</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="2" valign="top"> 0.93</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;84 〃 &emsp;88 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.88</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="5" valign="top"> 0.80</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;88 〃 &emsp;92 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.86</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="3" valign="top"> 0.81</td>
            <td align="center" colspan="1" nowrap="nowrap" rowspan="4" valign="top"> 0.90</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;92 〃 &emsp;96 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.99</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.84</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;96 〃 &emsp;100 〃</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.97</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.82</td>
          </tr>
          <tr>
            <th align="left" nowrap="nowrap" scope="row"> &emsp;100以上</th>
            <td align="center" nowrap="nowrap" valign="top"> 0.95</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.80</td>
            <td align="center" nowrap="nowrap" valign="top"> 0.80</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
    <video-modal v-if="helpUrlVideo" :url="helpUrlVideo"></video-modal>
  </b-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CanvasMixin from '../../mixins/canvas'
import validation from '../../mixins/validation'
import videoWatch from '../../mixins/video_watch'
import constants from '../../constants'
import ChangeTitle from '../../components/ChangeTitle'
import VideoModal from '../../components/VideoModal'
import PlayVideoBtn from '../../components/PlayVideoBtn'
import colors from '../../colors'

const SCALE_STEP = 0.1

export default {
  name: 'Edit2',
  mixins: [CanvasMixin, validation, videoWatch],
  components: {
    ChangeTitle,
    VideoModal,
    PlayVideoBtn
  },
  data: () => {
    return {
      color: colors.color, // これを書いておかないと、colors 変数使っていないとのエラーになる
      districtDivisionOptions: [],
      districtDivision: 0,
      isSelectedDistrictDivision: false, // 地区区分を選択済か否かのフラグ(true: 選択済, false: 未選択)
      canvasWidth: 0,
      canvasHeight: 600,
      canvasContext: undefined,
      vertexes: [],
      scale: 0,
      scaleIndex: 0,
      scaleIndexPDF: 0,
      base64Image: undefined,
      showTwoCanvas: false,
      showPdfCanvas: false,
      image: new Image(),
      imagePDF: new Image(),
      position: { x: 0, y: 0 },
      dragPDF: { dragging: 0, startImagePos: { x: 0, y: 0 }, startMousePos: { x: 0, y: 0 } },
      pdfDragging: false,
      pdfIndex: '',
      imageDrawInfo: {},
      activeTabIndex: 0,
      ranges: [],
      bgReversed: true,
      isImageDisplay: true,
      pdfFiles: [],
      drawing: undefined,
      addSetback: { startVertex: '', endVertex: '', width: '' },
      selectedSetback: -1,
      routeOptions: [[
        { text: '', value: '', disabled: false },
        { text: '正面', value: 'front', disabled: true },
        { text: '側面１', value: 'side1', disabled: false },
        { text: '側面２', value: 'side2', disabled: false },
        { text: '裏面', value: 'back', disabled: false }
      ]],
      updateCanvasCount: 0,
      title: '',
      refractVtxesBuffer: [], // 結節路の連携用(初期値で２件データが必要)
      drag: { dragging: 0, x: 0, y: 0 }, // ドラッグ処理のための座標情報
      startX: 0, // ドラッグ処理のための座標情報。移動前の座標
      startY: 0, // ドラッグ処理のための座標情報。移動前の座標
      // scale4PDF: 1, // 不要
      platArea: '',
      setBackVtxs: [],
      helpUrl: '',
      helpUrlVideo: '',
      needPlayVideo: false,
      isShowDetail: false,
      isShowTouchless: false,
      isShowCutoff: false,
      showRangeInfo: { area: 0, scale: 0 },
      prevScale: '',
      showPdfDropdown: true,
      listMR: {}, // 測定情報のデータ
      recordRLP: {}, // 路線価情報のテンプレ／データバッファ
      openingPassageway: { isPassagewayOpened: false }, // 通路開設に関するテンプレ／データバッファ
      templateRefractingRLP: {}, // 屈折路の路線価情報のテンプレ／データバッファ
      refractingRlpIndex: 0, // 選択された屈折路のインデックス
      indexes4RLPinMR: { index: 0, rIndex: 0 }, // 情報表示モーダルを開いている路線価情報のlistMR内でのインデックス
      isExpiration: false,
      tooltipTitle: ''
    }
  },
  computed: {
    scale () {
      return 1 + this.scaleIndex * SCALE_STEP
    },
    scalePDF () {
      return 1 + this.scaleIndexPDF * SCALE_STEP
    },
    ...mapState({
      rRLP: state => state.routeprice.recordRLP
    }),

    ...mapGetters('routeprice', ['getListMR', 'getRecordMR', 'getRecordRLP', 'getTemplateRefractingRLPs']),

    pixelsPerMeter () {
      return (100000 / 25.4)
    },
    scaledImageSize () {
      return {
        width: this.$refs.canvas.width / this.scale,
        height: this.$refs.canvas.height / this.scale
      }
    },
    scaledImageSizePDF () {
      return {
        width: this.$refs.canvas.width / this.scalePDF,
        height: this.$refs.canvas.height / this.scalePDF
      }
    },
    scaleFactor () {
      return 1 + this.scaleIndex * SCALE_STEP
    }
  },
  mounted () {
    this.$emit('loading', true)

    const username = this.$store.getters['user/user'].username
    const url = `/api/account/stripeExpiration/${username}`
    this.axios.post(url).then(res => {
      this.isExpiration = res.data.isExpiration
      if (this.isExpiration) {
        this.tooltipTitle = '有料版でご利用になれます'
      }
      this.$emit('loading', false)
    }).catch(error => {
      this.$emit('loading', false)
      if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
        this.validated(error.response.data)
      } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
        this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
      }
    })

    // 操作説明のURL取得
    this.axios.get('/api/master/search/help_urls').then(res => {
      const autoPlayedVideos = localStorage.getItem('autoPlayedVideos')
        ? JSON.parse(localStorage.getItem('autoPlayedVideos')) : []

      this.helpUrl = res.data.urls.step4
      this.helpUrlVideo = res.data.urls.step4_video

      if (this.helpUrlVideo && !autoPlayedVideos.includes(this.helpUrlVideo)) {
        this.needPlayVideo = true
        autoPlayedVideos.push(this.helpUrlVideo)
        localStorage.setItem('autoPlayedVideos', JSON.stringify(autoPlayedVideos))
      }
    }).catch(error => {
      this.errors = { error: `システムエラーが発生しました。${error}` }
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })

    this.axios.get(`/api/kagechi/edit2/${this.$route.params.main_id}`).then(res => {
      this.pdfFiles = res.data.pdfs
      this.setShowPdfDropdownFlag()
      this.base64Image = res.data.image
      this.vertexes = res.data.vertexes

      this.scale = res.data.scale
      this.prevScale = res.data.prevScale
      this.drawing = res.data.draw_layers
      this.districtDivisionOptions = res.data.district_divisions
      this.districtDivision = res.data.districtDivision // 旧データ(地区区分選択の路線価モーダル移動前)読込の際に必要
      this.ranges = res.data.ranges
      this.platArea = res.data.plat_image
      this.title = res.data.title
      if (res.data.opening_passageway) {
        this.openingPassageway = res.data.opening_passageway
      }

      // 測定範囲の頂点座標と土地の頂点座標を揃える
      // STEP3で回転操作をすると座標がずれるバグが起こる
      this.ranges.forEach(r => {
        r.vertexes.forEach((v, i) => {
          if (v.name !== undefined) {
            const index = v.name.split('頂点')[1] - 1
            v.x = this.vertexes[index].x
            v.y = this.vertexes[index].y
          } else {
            v.name = `頂点${i + 1}`
          }
        })
      })

      // 測定範囲が設定されている場合
      if (this.ranges.length > 0) {
        this.createRouteOptions()

        // 路線価情報のバッファーを用意
        this.clearListMR() // 初期化
        this.clearRecordRLP() // 初期化
        if (res.data.ranges.length > 0 && res.data.ranges[0].disp) {
          // DBから情報読込（STEP5 → 4）
          this.showRangeInfo.area = Math.round(res.data.ranges[0].disp.data[0].area * 100) / 100
          for (let i = 0; i < res.data.ranges[0].disp.data.length; i++) {
            // ranges[0]にすべての測定範囲と路線価表示情報が入っている
            this.setRecordMR(this.getCopyObject(res.data.ranges[0].disp.data[i])) // 測定範囲レコードの追加
          }
        } else {
          // 新規
          this.refreshMRandRLP()
          for (let index = 0; index < this.ranges.length; index++) {
            let area = this.calcPlatArea(this.ranges[index].vertexes) / Math.pow(this.pixelsPerMeter, 2) * Math.pow(this.scale, 2)
            area = this.openingPassageway.isPassagewayOpened && this.ranges[index].vertexes.length === this.vertexes.length ? area - this.openingPassageway.area : area // 通路開設している場合は通路面積を引いておく
            this.addRecordMR({
              index: index,
              districtDivision: 0,
              vertexes: this.ranges[index].vertexes,
              area: area
            }) // 測定範囲レコードの追加
            if (index === 0) {
              this.showRangeInfo.area = Math.round(area * 100) / 100
            }
          }
        }
        this.refreshMRandRLP() // 情報更新、ローカルのバッファにstoreのデータが入る
      }

      // 測定範囲の距離表示を更新
      this.ranges.forEach(r => {
        const max = r.vertexes.length - 1
        r.vertexes.forEach((v, i) => {
          const prev = i === 0 ? max : i - 1
          v.distance_meter = Math.sqrt(this.calcDistanceDot2Dot(v.x, v.y, r.vertexes[prev].x, r.vertexes[prev].y)) / this.pixelsPerMeter * this.scale
          if (this.openingPassageway.isPassagewayOpened) {
            // const v1Name = this.openingPassageway.passagewayVertexes[1].name
            const v2Name = this.openingPassageway.passagewayVertexes[2].name
            if (v2Name === v.name) {
              v.distance_meter = Math.round(v.distance_meter * 100) / 100 // 通路開設の部分を二桁に丸める
            }
          }
        })
      })

      this.image.onload = () => {
        this.initCanvas()

        const resizeObserver = new ResizeObserver((entries) => {
          this.resizeWindow()
        })
        if (this.$refs.box) {
          resizeObserver.observe(this.$refs.box)
        }
      }
      this.image.src = this.base64Image

      this.$emit('loading', false)

      this.drawing.forEach(line => {
        line.selected = false
      })

      // 無道路地判断
      this.recordRLP.noneAdjoining.activate = this.openingPassageway.isPassagewayOpened
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeWindow)
  },
  watch: {
    bgReversed () {
      this.updateCanvas()
    },
    isImageDisplay () {
      this.updateCanvas()
    },
    activeTabIndex () {
      this.selectedSetback = -1
      if (this.ranges.length > 0) {
        let area = this.calcPlatArea(this.ranges[this.activeTabIndex].vertexes) / Math.pow(this.pixelsPerMeter, 2) * Math.pow(this.scale, 2)
        area = this.openingPassageway.isPassagewayOpened && this.ranges[this.activeTabIndex].vertexes.length === this.vertexes.length ? area - this.openingPassageway.area : area
        this.showRangeInfo.area = Math.round(area * 100) / 100
        this.recordRLP.info.irregularLand.inputArea = this.showRangeInfo.area
        this.updateCanvas()
      }
    },
    refractingRlpIndex () {
      // 選択されたインデックスの路線価内の[chosenOne]を更新にする
      const info = this.listMR[this.indexes4RLPinMR.index].listRLP[this.indexes4RLPinMR.rIndex].info
      const refractingRLP = this.listMR[this.indexes4RLPinMR.index].listRLP[this.indexes4RLPinMR.rIndex].refracting
      refractingRLP.chosenOne = refractingRLP.possibleRLPs[this.refractingRlpIndex].info
      // 不整形地情報を更新
      info.irregularLand = this.getCopyObject(refractingRLP.chosenOne.irregularLand)
      // 想定整形地情報を更新
      info.assumedLand = this.getCopyObject(refractingRLP.chosenOne.assumedLand)
      // 路線価情報
      info.RLP = this.getCopyObject(refractingRLP.chosenOne.RLP)
      info.RLP.calculatedRLP = Math.round(info.RLP.calculatedRLP * 100) / 100
      // this.judgeFront() 屈折路のindexを変更しても、路線価が変更しないため、正面路線再度判断意味ない
      // judgeFrontのranges[index].routePrices[index]を更新、updateCanvas描画するため

      const rlp = this.getCopyObject(this.listMR[this.indexes4RLPinMR.index].listRLP[this.indexes4RLPinMR.rIndex])
      const rangeBuf = {
        // １．想定整形地情報
        assumedArea: rlp.info.assumedLand.area,
        assumedDepthDistance: rlp.info.assumedLand.depth,
        assumedFrontageDistance: rlp.info.assumedLand.frontage,
        assumedVertexes: rlp.info.assumedLand.vertexes,
        // ２．路線価情報
        districtDivision: rlp.info.RLP.districtDivision,
        frontageDistance: rlp.info.RLP.frontage,
        depthDistance: rlp.info.RLP.depth,
        price: rlp.info.RLP.inputRLP,
        correctedPrice: rlp.info.RLP.calculatedRLP,
        routeOption: this.ranges[this.activeTabIndex].routePrices[this.indexes4RLPinMR.rIndex].routeOption,
        isFront: this.ranges[this.activeTabIndex].routePrices[this.indexes4RLPinMR.rIndex].isFront,
        startVertex: rlp.info.RLP.frontageVtx.start,
        endVertex: rlp.info.RLP.frontageVtx.end,
        // ３．屈折路
        refract: false,
        refractNum: 0
      }
      this.ranges[this.activeTabIndex].routePrices[this.indexes4RLPinMR.rIndex] = rangeBuf
      // 再描画
      this.updateCanvas()
      this.mouseMove()
    }
  },
  methods: {
    ...mapActions('routeprice',
      [
        'setRecordMR',
        'addRecordMR',
        'clearListMR',
        'addRecordRLP',
        'clearRecordRLP',
        'clearListRLP'
      ]
    ),
    showToast (message, variant) {
      this.$bvToast.toast(message, {
        noCloseButton: true,
        toaster: 'b-toaster-bottom-center',
        variant: variant || 'secondary',
        autoHideDelay: 3000
      })
    },
    createRouteOptions () {
      const routeOptions = []
      this.ranges.forEach(range => {
        const routeOption = [
          { text: '', value: '', disabled: false },
          { text: '正面', value: 'front', disabled: false },
          { text: '側面１', value: 'side1', disabled: false },
          { text: '側面２', value: 'side2', disabled: false },
          { text: '裏面', value: 'back', disabled: false }
        ]
        let side1 = 0
        let side2 = 0
        let back = 0
        range.routePrices.forEach(routePrice => {
          if (routePrice.routeOption === 'side1') {
            side1 += 1
          }
          if (routePrice.routeOption === 'side2') {
            side2 += 1
          }
          if (routePrice.routeOption === 'back') {
            back += 1
          }
        })
        routeOption.splice(2, 3)
        routeOption.push({ text: '側面１', value: 'side1', disabled: (side1 === 1) })
        routeOption.push({ text: '側面２', value: 'side2', disabled: (side2 === 1) })
        routeOption.push({ text: '裏面', value: 'back', disabled: (back === 1) })
        routeOptions.push(routeOption)
      })
      this.routeOptions = routeOptions
    },
    changeScale (event) {
      // マウスホイールの回転方向を取得（+なら拡大、-なら縮小とする）
      const isZoom = event.wheelDelta > 0
      this.scaleIndex = isZoom ? this.scaleIndex + 1 : this.scaleIndex - 1
      if ((this.scaleIndex * SCALE_STEP) < -1) {
        this.scaleIndex = this.scaleIndex + 1
      }
      this.showRangeInfo.scale = this.scale - (this.scaleIndex * 10)
      /*
      const [x, y] = this.getPointerPosition(event) // マウスポジションを取得
      const deltaX = (x - (this.$refs.box.offsetWidth - 4) / 2) * SCALE_STEP / (this.scaleFactor * (this.scaleFactor - SCALE_STEP))
      const deltaY = (y - this.$refs.box.offsetHeight / 2) * SCALE_STEP / (this.scaleFactor * (this.scaleFactor - SCALE_STEP))
      if (isZoom) {
        this.imageDrawInfo.relativePosition.x += deltaX
        this.imageDrawInfo.relativePosition.y += deltaY
      } else {
        if (this.scaleIndex > 0) {
          this.imageDrawInfo.relativePosition.x -= deltaX
          this.imageDrawInfo.relativePosition.y -= deltaY
        }
      }
      */
      this.updateCanvas()
    },
    selectAll () {
      /*
      const selectedCount = this.ranges[this.activeTabIndex].vertexes.length
      this.ranges[this.activeTabIndex].vertexes = []

      if (this.vertexes.length === selectedCount) {
        this.updateCanvas()
        return
      }
      */
      if (!this.ranges[this.activeTabIndex].isSelectAll) {
        this.ranges[this.activeTabIndex].vertexes = []
        this.updateCanvas()
        return
      }

      this.vertexes.forEach(v => {
        this.ranges[this.activeTabIndex].vertexes.push(v)
      })
      this.updateCanvas()
    },
    selectChangeRangeVertexes (range) {
      this.updateCanvas()
    },
    getRoutePriceVertexOptions (vertexes) {
      const options = []
      if (!this.recordRLP.noneAdjoining.activate) {
        vertexes.forEach((v, i) => {
          options.push({ value: v, text: v.name })
        })
      }
      return options
    },
    // 地区区分リストボックスが変更されたときに実行
    changeDistrictDivision () {
      // 路線価モーダル内の 設定 ボタンを押下可能にする
      this.isSelectedDistrictDivision = true
    },
    changeRouteOptions (routePrices, rIndex) {
      let side1 = 0
      let side2 = 0
      let back = 0
      routePrices.forEach(routePrice => {
        if (routePrice.routeOption === 'side1') {
          side1 += 1
        } else if (routePrice.routeOption === 'side2') {
          side2 += 1
        } else if (routePrice.routeOption === 'back') {
          back += 1
        }
      })
      // 正面路線を指定した場合は、他の路線のisFrontを全てfalseにする
      if (routePrices[rIndex].routeOption === 'front') {
        routePrices.forEach((r, i) => {
          if (i !== rIndex) {
            r.routeOption = ''
            r.isFront = false
          } else {
            r.isFront = true
          }
        })
      }
      this.routeOptions[this.activeTabIndex].splice(2, 3)
      this.routeOptions[this.activeTabIndex].push({ text: '側面１', value: 'side1', disabled: (side1 === 1) })
      this.routeOptions[this.activeTabIndex].push({ text: '側面２', value: 'side2', disabled: (side2 === 1) })
      this.routeOptions[this.activeTabIndex].push({ text: '裏面', value: 'back', disabled: (back === 1) })
      // 自動判定された正面路線を選択路線に強制書き換え
      this.listMR[this.activeTabIndex].listRLP.forEach((r, i) => {
        const frontRoutes = routePrices.filter(rp => rp.isFront)
        if (JSON.stringify(r.info.RLP.frontageVtx.start) === JSON.stringify(frontRoutes[0].startVertex) &&
        JSON.stringify(r.info.RLP.frontageVtx.end) === JSON.stringify(frontRoutes[0].endVertex)) {
          r.isMax = true
        } else {
          r.isMax = false
        }
      })
      this.updateCanvas()
    },
    getSelectVertex (x, y, vertexes) {
      let name = ''
      vertexes.forEach((vertex, index) => {
        if (vertex.x === x && vertex.y === y) {
          name = `頂点${index + 1}`
        }
      })
      return name
    },
    next () {
      this.selectedSetback = -1

      for (const r of this.ranges) {
        // 路線価が設定されていない測定範囲があれば、エラーを返す
        if (r.routePrices.length === 0) {
          const rIdx = this.ranges.indexOf(r)
          this.errors = { error: `測定範囲${rIdx + 1}に路線価が設定されていません。` }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return
        }

        // 面積を小数点以下2桁に四捨五入
        if (r.area !== undefined) {
          r.area = Math.round(r.area * 100) / 100
        }
      }

      this.axios.post(`/api/kagechi/edit2/${this.$route.params.main_id}`,
        { ranges: this.ranges, districtDivision: this.districtDivision, dispData: { data: this.listMR } }).then(res => {
        this.$router.push(`/kagechi/result/${this.$route.params.main_id}`)
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
      })
    },
    resizeWindow () {
      this.showTwoCanvas = false
      this.initCanvas()
    },
    back () {
      this.$router.push({ path: `/kagechi/edit1/${this.$route.params.main_id}`, query: { unityScale: this.scale } })
    },
    setCheckMenu () {
      this.selectedSetback = -1
      this.updateCanvas()
    },
    setShowPdfDropdownFlag () {
      const fileName = this.pdfFiles[0].filename
      this.showPdfDropdown = !(fileName.includes('FreeHand') || fileName.includes('.gpx'))
    },
    openInNewTab (url) {
      window.open(url, '_blank', 'noreferrer')
    },
    getCopyObject (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    // #region マウス系=================================================
    mouseUp (event) {
      this.updateCanvas()
      this.drag.dragging = 0
    },
    mouseDown (event) {
      // canvas内でのマウスポインタの位置
      const [x, y] = this.getPointerPosition(event)
      this.drag = { dragging: 1, x: x, y: y, rx: this.startX, ry: this.startY }
      this.updateCanvas()
    },
    mouseMove (event) {
      // canvas内でのマウスポインタの位置
      const [x, y] = this.getPointerPosition(event)

      if (this.drag.dragging > 0) {
        // ドラッグが開始されていればオブジェクトの座標を更新して再描画
        this.drag.dragging += 1

        this.startX = x - this.drag.x + this.drag.rx
        this.startY = y - this.drag.y + this.drag.ry
        this.imageDrawInfo.relativePosition.x = x - this.drag.x + this.drag.rx
        this.imageDrawInfo.relativePosition.y = y - this.drag.y + this.drag.ry

        this.updateCanvas()
      }
    },
    // #endregion マウス系=================================================
    // #region canvas描画系=================================================
    initCanvas () {
      if (this.$refs.box === undefined) {
        return
      }
      this.canvasWidth = this.$refs.box.offsetWidth - 4 // border分だけマイナスする
      this.canvasHeight = this.canvasWidth / 2

      if (this.$refs.canvas && this.$refs.canvas.width > 0 && this.$refs.box.offsetWidth > 0) {
        this.canvasContext = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
        this.canvasContext.lineWidth = 1

        setTimeout(() => {
          if (this.showPdfCanvas === false) {
            this.updateCanvas()
          }
          if (this.showPdfCanvas === true && this.showTwoCanvas === false) {
            this.closeCanvasPDF()
            this.openCanvasPDF(this.pdfIndex)
            this.canvasUpdatePDF(this.pdfIndex)
            this.showTwoCanvas = true
          }
          this.updateCanvas()
        }, 10)
      } else {
        setTimeout(() => {
          this.initCanvas()
        }, 500)
      }
    },
    updateCanvas () {
      if (this.recordRLP.info.irregularLand.inputArea === '') {
        this.recordRLP.info.irregularLand.inputArea = this.showRangeInfo.area
      }

      if (this.$refs.canvas === undefined || this.canvasContext === undefined) {
        return
      }

      // 真っ白にする
      this.canvasContext.fillStyle = 'white'
      this.canvasContext.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContext.save()

      // 縮尺率を考える
      // const scaleFactor = this.scaleDefault / this.scale
      // マウスポジション取得

      // 画像を描画する
      this.calcImageCenter()
      if (this.isImageDisplay) {
        this.canvasContext.drawImage(
          this.image,
          0,
          0,
          this.image.width,
          this.image.height,
          this.imageDrawInfo.relativePosition.x,
          this.imageDrawInfo.relativePosition.y,
          this.image.width * this.scaleFactor,
          this.image.height * this.scaleFactor
        )
      }

      // 色反転
      if (this.bgReversed) {
        this.colorInversion(this.canvasContext, this.canvasWidth, this.canvasHeight)
      }

      // 頂点を描画する
      const drawRelativePosition = { x: -this.imageDrawInfo.relativePosition.x, y: -this.imageDrawInfo.relativePosition.y }
      this.drawPlat4Zooming(this.canvasContext, this.vertexes, this.scaleFactor, drawRelativePosition, `${colors.platColorRgba(this.bgReversed)}`)

      // 測定範囲を描画する
      this.canvasContext.lineWidth = 2
      this.ranges.forEach((range, index) => {
        if (index === this.activeTabIndex) {
          this.drawPlat4Zooming(this.canvasContext, range.vertexes, this.scaleFactor, drawRelativePosition, 'transparent', '', false, `${colors.rangeOfMeasurementColorRgba(this.bgReversed)}`)
        }
      })

      // 想定整形地を描画する
      this.ranges.forEach((range, index) => {
        if (index === this.activeTabIndex) {
          const frontRoutes = range.routePrices.filter(rp => rp.isFront)
          if (frontRoutes.length > 0) {
            this.drawPlat4Zooming(this.canvasContext, frontRoutes[0].assumedVertexes, this.scaleFactor, drawRelativePosition, 'transparent', 'dash', false, `${colors.assumedPlatsColorRgba(this.bgReversed)}`)
          }
        }
      })

      // 角切描画
      this.listMR[this.activeTabIndex].listRLP.forEach((r, i) => {
        if (r.isMax) {
          this.drawCornerCompletion(drawRelativePosition, r, this.scaleFactor)
        }
      })

      // セットバックを描画する
      this.ranges.forEach((range, index) => {
        if (range.setbacks && index === this.activeTabIndex) {
          range.setbacks.forEach((setback, sIndex) => {
            let setColor = `${colors.setBackColorRgba(this.bgReversed)}`
            if (this.selectedSetback !== -1 && this.selectedSetback === sIndex) {
              setColor = `${colors.selectingLayerColorRgba(this.bgReversed)}`
            }
            this.drawPlat4Zooming(
              this.canvasContext,
              setback.vertexes,
              this.scaleFactor,
              drawRelativePosition,
              setColor,
              '',
              false,
              setColor
            )
          })
        }
      })

      // 通路開設を描画する
      if (this.openingPassageway) {
        this.ranges.forEach((range, index) => {
          if (this.openingPassageway.passagewayVertexes && index === this.activeTabIndex) {
            // 頂点番号で座標更新
            range.vertexes.forEach((v) => {
              this.openingPassageway.passagewayVertexes.forEach((pv) => {
                if (v.name === pv.name) {
                  pv.x = v.x
                  pv.y = v.y
                }
              })
            })
            // 通路開設描画
            this.drawPlat4Zooming(
              this.canvasContext,
              this.openingPassageway.passagewayVertexes,
              this.scaleFactor,
              drawRelativePosition,
              `${colors.roadColorRgba(this.bgReversed)}`,
              '',
              false,
              `${colors.roadColorRgba(this.bgReversed)}`,
              true,
              true
            )
          }
        })
      }

      // 図形を描画する
      this.drawing.forEach(line => {
        if (line.showing === true) {
          // ズーム対応
          this.canvasContext.beginPath()
          this.canvasContext.moveTo(
            line.points.fromX * this.scaleFactor + this.imageDrawInfo.relativePosition.x,
            line.points.fromY * this.scaleFactor + this.imageDrawInfo.relativePosition.y
          )
          this.canvasContext.lineTo(
            line.points.toX * this.scaleFactor + this.imageDrawInfo.relativePosition.x,
            line.points.toY * this.scaleFactor + this.imageDrawInfo.relativePosition.y
          )
          this.canvasContext.lineWidth = line.lineWidth ? line.lineWidth : true
          this.canvasContext.strokeStyle = colors.strokeColor1Rgba(this.bgReversed)
          if (line.type === 'dashed') {
            this.canvasContext.setLineDash([5, 5])
          } else if (line.type === 'dotted') {
            this.canvasContext.setLineDash([2, 3])
          } else if (line.type === 'solid') {
            this.canvasContext.setLineDash([0])
          }
          this.canvasContext.closePath()
          this.canvasContext.stroke()
          this.canvasContext.setLineDash([0])
          this.canvasContext.strokeStyle = '#ffffff'
        }
      })
    },
    clickSetback (index) {
      if (this.selectedSetback !== -1 && index === this.selectedSetback) {
        this.selectedSetback = -1
      } else {
        this.selectedSetback = index
      }

      this.updateCanvas()
    },
    calcImageCenter () {
      this.updateCanvasCount += 1
      const imageCenterX = this.image.width / 2
      const imageCenterY = this.image.height / 2
      const canvasCenterX = (this.$refs.canvas.offsetWidth - 4) / 2
      const canvasCenterY = this.$refs.canvas.offsetHeight / 2

      const relativePositionX = canvasCenterX - imageCenterX
      const relativePositionY = canvasCenterY - imageCenterY

      if (this.imageDrawInfo.relativePosition === undefined) {
        this.imageDrawInfo.relativePosition = { x: relativePositionX, y: relativePositionY }
        this.startX = relativePositionX
        this.startY = relativePositionY
        this.showRangeInfo.scale = this.scale
      }

      // this.drawing.forEach(draw => {
      //   draw.points.toX += relativePositionX
      //   draw.points.toY += relativePositionY
      //   draw.points.fromX += relativePositionX
      //   draw.points.fromY += relativePositionY
      // })
    },
    getPointerPosition (event) {
      // const invertedScaleX = 1 / this.scale4PDF
      // const invertedScaleY = 1 / this.scale4PDF
      const invertedScaleX = 1
      const invertedScaleY = 1

      const transformedX = invertedScaleX * event.offsetX - invertedScaleX * this.scale
      const transformedY = invertedScaleY * event.offsetY - invertedScaleY * this.scale
      return [
        transformedX,
        transformedY
      ]
    },
    getCanvasTopLeftPosition () {
      const rect = this.$refs.canvas.getBoundingClientRect()
      return [rect.left, rect.top]
    },
    displayDistance (sx, sy, ex, ey, dist, relativeX, relativeY) {
      const prevX = (sx + ex) / 2 + relativeX
      const prevY = (sy + ey) / 2 + relativeY
      const textX = prevX + 3
      const textY = prevY + 3
      const meterDist = Math.sqrt(dist) / this.pixelsPerMeter * this.scale
      const textValue = (Math.round(meterDist * 100) / 100) + ''
      const bgHeight = 9 * 1.5 + 5
      const bgWidth = this.canvasContext.measureText(textValue).width + 5
      const bgY = textY - bgHeight
      const bgX = textX - (bgWidth / 2)
      this.canvasContext.fillStyle = 'white'
      this.canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
      this.canvasContext.fillStyle = 'blue'
      this.canvasContext.fillText(textValue, textX, textY)
    },
    drawLine (sx, sy, ex, ey, relativeX, relativeY, width, color, lineDash) {
      this.canvasContext.beginPath()
      this.canvasContext.moveTo(sx - relativeX, sy - relativeY)
      this.canvasContext.lineTo(ex - relativeX, ey - relativeY)
      this.canvasContext.lineWidth = width
      this.canvasContext.strokeStyle = color
      this.canvasContext.setLineDash(lineDash)
      this.canvasContext.closePath()
      this.canvasContext.stroke()
    },
    /* STEP4でのズーム機能に共通関数では対応できなかったので、追加。「変更部分」とコメントがついているところが共通関数との処理の違い */
    drawPlat4Zooming (
      canvasContext,
      vertexes,
      scale,
      relativePosition,
      fillColor,
      lineStyle,
      needVertexRect,
      strokeStyleColor,
      isDefaultColor = true,
      isOpeningPassageWay = false
    ) {
      if (!fillColor) {
        fillColor = `${colors.platColorRgba(isDefaultColor)}`
      }

      if (lineStyle === 'dash') {
        canvasContext.setLineDash([5, 2])
      } else {
        canvasContext.setLineDash([])
      }

      if (needVertexRect !== false) {
        needVertexRect = true
      }

      // 塗りつぶしする
      vertexes.forEach((vertex, vertexIndex) => {
        if (vertexIndex === 0) {
          canvasContext.beginPath()
        }

        // const x = (vertex.x - relativePosition.x) * scale
        // const y = (vertex.y - relativePosition.y) * scale
        const x = vertex.x * scale - relativePosition.x // 変更部分
        const y = vertex.y * scale - relativePosition.y // 変更部分

        if (vertexIndex === 0) {
          canvasContext.moveTo(x, y)
        } else {
          canvasContext.lineTo(x, y)
        }

        if ((vertexIndex + 1) === vertexes.length) {
          canvasContext.closePath()
          if (strokeStyleColor) {
            canvasContext.strokeStyle = strokeStyleColor
          } else {
            canvasContext.strokeStyle = `${colors.strokeColor2Rgba()}`
          }
          canvasContext.fillStyle = fillColor
          canvasContext.fill()
          canvasContext.stroke()
        }
      })

      if (isOpeningPassageWay) {
        return
      }

      // 頂点を打つ・番号表示
      if (needVertexRect) {
        canvasContext.fillStyle = `${colors.strokeColor2Rgba()}`
        canvasContext.font = '11pt Arial'
        canvasContext.textAlign = 'center'
        canvasContext.textBaseline = 'bottom'
        vertexes.forEach((vertex, vertexIndex) => {
          const x = vertex.x * scale - relativePosition.x // 変更部分
          const y = vertex.y * scale - relativePosition.y // 変更部分

          // 頂点番号背景
          const bgHeight = 9 * 1.5 + 5
          const bgWidth = canvasContext.measureText(vertexIndex + 1).width + 5
          // canvasContext.fillRect(x - bgWidth + 1, y - bgHeight - 3.5, bgWidth, bgHeight)
          canvasContext.beginPath()
          canvasContext.fillStyle = 'white'
          if (bgWidth > bgHeight) {
            if (vertexIndex + 1 < 10) {
              canvasContext.arc(x + bgWidth / 10 - bgWidth / 2, y - 5 - bgHeight / 2, bgWidth / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            } else {
              canvasContext.arc(x + bgWidth / 4 - bgWidth / 2, y - 5 - bgHeight / 2, bgWidth / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            }
            canvasContext.fill()
          } else {
            if (vertexIndex + 1 < 10) {
              canvasContext.arc(x + bgWidth / 10 - bgWidth / 2, y - 5 - bgHeight / 2, bgHeight / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            } else {
              canvasContext.arc(x + bgWidth / 4 - bgWidth / 2, y - 5 - bgHeight / 2, bgHeight / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            }
            canvasContext.fill()
          }
          // 頂点
          canvasContext.fillStyle = 'blue'
          canvasContext.fillRect(x - 5, y - 5, 10, 10)
          // 頂点番号
          canvasContext.fillText((vertexIndex + 1) + '', x - 5, y - 5)
        })
      }

      // 距離表示
      vertexes.forEach((vertex, vertexIndex) => {
        const x = vertex.x * scale - relativePosition.x // 変更部
        const y = vertex.y * scale - relativePosition.y // 変更部

        if (vertex.distance_meter && (vertex.showDistance === undefined || vertex.showDistance)) {
          const prevVertex = vertexes[(vertexIndex === 0) ? vertexes.length - 1 : vertexIndex - 1]
          const prevX = prevVertex.x * scale - relativePosition.x // 変更部
          const prevY = prevVertex.y * scale - relativePosition.y // 変更部
          const textX = (x + prevX) / 2
          const textY = (y + prevY) / 2
          const textValue = (Math.round(vertex.distance_meter * 100) / 100) + ''

          const bgHeight = 9 * 1.5 + 5
          const bgWidth = canvasContext.measureText(textValue).width + 5
          const bgY = textY - bgHeight
          const bgX = textX - (bgWidth / 2)

          canvasContext.fillStyle = 'white'
          canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
          canvasContext.fillStyle = 'blue'
          canvasContext.fillText(textValue, textX, textY)
        }
      })
    },
    // #endregion canvas描画系=================================================
    // #region セットバック系=================================================
    deleteSetback (index) {
      if (!confirm('セットバックを削除します。\nよろしいですか？')) {
        return
      }
      this.ranges[this.activeTabIndex].setbacks.splice(index, 1)
      this.updateCanvas()
    },
    setback (range, setbackIndex) {
      this.errors = []
      if (!!Object.keys(this.addSetback.startVertex).length && !!Object.keys(this.addSetback.endVertex).length && this.addSetback.width !== undefined) {
        const copyVertexes = []
        let startVertexIndex
        let endVertexIndex
        range.vertexes.forEach((v, i) => {
          copyVertexes.push({ ...v })

          if (v.x === this.addSetback.startVertex.x && v.y === this.addSetback.startVertex.y) {
            startVertexIndex = i
          }
          if (v.x === this.addSetback.endVertex.x && v.y === this.addSetback.endVertex.y) {
            endVertexIndex = i
          }
        })

        const pixelWidth = this.addSetback.width * this.pixelsPerMeter / this.scale
        this.addSetback.vertexes = this.calcSetback(copyVertexes, startVertexIndex, endVertexIndex, pixelWidth)
        if (this.addSetback.vertexes.length < 3) {
          this.errors = { error: '3点以上を指定して下さい。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return
        }

        this.addSetback.area = this.calcPlatArea(this.addSetback.vertexes) / Math.pow(this.pixelsPerMeter, 2) * Math.pow(this.scale, 2)
        if (setbackIndex !== undefined) {
          range.setbacks.splice(setbackIndex, 0, this.addSetback)
        } else {
          range.setbacks.push(this.addSetback)
        }
        this.updateCanvas()
        this.addSetback = { startVertex: '', endVertex: '', width: '' }
      }
    },
    copyArray (arr) {
      const copy = []
      for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
          copy[i] = this.copyArray(arr[i])
        } else if (typeof arr[i] === 'object' && arr[i] !== null) {
          copy[i] = this.copyObj(arr[i])
        } else {
          copy[i] = arr[i]
        }
      }
      return copy
    },
    copyObj (obj) {
      const copy = {}
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          copy[key] = this.copyArray(obj[key])
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          copy[key] = this.copyObj(obj[key])
        } else {
          copy[key] = obj[key]
        }
      }
      return copy
    },
    setbackCheck (range) {
      this.errors = []
      if (this.setBackVtxs.length > 2) {
        // 頂点ソート
        this.setBackVtxs.sort(function (first, second) {
          const firstVtxNumber = Number(first.name.replace('頂点', ''))
          const secondVtxNumber = Number(second.name.replace('頂点', ''))
          if (firstVtxNumber < secondVtxNumber) {
            return -1
          } else if (firstVtxNumber > secondVtxNumber) {
            return 1
          } else {
            return 0
          }
        })
        this.addSetback = { startVertex: { x: 0, y: 0 }, endVertex: { x: 0, y: 0 }, width: '2' }
        this.addSetback.vertexes = this.copyArray(this.setBackVtxs)
        // 面積計算
        this.addSetback.area = this.calcPlatArea(this.addSetback.vertexes) / Math.pow(this.pixelsPerMeter, 2) * Math.pow(this.scale, 2)
        // 距離計算
        let prevVertex = this.addSetback.vertexes[0]
        this.addSetback.vertexes.forEach((vertex, index) => {
          prevVertex = (index === 0
            ? this.addSetback.vertexes[this.addSetback.vertexes.length - 1]
            : this.addSetback.vertexes[index - 1])

          vertex.distance = this.calcDistance(prevVertex, vertex)
          vertex.distance_meter = (vertex.distance / this.pixelsPerMeter) * this.scale
        })
        range.setbacks.push(this.addSetback)

        this.updateCanvas()

        // 初期化
        this.setBackVtxs = []
        this.addSetback = { startVertex: '', endVertex: '', width: '' }
      } else {
        this.validated({ error: '3点以上を選択してください。' })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    changeSetback (range, setback) {
      this.addSetback.startVertex = setback.endVertex
      this.addSetback.endVertex = setback.startVertex
      this.addSetback.width = setback.width
      let setbackIndex
      range.setbacks.forEach((rangeSetback, index) => {
        if (rangeSetback === setback) {
          range.setbacks.splice(index, 1)
          setbackIndex = index
        }
      })
      this.setback(range, setbackIndex)
    },
    // #endregion セットバック系=================================================
    // #region 路線価追加処理==================================================
    async judgeFront () {
      // possibleRLPs（あり得るパターン）内を路線価でソートして最大のものを取得する
      const rlps = this.getCopyObject(this.listMR[this.activeTabIndex].listRLP)
      let maxIndex = 0 // 最大の路線価のインデックスを保存しておく
      rlps.sort((a, b) => b.info.RLP.frontage - a.info.RLP.frontage) // 同額の路線価が存在した場合、間口の大小で優先度が決まるので先に間口でソートしておく
      rlps.sort((a, b) => b.info.RLP.calculatedRLP - a.info.RLP.calculatedRLP) // 路線価でソート
      // ソートしたので最大フラグ(isMax)をオン、その他についてはオフ
      this.listMR[this.activeTabIndex].listRLP.forEach((r, i) => {
        if (r.info.RLP.calculatedRLP === rlps[0].info.RLP.calculatedRLP) {
          r.isMax = true
          maxIndex = i
        } else {
          r.isMax = false
        }
      })
      const maxRLP = this.listMR[this.activeTabIndex].listRLP[maxIndex]
      // 正面路線フラグを立てる、他についてはオフ
      this.ranges[this.activeTabIndex].routePrices.forEach((r, index) => {
        if (index === maxIndex) {
          const rlp = this.getCopyObject(maxRLP)
          const rangeBuf = {
            // １．想定整形地情報
            assumedArea: rlp.info.assumedLand.area,
            assumedDepthDistance: rlp.info.assumedLand.depth,
            assumedFrontageDistance: rlp.info.assumedLand.frontage,
            assumedVertexes: rlp.info.assumedLand.vertexes,
            // ２．路線価情報
            districtDivision: rlp.info.RLP.districtDivision,
            frontageDistance: rlp.info.RLP.frontage,
            depthDistance: rlp.info.RLP.depth,
            price: rlp.info.RLP.inputRLP,
            correctedPrice: rlp.info.RLP.calculatedRLP,
            routeOption: this.routeOptions[this.activeTabIndex][1].value,
            isFront: true,
            startVertex: rlp.info.RLP.frontageVtx.start,
            endVertex: rlp.info.RLP.frontageVtx.end,
            // ３．屈折路
            refract: false,
            refractNum: 0
          }
          this.ranges[this.activeTabIndex].routePrices[index] = rangeBuf
        } else {
          r.isFront = false
          r.routeOption = this.routeOptions[this.activeTabIndex][0].value
        }
      })
    },
    async addPrice () {
      if (this.recordRLP.noneAdjoining.activate) this.addTouchlessConfig()
      this.errors = []
      // 手動入力の不整形地面積を反映させる
      this.reflectInputIrregularArea()

      // 必要な情報を計算する
      if (this.recordRLP.noneAdjoining.activate) {
        // 入力チェック
        if (this.validateInputNoneAdjoing()) {
          return
        }
        // 無道路地の路線価追加処理
        await this.datas4NoneAdjoining()
      } else if (this.recordRLP.refracting.activate) {
        // 入力チェック
        if (this.validateInputRefracting()) {
          return
        }
        // 屈折路の路線価追加処理
        await this.datas4Refracting()
      } else {
        // 入力チェック
        if (this.validateInputNormal()) {
          return
        }
        // 通常の路線価追加処理
        await this.datas4Normal()
        this.isSelectedDistrictDivision = false // 路線価モーダル内の 追加 ボタンを押下不可能にする
      }
      setTimeout(() => {
        // this.rangesに格納
        this.listMR = this.$store.getters['routeprice/getListMR'] // データ更新
        this.ranges[this.activeTabIndex].area = this.recordRLP.info.irregularLand.area // 測定範囲の面積
        const rlp = this.getCopyObject(this.recordRLP)
        const rangeBuf = {
          // １．想定整形地情報
          assumedArea: rlp.info.assumedLand.area,
          assumedDepthDistance: rlp.info.assumedLand.depth,
          assumedFrontageDistance: rlp.info.assumedLand.frontage,
          assumedVertexes: rlp.info.assumedLand.vertexes,
          // ２．路線価情報
          districtDivision: rlp.info.RLP.districtDivision,
          frontageDistance: rlp.info.RLP.frontage,
          depthDistance: rlp.info.RLP.depth,
          price: rlp.info.RLP.inputRLP,
          correctedPrice: rlp.info.RLP.calculatedRLP,
          routeOption: this.routeOptions[this.activeTabIndex][0].value,
          isFront: false,
          startVertex: rlp.info.RLP.frontageVtx.start,
          endVertex: rlp.info.RLP.frontageVtx.end,
          // ３．屈折路
          refract: false,
          refractNum: 0
        }
        this.ranges[this.activeTabIndex].routePrices.push(rangeBuf)
        // 正面路線の判定処理（judgeFront）
        // recordRLP内の路線価結果でMaxを見つけてきてフラグ立てる
        this.refreshMRandRLP() // 情報更新
        this.judgeFront() // 判定
        this.clearRecord4RLP() // recordRLPのみクリア
        this.updateCanvas() // 画面更新
      }, 500)
    },
    deleteRoutePrice (index) {
      const option = this.ranges[this.activeTabIndex].routePrices[index].routeOption
      if (option === 'side1') {
        this.routeOptions[this.activeTabIndex][1].disabled = false
      } else if (option === 'side2') {
        this.routeOptions[this.activeTabIndex][2].disabled = false
      } else if (option === 'back') {
        this.routeOptions[this.activeTabIndex][3].disabled = false
      }
      // 削除
      this.ranges[this.activeTabIndex].routePrices.splice(index, 1)
      this.listMR[this.activeTabIndex].listRLP.splice(index, 1)
      // store更新
      this.clearListMR()
      this.listMR.forEach((mr) => {
        this.setRecordMR(mr)
      })
      this.refreshMRandRLP() // 情報更新情報更新
      // 正面路線の判定をやり直し
      if (this.ranges[this.activeTabIndex].routePrices.length > 0) {
        this.judgeFront()
      }
      // 画面更新
      this.updateCanvas()
    },
    findMaxRLPinRefractingRLPs () {
      // possibleRLPs（あり得るパターン）内を想定整形地面積でソートして最大のものを取得する
      // this.recordRLP.refracting.possibleRLPs.sort((a, b) => a.info.RLP.calculatedRLP - b.info.RLP.calculatedRLP)
      this.recordRLP.refracting.possibleRLPs.sort((a, b) => a.info.assumedLand.area - b.info.assumedLand.area)
      const maxOne = this.recordRLP.refracting.possibleRLPs[0]
      this.recordRLP.refracting.maxOne = maxOne
      // 不整形地情報を更新
      this.recordRLP.info.irregularLand = this.getCopyObject(maxOne.info.irregularLand)
      // 想定整形地情報を更新
      this.recordRLP.info.assumedLand = this.getCopyObject(maxOne.info.assumedLand)
      // 路線価情報
      this.recordRLP.info.RLP = this.getCopyObject(maxOne.info.RLP)
      this.recordRLP.info.RLP.calculatedRLP = Math.round(this.recordRLP.info.RLP.calculatedRLP * 100) / 100
    },
    refractBufferClear () {
      this.refractVtxesBuffer = [{ startVertex: '', endVertex: '', priceInput: '' }, { startVertex: '', endVertex: '', priceInput: '' }]
    },
    datas4Normal () {
      // １．想定整形地情報
      // 始点終点のインデックス取得
      const startIndex = this.listMR[this.activeTabIndex].vertexes.findIndex(v => this.recordRLP.info.RLP.frontageVtx.start.x === v.x && this.recordRLP.info.RLP.frontageVtx.start.y === v.y)
      const endIndex = this.listMR[this.activeTabIndex].vertexes.findIndex(v => this.recordRLP.info.RLP.frontageVtx.end.x === v.x && this.recordRLP.info.RLP.frontageVtx.end.y === v.y)

      // 想定整形地を作成
      const [assumedVertexes, assumedDepthDistance, assumedFrontageDistance] = this.createAssumedRect(this.listMR[this.activeTabIndex].vertexes, startIndex, endIndex)
      // const assumedArea = this.calcPlatArea(assumedVertexes) / Math.pow(this.pixelsPerMeter, 2) * Math.pow(this.scale, 2)

      // バッファーに格納
      this.recordRLP.info.assumedLand.frontage = Math.round(assumedFrontageDistance / this.pixelsPerMeter * this.scale * 100) / 100 // 縮尺適応
      this.recordRLP.info.assumedLand.depth = Math.round(assumedDepthDistance / this.pixelsPerMeter * this.scale * 100) / 100 // 縮尺適応
      this.recordRLP.info.assumedLand.area = Math.round(this.recordRLP.info.assumedLand.frontage * this.recordRLP.info.assumedLand.depth * 100) / 100
      this.recordRLP.info.assumedLand.vertexes = assumedVertexes

      // ２．不整形地情報
      let frontageIrregularLand = this.calcDistanceDot2Dot(
        this.recordRLP.info.RLP.frontageVtx.start.x,
        this.recordRLP.info.RLP.frontageVtx.start.y,
        this.recordRLP.info.RLP.frontageVtx.end.x,
        this.recordRLP.info.RLP.frontageVtx.end.y
      )
      frontageIrregularLand = Math.sqrt(frontageIrregularLand) / this.pixelsPerMeter * this.scale // 縮尺適応

      if (this.recordRLP.cornerCompletion.activate) {
        this.calcCutoff(this.ranges[this.activeTabIndex])
      }
      this.recordRLP.info.irregularLand.frontage = this.recordRLP.cornerCompletion.activate ? this.recordRLP.cornerCompletion.frontage : frontageIrregularLand // 屈折路の場合、間口に角切設定を適応
      this.recordRLP.info.irregularLand.area = this.listMR[this.activeTabIndex].area
      this.recordRLP.info.irregularLand.calculatedDepth = this.recordRLP.info.irregularLand.area / Math.min(this.recordRLP.info.assumedLand.frontage, this.recordRLP.info.irregularLand.frontage) // 想定整形地の間口と不整形地の間口の小さい方を採用する→絶対、不整形地の間口になる
      this.recordRLP.info.irregularLand.vertexes = this.listMR[this.activeTabIndex].vertexes
      // ３．路線価情報
      this.recordRLP.info.RLP.frontage = Math.min(this.recordRLP.info.assumedLand.frontage, this.recordRLP.info.irregularLand.frontage) // 想定整形地の間口と不整形地の間口の小さい方を採用する→絶対、不整形地の間口になる
      this.recordRLP.info.RLP.depth = Math.min(this.recordRLP.info.assumedLand.depth, this.recordRLP.info.irregularLand.calculatedDepth) // 計算上の奥行と想定整形地の奥行を比べて小さいほうを奥行とする

      if (isNaN(this.recordRLP.info.RLP.depth)) {
        this.errors = { error: '路線価の設定に誤りがあります。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      // ４．奥行き価格補正率の取得、最終路線価計算
      const params = {
        depth: Math.floor(this.recordRLP.info.RLP.depth),
        division: this.recordRLP.info.RLP.districtDivision
      }
      this.axios.get('/api/master/search/depth_correction', { params }).then(res => {
        this.recordRLP.info.RLP.depthPriceCorrectionRate = res.data.correction_factor // 奥行き価格補正率設定
        this.recordRLP.info.RLP.calculatedRLP = this.recordRLP.info.RLP.inputRLP * res.data.correction_factor // 最終路線価計算
        // this.addRecordRLP({ index: this.activeTabIndex, record: this.getCopyObject(this.recordRLP) }) // 結果を保存
        // state.listMRに追加する前に、一度同期する
        this.clearListMR()
        this.listMR.forEach((mr) => {
          this.setRecordMR(mr)
        })
        this.addRecordRLP({ index: this.activeTabIndex, record: JSON.parse(JSON.stringify(this.recordRLP)) }) // 結果を保存
        // this.clearRecord4RLP() // レコードクリア
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.errors = { error: `システムエラーが発生しました。${error}` }
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      })
    },
    getNewTemplate () {
      return JSON.parse(JSON.stringify(this.$store.getters['routeprice/getTemplateRefractingRLPs']))
    },
    clearRecord4RLP () {
      // 追加中の路線価情報をクリア
      this.clearRecordRLP()
      const copy = JSON.parse(JSON.stringify(this.$store.getters['routeprice/getRecordRLP']))
      this.recordRLP = copy
      // 無道路地フラグ設定
      this.recordRLP.noneAdjoining.activate = this.openingPassageway.isPassagewayOpened
    },
    showModal (index) {
      this.$refs[`route-price-modal-${index}`][0].show()
    },
    hideModal (index) {
      this.$refs[`route-price-modal-${index}`][0].hide()
    },
    showOptionModal (index) {
      this.$refs[`route-price-option-modal-${index}`][0].show()
    },
    hideOptionModal (index) {
      this.$refs[`route-price-option-modal-${index}`][0].hide()
    },
    refreshMRandRLP () {
      this.listMR = JSON.parse(JSON.stringify(this.$store.getters['routeprice/getListMR']))
      this.recordRLP = JSON.parse(JSON.stringify(this.$store.getters['routeprice/getRecordRLP']))
      this.recordRLP.noneAdjoining.activate = this.openingPassageway.isPassagewayOpened
      this.templateRefractingRLP = JSON.parse(JSON.stringify(this.$store.getters['routeprice/getTemplateRefractingRLPs']))
      this.refractBufferClear()
    },
    reflectInputIrregularArea () {
      // 手動入力の面積指定があった場合は不整形地の面積に反映
      this.listMR[this.activeTabIndex].area = this.recordRLP.info.irregularLand.inputArea !== '' ? this.recordRLP.info.irregularLand.inputArea : this.listMR[this.activeTabIndex].area
    },
    validateInputNoneAdjoing () {
      let isInvalid = false
      // 入力された路線価チェック
      isInvalid = isInvalid || this.isInvalidInputPrice(this.recordRLP.info.RLP.inputRLP)
      // 入力された面積チェック
      isInvalid = isInvalid || this.isInvalidInputArea(this.recordRLP.info.irregularLand.inputArea)
      return isInvalid
    },
    validateInputRefracting () {
      let isInvalid = false
      // 始点終点情報（一番目以降はstartVertexが空になっている）の補完
      this.refractVtxesBuffer.forEach((rvb, i) => {
        if (i > 0) {
          rvb.startVertex = this.refractVtxesBuffer[i - 1].endVertex
        }
      })
      this.refractVtxesBuffer.forEach(buf => {
        // 入力された路線価チェック
        isInvalid = isInvalid || this.isInvalidInputPrice(buf.priceInput)
        // 選択された頂点チェック
        isInvalid = isInvalid || this.isInvalidSelectedVertexes(buf.startVertex, buf.endVertex)
      })
      // 入力された面積チェック
      isInvalid = isInvalid || this.isInvalidInputArea(this.recordRLP.info.irregularLand.inputArea)
      return isInvalid
    },
    validateInputNormal () {
      let isInvalid = false
      // 入力された路線価チェック
      isInvalid = isInvalid || this.isInvalidInputPrice(this.recordRLP.info.RLP.inputRLP)
      // 入力された面積チェック
      isInvalid = isInvalid || this.isInvalidInputArea(this.recordRLP.info.irregularLand.inputArea)
      // 選択された頂点チェック
      isInvalid = isInvalid || this.isInvalidSelectedVertexes(this.recordRLP.info.RLP.frontageVtx.start, this.recordRLP.info.RLP.frontageVtx.end)
      return isInvalid
    },
    isInvalidInputPrice (price) {
      if (price < 0 || isNaN(price)) {
        this.errors = { error: '無効な路線価が入力されました。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return true
      } else {
        return false
      }
    },
    isInvalidInputArea (area) {
      if (area < 0) {
        this.errors = { error: '無効な面積が入力されました。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return true
      } else {
        return false
      }
    },
    isInvalidSelectedVertexes (start, end) {
      if (start === end) {
        this.errors = { error: '選択した頂点が重複しています。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return true
      } else {
        return false
      }
    },
    // #endregion 路線価追加処理==================================================
    // #region 屈折路==================================================
    datas4Refracting () {
      // 屈折路の可能な路線価情報をthis.refractVtxesBufferからrecordRLPに格納する
      // 必要な数だけテンプレートをセットする
      if (this.recordRLP.refracting.innerOrOuter) {
        // 内接の場合、屈折路の組み合わせとして隣り合う路線のペアが必要になる → 必要な数は屈折回数 +1
        for (let i = 0; i < this.recordRLP.refracting.refractNumber + 1; i++) {
          this.recordRLP.refracting.possibleRLPs.push(this.getNewTemplate())
          this.recordRLP.refracting.possibleRLPs[i].startVtx = this.getCopyObject(this.refractVtxesBuffer[i].startVertex) // 始点情報
          this.recordRLP.refracting.possibleRLPs[i].endVtx = this.getCopyObject(this.refractVtxesBuffer[i].endVertex) // 終点情報
          if (i > 0) {
            this.recordRLP.refracting.possibleRLPs[i].startVtx = this.getCopyObject(this.recordRLP.refracting.possibleRLPs[i - 1].endVtx) // 隣り合う路線のペア
          }
          this.recordRLP.refracting.possibleRLPs[i].index = i // possibleRLPs内でのインデックス
          this.recordRLP.refracting.possibleRLPs[i].priceInput = this.getCopyObject(this.refractVtxesBuffer[i].priceInput) // 入力された路線価
        }
      } else {
        // 外接の場合、屈折路の組み合わせとして隣り合う路線のペアと、両端のペアが必要になる → (必要な数は屈折回数 +1) +1
        for (let i = 0; i <= this.recordRLP.refracting.refractNumber + 1; i++) {
          this.recordRLP.refracting.possibleRLPs.push(this.getNewTemplate())
          if (i === this.recordRLP.refracting.refractNumber + 1) {
            const lastIdx = this.refractVtxesBuffer.length - 1
            this.recordRLP.refracting.possibleRLPs[i].startVtx = this.getCopyObject(this.recordRLP.refracting.possibleRLPs[0].startVtx) // 両端のペア
            this.recordRLP.refracting.possibleRLPs[i].endVtx = this.getCopyObject(this.recordRLP.refracting.possibleRLPs[lastIdx].endVtx) // 両端のペア
            this.recordRLP.refracting.possibleRLPs[i].index = i // possibleRLPs内でのインデックス
            this.recordRLP.refracting.possibleRLPs[i].priceInput = 0 // 路線価
          } else if (i >= 0) {
            this.recordRLP.refracting.possibleRLPs[i].startVtx = this.getCopyObject(this.refractVtxesBuffer[i].startVertex) // 始点情報
            this.recordRLP.refracting.possibleRLPs[i].endVtx = this.getCopyObject(this.refractVtxesBuffer[i].endVertex) // 終点情報
            this.recordRLP.refracting.possibleRLPs[i].index = i // possibleRLPs内でのインデックス
            this.recordRLP.refracting.possibleRLPs[i].priceInput = this.getCopyObject(this.refractVtxesBuffer[i].priceInput) // 入力された路線価
          }
        }
      }
      // 不整形地の間口計算／間口の長さに応じて路線価の加重平均を計算
      let frontageDistance = 0
      let sumOfRLP = 0
      this.recordRLP.refracting.possibleRLPs.forEach((rec, i) => {
        let frontageIrregularLand = this.calcDistanceDot2Dot(
          rec.startVtx.x,
          rec.startVtx.y,
          rec.endVtx.x,
          rec.endVtx.y
        )
        if (!this.recordRLP.refracting.innerOrOuter && i === this.recordRLP.refracting.possibleRLPs.length - 1) {
          // 外接の場合の最後は始点終点の両端をペアとするパターンなので計算の対象外にする
        } else {
          frontageIrregularLand = Math.sqrt(frontageIrregularLand) / this.pixelsPerMeter * this.scale
          frontageDistance += frontageIrregularLand // 間口距離を足しこむ
          // sumOfRLP += this.refractVtxesBuffer[i].priceInput * frontageIrregularLand // 間口×路線価を足しこむ
          sumOfRLP += this.recordRLP.refracting.possibleRLPs[i].priceInput * frontageIrregularLand // 間口×路線価を足しこむ
        }
      })
      sumOfRLP = sumOfRLP / frontageDistance
      this.recordRLP.info.RLP.inputRLP = sumOfRLP

      // 各パターンごとに想定整形地／不整形地／路線価情報を更新する
      this.recordRLP.refracting.possibleRLPs.forEach((rec, i) => {
        // 始点終点のインデックスを取得
        const startIndex = this.listMR[this.activeTabIndex].vertexes.findIndex(v => rec.startVtx.x === v.x && rec.startVtx.y === v.y)
        const endIndex = this.listMR[this.activeTabIndex].vertexes.findIndex(v => rec.endVtx.x === v.x && rec.endVtx.y === v.y)

        // 想定整形地情報
        const [assumedVertexes, assumedDepthDistance, assumedFrontageDistance] = this.createAssumedRect(this.listMR[this.activeTabIndex].vertexes, startIndex, endIndex)
        const assumedArea = this.calcPlatArea(assumedVertexes) / Math.pow(this.pixelsPerMeter, 2) * Math.pow(this.scale, 2)
        // バッファーに格納
        rec.info.assumedLand.frontage = assumedFrontageDistance / this.pixelsPerMeter * this.scale // 縮尺適応
        rec.info.assumedLand.depth = assumedDepthDistance / this.pixelsPerMeter * this.scale // 縮尺適応
        rec.info.assumedLand.area = assumedArea
        rec.info.assumedLand.vertexes = assumedVertexes

        // 不整形地情報
        rec.info.irregularLand.frontage = frontageDistance
        rec.info.irregularLand.area = this.listMR[this.activeTabIndex].area
        rec.info.irregularLand.inputArea = this.recordRLP.info.irregularLand.inputArea
        const area4Calculating = rec.info.irregularLand.inputArea !== '' ? rec.info.irregularLand.inputArea : rec.info.irregularLand.area
        rec.info.irregularLand.calculatedDepth = area4Calculating / Math.min(rec.info.assumedLand.frontage, rec.info.irregularLand.frontage)

        // 路線価情報
        rec.info.RLP.frontage = Math.min(rec.info.assumedLand.frontage, rec.info.irregularLand.frontage) // 想定整形地の間口と不整形地の間口の小さい方を採用する
        rec.info.RLP.depth = Math.min(rec.info.assumedLand.depth, rec.info.irregularLand.calculatedDepth) // 計算上の奥行と想定整形地の奥行を比べて小さいほうを奥行とする
        rec.info.RLP.frontageVtx.start = rec.startVtx
        rec.info.RLP.frontageVtx.end = rec.endVtx
        rec.info.RLP.inputRLP = this.recordRLP.info.RLP.inputRLP
        rec.info.RLP.districtDivision = this.recordRLP.info.RLP.districtDivision
        const params = {
          depth: Math.round(rec.info.RLP.depth),
          division: this.recordRLP.info.RLP.districtDivision
        }
        this.axios.get('/api/master/search/depth_correction', { params }).then(res => {
          rec.info.RLP.depthPriceCorrectionRate = res.data.correction_factor // 奥行き価格補正率設定
          rec.info.RLP.calculatedRLP = rec.info.RLP.inputRLP * res.data.correction_factor // 最終路線価計算
        }).catch(error => {
          if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
            this.errors = { error: `システムエラーが発生しました。${error}` }
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }
        })
      })
      // 最大の路線価を探す
      setTimeout(() => {
        this.findMaxRLPinRefractingRLPs()
        this.addRecordRLP({ index: this.activeTabIndex, record: this.getCopyObject(this.recordRLP) }) // 結果を保存
      }, 500)
    },
    addRefractPoint () {
      // 屈折回数を増やす
      if (this.recordRLP.refracting.refractNumber < 20) {
        this.recordRLP.refracting.refractNumber++
        this.refractVtxesBuffer.push({ startVertex: '', endVertex: '', priceInput: '' })
      }
    },
    removeRefractPoint () {
      // 屈折回数を減らす
      if (this.recordRLP.refracting.refractNumber > 1) {
        this.recordRLP.refracting.refractNumber--
        this.refractVtxesBuffer.pop()
      }
    },
    // 屈折路の想定整形地のパターンを変更する
    changeAssumedRect () {
      this.updateCanvas()
    },
    getRefractingRLPOptions (index, rIndex) {
      const options = []
      const isActivate = this.listMR[index].listRLP[rIndex].refracting.activate
      const possibleRLPs = this.listMR[index].listRLP[rIndex].refracting.possibleRLPs
      if (isActivate) {
        possibleRLPs.forEach((rlp, i) => {
          options.push({ value: i, text: `始点 : ${rlp.startVtx.name} / 終点 : ${rlp.endVtx.name} / 面積 : ${Math.round(rlp.info.assumedLand.area * 100) / 100}` })
        })
      }
      return options
    },
    setIndexes4RLPinMR (index, rIndex) {
      this.indexes4RLPinMR = { index: index, rIndex: rIndex }
    },
    // #endregion 屈折路==================================================
    // #region 無道路地==================================================
    datas4NoneAdjoining () {
      // 不整形地情報
      this.recordRLP.info.irregularLand.frontage = this.openingPassageway.isPassagewayOpened ? this.openingPassageway.frontage : '-'
      this.recordRLP.info.irregularLand.area = this.listMR[this.activeTabIndex].area
      this.recordRLP.info.irregularLand.vertexes = this.listMR[this.activeTabIndex].vertexes
      this.recordRLP.info.irregularLand.calculatedDepth = this.recordRLP.info.irregularLand.inputArea !== '' ? this.recordRLP.info.irregularLand.inputArea / this.recordRLP.info.irregularLand.frontage : this.recordRLP.info.irregularLand.area / this.recordRLP.info.irregularLand.frontage
      this.recordRLP.info.irregularLand.calculatedDepth = isNaN(this.recordRLP.info.irregularLand.calculatedDepth) ? '-' : Math.round(this.recordRLP.info.irregularLand.calculatedDepth * 100) / 100
      this.recordRLP.info.RLP.frontage = this.openingPassageway.isPassagewayOpened ? this.openingPassageway.frontage : '-'
      this.recordRLP.info.RLP.depth = Math.min(this.recordRLP.info.assumedLand.depth, this.recordRLP.info.irregularLand.calculatedDepth)

      // 想定整形地情報 → 編集の必要なし

      // TODO: 前面地についての計測について必要があれば実装

      // 路線価情報 → 奥行き価格補正率の取得、最終路線価計算
      const params = {
        depth: Math.round(this.recordRLP.info.assumedLand.depth),
        division: this.recordRLP.info.RLP.districtDivision
      }
      this.axios.get('/api/master/search/depth_correction', { params }).then(res => {
        this.recordRLP.info.RLP.depthPriceCorrectionRate = res.data.correction_factor // 奥行き価格補正率設定
        this.recordRLP.info.RLP.calculatedRLP = this.recordRLP.info.RLP.inputRLP * res.data.correction_factor // 最終路線価計算
        // this.addRecordRLP({ index: this.activeTabIndex, record: this.getCopyObject(this.recordRLP) }) // 結果を保存
        this.addRecordRLP({ index: this.activeTabIndex, record: JSON.parse(JSON.stringify(this.recordRLP)) }) // 結果を保存
        // this.clearRecord4RLP() // レコードクリア
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.errors = { error: `システムエラーが発生しました。${error}` }
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      })
    },
    getTouchlessRouteOptions (drawing) {
      const options = []
      options.push({ value: undefined, text: 'なし' })
      for (let i = 0; i < drawing.length; i++) {
        options.push({ value: i, text: `路線${i + 1}` })
      }
      return options
    },
    addTouchlessConfig () {
      this.errors = [] // エラーbuf初期化 不要？
      const range = this.listMR[this.activeTabIndex]

      this.recordRLP.noneAdjoining.activate = true // 無道路地フラグオン

      // 再描画
      // this.updateCanvas()

      // 想定整形地を作成
      this.calculateAsasumedLandVertexes(range)

      this.isShowTouchless = false // 無道路地オプション画面を閉じる
    },
    calculateAsasumedLandVertexes (range) {
      // 1.正面路線の傾きを求める
      const frontRoute = this.drawing[this.recordRLP.noneAdjoining.routeIndex]
      // const frontRouteTilt = this.calcTilt(frontRoute.points.fromX - this.imageDrawInfo.relativePosition.x, frontRoute.points.fromY - this.imageDrawInfo.relativePosition.y, frontRoute.points.toX - this.imageDrawInfo.relativePosition.x, frontRoute.points.toY - this.imageDrawInfo.relativePosition.y)
      const frontRouteTilt = this.calcTilt(frontRoute.points.fromX, frontRoute.points.fromY, frontRoute.points.toX, frontRoute.points.toY)
      // 2.法線の傾きを求める
      const verticalTilt = -1 / frontRouteTilt.tilt
      const vertexesFromtouchlessToFront = []
      // 3.各頂点と正面路線の距離等を計算
      // if (range.vertexes.length <= 2) {
      //   this.errors = { error: '測定範囲を指定してください。' }
      //   window.scrollTo({ top: 0, behavior: 'smooth' })
      //   this.touchless = false
      //   this.touchlessRoute.num = undefined
      //   return
      // }
      const frontVertexes = []
      for (let i = 0; i < range.vertexes.length; i++) {
        // 各頂点を通る法線の式を計算
        const x1 = range.vertexes[i].x
        const y1 = range.vertexes[i].y
        const intercept = y1 - verticalTilt * x1
        // 各頂点から正面路線に法線を下した場合の座標を計算
        let crossX = 0
        let crossY = 0
        if (!isFinite(frontRouteTilt.tilt) && isFinite(verticalTilt)) {
          crossX = frontRoute.points.fromX
          crossY = verticalTilt * frontRoute.points.fromX + intercept
        } else if (!isFinite(verticalTilt) && isFinite(frontRouteTilt.tilt)) {
          crossX = x1
          crossY = frontRouteTilt.tilt * x1 + frontRouteTilt.intcpt
        } else {
          crossX = (frontRouteTilt.intcpt - intercept) / (verticalTilt - frontRouteTilt.tilt)
          crossY = verticalTilt * crossX + intercept
        }
        // 法線の長さを取得
        const distToLine = this.calcDistanceDot2Dot(x1, y1, crossX, crossY)
        frontVertexes.push({ dist: distToLine, x: crossX, y: crossY })
      }
      frontVertexes.sort(
        function (first, second) {
          if (first.dist > second.dist) {
            return 1
          } else if (first.dist < second.dist) {
            return -1
          } else {
            return 0
          }
        }
      )
      frontRoute.points.fromX = frontVertexes[0].x
      frontRoute.points.fromY = frontVertexes[0].y
      frontRoute.points.toX = frontVertexes[1].x
      frontRoute.points.toY = frontVertexes[1].y
      for (let i = 0; i < range.vertexes.length; i++) {
        // 各頂点を通る法線の式を計算
        const x1 = range.vertexes[i].x
        const y1 = range.vertexes[i].y
        const intercept = y1 - verticalTilt * x1
        // 各頂点から正面路線に法線を下した場合の座標を計算
        let crossX = 0
        let crossY = 0
        if (!isFinite(frontRouteTilt.tilt) && isFinite(verticalTilt)) {
          crossX = frontRoute.points.fromX
          crossY = verticalTilt * frontRoute.points.fromX + intercept
        } else if (!isFinite(verticalTilt) && isFinite(frontRouteTilt.tilt)) {
          crossX = x1
          crossY = frontRouteTilt.tilt * x1 + frontRouteTilt.intcpt
        } else {
          crossX = (frontRouteTilt.intcpt - intercept) / (verticalTilt - frontRouteTilt.tilt)
          crossY = verticalTilt * crossX + intercept
        }
        // 法線の長さを取得
        const distToLine = this.calcDistanceDot2Dot(x1, y1, crossX, crossY)
        // 交点座標全てを正面路線始点からの距離に変換
        // const distFromStart = this.calcDistanceDot2Dot(frontRoute.points.fromX - this.imageDrawInfo.relativePosition.x, frontRoute.points.fromY - this.imageDrawInfo.relativePosition.y, crossX, crossY)
        // const distFromEnd = this.calcDistanceDot2Dot(frontRoute.points.toX - this.imageDrawInfo.relativePosition.x, frontRoute.points.toY - this.imageDrawInfo.relativePosition.y, crossX, crossY)
        const distFromStart = this.calcDistanceDot2Dot(frontRoute.points.fromX, frontRoute.points.fromY, crossX, crossY)
        const distFromEnd = this.calcDistanceDot2Dot(frontRoute.points.toX, frontRoute.points.toY, crossX, crossY)
        if (distFromEnd !== distFromStart) {
          if (distFromEnd < distFromStart) {
            vertexesFromtouchlessToFront.push({ index: i, crossX: crossX, crossY: crossY, distToLine: distToLine, distFromStart: distFromStart })
          } else {
            vertexesFromtouchlessToFront.push({ index: i, crossX: crossX, crossY: crossY, distToLine: distToLine, distFromStart: -distFromStart })
          }
        }
      }
      // 4.最も近い頂点と最も遠い頂点、一番奥にある頂点を記録
      // 一番奥にある頂点
      vertexesFromtouchlessToFront.sort(
        function (first, second) {
          if (first.distToLine > second.distToLine) {
            return 1
          } else if (first.distToLine < second.distToLine) {
            return -1
          } else {
            return 0
          }
        }
      )
      const depthLength = vertexesFromtouchlessToFront[vertexesFromtouchlessToFront.length - 1].distToLine
      // 最も近い頂点と最も遠い頂点
      vertexesFromtouchlessToFront.sort(
        function (first, second) {
          if (first.distFromStart > second.distFromStart) {
            return 1
          } else if (first.distFromStart < second.distFromStart) {
            return -1
          } else {
            return 0
          }
        }
      )

      let assumedRectVertxes = { front: { x: 0, y: 0 }, rear: { x: 0, y: 0 }, backFront: { x: 0, y: 0 }, backRear: { x: 0, y: 0 }, touchVrtxIdx: { front: 0, frontDist: 0, rear: 0, rearDist: 0 } }
      assumedRectVertxes.front = { x: vertexesFromtouchlessToFront[0].crossX, y: vertexesFromtouchlessToFront[0].crossY }
      assumedRectVertxes.touchVrtxIdx = { front: vertexesFromtouchlessToFront[0].index, frontDist: vertexesFromtouchlessToFront[0].distToLine, rear: vertexesFromtouchlessToFront[vertexesFromtouchlessToFront.length - 1].index, rearDist: vertexesFromtouchlessToFront[vertexesFromtouchlessToFront.length - 1].distToLine }
      assumedRectVertxes.rear = { x: vertexesFromtouchlessToFront[vertexesFromtouchlessToFront.length - 1].crossX, y: vertexesFromtouchlessToFront[vertexesFromtouchlessToFront.length - 1].crossY }
      // 想定整形地の間口の長さ
      const frontLength = this.calcDistanceDot2Dot(vertexesFromtouchlessToFront[0].crossX, vertexesFromtouchlessToFront[0].crossY, vertexesFromtouchlessToFront[vertexesFromtouchlessToFront.length - 1].crossX, vertexesFromtouchlessToFront[vertexesFromtouchlessToFront.length - 1].crossY)
      // 想定整形地を作成(4頂点の座標計算)
      const dx = Math.sqrt(depthLength / (1 + verticalTilt * verticalTilt))
      let dy = Math.sqrt(depthLength * (verticalTilt * verticalTilt) / (1 + verticalTilt * verticalTilt))
      if (!isFinite(verticalTilt)) {
        dy = Math.sqrt(depthLength)
      }
      const directionRect = this.calRectDirection(verticalTilt, dx, dy, assumedRectVertxes) // 頂点の時計回りか反時計回りをチェック
      assumedRectVertxes = this.calcAssumedRectVtx(verticalTilt, dx, dy, directionRect, assumedRectVertxes) // 想定整形地を作成
      let rectVtx = []
      rectVtx.push({ x: assumedRectVertxes.front.x, y: assumedRectVertxes.front.y })
      rectVtx.push({ x: assumedRectVertxes.rear.x, y: assumedRectVertxes.rear.y })
      rectVtx.push({ x: assumedRectVertxes.backRear.x, y: assumedRectVertxes.backRear.y })
      rectVtx.push({ x: assumedRectVertxes.backFront.x, y: assumedRectVertxes.backFront.y })
      // 想定整形地が不整形地を囲っているか(路線に対して逆側になっていないか)チェックする
      let isIn = true
      range.vertexes.forEach(v => {
        if (!this.isInPolygonForAssumedRect(rectVtx, v.x, v.y)) {
          isIn = false
        }
      })
      // 想定整形地が逆側だったら作り直し
      if (!isIn) {
        assumedRectVertxes = this.calcAssumedRectVtx(verticalTilt, -dx, -dy, directionRect, assumedRectVertxes)
        // 座標の入れなおし
        rectVtx = []
        rectVtx.push({ x: assumedRectVertxes.front.x, y: assumedRectVertxes.front.y })
        rectVtx.push({ x: assumedRectVertxes.rear.x, y: assumedRectVertxes.rear.y })
        rectVtx.push({ x: assumedRectVertxes.backRear.x, y: assumedRectVertxes.backRear.y })
        rectVtx.push({ x: assumedRectVertxes.backFront.x, y: assumedRectVertxes.backFront.y })
      }

      // 想定整形地の間口・奥行
      this.recordRLP.info.assumedLand.frontage = Math.sqrt(frontLength) / this.pixelsPerMeter * this.scale
      this.recordRLP.info.assumedLand.depth = Math.sqrt(depthLength) / this.pixelsPerMeter * this.scale
      this.recordRLP.info.assumedLand.area = this.recordRLP.info.assumedLand.frontage * this.recordRLP.info.assumedLand.depth
      this.recordRLP.info.assumedLand.vertexes = rectVtx
    },
    calcAssumedRectVtx (verticalTilt, dx, dy, directionRect, assumedRectVertxes) {
      // 無道路地のための想定整形地の頂点座標を計算
      const v = assumedRectVertxes
      if (verticalTilt > 0) {
        if (directionRect < 0) {
          v.backFront.x = v.front.x + dx
          v.backFront.y = v.front.y + dy
          v.backRear.x = v.rear.x + dx
          v.backRear.y = v.rear.y + dy
        } else {
          v.backFront.x = v.front.x - dx
          v.backFront.y = v.front.y - dy
          v.backRear.x = v.rear.x - dx
          v.backRear.y = v.rear.y - dy
        }
      } else if (verticalTilt === 0) {
        if (directionRect < 0) {
          v.backFront.x = v.front.x
          v.backFront.y = v.front.y + dy
          v.backRear.x = v.rear.x
          v.backRear.y = v.rear.y + dy
        } else {
          v.backFront.x = v.front.x + dx
          v.backFront.y = v.front.y - dy
          v.backRear.x = v.rear.x + dx
          v.backRear.y = v.rear.y - dy
        }
      } else if (verticalTilt < 0) {
        if (directionRect < 0) {
          v.backFront.x = v.front.x - dx
          v.backFront.y = v.front.y + dy
          v.backRear.x = v.rear.x - dx
          v.backRear.y = v.rear.y + dy
        } else {
          v.backFront.x = v.front.x + dx
          v.backFront.y = v.front.y - dy
          v.backRear.x = v.rear.x + dx
          v.backRear.y = v.rear.y - dy
        }
      }
      return v
    },
    calRectDirection (verticalTilt, dx, dy, assumedRectVertxes) {
      // 無道路地の想定整形地作成の頂点の時計回り／反時計回りを判別する
      const v = assumedRectVertxes
      if (verticalTilt > 0) {
        const x1 = v.front.x + dx
        const y1 = v.front.y + dy
        const directionRect = Math.sign((v.rear.x - v.front.x) * (y1 - v.front.y) - (v.rear.y - v.front.y) * (x1 - v.front.x))
        return directionRect
      } else if (verticalTilt === 0) {
        const x1 = v.front.x
        const y1 = v.front.y + dy
        const directionRect = Math.sign((v.rear.x - v.front.x) * (y1 - v.front.y) - (v.rear.y - v.front.y) * (x1 - v.front.x))
        return directionRect
      } else if (verticalTilt < 0) {
        const x1 = v.front.x - dx
        const y1 = v.front.y + dy
        const directionRect = Math.sign((v.rear.x - v.front.x) * (y1 - v.front.y) - (v.rear.y - v.front.y) * (x1 - v.front.x))
        return directionRect
      }
    },
    cancelTouchlessConfig () {
      // TODO: キャンセル動作はこれで良い？
      // this.touchlessRoute = { num: undefined, vertexes: { sx: 0, sy: 0, ex: 0, ey: 0 } }
      this.clearRecordRLP() // 路線価データクリア
      this.isShowTouchless = false
    },
    // 不要関数
    changeTouchlessRoute () {
      this.drawing.forEach(line => {
        line.selected = false
      })
      if (this.touchlessRoute.num !== undefined && this.touchlessRoute.num !== null) {
        this.drawing[this.touchlessRoute.num].selected = true
      }
      this.updateCanvas()
      this.drawing.forEach(line => {
        if (line.showing === true && line.selected) {
          this.canvasContext.beginPath()
          this.canvasContext.moveTo(line.points.fromX, line.points.fromY)
          this.canvasContext.lineTo(line.points.toX, line.points.toY)
          this.canvasContext.lineWidth = 5
          this.canvasContext.strokeStyle = line.color
          if (line.type === 'dashed') {
            this.canvasContext.setLineDash([5, 5])
          } else if (line.type === 'dotted') {
            this.canvasContext.setLineDash([2, 3])
          } else if (line.type === 'solid') {
            this.canvasContext.setLineDash([0])
          }
          this.canvasContext.closePath()
          this.canvasContext.stroke()
          this.canvasContext.setLineDash([0])
          this.canvasContext.strokeStyle = '#ffffff'
          this.canvasContext.lineWidth = 1
        }
      })
    },
    // #endregion 無道路地==================================================
    // #region 角切==================================================
    getCutoffVertexOptions (vertexes) {
      const options = []
      if (!this.recordRLP.noneAdjoining.activate) {
        vertexes.forEach((v, i) => {
          options.push({ value: i, text: v.name })
        })
      }
      return options
    },
    addCutoffConfig () {
      this.errors = []
      const vtxValidationtarget = Math.abs(this.recordRLP.cornerCompletion.targetVtx.start.index - this.recordRLP.cornerCompletion.targetVtx.end.index)
      // 側方路線1は入力必須。なので始点終点の数値チェックをする。trueで正常
      const vtxValidationSide1 = Number.isInteger(this.recordRLP.cornerCompletion.sideVtx1.start.index) && Number.isInteger(this.recordRLP.cornerCompletion.sideVtx1.end.index)
      // 側方路線2は入力自由。なので片方が数値で片方が数値でない時に異常。trueで正常
      const vtxValidationSide2 = Number.isInteger(this.recordRLP.cornerCompletion.sideVtx2.start.index) === Number.isInteger(this.recordRLP.cornerCompletion.sideVtx2.end.index)
      if (isNaN(vtxValidationtarget)) {
        this.errors = { error: '正面路線の頂点を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }
      if (!vtxValidationSide1) {
        this.errors = { error: '側方路線1の頂点を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }
      if (!vtxValidationSide2) {
        this.errors = { error: '側方路線2の頂点を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      this.recordRLP.cornerCompletion.activate = true
      this.isShowCutoff = false
      this.showToast('角切オプションを設定しました。', 'success')
    },
    cancelCutoffConfig () {
      // TODO: キャンセル動作はこれで良い？
      // this.cutoff = { flag: false, from: '', to: '', side1From: '', side1To: '', side2From: '', side2To: '' }
      this.isShowCutoff = false
      // this.dispCutoff.flag = false
      // this.clearRecordRLP() // 路線価データクリア
      this.clearRecord4RLP() // 路線価データクリア
      this.updateCanvas()
    },
    calcCutoff (range) {
      // 角切処理
      // 頂点セット
      const routeStartVtx = range.vertexes[this.recordRLP.cornerCompletion.targetVtx.start.index]
      const routeEndVtx = range.vertexes[this.recordRLP.cornerCompletion.targetVtx.end.index]
      const side1StartVtx = range.vertexes[this.recordRLP.cornerCompletion.sideVtx1.start.index]
      const side1EndVtx = range.vertexes[this.recordRLP.cornerCompletion.sideVtx1.end.index]
      const side2StartVtx = this.recordRLP.cornerCompletion.sideVtx2.start.index !== '' ? range.vertexes[this.recordRLP.cornerCompletion.sideVtx2.start.index] : ''
      const side2EndVtx = this.recordRLP.cornerCompletion.sideVtx2.end.index !== '' ? range.vertexes[this.recordRLP.cornerCompletion.sideVtx2.end.index] : ''

      // 正面路線の指定と、角切での正面路線の指定が一致しなければ角切処理はしない
      let matchRoute = false
      const startIdx = this.recordRLP.cornerCompletion.targetVtx.start.index
      const endIdx = this.recordRLP.cornerCompletion.targetVtx.end.index
      const startMatch = this.recordRLP.info.RLP.frontageVtx.start.name === range.vertexes[startIdx].name
      const endMatch = this.recordRLP.info.RLP.frontageVtx.end.name === range.vertexes[endIdx].name
      if (startMatch && endMatch) {
        matchRoute = true
      } else {
        this.errors = { error: '正面路線を選択してください' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }
      if (matchRoute) {
        // 正面路線の式を求める
        const tiltFront = this.calcTilt(routeStartVtx.x, routeStartVtx.y, routeEndVtx.x, routeEndVtx.y)
        // 角切の路線の式を求める(1)
        const tiltSide1 = this.calcTilt(side1StartVtx.x, side1StartVtx.y, side1EndVtx.x, side1EndVtx.y)
        // 交点を求める(1)
        let crossX1 = 0
        let crossY1 = 0
        if (!isFinite(tiltFront.tilt) && isFinite(tiltSide1.tilt)) {
          crossX1 = routeStartVtx.x
          crossY1 = tiltSide1.tilt * routeStartVtx.x + tiltSide1.intcpt
        } else if (!isFinite(tiltSide1.tilt) && isFinite(tiltFront.tilt)) {
          crossX1 = side1StartVtx.x
          crossY1 = tiltFront.tilt * side1StartVtx.x + tiltFront.intcpt
        } else if (tiltFront.tilt === 0 && tiltSide1.tilt !== 0) {
          crossX1 = (routeStartVtx.y - tiltSide1.intcpt) / tiltSide1.tilt
          crossY1 = routeStartVtx.y
        } else if (tiltSide1.tilt === 0 && tiltFront.tilt !== 0) {
          crossX1 = (side1StartVtx.y - tiltFront.intcpt) / tiltFront.tilt
          crossY1 = side1StartVtx.y
        } else {
          crossX1 = (tiltSide1.intcpt - tiltFront.intcpt) / (tiltFront.tilt - tiltSide1.tilt)
          crossY1 = crossX1 * tiltFront.tilt + tiltFront.intcpt
        }
        // 表示用の座標取得（交点、正面路線で近い方の点、側方路線1で近い方の点の3点）
        const closerVtxSide1From = Math.sqrt(this.calcDistanceDot2Dot(crossX1, crossY1, side1StartVtx.x, side1StartVtx.y)) > Math.sqrt(this.calcDistanceDot2Dot(crossX1, crossY1, side1EndVtx.x, side1EndVtx.y))
        if (closerVtxSide1From) {
          this.recordRLP.cornerCompletion.displayVtx.side1From = side1EndVtx
          this.recordRLP.cornerCompletion.displayVtx.side1 = { x: crossX1, y: crossY1 }
        } else {
          this.recordRLP.cornerCompletion.displayVtx.side1From = side1StartVtx
          this.recordRLP.cornerCompletion.displayVtx.side1 = { x: crossX1, y: crossY1 }
        }
        const closerVtxSide1To = Math.sqrt(this.calcDistanceDot2Dot(crossX1, crossY1, routeStartVtx.x, routeStartVtx.y)) > Math.sqrt(this.calcDistanceDot2Dot(crossX1, crossY1, routeEndVtx.x, routeEndVtx.y))
        if (closerVtxSide1To) {
          this.recordRLP.cornerCompletion.displayVtx.side1To = routeEndVtx
        } else {
          this.recordRLP.cornerCompletion.displayVtx.side1To = routeStartVtx
        }
        if (side2StartVtx !== '' && side2EndVtx !== '') {
          // 角切の路線の式を求める(2)
          const tiltSide2 = this.calcTilt(side2StartVtx.x, side2StartVtx.y, side2EndVtx.x, side2EndVtx.y)
          // 交点を求める(2)
          let crossX2 = 0
          let crossY2 = 0
          if (!isFinite(tiltFront.tilt) && isFinite(tiltSide2.tilt)) {
            crossX2 = routeStartVtx.x
            crossY2 = tiltSide2.tilt * routeStartVtx.x + tiltSide2.intcpt
          } else if (!isFinite(tiltSide2.tilt) && isFinite(tiltFront.tilt)) {
            crossX2 = side2StartVtx.x
            crossY2 = tiltFront.tilt * side2StartVtx.x + tiltFront.intcpt
          } else if (tiltFront.tilt === 0 && tiltSide2.tilt !== 0) {
            crossX2 = (routeStartVtx.y - tiltSide2.intcpt) / tiltSide2.tilt
            crossY2 = routeStartVtx.y
          } else if (tiltSide2.tilt === 0 && tiltFront.tilt !== 0) {
            crossX2 = (side2StartVtx.y - tiltFront.intcpt) / tiltFront.tilt
            crossY2 = side2StartVtx.y
          } else {
            crossX2 = (tiltSide2.intcpt - tiltFront.intcpt) / (tiltFront.tilt - tiltSide2.tilt)
            crossY2 = crossX2 * tiltFront.tilt + tiltFront.intcpt
          }
          // 求めた交点同士の距離を正面路線の長さにする
          const distCutoff = Math.sqrt(this.calcDistanceDot2Dot(crossX1, crossY1, crossX2, crossY2)) / this.pixelsPerMeter * this.scale
          // routePrice.frontageDistance = distCutoff
          this.recordRLP.cornerCompletion.frontage = distCutoff
          // 表示用の座標取得（交点、正面路線で近い方の点、側方路線1で近い方の点の3点）
          const closerVtxSide2From = Math.sqrt(this.calcDistanceDot2Dot(crossX2, crossY2, side2StartVtx.x, side2StartVtx.y)) > Math.sqrt(this.calcDistanceDot2Dot(crossX2, crossY2, side2EndVtx.x, side2EndVtx.y))
          if (closerVtxSide2From) {
            this.recordRLP.cornerCompletion.displayVtx.side2 = { x: crossX2, y: crossY2 }
            this.recordRLP.cornerCompletion.displayVtx.side2From = side2EndVtx
          } else {
            this.recordRLP.cornerCompletion.displayVtx.side2 = { x: crossX2, y: crossY2 }
            this.recordRLP.cornerCompletion.displayVtx.side2From = side2StartVtx
          }
          const closerVtxSide2To = Math.sqrt(this.calcDistanceDot2Dot(crossX2, crossY2, routeStartVtx.x, routeStartVtx.y)) > Math.sqrt(this.calcDistanceDot2Dot(crossX2, crossY2, routeEndVtx.x, routeEndVtx.y))
          if (closerVtxSide2To) {
            this.recordRLP.cornerCompletion.displayVtx.side2To = routeEndVtx
          } else {
            this.recordRLP.cornerCompletion.displayVtx.side2To = routeStartVtx
          }
        } else {
          // 求めた交点同士の距離を正面路線の長さにする
          const closerVtxSide2 = Math.sqrt(this.calcDistanceDot2Dot(crossX1, crossY1, routeStartVtx.x, routeStartVtx.y)) > Math.sqrt(this.calcDistanceDot2Dot(crossX1, crossY1, routeEndVtx.x, routeEndVtx.y))
          if (closerVtxSide2) {
            const distCutoff = Math.sqrt(this.calcDistanceDot2Dot(crossX1, crossY1, routeStartVtx.x, routeStartVtx.y)) / this.pixelsPerMeter * this.scale
            // routePrice.frontageDistance = distCutoff
            this.recordRLP.cornerCompletion.frontage = distCutoff
          } else {
            const distCutoff = Math.sqrt(this.calcDistanceDot2Dot(crossX1, crossY1, routeEndVtx.x, routeEndVtx.y)) / this.pixelsPerMeter * this.scale
            // routePrice.frontageDistance = distCutoff
            this.recordRLP.cornerCompletion.frontage = distCutoff
          }
        }
      }
      // this.dispCutoff.flag = true
    },
    drawCornerCompletion (drawRelativePosition, rlp, scale) {
      const dispCutoff = rlp.cornerCompletion.displayVtx
      // 側方路線１
      this.drawLine(
        dispCutoff.side1.x * scale,
        dispCutoff.side1.y * scale,
        dispCutoff.side1From.x * scale,
        dispCutoff.side1From.y * scale,
        drawRelativePosition.x,
        drawRelativePosition.y,
        2, `${colors.assumedPlatsColorRgba(this.bgReversed)}`, [5, 5])
      this.drawLine(
        dispCutoff.side1.x * scale,
        dispCutoff.side1.y * scale,
        dispCutoff.side1To.x * scale,
        dispCutoff.side1To.y * scale,
        drawRelativePosition.x,
        drawRelativePosition.y,
        2, `${colors.assumedPlatsColorRgba(this.bgReversed)}`, [5, 5])
      this.displayDistance(
        dispCutoff.side1.x * scale,
        dispCutoff.side1.y * scale,
        dispCutoff.side1To.x * scale,
        dispCutoff.side1To.y * scale,
        this.calcDistanceDot2Dot(dispCutoff.side1.x, dispCutoff.side1.y, dispCutoff.side1To.x, dispCutoff.side1To.y),
        -drawRelativePosition.x,
        -drawRelativePosition.y)
      // 側方路線２
      this.drawLine(
        dispCutoff.side2.x * scale,
        dispCutoff.side2.y * scale,
        dispCutoff.side2From.x * scale,
        dispCutoff.side2From.y * scale,
        drawRelativePosition.x,
        drawRelativePosition.y,
        2, `${colors.assumedPlatsColorRgba(this.bgReversed)}`, [5, 5])
      this.drawLine(
        dispCutoff.side2.x * scale,
        dispCutoff.side2.y * scale,
        dispCutoff.side2To.x * scale,
        dispCutoff.side2To.y * scale,
        drawRelativePosition.x,
        drawRelativePosition.y,
        2, `${colors.assumedPlatsColorRgba(this.bgReversed)}`, [5, 5])
      this.displayDistance(
        dispCutoff.side2.x * scale,
        dispCutoff.side2.y * scale,
        dispCutoff.side2To.x * scale,
        dispCutoff.side2To.y * scale,
        this.calcDistanceDot2Dot(dispCutoff.side2.x, dispCutoff.side2.y, dispCutoff.side2To.x, dispCutoff.side2To.y),
        -drawRelativePosition.x,
        -drawRelativePosition.y)
    },
    vertexChanged () {
      if (this.recordRLP.info.RLP.frontageVtx.start) {
        this.recordRLP.cornerCompletion.targetVtx.start.index = this.recordRLP.info.RLP.frontageVtx.start.name.split('頂点')[1] - 1
      }
      if (this.recordRLP.info.RLP.frontageVtx.end) {
        this.recordRLP.cornerCompletion.targetVtx.end.index = this.recordRLP.info.RLP.frontageVtx.end.name.split('頂点')[1] - 1
      }
    },
    cornerVertexChanged () {
      if (this.recordRLP.cornerCompletion.targetVtx.start.index >= 0) {
        this.recordRLP.info.RLP.frontageVtx.start = this.ranges[this.activeTabIndex].vertexes[this.recordRLP.cornerCompletion.targetVtx.start.index]
      }
      if (this.recordRLP.cornerCompletion.targetVtx.end.index >= 0) {
        this.recordRLP.info.RLP.frontageVtx.end = this.ranges[this.activeTabIndex].vertexes[this.recordRLP.cornerCompletion.targetVtx.end.index]
      }
    },
    // #endregion 角切==================================================
    // #region PDF表示canvas用処理=================================================
    openCanvasPDF (index) {
      this.canvasWidth = this.canvasWidth / 2
      this.pdfIndex = index
      this.showPdfCanvas = true
      setTimeout(() => {
        this.canvasUpdatePDF(index)
        // 元の画面を更新
        this.updateCanvas()
        this.scaleIndex = this.scaleIndex + 1
        this.updateCanvas()
        this.scaleIndex = Math.max(0, this.scaleIndex - 1)
        this.updateCanvas()
        this.canvasUpdatePDF(index)
      }, 50)
    },
    canvasUpdatePDF (index) {
      this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
      const scaledSize = this.scaledImageSizePDF
      this.imagePDF.src = this.pdfFiles[index].image
      this.imagePDF.onload = () => {
        this.canvasContextPDF.drawImage(this.imagePDF, this.position.x, this.position.y, scaledSize.width, scaledSize.height,
          0, 0, scaledSize.width * this.scalePDF, scaledSize.height * this.scalePDF)
        this.canvasContextPDF.restore()
      }
    },
    closeCanvasPDF () {
      this.showPdfCanvas = false
      this.canvasWidth = this.canvasWidth * 2
      this.initCanvas()
      setTimeout(() => {
        this.updateCanvas()
        this.scaleIndex = this.scaleIndex + 1
        this.updateCanvas()
        this.scaleIndex = Math.max(0, this.scaleIndex - 1)
        this.updateCanvas()
      }, 10)
    },
    mouseDownPDF () {
      this.pdfDragging = true
      const [x, y] = this.getPointerPosition(event)
      this.dragPDF = {
        dragging: 1,
        startImagePos: { x: this.position.x, y: this.position.y },
        startMousePos: { x: x - this.$refs.canvas.width, y },
        vertexIndexes: []
      }
    },
    mouseUpPDF () {
      this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
      this.canvasContextPDF.fillStyle = 'white'
      this.canvasContextPDF.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContextPDF.save()
      this.canvasUpdatePDF(this.pdfIndex)
      this.pdfDragging = false
    },
    mouseMovePDF () {
      // canvas内でのマウスポインタの位置
      if (this.pdfDragging) {
        this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
        this.canvasContextPDF.fillStyle = 'white'
        this.canvasContextPDF.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
        this.canvasContextPDF.save()
        const [x, y] = this.getPointerPosition(event)
        this.position.x = this.dragPDF.startImagePos.x + (this.dragPDF.startMousePos.x - (x - this.$refs.canvas.width)) / this.scalePDF
        this.position.y = this.dragPDF.startImagePos.y + (this.dragPDF.startMousePos.y - y) / this.scalePDF
        this.position.x = Math.max(0, this.position.x)
        this.position.y = Math.max(0, this.position.y)
      }
      this.canvasUpdatePDF(this.pdfIndex)
    },
    changeScalePDF (event) {
      const isZoom = event.wheelDelta > 0
      this.scaleIndexPDF = isZoom ? this.scaleIndexPDF + 1 : Math.max(-9, this.scaleIndexPDF - 1)
      // 真っ白にする
      this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
      this.canvasContextPDF.fillStyle = 'white'
      this.canvasContextPDF.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContextPDF.save()
      this.canvasUpdatePDF(this.pdfIndex)
    },
    // #endregion PDF表示canvas用処理=================================================
    downloadPDF (index, rIndex) {
      const data = {
        routePriceInfo: this.listMR[index].listRLP[rIndex],
        rIndex: rIndex
      }
      this.axios.post(`/api/kagechi/edit2/download/${this.$route.params.main_id}`, data).then(res => {
        const link = document.createElement('a')
        link.href = res.data.base64Pdf
        link.download = res.data.pdfName
        link.click()
        this.$emit('loading', false)
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
        this.$emit('loading', false)
      })
    },
    makeModalDraggable (event) {
      // ドラッグ可能な状態にするためのマウスイベントの追加
      const modal = event.target
      const modalHeader = modal.querySelector('.modal-header')

      // モーダルのヘッダーにクラスを追加してスタイルを適用
      modalHeader.classList.add('draggable-modal-header')
      modalHeader.style.cursor = 'move'

      let pos1 = 0
      let pos2 = 0
      let pos3 = 0
      let pos4 = 0
      modalHeader.onmousedown = dragMouseDown

      function dragMouseDown (e) {
        e.preventDefault()
        pos3 = e.clientX
        pos4 = e.clientY
        document.onmouseup = closeDragElement
        document.onmousemove = elementDrag
      }

      function elementDrag (e) {
        e.preventDefault()
        pos1 = pos3 - e.clientX
        pos2 = pos4 - e.clientY
        pos3 = e.clientX
        pos4 = e.clientY
        modal.style.top = modal.offsetTop - pos2 + 'px'
        modal.style.left = modal.offsetLeft - pos1 + 'px'
      }

      function closeDragElement () {
        document.onmouseup = null
        document.onmousemove = null
      }
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
  table-layout: fixed;
}
td {
  vertical-align: middle;
  padding-left: 4px;
  padding-right: 4px;
}
.modalTitle {
  font-weight: bold;
}
.icon {
  position: absolute;
  right: auto;
  border-color: #ffffff;
  border: solid 2px;
}
</style>
