<template>
  <div>
    <h4 class="text-center my-4">ログイン</h4>
    <div class="d-flex justify-content-center">
      <div class="col-md-4">
        <b-card>
          <p class="text-danger" v-if="errors.non_field_errors">{{ errors.non_field_errors[0] }}</p>

          <b-form @submit.prevent="onSubmit">
            <b-form-group label="ユーザID/メールアドレス" label-for="username">
              <b-form-input id="username" v-model="username" required :state="isValid(['non_field_errors', 'username'])" autofocus />
              <b-form-invalid-feedback v-if="errors.username" :state="isValid(['non_field_errors', 'username'])">
                {{ errors.username[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="パスワード" label-for="password">
              <b-form-input id="password" v-model="password" required type="password" :state="isValid(['non_field_errors', 'password'])" />
              <b-form-invalid-feedback v-if="errors.password" :state="isValid(['non_field_errors', 'password'])">{{ errors.password[0] }}</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox v-model="auto_login" value="on" unchecked-value="off">自動的にログインする</b-form-checkbox>
            </b-form-group>

            <div class="text-center mb-3">
              <b-button type="submit" variant="primary" pill class="px-4">ログイン</b-button>
            </div>
            <div class="text-center">
              <a href="/password_reset">パスワードを忘れた</a>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '../constants'
import validation from '../mixins/validation'

export default {
  name: 'Login',
  mixins: [validation],
  data () {
    return {
      username: '',
      password: '',
      auto_login: this.$store.getters['user/isAutoLogin']
    }
  },
  mounted () {
    this.$emit('loading', false) // 他画面で表示されたLoadingを非表示にする
    if (this.auto_login && this.$store.getters['user/isLoggedIn']) {
      this.$emit('loading', true)
      this.axios.post('/api/refresh_token', { token: this.$store.getters['user/token'] }).then(res => {
        this.loggedIn(res.data)
      }).catch(error => {
        this.$emit('loading', false)
        console.log(error)
      })
    }
  },
  methods: {
    onSubmit () {
      this.$emit('loading', true)
      const form = { username: this.username, password: this.password }
      this.axios.post('/api/login', form).then(res => {
        this.loggedIn(res.data)
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.$emit('loading', false)
          this.validated(error.response.data)
        }
      })
    },
    loggedIn (responseData) {
      const payload = { token: responseData.token, user: responseData.user, autoLogin: this.auto_login }
      this.$store.dispatch('user/loggedIn', payload)
      this.$router.push(this.$route.query.redirect || '/menu')
      this.$emit('loading', false)
    }
  }
}
</script>

<style scoped>

</style>
