import axios from 'axios'

const state = {
  token: '',
  autoLogin: false,
  user: {}
}

const mutations = {
  setAll (state, value) {
    state.token = value.token
    state.user = value.user
    state.autoLogin = value.autoLogin
  },
  updateToken (state, value) {
    state.token = value
  }
}
const getters = {
  isLoggedIn: state => {
    axios.defaults.headers.common.Authorization = 'JWT ' + state.token
    return !!state.token
  },
  isAutoLogin: state => {
    return state.autoLogin
  },
  token: state => {
    return state.token
  },
  user: state => {
    return state.user
  }
}

const actions = {
  loggedIn ({ commit }, value) {
    commit('setAll', value)
  },
  refreshToken ({ commit }, value) {
    axios.defaults.headers.common.Authorization = 'JWT ' + state.token
    commit('updateToken', value)
  },
  logout ({ commit }) {
    commit('setAll', { token: '', user: {} })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
