<template>
  <b-container fluid>
    <div id="full-width-section" class="align-items-center d-flex justify-content-between mb-4 row">
      <change-title v-model="title" @change-title-error="validated"></change-title>

      <div class="ml-5 col-md-6">
        <ul class="stepFlow">
          <li class="active">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 1</p>トリミング</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 2</p>評価対象地の測定</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 3</p>評価対象地の編集</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 4</p>路線価の設定</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 5</p>測定結果</span>
          </li>
        </ul>
      </div>

      <div class="text-right">
        <b-btn variant="outline-secondary" class="px-5 mr-3" @click="$router.push('/menu')">戻る</b-btn>
        <b-btn variant="primary" class="px-3" @click="next2">
          <div class="btn-2rows text-left">土地選択を完了し<br>トリミングを開始する</div>
        </b-btn>
      </div>
    </div>

    <div>
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>

    <div class="d-flex mb-4">
      <b-button v-if="helpUrl != ''" variant="secondary" @click="openInNewTab(helpUrl)" class="align-items-center d-flex mr-2">
        <span class="align-items-center bg-white d-flex mr-2 rounded-pill" style="width: 18px; height: 12px;">
          <b-icon-book-fill variant="info"/>
        </span>
        詳細説明を見る
      </b-button>
      <play-video-btn v-if="helpUrlVideo"></play-video-btn>
    </div>

    <b-tabs content-class="mt-3">
      <template #tabs-start>
        <li role="presentation" class="align-self-center border-b-3 border-dark-grey border-solid border-t-0 border-x-0 nav-item page-sum-label px-4 py-2">
          ページ数合計：{{files.length}}枚
        </li>
      </template>
      <b-tab v-for="(f, index) in files" :key="index">
        <template #title>{{f.name}}</template>
        <trimming-image-free-hand v-if="isGoogleMapUsed" ref="trimmingImages" :base64-image="f.image" :vertexes="vertexes[index]" />
        <trimming-image v-else ref="trimmingImages" :base64-image="f.image" :vertexes="vertexes[index]" />
      </b-tab>
      <template #tabs-end>
        <li class="grow border-3 border-b-3 border-solid border-dark-grey border-x-0 border-t-0"></li>
      </template>
    </b-tabs>

    <video-modal v-if="helpUrlVideo" :url="helpUrlVideo"></video-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TrimmingImage from '../../components/TrimmingImage'
import TrimmingImageFreeHand from '../../components/TrimmingImageFreeHand'
import ChangeTitle from '../../components/ChangeTitle'
import VideoModal from '../../components/VideoModal'
import PlayVideoBtn from '../../components/PlayVideoBtn'
import CanvasMixin from '../../mixins/canvas'
import GPX from '../../mixins/gpx'
import validation from '../../mixins/validation'
import videoWatch from '../../mixins/video_watch'
import constants from '../../constants'

export default {
  name: 'Trimming',
  mixins: [CanvasMixin, validation, GPX, videoWatch],
  components: {
    TrimmingImage,
    TrimmingImageFreeHand,
    ChangeTitle,
    VideoModal,
    PlayVideoBtn
  },
  data: () => ({
    main: {},
    files: ['init'],
    vertexes: [],
    areaVal: 0,
    helpUrl: '',
    helpUrlVideo: '',
    needPlayVideo: false,
    title: ''
  }),
  computed: {
    ...mapState({
      isGoogleMapUsed: state => state.googleMap.isGoogleMapUsed,
      loadingStatus: state => state.googleMap.loadingStatus,
      mainId: state => state.googleMap.mainId
    })
  },
  watch: {
    loadingStatus () {
      if (this.loadingStatus) {
        this.$emit('loading', true)
      } else {
        this.$emit('loading', false)
      }
    }
  },
  mounted () {
    this.$emit('loading', true)

    // 操作説明のURL取得
    this.axios.get('/api/master/search/help_urls').then(res => {
      const autoPlayedVideos = localStorage.getItem('autoPlayedVideos')
        ? JSON.parse(localStorage.getItem('autoPlayedVideos')) : []

      if (this.isGoogleMapUsed) {
        this.helpUrl = res.data.urls.step1_FreehandWithGoogleMap
        this.helpUrlVideo = res.data.urls.step1_FreehandWithGoogleMap_video
      } else {
        this.helpUrl = res.data.urls.step1_Freehand
        this.helpUrlVideo = res.data.urls.step1_Freehand_video
      }

      if (this.helpUrlVideo && !autoPlayedVideos.includes(this.helpUrlVideo)) {
        this.needPlayVideo = true
        autoPlayedVideos.push(this.helpUrlVideo)
        localStorage.setItem('autoPlayedVideos', JSON.stringify(autoPlayedVideos))
      }
    }).catch(error => {
      this.errors = { error: `システムエラーが発生しました。${error}` }
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })

    window.addEventListener('resize', this.resizeWindow)

    this.axios.get(`/api/kagechi/trimming/${this.$route.params.main_id}`).then(res => {
      this.files = res.data.images
      this.files.forEach(f => {
        const index = res.data.images.indexOf(f)
        this.vertexes.push(res.data.vertexes[index])
      })
      this.title = res.data.title
    })

    this.$emit('loading', false)
  },
  created () {
    const urlId = this.$route.params.main_id
    if (this.mainId !== urlId) {
      this.deleteMarkers()
      this.deleteGSCoordinates()
      this.setMainId(urlId)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeWindow)
  },
  methods: {
    ...mapActions('googleMap',
      [
        'setMainId',
        'deleteMarkers',
        'deleteGSCoordinates'
      ]
    ),

    async next2 () {
      if (this.isGoogleMapUsed) {
        // 1つファイルしかない想定
        this.$refs.trimmingImages[0].updatePlats()
          .then(() => {
            this.next()
          })
      } else {
        this.next()
      }
    },

    next () {
      this.$emit('loading', true)

      this.files.forEach((file, index) => {
        const plats = []
        this.$refs.trimmingImages[index].plats.forEach(p => {
          const plat = { ...p }
          delete plat.visible
          plat.vertexes.forEach((vertex, vIndex) => {
            if (vIndex === 0) {
              vertex.distance = this.calcDistance(plat.vertexes[plat.vertexes.length - 1], vertex)
            } else {
              vertex.distance = this.calcDistance(plat.vertexes[vIndex - 1], vertex)
            }
          })

          plats.push(plat)

          if (p.vertexes.length > 3) {
            // 縮尺計算
            const scale = p.vertexes[1].distance_meter / p.vertexes[1].distance
            // 面積を計算
            this.areaVal = Math.round(this.calcPlatArea(p.vertexes) * Math.pow(scale, 2) * 100) / 100
          }
        })
        file.plats = plats
      })

      this.axios.post(`/api/kagechi/trimming/${this.$route.params.main_id}`, this.files).then(res => {
        this.$emit('loading', false)
        if (this.areaVal > 0) {
          this.$router.push({ path: `/kagechi/correct/${this.$route.params.main_id}`, query: { areaVal: this.areaVal } })
        } else {
          this.$router.push({ path: `/kagechi/correct/${this.$route.params.main_id}` })
        }
      }).catch(error => {
        this.$emit('loading', false)
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
      })
    },
    resizeWindow () {
      if (!this.isGoogleMapUsed) {
        this.$refs.trimmingImages.forEach(component => {
          component.initCanvas()
        })
      }
    },
    openInNewTab (url) {
      window.open(url, '_blank', 'noreferrer')
    }
  }
}
</script>

<style scoped>
#description .btn {
  pointer-events: none;
}
</style>
