import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'
import constants from '../constants'
import Login from '../views/Login'
import SignupForm from '../views/signup/Form'
import SignupConfirm from '../views/signup/Confirm'
import Checkout from '../views/signup/Checkout'
import CheckoutFromMenu from '../views/signup/CheckoutFromMenu'
import CheckoutSuccess from '../views/signup/Success'
import PasswordResetForm1 from '../views/password_reset/Form1'
import PasswordResetForm2 from '../views/password_reset/Form2'
import Top from '../views/Top'
import Menu from '../views/Menu'
import KagechiUpload from '../views/kagechi/Upload'
import KagechiUploadGPX from '../views/kagechi/UploadGPX'
import KagechiUploadEditGPX from '../views/kagechi/UploadEditGPX'
import KagechiTrimming from '../views/kagechi/Trimming'
import KagechiTrimmingGPX from '../views/kagechi/TrimmingGPX'
import KagechiEditGPX from '../views/kagechi/EditGPX'
import KagechiTrimmingFH from '../views/kagechi/TrimmingFreeHand'
import KagechiCorrect from '../views/kagechi/Correct'
import KagechiEdit1 from '../views/kagechi/Edit1'
import KagechiEdit2 from '../views/kagechi/Edit2'
import KagechiResult from '../views/kagechi/Result'
import KagechiList from '../views/kagechi/List'
import KagechiColorSetting from '../views/kagechi/ColorSetting'
import Download from '../views/kagechi/Download'
import PlatDownload from '../views/kagechi/PlatDownload'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Top', component: Top, meta: { isPublic: true } },
  { path: '/login', name: 'Login', component: Login, meta: { isPublic: true } },
  { path: '/signup', name: 'SignupForm', component: SignupForm, meta: { isPublic: true } },
  { path: '/signup/:id/:token', name: 'SignupConfirm', component: SignupConfirm, meta: { isPublic: true } },
  { path: '/checkout/:id/:token', name: 'Checkout', component: Checkout, meta: { isPublic: true } },
  { path: '/checkout/', name: 'CheckoutFromMenu', component: CheckoutFromMenu, meta: { isPublic: true } },
  { path: '/success/:id/:token', name: 'CheckoutSuccess', component: CheckoutSuccess, meta: { isPublic: true } },
  { path: '/password_reset', name: 'PasswordResetForm1', component: PasswordResetForm1, meta: { isPublic: true } },
  { path: '/password_reset/:id/:token', name: 'PasswordResetForm2', component: PasswordResetForm2, meta: { isPublic: true } },

  { path: '/menu', name: 'Menu', component: Menu },
  { path: '/kagechi/upload', name: 'KagechiUpload', component: KagechiUpload, props: true },
  { path: '/kagechi/uploadGPX', name: 'KagechiUploadGPX', component: KagechiUploadGPX },
  { path: '/kagechi/uploadEditGPX', name: 'KagechiUploadEditGPX', component: KagechiUploadEditGPX },
  { path: '/kagechi/trimming/:main_id', name: 'KagechiTrimming', component: KagechiTrimming },
  { path: '/kagechi/trimmingGPX/:main_id', name: 'KagechiTrimmingGPX', component: KagechiTrimmingGPX },
  { path: '/kagechi/editGPX', name: 'KagechiEditGPX', component: KagechiEditGPX },
  { path: '/kagechi/trimmingFH/:main_id', name: 'KagechiTrimmingFH', component: KagechiTrimmingFH },
  { path: '/kagechi/correct/:main_id', name: 'KagechiCorrect', component: KagechiCorrect },
  { path: '/kagechi/edit1/:main_id', name: 'KagechiEdit1', component: KagechiEdit1 },
  { path: '/kagechi/edit2/:main_id', name: 'KagechiEdit2', component: KagechiEdit2 },
  { path: '/kagechi/result/:main_id', name: 'KagechiResult', component: KagechiResult },
  { path: '/kagechi/list', name: 'KagechiList', component: KagechiList },
  { path: '/kagechi/colorSetting', name: 'KagechiColorSetting', component: KagechiColorSetting },
  { path: '/kagechi/download/:main_id/:count', name: 'Download', component: Download },
  { path: '/kagechi/platDownload/:main_id/:id', name: 'PlatDownload', component: PlatDownload }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('alert/stateUpdate')

  if (to.matched.some(record => !record.meta.isPublic) && !store.getters['user/isLoggedIn']) {
    store.dispatch('alert/show', { variant: 'danger', message: constants.MESSAGE.SESSION_TIMEOUT })
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

export default router
