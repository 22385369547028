import axios from 'axios'
import constants from '../../constants'

const state = {
  coordinates: [],
  loadingStatus: false,
  files: [],
  title: '',
  vertexes: [],
  titleErrorMessages: {},
  edit: false
}

const getters = {
  getVertexesByFileIndex: (state) => (index) => {
    return state.vertexes[index]
  }
}

const mutations = {
  setCoordinates (state, data) {
    state.coordinates = data
  },
  clearCoordinates (state) {
    state.coordinates = []
  },
  setLoadingStatus (state, status) {
    state.loadingStatus = status
  },
  setFileData (state, data) {
    state.files = data.images
    state.title = data.title
    state.vertexes = data.vertexes
    state.edit = false
  },
  updateTitle (state, title) {
    state.title = title
  },
  setTitleErrorMessages (state, messages) {
    state.titleErrorMessages = messages
  },
  setEdit (state, data) {
    state.edit = data
  }
}

const actions = {
  saveCoordinates (context, data) {
    context.commit('setCoordinates', data)
  },
  deleteCoordinates (context) {
    context.commit('clearCoordinates')
  },
  async getFilesData (context, id) {
    context.commit('setLoadingStatus', true)

    const response = await axios.get('/api/kagechi/trimming/' + id)

    context.commit('setFileData', response.data)
    context.commit('setLoadingStatus', false)
  },
  async saveTitle (context, data) {
    await axios.post('/api/kagechi/title/' + data.mainId, data).then(res => {
      context.commit('setTitleErrorMessages', {})
    }).catch(error => {
      if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
        context.commit('setTitleErrorMessages', error.response.data)
      }
      if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
        context.commit('setTitleErrorMessages', constants.MESSAGE.SYSTEM_ERROR)
      }
      context.commit('setEdit', true)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
