<template>
  <b-container fluid>
    <div id="full-width-section" class="align-items-center d-flex justify-content-between mb-4 row">
      <change-title v-model="title" @change-title-error="validated"></change-title>

      <div class="ml-5 col-md-6">
        <ul class="stepFlow">
          <li class="completed">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 1</p>トリミング</span>
          </li>
          <li class="completed">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 2</p>評価対象地の測定</span>
          </li>
          <li class="completed">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 3</p>評価対象地の編集</span>
          </li>
          <li class="completed">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 4</p>路線価の設定</span>
          </li>
          <li class="active">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 5</p>測定結果</span>
          </li>
        </ul>
      </div>

      <div class="text-right">
        <b-btn variant="outline-secondary" class="px-5 mr-3" :disabled="!isPdf && stripeExpiration" @click="$router.push({name: 'KagechiEdit2', params: {mainId: $route.params.main_id}})">戻る</b-btn>
        <b-btn variant="primary" class="px-4" @click="next">
          PDFダウンロード
        </b-btn>
      </div>
    </div>

    <div>
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>

    <div class="align-items-center d-flex mb-4">
      <b-button v-if="helpUrl != ''" v-b-toggle.description variant="secondary" @click="openInNewTab(helpUrl)" class="align-items-center d-flex mr-2">
        <span class="bg-white d-flex align-items-center mr-2 rounded-pill" style="width: 18px; height: 12px;">
          <b-icon-book-fill variant="info"/>
        </span>
        詳細説明を見る
      </b-button>
      <play-video-btn v-if="helpUrlVideo"></play-video-btn>
      <b-button variant="warning" @click="toggleSubPrint()" class="align-items-center d-flex mr-2">
        <span class="d-flex align-items-center mr-2">
          <b-icon-files variant="dark" />
        </span>
        正面路線のみ表示
      </b-button>
    </div>

    <div v-for="(irregularLand, index) in irregularLands" :key="index" class="mb-4">
      <div class="row" v-show="irregularLand.price || subPrint">
        <div class="col-xl-8 col-lg-6 col-12">
          <div ref="boxes">
            <canvas ref="canvases" :width="canvasWidth" :height="canvasHeight" />
          </div>
        </div>

        <div class="col-xl-4 col-lg-6 col-12">
          <b-card class="mb-3">
            <p>印刷前図面プレビュー</p>
            <p class="small mb-3">
              ※印刷図面が表示枠内に納まることをプレビュー画面でご確認ください<br>
              もし表示枠内に納まっていない場合は、縮尺率を変更してご調整ください。
            </p>

            <div class="col-12">
              <b-form-group class="col-12" label="縮尺">
                <b-input-group prepend="１／">
                  <b-form-input class="col-3" max="2000" min="0" type="number" v-model="scaleInput" />
                  <b-input-group-append>
                    <b-btn variant="primary" @click="$bvModal.show(`preview-${index}`);previewCvsUpdate(index)">プレビュー</b-btn>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>

            <p class="small">
              ※印刷で縮尺がズレる場合は？<br>
              ダウンロードされたPDFは、印刷倍率100％で印刷いただくことを想定しております。<br>
              もし印刷物の縮尺が合わない場合は、印刷倍率にてご調整ください。
            </p>

            <b-modal :id="`preview-${index}`" hide-header size="xl" @ok="closePreviewCvsUpdate">
              <div style="text-align:center">
                <canvas id="cvsPreview" :width="canvasWidth" :height="canvasHeight"/>
              </div>
            </b-modal>
          </b-card>
          <b-tabs>
            <b-tab title="測定結果" class="p-3 mt-3 section-panel">
              <table class="text-left">
                <tr><td>想定整形地</td><td>：&nbsp;{{ irregularLand.assumedArea }}m&sup2;</td></tr>
                <tr><td>間口</td><td>：&nbsp;{{ irregularLand.assumedFrontageDistance }}m</td></tr>
                <tr><td>奥行</td><td>：&nbsp;{{ irregularLand.assumedDepthDistance }}m</td></tr>
                <tr><td>不整形地</td><td>：&nbsp;{{ irregularLand.area }}m&sup2;</td></tr>
                <tr><td>計算上の間口</td><td>：&nbsp;{{ irregularLand.frontageDistance }}m</td></tr>
                <tr><td>計算上の奥行</td><td>：&nbsp;{{ irregularLand.depthDistance }}m</td></tr>
                <tr><td>かげ地割合</td><td>：&nbsp;{{ irregularLand.shadeCut }} %</td></tr>
                <tr v-if="irregularLand.price"><td>路線価(正面)</td><td>：&nbsp;{{ irregularLand.price }}</td></tr>
                <tr v-if="irregularLand.side1Price"><td>路線価(側方)</td><td>：&nbsp;{{ irregularLand.side1Price }}</td></tr>
                <tr v-if="irregularLand.side2Price"><td>路線価(側方)</td><td>：&nbsp;{{ irregularLand.side2Price }}</td></tr>
                <tr v-if="irregularLand.backPrice"><td>路線価(裏面)</td><td>：&nbsp;{{ irregularLand.backPrice }}</td></tr>
                <tr v-if="irregularLand.blankPrice"><td>路線価(－)</td><td>：&nbsp;{{ irregularLand.blankPrice }}</td></tr>
                <tr v-for="(setback, index) in irregularLand.setbacks" :key="`setback-${index}`">
                  <td>セットバック{{ index + 1 }}面積</td><td>：&nbsp;{{ Math.round(setback.area * 100) / 100 }} m&sup2;</td>
                </tr>
              </table>
            </b-tab>
            <b-tab title="表示設定" class="p-3 mt-3 section-panel">
              <div>
                <b-form-group  label="背景色">
                  <b-form-radio-group v-model="bgReversed[index]">
                    <b-form-radio :value="true" class="mr-5">ダークモード</b-form-radio>
                    <b-form-radio :value="false">ライトモード</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div v-if="isPdf">
                <b-form-group label="土地画像">
                  <b-form-radio-group v-model="isImageDisplay[index]">
                    <b-form-radio :value="true" class="mr-5">表示</b-form-radio>
                    <b-form-radio :value="false">非表示</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="頂点番号">
                  <b-form-radio-group v-model="vtxNumber[index]">
                    <b-form-radio :value="true" class="mr-5">表示</b-form-radio>
                    <b-form-radio :value="false">非表示</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="距離">
                  <b-form-radio-group v-model="distance[index]">
                    <b-form-radio :value="true" class="mr-5">表示</b-form-radio>
                    <b-form-radio :value="false">非表示</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="想定整形地">
                  <b-form-radio-group v-model="assumedRect[index]">
                    <b-form-radio :value="true" class="mr-5">表示</b-form-radio>
                    <b-form-radio :value="false">非表示</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="測定対象地">
                  <b-form-radio-group v-model="measurementArea[index]">
                    <b-form-radio :value="true" class="mr-5">表示</b-form-radio>
                    <b-form-radio :value="false">非表示</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="作図">
                  <b-form-radio-group v-model="drawings[index]">
                    <b-form-radio :value="true" class="mr-5">表示</b-form-radio>
                    <b-form-radio :value="false">非表示</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </b-tab>
            <b-tab title="基本情報" class="p-3 mt-3 section-panel">
              <div>
                <b-form-group label="所有者：">
                  <div class="input-group">
                    <b-form-input type="text" v-model="owner[index]"/>
                  </div>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="所在地番：">
                  <div class="input-group">
                    <b-form-input type="text" v-model="address[index]" />
                  </div>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="利用単位の名称：">
                  <div class="input-group">
                    <b-form-input type="text" v-model="partName[index]" />
                  </div>
                </b-form-group>
              </div>
              <div>
                <b-form-group label="備考：">
                  <div class="input-group">
                    <b-form-textarea
                      v-model="textMemo[index]"
                      rows="3"
                      max-rows="6"
                    />
                  </div>
                </b-form-group>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <video-modal v-if="helpUrlVideo" :url="helpUrlVideo"></video-modal>
  </b-container>
</template>

<script>
import CanvasMixin from '../../mixins/canvas'
import constants from '../../constants'
import validation from '../../mixins/validation'
import videoWatch from '../../mixins/video_watch'
import ChangeTitle from '../../components/ChangeTitle'
import VideoModal from '../../components/VideoModal'
import PlayVideoBtn from '../../components/PlayVideoBtn'
import colors from '../../colors'

export default {
  name: 'Result',
  mixins: [CanvasMixin, validation, videoWatch],
  components: {
    ChangeTitle,
    VideoModal,
    PlayVideoBtn
  },
  data: () => {
    return {
      color: colors.color, // これを書いておかないと、colors 変数使っていないとのエラーになる
      canvasWidth: undefined,
      canvasHeight: undefined,
      adjustScale: 1.89,
      canvasContext: [],
      irregularLands: [],
      bgReversed: [],
      isImageDisplay: [],
      drawing: undefined,
      composed_vertexes: undefined,
      imageDrawInfo: {},
      updateCanvasCount: 0,
      helpUrl: '',
      helpUrlVideo: '',
      needPlayVideo: false,
      title: '',
      vtxNumber: [],
      distance: [],
      assumedRect: [],
      assumedRectDist: true,
      measurementArea: [],
      drawings: [],
      scale4Pdf: 1,
      scaleInput: '',
      partName: [],
      textMemo: [],
      owner: [],
      address: [],
      stripeExpiration: false,
      isPdf: false,
      subPrint: true
    }
  },
  computed: {
    pixelsPerMeter () {
      return (100000 / 25.4)
    }
  },
  mounted () {
    this.$emit('loading', true)

    // 操作説明のURL取得
    this.axios.get('/api/master/search/help_urls').then(res => {
      const autoPlayedVideos = localStorage.getItem('autoPlayedVideos')
        ? JSON.parse(localStorage.getItem('autoPlayedVideos')) : []

      this.helpUrl = res.data.urls.step5
      this.helpUrlVideo = res.data.urls.step5_video

      if (this.helpUrlVideo && !autoPlayedVideos.includes(this.helpUrlVideo)) {
        this.needPlayVideo = true
        autoPlayedVideos.push(this.helpUrlVideo)
        localStorage.setItem('autoPlayedVideos', JSON.stringify(autoPlayedVideos))
      }
    }).catch(error => {
      this.errors = { error: `システムエラーが発生しました。${error}` }
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })

    const username = this.$store.getters['user/user'].username
    this.axios.post(`/api/account/stripeExpiration/${username}`).then(res => {
      this.stripeExpiration = res.data.isExpiration
    })

    this.axios.get(`/api/kagechi/result/${this.$route.params.main_id}`).then(res => {
      const tempIrregularLands = res.data.irregularLands
      this.composed_vertexes = res.data.composedVertexes
      this.drawing = res.data.drawLayers
      this.title = res.data.title
      this.scaleInput = res.data.scale
      this.adjustScale = res.data.adjustScale
      this.isPdf = res.data.isPdf

      // 路線価ごとに土地データを分割
      tempIrregularLands.forEach((irregularLand, index) => {
        const frontPrice = irregularLand.route_prices.filter(p => (p.routeOption === 'front'))
        if (frontPrice.length > 0) {
          this.irregularLands.push(JSON.parse(JSON.stringify(irregularLand)))
          const currentData = this.irregularLands[this.irregularLands.length - 1]
          currentData.blankPrice = undefined
          currentData.route_prices = frontPrice
        }

        const side1Price = irregularLand.route_prices.filter(p => (p.routeOption === 'side1'))
        if (side1Price.length > 0) {
          this.irregularLands.push(JSON.parse(JSON.stringify(irregularLand)))
          const currentData = this.irregularLands[this.irregularLands.length - 1]
          currentData.route_prices = side1Price
          currentData.assumedArea = side1Price[0].assumedArea
          currentData.assumed_area = side1Price[0].assumedArea
          currentData.assumedDepthDistance = Math.round(side1Price[0].assumedDepthDistance * 100) / 100
          currentData.assumedFrontageDistance = Math.round(side1Price[0].assumedFrontageDistance * 100) / 100
          currentData.assumed_shape_vertexes = side1Price[0].assumedVertexes
          currentData.depthDistance = Math.round(side1Price[0].depthDistance * 100) / 100
          currentData.frontageDistance = Math.round(side1Price[0].frontageDistance * 100) / 100
          const string4Display = side1Price[0] ? `${Math.round(side1Price[0].price * 100) / 100} × ${Math.round((side1Price[0].correctedPrice / side1Price[0].price) * 100) / 100}（奥行価格補正率）= ${Math.round(side1Price[0].correctedPrice * 100) / 100}` : '--'
          currentData.price = undefined
          currentData.side1Price = string4Display
          currentData.side2Price = undefined
          currentData.backPrice = undefined
          currentData.blankPrice = undefined
          currentData.shadeCut = Math.round(
            (((currentData.assumed_area - currentData.area) / currentData.assumed_area) * 100) * 100) / 100
        }

        const side2Price = irregularLand.route_prices.filter(p => (p.routeOption === 'side2'))
        if (side2Price.length > 0) {
          this.irregularLands.push(JSON.parse(JSON.stringify(irregularLand)))
          const currentData = this.irregularLands[this.irregularLands.length - 1]
          currentData.route_prices = side2Price
          currentData.assumedArea = side2Price[0].assumedArea
          currentData.assumed_area = side2Price[0].assumedArea
          currentData.assumedDepthDistance = Math.round(side2Price[0].assumedDepthDistance * 100) / 100
          currentData.assumedFrontageDistance = Math.round(side2Price[0].assumedFrontageDistance * 100) / 100
          currentData.assumed_shape_vertexes = side2Price[0].assumedVertexes
          currentData.depthDistance = Math.round(side2Price[0].depthDistance * 100) / 100
          currentData.frontageDistance = Math.round(side2Price[0].frontageDistance * 100) / 100
          const string4Display = side2Price[0] ? `${Math.round(side2Price[0].price * 100) / 100} × ${Math.round((side2Price[0].correctedPrice / side2Price[0].price) * 100) / 100}（奥行価格補正率）= ${Math.round(side2Price[0].correctedPrice * 100) / 100}` : '--'
          currentData.price = undefined
          currentData.side1Price = undefined
          currentData.side2Price = string4Display
          currentData.backPrice = undefined
          currentData.blankPrice = undefined
          currentData.shadeCut = Math.round(
            (((currentData.assumed_area - currentData.area) / currentData.assumed_area) * 100) * 100) / 100
        }

        const backPrice = irregularLand.route_prices.filter(p => (p.routeOption === 'back'))
        if (backPrice.length > 0) {
          this.irregularLands.push(JSON.parse(JSON.stringify(irregularLand)))
          const currentData = this.irregularLands[this.irregularLands.length - 1]
          currentData.route_prices = backPrice
          currentData.assumedArea = backPrice[0].assumedArea
          currentData.assumed_area = backPrice[0].assumedArea
          currentData.assumedDepthDistance = Math.round(backPrice[0].assumedDepthDistance * 100) / 100
          currentData.assumedFrontageDistance = Math.round(backPrice[0].assumedFrontageDistance * 100) / 100
          currentData.assumed_shape_vertexes = backPrice[0].assumedVertexes
          currentData.depthDistance = Math.round(backPrice[0].depthDistance * 100) / 100
          currentData.frontageDistance = Math.round(backPrice[0].frontageDistance * 100) / 100
          const string4Display = backPrice[0] ? `${Math.round(backPrice[0].price * 100) / 100} × ${Math.round((backPrice[0].correctedPrice / backPrice[0].price) * 100) / 100}（奥行価格補正率）= ${Math.round(backPrice[0].correctedPrice * 100) / 100}` : '--'
          currentData.price = undefined
          currentData.side1Price = undefined
          currentData.side2Price = undefined
          currentData.backPrice = string4Display
          currentData.blankPrice = undefined
          currentData.shadeCut = Math.round(
            (((currentData.assumed_area - currentData.area) / currentData.assumed_area) * 100) * 100) / 100
        }

        const blankPrices = irregularLand.route_prices.filter(p => (p.routeOption === ''))
        if (blankPrices.length > 0) {
          blankPrices.forEach((blankPrice) => {
            this.irregularLands.push(JSON.parse(JSON.stringify(irregularLand)))
            const currentData = this.irregularLands[this.irregularLands.length - 1]
            currentData.route_prices = [blankPrice]
            currentData.assumedArea = blankPrice.assumedArea
            currentData.assumed_area = blankPrice.assumedArea
            currentData.assumedDepthDistance = Math.round(blankPrice.assumedDepthDistance * 100) / 100
            currentData.assumedFrontageDistance = Math.round(blankPrice.assumedFrontageDistance * 100) / 100
            currentData.assumed_shape_vertexes = blankPrice.assumedVertexes
            currentData.depthDistance = Math.round(blankPrice.depthDistance * 100) / 100
            currentData.frontageDistance = Math.round(blankPrice.frontageDistance * 100) / 100
            const string4Display = blankPrice ? `${Math.round(blankPrice.price * 100) / 100} × ${Math.round((blankPrice.correctedPrice / blankPrice.price) * 100) / 100}（奥行価格補正率）= ${Math.round(blankPrice.correctedPrice * 100) / 100}` : '--'
            currentData.price = undefined
            currentData.side1Price = undefined
            currentData.side2Price = undefined
            currentData.backPrice = undefined
            currentData.blankPrice = string4Display
            currentData.shadeCut = Math.round(
              (((currentData.assumed_area - currentData.area) / currentData.assumed_area) * 100) * 100) / 100
          })
        }
      })

      this.irregularLands.forEach((irregularLand, index) => {
        this.bgReversed.push(false)
        this.isImageDisplay.push(true)
        this.vtxNumber.push(true)
        this.distance.push(true)
        this.assumedRect.push(true)
        this.measurement(irregularLand)
        irregularLand.imageObj = new Image()
        irregularLand.imageObj.src = res.data.base64Image
        irregularLand.imageObj.onload = () => {
          this.initCanvas(irregularLand, index)
        }
        this.partName.push(irregularLand.partName === null || irregularLand.partName === undefined ? '' : JSON.parse(irregularLand.partName))
        this.textMemo.push(irregularLand.textMemo === null || irregularLand.textMemo === undefined ? '' : JSON.parse(irregularLand.textMemo))
        this.owner.push(irregularLand.owner ?? '')
        this.address.push(irregularLand.address ?? '')
      })

      this.$emit('loading', false)
    })
  },
  watch: {
    bgReversed: {
      deep: true,
      handler () {
        this.irregularLands.forEach((irregularLand, index) => {
          if (this.canvasContext[index] !== undefined) {
            this.canvasUpdate(irregularLand, index)
          }
        })
      }
    },
    isImageDisplay: {
      deep: true,
      handler () {
        this.irregularLands.forEach((irregularLand, index) => {
          if (this.canvasContext[index] !== undefined) {
            this.canvasUpdate(irregularLand, index)
          }
        })
      }
    },
    vtxNumber: {
      deep: true,
      handler () {
        this.irregularLands.forEach((irregularLand, index) => {
          if (this.canvasContext[index] !== undefined) {
            this.canvasUpdate(irregularLand, index)
          }
        })
      }
    },
    distance: {
      deep: true,
      handler () {
        this.irregularLands.forEach((irregularLand, index) => {
          if (this.canvasContext[index] !== undefined) {
            this.canvasUpdate(irregularLand, index)
          }
        })
      }
    },
    assumedRect: {
      deep: true,
      handler () {
        this.irregularLands.forEach((irregularLand, index) => {
          if (this.canvasContext[index] !== undefined) {
            this.canvasUpdate(irregularLand, index)
          }
        })
      }
    },
    measurementArea: {
      deep: true,
      handler () {
        this.irregularLands.forEach((irregularLand, index) => {
          if (this.canvasContext[index] !== undefined) {
            this.canvasUpdate(irregularLand, index)
          }
        })
      }
    },
    drawings: {
      deep: true,
      handler () {
        this.irregularLands.forEach((irregularLand, index) => {
          if (this.canvasContext[index] !== undefined) {
            this.canvasUpdate(irregularLand, index)
          }
        })
      }
    }
  },
  methods: {
    initCanvas (irregularLand, index) {
      if (!this.canvasWidth) {
        this.canvasWidth = this.$refs.boxes[index].offsetWidth - 4 // border分だけマイナスする
        this.canvasHeight = this.canvasWidth / 2
      }

      if (this.$refs.canvases[index] && this.$refs.canvases[index].width > 0 && this.$refs.boxes[index].offsetWidth > 0) {
        this.canvasContext.push(this.$refs.canvases[index].getContext('2d', { willReadFrequently: true }))
        this.canvasContext[index].lineWidth = 1
        setTimeout(() => {
          this.canvasUpdate(irregularLand, index)
        }, 10)
      } else {
        setTimeout(() => {
          this.initCanvas(irregularLand, index)
        }, 500)
      }
    },
    canvasUpdate (irregularLand, index) {
      // 真っ白にする
      this.canvasContext[index].fillStyle = 'white'
      this.canvasContext[index].fillRect(
        0, 0, this.$refs.canvases[index].clientWidth, this.$refs.canvases[index].clientHeight)
      this.canvasContext[index].save()

      // 画像を描画する
      this.calcImageCenter(irregularLand, index)
      if (this.isImageDisplay[index]) {
        this.canvasContext[index].drawImage(
          irregularLand.imageObj,
          0,
          0,
          irregularLand.imageObj.width,
          irregularLand.imageObj.height,
          this.imageDrawInfo.relativePosition.x * this.scale4Pdf,
          this.imageDrawInfo.relativePosition.y * this.scale4Pdf,
          irregularLand.imageObj.width * this.scale4Pdf,
          irregularLand.imageObj.height * this.scale4Pdf
        )
      }

      // 色反転
      if (this.bgReversed[index]) {
        this.colorInversion(this.canvasContext[index], this.canvasWidth, this.canvasHeight)
      }

      // 不整形地（輪郭、頂点、距離表示）を描画する
      const drawRelativePosition = { x: -this.imageDrawInfo.relativePosition.x, y: -this.imageDrawInfo.relativePosition.y }
      this.drawingPlat(index, this.canvasContext[index], irregularLand.vertexes, this.scale4Pdf, drawRelativePosition, colors.platColorRgba(this.bgReversed[index]))

      // 測定範囲を描画する
      if (this.measurementArea[index]) {
        this.canvasContext[index].lineWidth = 2
        this.drawingMeasurementArea(index, this.canvasContext[index], irregularLand.vertexes, this.scale4Pdf, drawRelativePosition,
          'transparent', 'solid', false, `${colors.rangeOfMeasurementColorRgba(this.bgReversed[index])}`)
      }

      // 想定整形地を描画する
      if (this.assumedRect[index]) {
        this.canvasContext[index].lineWidth = 3
        this.drawPlat(this.canvasContext[index], irregularLand.assumed_shape_vertexes, this.scale4Pdf, drawRelativePosition,
          'transparent', 'dash', false, `${colors.assumedPlatsColorRgba(this.bgReversed[index])}`)
      }

      // 想定整形地の距離を描画する
      let scale = 1
      scale = Math.sqrt(this.irregularLands[index].area / this.calcPlatArea(this.irregularLands[index].vertexes) * Math.pow(this.pixelsPerMeter, 2))
      if (this.assumedRect[index] && this.distance[index]) {
        const vtx = irregularLand.assumed_shape_vertexes
        // 想定整形地の右上と右下、左下の頂点を確定する
        const sumVrx = vtx.map((o) => { return o.x + o.y })
        const minIdx = sumVrx.indexOf(Math.min(...sumVrx))
        const maxIdx = sumVrx.indexOf(Math.max(...sumVrx))
        let xIdx, yIdx
        vtx.forEach((v, index) => {
          if (index !== minIdx && index !== maxIdx) {
            const difX = Math.abs(v.x - vtx[minIdx].x)
            const difY = Math.abs(v.y - vtx[minIdx].y)
            if (difX > difY) {
              // 左上の座標インデックス
              xIdx = index
            } else {
              // 右下の座標インデックス
              yIdx = index
            }
          }
        })
        // 右上→右下に奥行を描画
        const deltaDepth = this.calcHorizontalDelta(vtx[minIdx].x, vtx[minIdx].y, vtx[yIdx].x, vtx[yIdx].y, 35)
        const sxDepth = (vtx[minIdx].x - drawRelativePosition.x) * this.scale4Pdf - deltaDepth.dx
        const syDepth = (vtx[minIdx].y - drawRelativePosition.y) * this.scale4Pdf - deltaDepth.dy
        const exDepth = (vtx[yIdx].x - drawRelativePosition.x) * this.scale4Pdf - deltaDepth.dx
        const eyDepth = (vtx[yIdx].y - drawRelativePosition.y) * this.scale4Pdf - deltaDepth.dy

        const dist = Math.sqrt(this.calcDistanceDot2Dot(vtx[minIdx].x, vtx[minIdx].y, vtx[yIdx].x, vtx[yIdx].y)) / this.pixelsPerMeter * scale

        // 奥行判定
        const absDepth = Math.abs(dist - irregularLand.assumedDepthDistance)
        const absFrontage = Math.abs(dist - irregularLand.assumedFrontageDistance)
        const lengthCheckFlag = absDepth < absFrontage
        if (lengthCheckFlag) {
          this.displayDistanceAssumedland(index, sxDepth, syDepth, exDepth, eyDepth, irregularLand.assumedDepthDistance)
        } else {
          this.displayDistanceAssumedland(index, sxDepth, syDepth, exDepth, eyDepth, irregularLand.assumedFrontageDistance)
        }

        // 右上→左上に間口を描画
        const deltaFront = this.calcHorizontalDelta(vtx[minIdx].x, vtx[minIdx].y, vtx[xIdx].x, vtx[xIdx].y, 35)
        const tilt = this.calcTilt(vtx[minIdx].x, vtx[minIdx].y, vtx[xIdx].x, vtx[xIdx].y).tilt
        if (tilt < 0) {
          const sxFront = (vtx[minIdx].x - drawRelativePosition.x) * this.scale4Pdf - deltaFront.dx
          const syFront = (vtx[minIdx].y - drawRelativePosition.y) * this.scale4Pdf - deltaFront.dy
          const exFront = (vtx[xIdx].x - drawRelativePosition.x) * this.scale4Pdf - deltaFront.dx
          const eyFront = (vtx[xIdx].y - drawRelativePosition.y) * this.scale4Pdf - deltaFront.dy
          if (lengthCheckFlag) {
            this.displayDistanceAssumedland(index, sxFront, syFront, exFront, eyFront, irregularLand.assumedFrontageDistance)
          } else {
            this.displayDistanceAssumedland(index, sxFront, syFront, exFront, eyFront, irregularLand.assumedDepthDistance)
          }
        } else {
          const sxFront = (vtx[minIdx].x - drawRelativePosition.x) * this.scale4Pdf + deltaFront.dx
          const syFront = (vtx[minIdx].y - drawRelativePosition.y) * this.scale4Pdf + deltaFront.dy
          const exFront = (vtx[xIdx].x - drawRelativePosition.x) * this.scale4Pdf + deltaFront.dx
          const eyFront = (vtx[xIdx].y - drawRelativePosition.y) * this.scale4Pdf + deltaFront.dy
          if (lengthCheckFlag) {
            this.displayDistanceAssumedland(index, sxFront, syFront, exFront, eyFront, irregularLand.assumedFrontageDistance)
          } else {
            this.displayDistanceAssumedland(index, sxFront, syFront, exFront, eyFront, irregularLand.assumedDepthDistance)
          }
        }
      }

      // セットバックを描画する
      if (irregularLand.setbacks.length > 0) {
        irregularLand.setbacks.forEach((setback) => {
          this.drawPlat(this.canvasContext[index], setback.vertexes, this.scale4Pdf, drawRelativePosition,
            `${colors.setBackColorRgba(this.bgReversed[index])}`, '', false, `${colors.setBackColorRgba(this.bgReversed[index])}`)
        })
      }

      // 角切描画
      irregularLand.displayDatas[0].listRLP.forEach((r, i) => {
        if ((irregularLand.price && r.isMax) || (irregularLand.price === undefined && !r.isMax)) {
          this.drawCornerCompletion(drawRelativePosition, r, scale, index)
        }
      })

      // 図形を描画する
      if (this.drawings) {
        this.drawing.forEach(line => {
          if (line.showing === true) {
            this.canvasContext[index].beginPath()
            this.canvasContext[index].moveTo((line.points.fromX - drawRelativePosition.x) * this.scale4Pdf, (line.points.fromY - drawRelativePosition.y) * this.scale4Pdf)
            this.canvasContext[index].lineTo((line.points.toX - drawRelativePosition.x) * this.scale4Pdf, (line.points.toY - drawRelativePosition.y) * this.scale4Pdf)
            this.canvasContext[index].lineWidth = line.lineWidth ? line.lineWidth : true
            this.canvasContext[index].strokeStyle = colors.strokeColor1Rgba(this.bgReversed[index])
            if (line.type === 'dashed') {
              this.canvasContext[index].setLineDash([5, 5])
            } else if (line.type === 'dotted') {
              this.canvasContext[index].setLineDash([2, 3])
            } else if (line.type === 'solid') {
              this.canvasContext[index].setLineDash([0])
            }
            this.canvasContext[index].closePath()
            this.canvasContext[index].stroke()
            this.canvasContext[index].setLineDash([0])
            this.canvasContext[index].strokeStyle = '#ffffff'
          }
        })
      }
    },
    measurement (irregularLand) {
      irregularLand.assumedArea = Math.round((irregularLand.assumed_area) * 100) / 100

      irregularLand.area = Math.round((irregularLand.area) * 100) / 100

      const frontRoutes = irregularLand.route_prices.filter(p => p.isFront)

      if (frontRoutes !== undefined && frontRoutes.length > 0) {
        const frontRoute = frontRoutes[0]
        irregularLand.frontageDistance = Math.round(frontRoute.frontageDistance * 100) / 100
        irregularLand.depthDistance = Math.round(frontRoute.depthDistance * 100) / 100
        irregularLand.assumedFrontageDistance = Math.round(frontRoute.assumedFrontageDistance * 100) / 100
        irregularLand.assumedDepthDistance = Math.round(frontRoute.assumedDepthDistance * 100) / 100
        // irregularLand.price = Math.round(frontRoute.price)
        irregularLand.price = `${Math.round(frontRoute.price * 100) / 100} × ${Math.round((frontRoute.correctedPrice / frontRoute.price) * 100) / 100}（奥行価格補正率）= ${Math.round(frontRoute.correctedPrice * 100) / 100}`

        const side1Price = irregularLand.route_prices.filter(p => (p.routeOption === 'side1'))
        if (side1Price.length > 0) {
          // irregularLand.side1Price = side1Price[0] ? Math.round(side1Price[0].price) : '--'
          const string4Display = side1Price[0] ? `${Math.round(side1Price[0].price * 100) / 100} × ${Math.round((side1Price[0].correctedPrice / side1Price[0].price) * 100) / 100}（奥行価格補正率）= ${Math.round(side1Price[0].correctedPrice * 100) / 100}` : '--'
          irregularLand.side1Price = string4Display
        }

        const side2Price = irregularLand.route_prices.filter(p => (p.routeOption === 'side2'))
        if (side2Price.length > 0) {
          const string4Display = side2Price[0] ? `${Math.round(side2Price[0].price * 100) / 100} × ${Math.round((side2Price[0].correctedPrice / side2Price[0].price) * 100) / 100}（奥行価格補正率）= ${Math.round(side2Price[0].correctedPrice * 100) / 100}` : '--'
          irregularLand.side2Price = string4Display
        }

        const backPrice = irregularLand.route_prices.filter(p => (p.routeOption === 'back'))
        if (backPrice.length > 0) {
          const string4Display = backPrice[0] ? `${Math.round(backPrice[0].price * 100) / 100} × ${Math.round((backPrice[0].correctedPrice / backPrice[0].price) * 100) / 100}（奥行価格補正率）= ${Math.round(backPrice[0].correctedPrice * 100) / 100}` : '--'
          irregularLand.backPrice = string4Display
        }

        const blankPrice = irregularLand.route_prices.filter(p => (p.routeOption === ''))
        if (blankPrice.length > 0) {
          const string4Display = blankPrice[0] ? `${Math.round(blankPrice[0].price * 100) / 100} × ${Math.round((blankPrice[0].correctedPrice / blankPrice[0].price) * 100) / 100}（奥行価格補正率）= ${Math.round(blankPrice[0].correctedPrice * 100) / 100}` : '--'
          irregularLand.blankPrice = string4Display
        }

        irregularLand.shadeCut = Math.round(
          (((irregularLand.assumed_area - irregularLand.area) / irregularLand.assumed_area) * 100) * 100) / 100
      }
    },
    calcImageCenter (irregularLand, index) {
      this.updateCanvasCount += 1
      const imageCenterX = irregularLand.imageObj.width / 2
      const imageCenterY = irregularLand.imageObj.height / 2
      const canvasCenterX = (this.$refs.boxes[index].offsetWidth - 4) / 2 / this.scale4Pdf
      const canvasCenterY = this.$refs.boxes[index].offsetHeight / 2 / this.scale4Pdf

      const relativePositionX = canvasCenterX - imageCenterX
      const relativePositionY = canvasCenterY - imageCenterY

      this.imageDrawInfo.relativePosition = { x: relativePositionX, y: relativePositionY }
    },
    next () {
      this.$emit('loading', true)
      this.owner = this.owner === undefined ? '' : this.owner
      this.address = this.address === undefined ? '' : this.address
      const data = { data: [], owner: this.owner, address: this.address, partName: [], textMemo: [], scaleInput: this.scaleInput, irregularLands: [] }

      // PDF用に縮尺変更
      const scale = Math.sqrt(this.irregularLands[0].area / this.calcPlatArea(this.irregularLands[0].vertexes) * Math.pow(this.pixelsPerMeter, 2))
      if (this.scaleInput !== '') {
        this.scale4Pdf = this.adjustScale * scale / this.scaleInput
      } else {
        this.scale4Pdf = this.adjustScale
      }
      this.scale4Pdf = this.scale4Pdf * this.canvasContext[0].canvas.width / 1248.66

      this.irregularLands.forEach((irregularLand, index) => {
        if (irregularLand.price || this.subPrint) {
          if (this.canvasContext[index] !== undefined) {
            this.canvasUpdate(irregularLand, index)
          }
          data.data.push(this.canvasContext[index].canvas.toDataURL())
          data.partName.push(JSON.stringify(this.partName[index]))
          data.textMemo.push(JSON.stringify(this.textMemo[index]))
          data.scaleInput = JSON.stringify(this.scaleInput)
          data.irregularLands.push(JSON.stringify(irregularLand))
        }
      })

      // 縮尺を戻す
      this.scale4Pdf = 1
      this.irregularLands.forEach((irregularLand, index) => {
        if (irregularLand.price || this.subPrint) {
          if (this.canvasContext[index] !== undefined) {
            this.canvasUpdate(irregularLand, index)
          }
        }
      })

      this.axios.post(`/api/kagechi/result/${this.$route.params.main_id}`, data).then(res => {
        const link = document.createElement('a')
        link.href = res.data.base64Pdf
        link.download = res.data.pdfName
        link.click()
        this.$emit('loading', false)
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
        this.$emit('loading', false)
      })
    },
    drawingPlat (index, canvasContext, vertexes, scale, relativePosition, fillColor, lineStyle, needVertexRect, strokeStyleColor) {
      if (lineStyle === 'dash') {
        canvasContext.setLineDash([5, 2])
      } else {
        canvasContext.setLineDash([])
      }

      if (needVertexRect !== false) {
        needVertexRect = true
      }

      // 塗りつぶしする
      vertexes.forEach((vertex, vertexIndex) => {
        if (vertexIndex === 0) {
          canvasContext.beginPath()
        }

        const x = (vertex.x - relativePosition.x) * scale
        const y = (vertex.y - relativePosition.y) * scale

        if (vertexIndex === 0) {
          canvasContext.moveTo(x, y)
        } else {
          canvasContext.lineTo(x, y)
        }

        if ((vertexIndex + 1) === vertexes.length) {
          canvasContext.closePath()
          if (strokeStyleColor) {
            canvasContext.strokeStyle = strokeStyleColor
          } else {
            canvasContext.strokeStyle = `${colors.strokeColor2Rgba()}`
          }
          canvasContext.fillStyle = fillColor
          canvasContext.fill()
          canvasContext.stroke()
        }
      })

      // 頂点を打つ・番号表示
      if (this.vtxNumber[index]) {
        canvasContext.fillStyle = `${colors.strokeColor2Rgba()}`
        canvasContext.font = '11pt Arial'
        canvasContext.textAlign = 'center'
        canvasContext.textBaseline = 'bottom'
        vertexes.forEach((vertex, vertexIndex) => {
          const x = (vertex.x - relativePosition.x) * scale
          const y = (vertex.y - relativePosition.y) * scale

          // 頂点番号背景
          const bgHeight = 9 * 1.5 + 5
          const bgWidth = canvasContext.measureText(vertexIndex + 1).width + 5
          // canvasContext.fillRect(x - bgWidth + 1, y - bgHeight - 3.5, bgWidth, bgHeight)
          canvasContext.beginPath()
          canvasContext.fillStyle = 'white'
          if (bgWidth > bgHeight) {
            if (vertexIndex + 1 < 10) {
              canvasContext.arc(x + bgWidth / 10 - bgWidth / 2, y - 5 - bgHeight / 2, bgWidth / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            } else {
              canvasContext.arc(x + bgWidth / 4 - bgWidth / 2, y - 5 - bgHeight / 2, bgWidth / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            }
            canvasContext.fill()
          } else {
            if (vertexIndex + 1 < 10) {
              canvasContext.arc(x + bgWidth / 10 - bgWidth / 2, y - 5 - bgHeight / 2, bgHeight / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            } else {
              canvasContext.arc(x + bgWidth / 4 - bgWidth / 2, y - 5 - bgHeight / 2, bgHeight / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            }
            canvasContext.fill()
          }
          // 頂点
          canvasContext.fillStyle = 'blue'
          canvasContext.fillRect(x - 5, y - 5, 10, 10)
          // 頂点番号
          canvasContext.fillText((vertexIndex + 1) + '', x - 5, y - 5)
        })
      }

      // 距離表示
      if (this.distance[index]) {
        vertexes.forEach((vertex, vertexIndex) => {
          const x = (vertex.x - relativePosition.x) * scale
          const y = (vertex.y - relativePosition.y) * scale
          if (vertex.distance_meter && (vertex.showDistance === undefined || vertex.showDistance)) {
            const prevVertex = vertexes[(vertexIndex === 0) ? vertexes.length - 1 : vertexIndex - 1]
            const prevX = (prevVertex.x - relativePosition.x) * scale
            const prevY = (prevVertex.y - relativePosition.y) * scale
            const textX = (x + prevX) / 2
            const textY = (y + prevY) / 2
            const textValue = (Math.round(vertex.distance_meter * 100) / 100) + ''

            const bgHeight = 9 * 1.5 + 5
            const bgWidth = canvasContext.measureText(textValue).width + 5
            const bgY = textY - bgHeight
            const bgX = textX - (bgWidth / 2)

            canvasContext.fillStyle = 'white'
            canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
            canvasContext.fillStyle = 'blue'
            canvasContext.fillText(textValue, textX, textY)
          }
        })
      }
    },
    drawingMeasurementArea (index, canvasContext, vertexes, scale, relativePosition, fillColor, lineStyle, needVertexRect, strokeStyleColor) {
      if (lineStyle === 'dash') {
        canvasContext.setLineDash([5, 2])
      } else {
        canvasContext.setLineDash([0])
      }

      if (needVertexRect !== false) {
        needVertexRect = true
      }

      // 塗りつぶしする
      vertexes.forEach((vertex, vertexIndex) => {
        if (vertexIndex === 0) {
          canvasContext.beginPath()
        }

        const x = (vertex.x - relativePosition.x) * scale
        const y = (vertex.y - relativePosition.y) * scale

        if (vertexIndex === 0) {
          canvasContext.moveTo(x, y)
        } else {
          canvasContext.lineTo(x, y)
        }

        if ((vertexIndex + 1) === vertexes.length) {
          canvasContext.closePath()
          if (strokeStyleColor) {
            canvasContext.strokeStyle = strokeStyleColor
          } else {
            canvasContext.strokeStyle = `${colors.strokeColor2Rgba()}`
          }
          canvasContext.fillStyle = fillColor
          canvasContext.fill()
          canvasContext.stroke()
        }
      })
      // 距離表示
      if (this.distance[index]) {
        vertexes.forEach((vertex, vertexIndex) => {
          const x = (vertex.x - relativePosition.x) * scale
          const y = (vertex.y - relativePosition.y) * scale

          if (vertex.distance_meter && (vertex.showDistance === undefined || vertex.showDistance)) {
            const prevVertex = vertexes[(vertexIndex === 0) ? vertexes.length - 1 : vertexIndex - 1]
            const prevX = (prevVertex.x - relativePosition.x) * scale
            const prevY = (prevVertex.y - relativePosition.y) * scale
            const textX = (x + prevX) / 2
            const textY = (y + prevY) / 2
            const textValue = (Math.round(vertex.distance_meter * 100) / 100) + ''

            const bgHeight = 9 * 1.5 + 5
            const bgWidth = canvasContext.measureText(textValue).width + 5
            const bgY = textY - bgHeight
            const bgX = textX - (bgWidth / 2)

            canvasContext.fillStyle = 'white'
            canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
            canvasContext.fillStyle = 'blue'
            canvasContext.fillText(textValue, textX, textY)
          }
        })
      }
    },
    previewCvsUpdate (index) {
      // 縮尺計算
      const scale = Math.sqrt(this.irregularLands[index].area / this.calcPlatArea(this.irregularLands[index].vertexes) * Math.pow(this.pixelsPerMeter, 2))

      let cvs
      if (this.scaleInput !== '') {
        this.scale4Pdf = this.adjustScale * scale / this.scaleInput
      } else {
        this.scale4Pdf = this.adjustScale
      }
      this.irregularLands.forEach((irregularLand, index) => {
        if (this.canvasContext[index] !== undefined) {
          this.canvasUpdate(irregularLand, index)
        }
      })
      const image = this.canvasContext[index].getImageData(0, 0, this.canvasWidth, this.canvasHeight)

      setTimeout(() => {
        cvs = document.getElementById('cvsPreview')
        cvs.getContext('2d', { willReadFrequently: true }).putImageData(image, 0, 0)
        // 縮尺戻す
        this.scale4Pdf = 1
        this.irregularLands.forEach((irregularLand, index) => {
          if (this.canvasContext[index] !== undefined) {
            this.canvasUpdate(irregularLand, index)
          }
        })
      }, 50)
    },
    closePreviewCvsUpdate () {
      this.scale4Pdf = 1
      this.irregularLands.forEach((irregularLand, index) => {
        if (this.canvasContext[index] !== undefined) {
          this.canvasUpdate(irregularLand, index)
        }
      })
    },
    // 想定整形地の距離表示用
    displayDistanceAssumedland (index, sx, sy, ex, ey, dist) {
      // 描画
      this.canvasContext[index].beginPath()
      this.canvasContext[index].moveTo(sx, sy)
      this.canvasContext[index].lineTo(ex, ey)
      this.canvasContext[index].lineWidth = 2
      this.canvasContext[index].strokeStyle = `${colors.assumedPlatsColorRgba(this.bgReversed[index])}`
      this.canvasContext[index].setLineDash([0])
      this.canvasContext[index].closePath()
      this.canvasContext[index].stroke()

      // 両端に飾りを付ける
      const dx = this.calcHorizontalDelta(sx, sy, ex, ey, 5).dx
      const dy = this.calcHorizontalDelta(sx, sy, ex, ey, 5).dy
      this.canvasContext[index].beginPath()
      this.canvasContext[index].moveTo(sx + dx, sy + dy)
      this.canvasContext[index].lineTo(sx - dx, sy - dy)
      this.canvasContext[index].closePath()
      this.canvasContext[index].stroke()
      this.canvasContext[index].beginPath()
      this.canvasContext[index].moveTo(ex + dx, ey + dy)
      this.canvasContext[index].lineTo(ex - dx, ey - dy)
      this.canvasContext[index].closePath()
      this.canvasContext[index].stroke()

      // 距離表示
      const bgHeight = 9 * 1.5 + 5
      const bgWidth = this.canvasContext[index].measureText(dist).width + 5
      const bgY = (sy + ey) / 2 - bgHeight
      const bgX = (sx + ex) / 2 - (bgWidth / 2)

      this.canvasContext[index].fillStyle = 'white'
      this.canvasContext[index].fillRect(bgX, bgY, bgWidth, bgHeight)
      this.canvasContext[index].fillStyle = 'blue'
      this.canvasContext[index].fillText(dist, (sx + ex) / 2, (sy + ey) / 2)
    },
    displayDistance (sx, sy, ex, ey, dist, relativeX, relativeY, scale, index) {
      const prevX = (sx + ex) / 2 + relativeX
      const prevY = (sy + ey) / 2 + relativeY
      const textX = prevX + 3
      const textY = prevY + 3
      const meterDist = Math.sqrt(dist) / this.pixelsPerMeter * scale
      const textValue = (Math.round(meterDist * 100) / 100) + ''
      const bgHeight = 9 * 1.5 + 5
      const bgWidth = this.canvasContext[index].measureText(textValue).width + 5
      const bgY = textY - bgHeight
      const bgX = textX - (bgWidth / 2)
      this.canvasContext[index].fillStyle = 'white'
      this.canvasContext[index].fillRect(bgX, bgY, bgWidth, bgHeight)
      this.canvasContext[index].fillStyle = 'blue'
      this.canvasContext[index].fillText(textValue, textX, textY)
    },
    calcHorizontalDelta (sx, sy, ex, ey, dist) {
      const tilt = this.calcTilt(sx, sy, ex, ey)
      const revTilt = -1 / tilt.tilt
      const deltaX = dist / Math.sqrt((1 + Math.pow(revTilt, 2)))
      const deltaY = deltaX * revTilt
      return { dx: deltaX, dy: deltaY }
    },
    openInNewTab (url) {
      window.open(url, '_blank', 'noreferrer')
    },
    toggleSubPrint () {
      if (this.subPrint) {
        this.subPrint = false
      } else {
        this.subPrint = true
      }
    },
    drawCornerCompletion (drawRelativePosition, rlp, scale, index) {
      const dispCutoff = rlp.cornerCompletion.displayVtx
      // 側方路線１
      this.drawLine(
        dispCutoff.side1.x * this.scale4Pdf,
        dispCutoff.side1.y * this.scale4Pdf,
        dispCutoff.side1From.x * this.scale4Pdf,
        dispCutoff.side1From.y * this.scale4Pdf,
        drawRelativePosition.x * this.scale4Pdf,
        drawRelativePosition.y * this.scale4Pdf,
        2, `${colors.assumedPlatsColorRgba(this.bgReversed)}`, [5, 5],
        index
      )
      this.drawLine(
        dispCutoff.side1.x * this.scale4Pdf,
        dispCutoff.side1.y * this.scale4Pdf,
        dispCutoff.side1To.x * this.scale4Pdf,
        dispCutoff.side1To.y * this.scale4Pdf,
        drawRelativePosition.x * this.scale4Pdf,
        drawRelativePosition.y * this.scale4Pdf,
        2, `${colors.assumedPlatsColorRgba(this.bgReversed)}`, [5, 5],
        index
      )
      if (this.distance[index]) {
        this.displayDistance(
          dispCutoff.side1.x * this.scale4Pdf,
          dispCutoff.side1.y * this.scale4Pdf,
          dispCutoff.side1To.x * this.scale4Pdf,
          dispCutoff.side1To.y * this.scale4Pdf,
          this.calcDistanceDot2Dot(dispCutoff.side1.x, dispCutoff.side1.y, dispCutoff.side1To.x, dispCutoff.side1To.y),
          -drawRelativePosition.x * this.scale4Pdf,
          -drawRelativePosition.y * this.scale4Pdf,
          scale,
          index
        )
      }
      // 側方路線２
      this.drawLine(
        dispCutoff.side2.x * this.scale4Pdf,
        dispCutoff.side2.y * this.scale4Pdf,
        dispCutoff.side2From.x * this.scale4Pdf,
        dispCutoff.side2From.y * this.scale4Pdf,
        drawRelativePosition.x * this.scale4Pdf,
        drawRelativePosition.y * this.scale4Pdf,
        2, `${colors.assumedPlatsColorRgba(this.bgReversed)}`, [5, 5],
        index
      )
      this.drawLine(
        dispCutoff.side2.x * this.scale4Pdf,
        dispCutoff.side2.y * this.scale4Pdf,
        dispCutoff.side2To.x * this.scale4Pdf,
        dispCutoff.side2To.y * this.scale4Pdf,
        drawRelativePosition.x * this.scale4Pdf,
        drawRelativePosition.y * this.scale4Pdf,
        2, `${colors.assumedPlatsColorRgba(this.bgReversed)}`, [5, 5],
        index
      )
      if (this.distance[index]) {
        this.displayDistance(
          dispCutoff.side2.x * this.scale4Pdf,
          dispCutoff.side2.y * this.scale4Pdf,
          dispCutoff.side2To.x * this.scale4Pdf,
          dispCutoff.side2To.y * this.scale4Pdf,
          this.calcDistanceDot2Dot(dispCutoff.side2.x, dispCutoff.side2.y, dispCutoff.side2To.x, dispCutoff.side2To.y),
          -drawRelativePosition.x * this.scale4Pdf,
          -drawRelativePosition.y * this.scale4Pdf,
          scale,
          index
        )
      }
    },
    drawLine (sx, sy, ex, ey, relativeX, relativeY, width, color, lineDash, index) {
      this.canvasContext[index].beginPath()
      this.canvasContext[index].moveTo(sx - relativeX, sy - relativeY)
      this.canvasContext[index].lineTo(ex - relativeX, ey - relativeY)
      this.canvasContext[index].lineWidth = width
      this.canvasContext[index].strokeStyle = color
      this.canvasContext[index].setLineDash(lineDash)
      this.canvasContext[index].closePath()
      this.canvasContext[index].stroke()
    }
  }
}

</script>

<style scoped>
p {
  margin: 0;
}
</style>
