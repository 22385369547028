<template>
  <b-container fluid>
    <div id="full-width-section" class="align-items-center d-flex justify-content-between mb-4 row">
      <change-title v-model="title" @change-title-error="validated"></change-title>

      <div class="ml-5 col-md-6">
        <ul class="stepFlow">
          <li class="active">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 1</p>トリミング</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 2</p>評価対象地の測定</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 3</p>評価対象地の編集</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 4</p>路線価の設定</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 5</p>測定結果</span>
          </li>
        </ul>
      </div>

      <div class="text-right">
        <b-btn variant="outline-secondary" class="px-5 mr-3" @click="$router.push({name: 'KagechiUploadGPX', params: {mainId: $route.params.main_id}})">戻る</b-btn>
        <b-btn variant="primary" class="px-3" @click="next2">
          <div class="btn-2rows text-left">土地選択を完了し<br>トリミングを開始する</div>
        </b-btn>
      </div>
    </div>

    <div>
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>

    <div class="d-flex mb-4">
      <b-button v-if="helpUrl != ''" variant="secondary" @click="openInNewTab(helpUrl)" class="align-items-center d-flex mr-2">
        <span class="align-items-center bg-white d-flex mr-2 rounded-pill" style="width: 18px; height: 12px;">
          <b-icon-book-fill variant="info"/>
        </span>
        詳細説明を見る
      </b-button>
      <play-video-btn v-if="helpUrlVideo"></play-video-btn>
    </div>

    <b-tabs content-class="mt-3">
      <template #tabs-start>
        <li role="presentation" class="align-self-center border-b-3 border-dark-grey border-solid border-t-0 border-x-0 nav-item page-sum-label px-4 py-2">
          ページ数合計：{{files.length}}枚
        </li>
      </template>
      <b-tab v-for="(f, index) in files" :key="index">
        <template #title>{{f.name}}</template>
        <trimming-image ref="trimmingImages" :base64-image="f.image" :fileIndex="index"/>
      </b-tab>
      <template #tabs-end>
        <li class="grow border-3 border-b-3 border-solid border-dark-grey border-x-0 border-t-0"></li>
      </template>
    </b-tabs>

    <video-modal v-if="helpUrlVideo" :url="helpUrlVideo"></video-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import TrimmingImage from '../../components/TrimmingImageGPX'
import ChangeTitle from '../../components/ChangeTitleGPX'
import VideoModal from '../../components/VideoModal'
import PlayVideoBtn from '../../components/PlayVideoBtn'
import CanvasMixin from '../../mixins/canvas'
import GPX from '../../mixins/gpx'
import validation from '../../mixins/validation'
import videoWatch from '../../mixins/video_watch'
import constants from '../../constants'

export default {
  name: 'Trimming',
  mixins: [CanvasMixin, validation, GPX, videoWatch],
  components: {
    TrimmingImage,
    ChangeTitle,
    VideoModal,
    PlayVideoBtn
  },
  data: () => ({
    main: {},
    gcs: undefined,
    gs: {},
    keys: undefined,
    keyIdx: 0,
    standardDist: undefined,
    helpUrl: '',
    helpUrlVideo: '',
    needPlayVideo: false
  }),
  mounted () {
    window.addEventListener('resize', this.resizeWindow)
  },
  created () {
    this.$emit('loading', true)

    // 操作説明のURL取得
    this.axios.get('/api/master/search/help_urls').then(res => {
      const autoPlayedVideos = localStorage.getItem('autoPlayedVideos')
        ? JSON.parse(localStorage.getItem('autoPlayedVideos')) : []

      this.helpUrl = res.data.urls.step1_GPX
      this.helpUrlVideo = res.data.urls.step1_GPX_video

      if (this.helpUrlVideo && !autoPlayedVideos.includes(this.helpUrlVideo)) {
        this.needPlayVideo = true
        autoPlayedVideos.push(this.helpUrlVideo)
        localStorage.setItem('autoPlayedVideos', JSON.stringify(autoPlayedVideos))
      }
    }).catch(error => {
      this.errors = { error: `システムエラーが発生しました。${error}` }
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })

    const urlId = this.$route.params.main_id

    if (this.mainId !== urlId) {
      this.deleteMarkers()
      this.deleteGSCoordinates()
      this.setMainId(urlId)
    }

    this.getFilesData(urlId)

    if (this.coordinates.length === 0) {
      this.errors = { error: '最初からやり直してください。' }
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      setTimeout(() => {
        this.gcs = this.coordinates
        this.standardDist = this.calcDist(this.gcs['0_0'].coordinates.lat * (Math.PI / 180),
          this.gcs['0_0'].coordinates.lon * (Math.PI / 180),
          this.gcs['0_1'].coordinates.lat * (Math.PI / 180),
          this.gcs['0_1'].coordinates.lon * (Math.PI / 180))
        this.keys = Object.keys(this.gcs)
        this.keyIdx = 0
        this.loopFiles(0).then(() => {
        })
      }, 300)
    }

    this.$emit('loading', false)
  },
  computed: {
    ...mapState({
      coordinates: state => state.gpx.coordinates,
      files: state => state.gpx.files,
      vertexes: state => state.gpx.vertexes,
      loading: state => state.gpx.loadingStatus,
      loadingStatus: state => state.googleMap.loadingStatus,
      mainId: state => state.googleMap.mainId
    })
  },
  watch: {
    loadingStatus () {
      if (this.loadingStatus) {
        this.$emit('loading', true)
      } else {
        this.$emit('loading', false)
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeWindow)
  },
  methods: {
    ...mapActions('gpx',
      [
        'getFilesData',
        'deleteCoordinates'
      ]
    ),

    ...mapActions('googleMap',
      [
        'setMainId',
        'deleteMarkers',
        'deleteGSCoordinates'
      ]
    ),

    async next2 () {
      // 1つファイルしかない想定
      this.$refs.trimmingImages[0].updatePlats()
        .then(() => {
          this.next()
        })
    },

    next () {
      this.$emit('loading', true)

      this.files.forEach((file, index) => {
        const plats = []
        this.$refs.trimmingImages[index].plats.forEach(p => {
          const plat = { ...p }
          delete plat.visible
          plat.vertexes.forEach((vertex, vIndex) => {
            if (vIndex === 0) {
              vertex.distance = this.calcDistance(plat.vertexes[plat.vertexes.length - 1], vertex)
            } else {
              vertex.distance = this.calcDistance(plat.vertexes[vIndex - 1], vertex)
            }
          })

          plats.push(plat)
        })
        file.plats = plats
      })
      this.axios.post(`/api/kagechi/trimming/${this.$route.params.main_id}`, this.files).then(res => {
        this.$emit('loading', false)
        this.deleteCoordinates()
        this.$router.push({ path: `/kagechi/correct/${this.$route.params.main_id}` })
      }).catch(error => {
        this.$emit('loading', false)
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
      })
    },
    resizeWindow () {
      this.$refs.trimmingImages.forEach(component => {
        component.initCanvas()
      })
    },
    getVtx (paramKey, kIdx) {
      return new Promise((resolve) => {
        const key = paramKey
        if (this.gcs[key].coordinates.lon > 0 && this.gcs[key].coordinates.lat > 0) {
          this.axios.get(`/api/kagechi/gpx?lon=${this.gcs[key].coordinates.lon}&lat=${this.gcs[key].coordinates.lat}`).then(res => {
            this.gs[this.gcs[key].crdnIndex] = {
              x: res.data.x,
              y: res.data.y,
              // 緯度，経度 のデータ取得を追加
              lat: Number(this.gcs[key].coordinates.lat),
              lon: Number(this.gcs[key].coordinates.lon)
            }
            resolve()
          })
        }
      })
    },
    loopVtx (kIdx) {
      return new Promise((resolve) => {
        if (this.keyIdx === 0) {
          this.getVtx(this.keys[this.keyIdx]).then(() => {
            this.keyIdx++
            this.loopVtx().then(() => {
              resolve()
            })
          })
        } else if (this.keyIdx === this.keys.length) {
          resolve()
        } else if (kIdx !== this.keyIdx && this.keys[this.keyIdx].split('_')[0] !== this.keys[this.keyIdx - 1].split('_')[0]) {
          resolve()
        } else {
          this.getVtx(this.keys[this.keyIdx], kIdx).then(() => {
            this.keyIdx++
            this.loopVtx().then(() => {
              resolve()
            })
          })
        }
      })
    },
    loopFiles (fIdx) {
      return new Promise((resolve) => {
        if (fIdx === this.files.length) {
          resolve() // 解読by厳：loopFilesのthenはなにもしないため、ここですべてのファイル処理済み、loopFiles終了
        } else {
          this.$refs.trimmingImages[fIdx].addPlat()
          this.$refs.trimmingImages[fIdx].onSelectChangePlat(this.$refs.trimmingImages[fIdx].plats[0])
          const prevIdx = this.keyIdx
          this.loopVtx(prevIdx).then(() => {
            // 座標変換
            const gs = this.adjustPlatSize(this.gs)
            const vtxs = []
            Object.keys(gs).forEach((key, index) => {
              vtxs.push(gs[key])
            })
            if (vtxs.length > 2) {
              const sumVrx = vtxs.map((o) => { return parseFloat(o.x) + parseFloat(o.y) })
              vtxs.forEach((v, index) => {
                gs[index].x = v.x * 400 / Math.max(...sumVrx) + 100
                gs[index].y = v.y * 400 / Math.max(...sumVrx) + 100
                // 緯度，経度 の項目を追加
                gs[index].lat = v.lat
                gs[index].lon = v.lon
              })
              this.$refs.trimmingImages[fIdx].plotCoordinates(gs)
            } else if (vtxs.length === 2) {
              // 点が２点の場合
              // 緯度，経度 の項目を追加
              gs[0].lat = vtxs[0].lat
              gs[0].lon = vtxs[0].lon
              gs[1].lat = vtxs[1].lat
              gs[1].lon = vtxs[1].lon

              if (String(vtxs[0].x) === '0' && String(vtxs[0].y) === '0') {
                if (vtxs[1].x > vtxs[1].y) {
                  gs[0].x = 100
                  gs[0].y = 100
                  gs[1].x = vtxs[1].x * (200 / vtxs[1].x) + 100
                  gs[1].y = vtxs[1].y * (200 / vtxs[1].x) + 100
                } else {
                  gs[0].x = 50
                  gs[0].y = 50
                  gs[1].x = vtxs[1].x * (200 / vtxs[1].y) + 100
                  gs[1].y = vtxs[1].y * (200 / vtxs[1].y) + 100
                }
              } else {
                if (vtxs[0].x > vtxs[0].y) {
                  gs[1].x = 100
                  gs[1].y = 100
                  gs[0].x = vtxs[0].x * (200 / vtxs[0].x) + 100
                  gs[0].y = vtxs[0].y * (200 / vtxs[0].x) + 100
                } else {
                  gs[1].x = 100
                  gs[1].y = 100
                  gs[0].x = vtxs[0].x * (200 / vtxs[0].y) + 100
                  gs[0].y = vtxs[0].y * (200 / vtxs[0].y) + 100
                }
              }
              this.$refs.trimmingImages[fIdx].plotCoordinates(gs)
            }
            this.gs = {}
            this.loopFiles(fIdx + 1).then(() => {
              resolve()
            })
            if (fIdx === this.files.length - 1) {
              this.$emit('loading', false)
            }
          })
        }
      })
    },
    openInNewTab (url) {
      window.open(url, '_blank', 'noreferrer')
    }
  }
}
</script>

<style scoped>

</style>
