<template>
    <!-- b-modalのv-modelは is always automatically synced with <b-modal> visible state and you can show/hide using v-model. -->
    <b-modal v-model="showVideoModal" id="video-modal" size="xl" title="簡易動画を見る">
        <video controls muted autoplay preload>
            <source :src="url" type="video/mp4">
            "Your browser does not support HTML video."
        </video>
        <template #modal-footer>
          <b-button variant="danger" @click="showVideoModal=false">
            Skip
          </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
  name: 'VideoModal',
  props: {
    url: String
  },
  data: () => ({
    showVideoModal: false
  })
}
</script>

<style scoped>
  video {
    width: 100%;
    height: auto;
    margin: auto;
  }
</style>
