<template>
  <b-container fluid>
    <div id="full-width-section" class="align-items-center d-flex justify-content-between mb-4 row">
      <change-title v-model="title" @change-title-error="validated"></change-title>

      <div class="ml-5 col-md-6">
        <ul class="stepFlow">
          <li class="active">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 1</p>トリミング</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 2</p>評価対象地の測定</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 3</p>評価対象地の編集</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 4</p>路線価の設定</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 5</p>測定結果</span>
          </li>
        </ul>
      </div>

      <div class="text-right">
        <b-btn variant="outline-secondary" class="px-5 mr-3" @click="$router.push({name: 'KagechiUpload', params: {mainId: $route.params.main_id}})">戻る</b-btn>
        <b-btn variant="primary" class="px-3" @click="next">
          <div class="btn-2rows text-left">土地選択を完了し<br>トリミングを開始する</div>
        </b-btn>
      </div>
    </div>

    <div>
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>

    <div class="d-flex mb-4">
      <b-button v-if="helpUrl != ''" variant="secondary" @click="openInNewTab(helpUrl)" class="align-items-center d-flex mr-2">
        <span class="align-items-center bg-white d-flex mr-2 rounded-pill" style="width: 18px; height: 12px;">
          <b-icon-book-fill variant="info"/>
        </span>
        詳細説明を見る
      </b-button>
      <play-video-btn v-if="helpUrlVideo"></play-video-btn>
    </div>

    <b-tabs content-class="mt-3">
      <template #tabs-start>
        <li role="presentation" class="align-self-center border-b-3 border-dark-grey border-solid border-t-0 border-x-0 nav-item page-sum-label px-4 py-2">
          ページ数合計：{{files.length}}枚
        </li>
      </template>
      <b-tab v-for="(f, index) in files" :key="index">
        <template #title>{{f.name}}</template>
        <trimming-image ref="trimmingImages" :error="error" :pdfName="f.name" :base64-image="f.image" :vertexes="vertexes[index]" :tabIndex="index" :synthFlag="synthFlag"/>
      </b-tab>
      <template #tabs-end>
        <li class="grow border-3 border-b-3 border-solid border-dark-grey border-x-0 border-t-0"></li>
      </template>
    </b-tabs>

    <video-modal v-if="helpUrlVideo" :url="helpUrlVideo"></video-modal>
  </b-container>
</template>

<script>
import TrimmingImage from '../../components/TrimmingImage'
import ChangeTitle from '../../components/ChangeTitle'
import VideoModal from '../../components/VideoModal'
import PlayVideoBtn from '../../components/PlayVideoBtn'
import CanvasMixin from '../../mixins/canvas'
import validation from '../../mixins/validation'
import videoWatch from '../../mixins/video_watch'
import constants from '../../constants'

export default {
  name: 'Trimming',
  mixins: [CanvasMixin, validation, videoWatch],
  components: {
    TrimmingImage,
    ChangeTitle,
    VideoModal,
    PlayVideoBtn
  },
  data: () => ({
    main: {},
    files: [],
    vertexes: [],
    title: '',
    error: '',
    helpUrl: '',
    helpUrlVideo: '',
    needPlayVideo: false,
    synthFlag: false
  }),
  watch: {
    error () {
      this.errors = this.error
    }
  },
  mounted () {
    this.$emit('loading', true)

    // 操作説明のURL取得
    this.axios.get('/api/master/search/help_urls').then(res => {
      const autoPlayedVideos = localStorage.getItem('autoPlayedVideos')
        ? JSON.parse(localStorage.getItem('autoPlayedVideos')) : []

      this.helpUrl = res.data.urls.step1_Normal
      this.helpUrlVideo = res.data.urls.step1_Normal_video

      if (this.helpUrlVideo && !autoPlayedVideos.includes(this.helpUrlVideo)) {
        this.needPlayVideo = true
        autoPlayedVideos.push(this.helpUrlVideo)
        localStorage.setItem('autoPlayedVideos', JSON.stringify(autoPlayedVideos))
      }
    }).catch(error => {
      this.errors = { error: `システムエラーが発生しました。${error}` }
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })

    window.addEventListener('resize', this.resizeWindow)

    this.axios.get(`/api/kagechi/trimming/${this.$route.params.main_id}`).then(res => {
      // PDF関連処理
      this.files = res.data.images
      const sortBuff = [] // 土地→建物の順にthis.filesの中身をソートするためのバッファ
      // バッファーに土地から格納
      this.files.forEach((f) => {
        const isPlat = f.name.indexOf('[土地]', 0)
        if (isPlat === 0) {
          sortBuff.push(f)
        }
      })
      // 次に建物を格納
      this.files.forEach((f) => {
        const isBuilding = f.name.indexOf('[建物]', 0)
        if (isBuilding === 0) {
          sortBuff.push(f)
        }
      })
      this.files = sortBuff // バッファーの中身でthis.files更新

      // this.filesをソートした順でthis.vertexesにデータ格納
      this.files.forEach(f => {
        const index = res.data.images.indexOf(f)
        this.vertexes.push(res.data.vertexes[index])
      })
      // 合成したPDFがあれば先頭に割り込み
      if (this.$route.query.image !== undefined) {
        this.synthFlag = true
        const filesBuf = []
        filesBuf.push(this.files[0])
        this.files = []
        this.files.push(filesBuf[0])
        this.files[0].image = this.$route.query.image
        this.files[0].name = res.data.title
      }
      this.title = res.data.title

      this.$emit('loading', false)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeWindow)
  },
  methods: {
    next () {
      this.$emit('loading', true)

      this.files.forEach((file, index) => {
        const plats = []
        this.$refs.trimmingImages[index].plats.forEach(p => {
          const plat = { ...p }
          delete plat.visible
          plat.vertexes.forEach((vertex, vIndex) => {
            if (vIndex === 0) {
              vertex.distance = this.calcDistance(plat.vertexes[plat.vertexes.length - 1], vertex)
            } else {
              vertex.distance = this.calcDistance(plat.vertexes[vIndex - 1], vertex)
            }
          })

          plats.push(plat)
        })
        file.plats = plats
      })

      this.axios.post(`/api/kagechi/trimming/${this.$route.params.main_id}`, this.files).then(res => {
        this.$emit('loading', false)
        this.$router.push(`/kagechi/correct/${this.$route.params.main_id}`)
      }).catch(error => {
        this.$emit('loading', false)
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
      })
    },
    resizeWindow () {
      this.$refs.trimmingImages.forEach(component => {
        component.initCanvas()
      })
    },
    openInNewTab (url) {
      window.open(url, '_blank', 'noreferrer')
    }
  }
}
</script>

<style scoped>
#description .btn {
  pointer-events: none;
}
</style>
