<template>
  <b-container class="text-center">
    <div v-if="is_done">
      <b-alert show :variant="variant" class="col-md-6 mx-auto">
        <div v-if="confirmed">
          <div class="mt-4">
            ダウンロードが完了しました！<br>
          </div>
        </div>
        <div v-else>
          <div class="mt-4">
            ダウンロードに失敗しました。<br>
            お手数をおかけいたしますが、<br>
            URLをご確認ください。
          </div>
        </div>
      </b-alert>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'Download',
  data: () => ({
    confirmed: true,
    numbers: ['', '', '', '', ''],
    error_message: '',
    is_done: false
  }),
  computed: {
    variant () {
      if (this.error_message.length) {
        return 'danger'
      }

      return 'success'
    }
  },
  mounted () {
    this.$emit('loading', true)
    const data = {
    }
    const url = `/api/kagechi/download/${this.$route.params.main_id}/${this.$route.params.count}`
    this.axios.post(url, data).then(res => {
      this.confirmed = true
      const link = document.createElement('a')
      link.href = res.data.base64Pdf
      link.download = res.data.pdfName
      link.click()
      this.error_message = ''
      this.is_done = true
      this.$emit('loading', false)
    }).catch(error => {
      this.confirmed = false
      if (error.response.data.error) {
        this.error_message = error.response.data.error
      } else {
        this.error_message = 'error'
      }
      this.is_done = true
      this.$emit('loading', false)
    })
  }
}
</script>
