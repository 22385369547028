import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from './modules/user'
import alert from './modules/alert'
import gpx from './modules/gpx'
import googleMap from './modules/google-map'
import routeprice from './modules/routeprice'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    alert,
    gpx,
    googleMap,
    routeprice
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage
    })
  ]
})
