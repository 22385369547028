<template>
  <div></div>
</template>

<script>
export default {
  name: 'Top',
  data () {
    return {
    }
  },
  mounted () {
    if (this.$store.getters['user/isAutoLogin'] || this.$store.getters['user/isLoggedIn']) {
      location.href = '/menu'
    } else {
      location.href = '/login'
    }
  }
}
</script>
