<template>
  <b-container fluid>
    <div id="full-width-section" class="align-items-center d-flex justify-content-between mb-4 row">
      <change-title v-model="title" @change-title-error="validated"></change-title>

      <div class="ml-5 col-md-6">
        <ul class="stepFlow">
          <li class="completed">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 1</p>トリミング</span>
          </li>
          <li class="completed">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 2</p>評価対象地の測定</span>
          </li>
          <li class="active">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 3</p>評価対象地の編集</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 4</p>路線価の設定</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 5</p>測定結果</span>
          </li>
        </ul>
      </div>

      <div class="text-right">
        <b-btn variant="outline-secondary" class="px-5 mr-3" :to="`/kagechi/correct/${this.$route.params.main_id}`">戻る</b-btn>
        <b-btn variant="primary" class="px-3" @click="next">
          <div class="btn-2rows text-left">編集を確定し<br>路線価設定を開始する</div>
        </b-btn>
      </div>
    </div>

    <div>
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>

    <div class="align-items-center d-flex mb-4">
      <b-button v-if="helpUrl != ''" v-b-toggle.description variant="secondary" @click="openInNewTab(helpUrl)" class="align-items-center d-flex mr-2">
        <span class="align-items-center bg-white d-flex mr-2 rounded-pill" style="width: 18px; height: 12px;">
          <b-icon-book-fill variant="info"/>
        </span>
        詳細説明を見る
      </b-button>
      <play-video-btn v-if="helpUrlVideo"></play-video-btn>

      <b-dropdown variant="secondary" class="mx-2" v-if="showPdfDropdown">
        <template #button-content><b-icon-files class="mr-1" />PDFの確認</template>
        <b-dropdown-item-button @click="closeCanvasPDF" class="small">なし</b-dropdown-item-button>
        <b-dropdown-item-button v-for="(pdf, index) in pdfFiles" :key="index" @click="openCanvasPDF(index)" class="small">{{pdf.filename}} ({{pdf.page}}ページ)</b-dropdown-item-button>
      </b-dropdown>
    </div>

    <div class="row">
      <b-form inline class="pl-3 mb-4" style="width: 100%;">
        <b-button variant="danger" class="mr-5" @click="resetPage">編集前の状態に戻す</b-button>
        <b-form-radio-group v-model="bgReversed">
          <b-form-radio :value="true" class="mr-5">ダークモード</b-form-radio>
          <b-form-radio :value="false" class="mr-5">ライトモード</b-form-radio>
        </b-form-radio-group>
      </b-form>

      <div class="col-xl-8 col-lg-6 col-12">
        <div ref="box">
          <div class="d-flex">
            <canvas
              id="canvasPDF"
              ref="canvasPDF"
              v-show="showPdfCanvas"
              :width="canvasWidth"
              :height="canvasHeight"
              @mouseup="mouseUpPDF"
              @mousedown="mouseDownPDF"
              @mouseout="mouseUpPDF"
              @mousemove="mouseMovePDF"
              @wheel.prevent="changeScalePDF"
              />
            <canvas
              id="canvas"
              ref="canvas"
              :width="canvasWidth"
              :height="canvasHeight"
              @mouseup="mouseUp"
              @mousedown="mouseDown"
              @mousemove="mouseMove"
              @wheel.prevent="changeScale"
              />
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-6 col-12">
        <b-form class="mx-2 pt-2 row section-panel" @submit.prevent="adaptScaleValue">
          <b-form-group class="col-12" label="縮尺">
            <b-input-group prepend="１／">
              <b-form-input class="col-3" max="2000" min="0" type="number" v-model="scaleInput" />
              <b-input-group-append>
                <b-btn type="submit" variant="primary">決定</b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-form>

        <div class="mx-2 pt-2 row section-panel">
          <b-form-group class="col-12" label="操作">
            <b-form-radio-group id="toolbar" v-model="opMode" buttons size="sm">
              <b-form-radio
                value="move"
                :button-variant="opMode === 'move' ? 'primary' : 'outline-secondary'"
                @change="fhModeChage"
                v-b-tooltip.hover
                title="オブジェクトの移動"
                >
                <b-icon-arrows-move />
              </b-form-radio>
              <b-form-radio
                value="rotate"
                :button-variant="opMode === 'rotate' ? 'primary' : 'outline-secondary'"
                @change="fhModeChage"
                v-b-tooltip.hover
                title="オブジェクトの回転"
                >
                <b-icon-arrow-clockwise />
              </b-form-radio>
              <b-form-radio
                value="solid"
                :button-variant="opMode === 'solid' ? 'primary' : 'outline-secondary'"
                @change="fhModeChage"
                v-b-tooltip.hover
                title="実線の描画と実線の延長"
                >
                <img src="../../assets/images/toolbar/solid.png">
              </b-form-radio>
              <b-form-radio
                value="assist"
                :button-variant="opMode === 'assist' ? 'primary' : 'outline-secondary'"
                @change="fhModeChage"
                v-b-tooltip.hover
                title="アシスト線"
                >
                <img src="../../assets/images/ruler.png">
              </b-form-radio>
              <b-form-radio
                value="horizontal"
                :button-variant="opMode === 'horizontal' ? 'primary' : 'outline-secondary'"
                @change="fhModeChage"
                v-b-tooltip.hover
                title="平行描画"
                >
                H
              </b-form-radio>
              <b-form-radio
                value="vertical"
                :button-variant="opMode === 'vertical' ? 'primary' : 'outline-secondary'"
                @change="fhModeChage"
                v-b-tooltip.hover
                title="垂直描画"
                >
                V
              </b-form-radio>
              <b-form-radio
                :disabled="isExpiration"
                value="pasting"
                :button-variant="opMode === 'pasting' ? 'primary' : 'outline-secondary'"
                @change="fhModeChage"
                v-b-tooltip.hover
                title="合成（β版）"
                class="icon-btn"
                >
                <img src="../../assets/images/paste.png">
                <b-icon-award-fill v-if="isExpiration" class="freemium-icon icon" style="top:-10px" font-scale="1" />
              </b-form-radio>
              <b-form-radio
                :disabled="isExpiration"
                value="addPlot"
                :button-variant="opMode === 'addPlot' ? 'primary' : 'outline-secondary'"
                @change="fhModeChage"
                v-b-tooltip.hover
                title="頂点の追加及び削除"
                >
                <img src="../../assets/images/pointer.png">
                <b-icon-award-fill v-if="isExpiration" class="freemium-icon icon" style="top:-10px" font-scale="1" />
              </b-form-radio>
              <b-button
                :disabled="isExpiration"
                value="addVtx"
                :variant="isAddVtx ? 'primary' : 'outline-secondary'"
                @change="fhModeChage"
                @click="fhModeChage();setAddVtx();changeAddVtxFlag()"
                v-b-tooltip.hover
                title="頂点追加（距離指定）"
                >
                <img src="../../assets/images/menu_kagechi.png">
                <b-icon-award-fill v-if="isExpiration" class="freemium-icon icon" style="top:-10px" font-scale="1" />
              </b-button>
              <b-modal
                ref="AddVtx-modal"
                :id="`addVtx`"
                title="頂点追加（距離指定）"
                @ok="addVtx()"
                @hidden="changeAddVtxFlag()"
              >
                <div class="mt-3 mb-3">追加する頂点に隣接する頂点を指定してください。</div>
                <table>
                  <tr>
                    <th width="100">指定１</th>
                    <th width="100">指定２</th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-select v-model="addVtxData.vtx1" :options="conditionVertexes" />
                    </td>
                    <td>
                      <b-form-select v-model="addVtxData.vtx2" :options="conditionVertexes" />
                    </td>
                  </tr>
                </table>
                <div class="mt-3 mb-3">指定１からの距離を指定してください。</div>
                <table>
                  <tr>
                    <th width="100">位置</th>
                  </tr>
                  <b-input-group>
                    <b-form-input v-model="addVtxData.distance" />
                    <b-input-group-append>
                      <b-input-group-text>ｍ</b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </table>
              </b-modal>
              <!-- <b-form-radio value="freeHandPlot" :button-variant="opMode === 'freeHandPlot' ? 'primary' : 'outline-secondary'" @change="fhModeChage" v-b-tooltip.hover title="領域の追加"><b-icon-fullscreen /></b-form-radio> -->
              <b-button
                :disabled="isExpiration"
                value="condition"
                :variant="isConditionSelected ? 'primary' : 'outline-secondary'"
                v-b-tooltip.hover
                title="条件作図"
                @click="fhModeChage();setConditionDrawingVtxes();changeConditionDrawingdFlag()"
                >
                <b-icon-sliders />
                <b-icon-award-fill v-if="isExpiration" class="freemium-icon icon" style="top:-10px" font-scale="1" />
              </b-button>
              <b-modal
                ref="conditional-modal"
                :id="`drawingCondition`"
                title="条件作図"
                @ok="conditionDrawing"
                @hidden="changeConditionDrawingdFlag"
              >
                <table class="mb-3">
                  <tr>
                    <th width="100">頂点</th>
                    <th>距離<small>(m)</small></th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-select v-model="condition1.index" :options="conditionVertexes"/>
                    </td>
                    <td>
                      <b-form-input v-model="condition1.distance"/>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b-form-select v-model="condition2.index" :options="conditionVertexes"/>
                    </td>
                    <td>
                      <b-form-input v-model="condition2.distance"/>
                    </td>
                  </tr>
                </table>
              </b-modal>
              <b-button
                :disabled="isExpiration"
                title="無道路地の道路開設の設定"
                value="roadPlot"
                v-b-tooltip.hover
                :variant="isOpeningPassage ? 'primary' : 'outline-secondary'"
                @change="fhModeChage"
                @click="fhModeChage();setAddPlatVtxes();changeOpeningPassageFlag()"
                >
                <img src="../../assets/images/road.png">
                <b-icon-award-fill v-if="isExpiration" class="freemium-icon icon" style="top:-10px" font-scale="1" />
              </b-button>
              <b-modal
                ref="AddPlat-modal"
                :id="`addRoad`"
                title="無道路地の道路開設の設定"
                @ok="addRoad()"
                @hidden="changeOpeningPassageFlag()"
              >
                <span>対象地の不整形地が無道路地に該当する場合には、こちらの機能をご利用ください。</span>
                <span>指定する道路までの距離を無道路地の計算における通路開設部分として指定することができます。<br></span>
                <span>※指定した通路開設部分については、対象地の不整形地の面積に算入しておりません。</span>
                <div class="mt-3 mb-3">通路を開設する土地の辺の両端の頂点を指定してください。</div>
                <table>
                  <tr>
                    <th width="100">頂点１</th>
                    <th width="100">頂点２</th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-select v-model="addPlat.vtx1" :options="conditionVertexes"/>
                    </td>
                    <td>
                      <b-form-select v-model="addPlat.vtx2" :options="conditionVertexes"/>
                    </td>
                  </tr>
                </table>
                <div class="mt-3 mb-3">上で指定した頂点のどちら側に開設するか指定してください。</div>
                <table>
                  <tr>
                    <th width="100">基準点</th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-select v-model="addPlat.whichSide" :options="getOption4WhichSide()" />
                    </td>
                  </tr>
                </table>
                <div class="mt-3 mb-3">通路を開設する路線を選択してください。</div>
                <table>
                  <tr>
                    <th width="100">路線</th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-select v-model="addPlat.line" :options="addPlatLines" />
                    </td>
                  </tr>
                </table>
                <div class="mt-3 mb-3">通路幅を指定してください。</div>
                <table>
                  <tr>
                    <th width="100">幅</th>
                  </tr>
                  <b-input-group>
                    <b-form-input v-model="addPlat.width" />
                    <b-input-group-append>
                      <b-input-group-text>ｍ</b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </table>
              </b-modal>
              <b-modal
                ref="confirm-addplat-modal"
                :id="`confirmAddPlat`"
                title="確認"
                @ok="clearPassageOpeningBuffer"
              >
                既に通路開設が行われています。
                現在の通路開設をキャンセルしますか？
              </b-modal>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="mx-2 pt-2 row section-panel">
          <b-form-group label="レイヤー" class="col-12">
            <div class="small layer-caption">※作図内容は、土地より背面に描画できません。</div>
            <draggable id="layer-list" tag="ul" class="list-group" v-model="layers" @end="draggableEnd">
              <b-list-group-item
                button
                v-for="(layer, index) in layers"
                v-bind:class="layer.selected ? 'bg-dark' : ''"
                :active="layer.selected"
                :key="index"
                @click.stop="selectChangeLayer(layer)"
                >
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <b-button size="sm" variant="dark" @click="toggleShowLayer(layer)" class="mr-3">
                      <b-icon-eye-fill v-if="layer.showing" />
                      <b-icon-eye-slash-fill v-else />
                    </b-button>
                    <span class="pl-2 layer-icon">
                      <b-icon-card-image v-if="layer.type === 'plat'" />
                      <img src="../../assets/images/toolbar/solid.png" v-if="layer.type === 'solid'" class="color-invert">
                      <img src="../../assets/images/toolbar/dashed.png" v-if="layer.type === 'dashed'" class="color-invert">
                      <img src="../../assets/images/toolbar/dotted.png" v-if="layer.type === 'dotted'" class="color-invert">
                    </span>
                    <span class="pl-2">{{ layer.name }}</span>
                  </div>
                  <div>
                    <template v-if="layer.type === 'plat'">
                      <b-button size="sm" variant="danger" @click="$bvModal.show(`delete-confirm-${index}`)">×</b-button>
                      <b-modal :id="`delete-confirm-${index}`" hide-header @ok="deleteLayer(index)" ok-variant="danger">
                        <div>土地の削除操作は元に戻せません。本当によろしいですか？</div>
                      </b-modal>
                    </template>
                    <template v-else>
                      <b-button size="sm" variant="danger" @click="deleteLayer(index)">×</b-button>
                    </template>
                  </div>
                </div>
              </b-list-group-item>
            </draggable>
          </b-form-group>
        </div>

        <div class="mx-2 pt-2 row section-panel">
          <p v-if="isExpiration">
            <b-icon-award-fill class="freemium-icon icon ml-1" font-scale="1.5"/>
            <span class="ml-5">以下は有料版でご利用になれます</span>
          </p>
          <b-form-group label="測定範囲の設定" v-bind:class="isExpiration ? 'col-12 text-muted' : 'col-12'">
            <b-button
              :disabled="isExpiration"
              variant="primary"
              class="mr-3"
              @click="addRange()"
              >
              追加
            </b-button>
            <b-button
              :disabled="isExpiration"
              variant="danger"
              @click="deleteRange()"
              >
              削除
            </b-button>

            <b-tabs class="mt-3" v-if="ranges !== undefined && ranges.length > 0" v-model="activeTabIndex">
              <b-tab v-for="(range, index) in ranges" :key="index" :title="`測定範囲${(index + 1)}`">
                <b-btn class="mt-3" size="sm" variant="primary" @click="selectAll">全て選択</b-btn>
                <b-checkbox-group class="mt-2" v-model="range.vertexes" @change="canvasUpdate()">
                  <b-checkbox v-for="(v, i) in rangeVertexes" :key="i" :value="v">頂点{{ i + 1 }}</b-checkbox>
                </b-checkbox-group>
              </b-tab>
            </b-tabs>
          </b-form-group>
        </div>

        <div class="row section-panel mx-2 pt-2">
          <div v-bind:class="isExpiration ? 'col-12 col-xl-12 col-lg-12 text-muted' : 'col-12 col-xl-12 col-lg-12'">
            <p>
              ※合成・編集した評価対象地に対し再測定を行う場合<br>
              こちらのステップで一度PDFをダウンロードいただき、再度アップロード画面からお進みください。
            </p>
            <b-form-group :disabled="isExpiration" label="PDFをダウンロード">
              <div>
                <b-form-group  label="頂点番号">
                  <b-form-radio-group v-model="dispVertexNum">
                    <b-form-radio :value="true" class="mr-5">表示</b-form-radio>
                    <b-form-radio :value="false">非表示</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <div>
                <b-form-group  label="距離表示">
                  <b-form-radio-group v-model="dispDistance">
                    <b-form-radio :value="true" class="mr-5">表示</b-form-radio>
                    <b-form-radio :value="false">非表示</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
              <b-btn variant="primary" class="px-2" @click="downloadPDF">ダウンロード</b-btn>
              <br>
              <br>
              <b-btn variant="info" class="px-2" @click="go2Trimming">⇦アップロード画面へ戻る</b-btn>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    <video-modal v-if="helpUrlVideo" :url="helpUrlVideo"></video-modal>
  </b-container>
</template>

<script>
import CanvasMixin from '../../mixins/canvas'
import ChangeTitle from '../../components/ChangeTitle'
import VideoModal from '../../components/VideoModal'
import PlayVideoBtn from '../../components/PlayVideoBtn'
import draggable from 'vuedraggable'
import validation from '../../mixins/validation'
import videoWatch from '../../mixins/video_watch'
import constants from '../../constants'
import colors from '../../colors'

const SCALE_STEP = 0.1
// const SCALE4PDF = 1.7391

export default {
  name: 'Edit1',
  mixins: [CanvasMixin, validation, videoWatch],
  components: {
    draggable,
    ChangeTitle,
    VideoModal,
    PlayVideoBtn
  },
  data: () => {
    return {
      color: colors.color, // これを書いておかないと、colors 変数使っていないとのエラーになる
      drag: { dragging: 0, x: 0, y: 0 },
      rotate: { rotating: false, startAngle: 0, downPointAngle: 0 },
      canvasWidth: 0,
      canvasHeight: 600,
      canvasContext: undefined,
      canvasContextPDF: undefined,
      pdfFiles: undefined,
      bgReversed: true,
      opMode: 'move',
      showingColorPicker: false,
      selectingColor: { rgba: { r: 255, g: 0, b: 0, a: 1 } },
      layers: [],
      drawingLayer: {},
      lineDefaultName: { solid: '実線', dashed: 'アシスト線' },
      title: '',
      defaultMarginBtwPlats_X: 10,
      defaultMarginBtwPlats_Y: 10,
      drawings: [],
      roadAreaSum: 0,
      showTwoCanvas: false,
      showPdfCanvas: false,
      image: new Image(),
      position: { x: 0, y: 0 },
      dragPDF: { dragging: 0, startImagePos: { x: 0, y: 0 }, startMousePos: { x: 0, y: 0 } },
      pdfDragging: false,
      pdfIndex: '',
      isExpiration: false,
      // #region 縮尺率関連=================================================
      scaleIndex: 0,
      scaleIndexPDF: 0,
      scaleInput: 0, // 現在の縮尺(ホイール操作で加減／手入力で指定)
      scaleFactor: 1, // (現在の縮尺 / STEP3での縮尺の初期値)
      unityScale: 0, // 初期表示の縮尺
      // #endregion 縮尺率関連=================================================
      // #region アシスト線に関するデータ=================================================
      assistFlag: false,
      assistEdgeOn: { edgeFlag: false, movingFlag: false, vIdx: '', pIdx: '', start: { x: '', y: '' }, end: { x: '', y: '' } },
      // #endregion アシスト線に関するデータ=================================================
      // #region 頂点追加削除処理用／頂点、辺にマウスオンしているかに関するデータ=================================================
      addOrDeleteVertex: false, // 頂点削除の際に使用するバッファー
      addOrDeleteVertexIndex: 0, // 頂点削除の際に使用するバッファー
      onTheLineIndex: 0, // 土地の辺にマウスオーバーしている際のその辺を表すインデックスとして、両端の頂点のうち頂点番号が小さい方を保存する
      fhOnTheLine: false, // 頂点、辺にマウスオンしているかのフラグ → 頂点追加、削除用
      fhOnTheLineIndex: 0, // マウスオンしている辺や線のインデックス
      // lineDraggFrom: { x: 0, y: 0 },
      addPlatBothEndsIndexes: { platId: '', start: '', end: '' }, // 頂点追加の際のバッファ
      // #endregion 頂点、辺にマウスオンしているかに関するデータ=================================================
      // #region フリーハンド用のデータ 現行で不要(2023/06/15)=================================================
      fhPlatlist: [], // 追加した領域のバッファ
      fhOnThePlatIndex: 0,
      fhRelativePosition: { x: '', y: '' },
      fhOnTheLineMouseDown: 0, // フリーハンドでの領域追加用。現行で不要(2023/06/15)
      // #endregion フリーハンド用のデータ 現行で不要(2023/06/15)=================================================
      // #region 垂直・平行作図／線の延長用データ=================================================
      fhVtxArray: [], // フリーハンドでの土地追加に使っていたバッファ。現行で不要(2023/06/15)
      hvTarget: { // 垂直・平行作図の対象に関してのデータ。現行で不要(2023/06/15)
        flag: false, // フラグ
        type: '', // 対象のタイプ(土地：'plat' もしくは 線 : 'solid'/'dashed')
        index: 0 // インデックス
      },
      verticalDrawing: { // 垂直描画の座標情報
        sx: '', // 始点X座標
        sy: '', // 始点Y座標
        ex: '', // 終点X座標
        ey: '' // 終点Y座標
      },
      horizontalDrawing: { // 平行描画の座標情報
        sx: 0, // 始点X座標
        sy: 0, // 始点Y座標
        ex: 0, // 終点X座標
        ey: 0 // 終点Y座標
      },
      vh2Lines: { // 垂直・平行作図の対象に関してのデータ。
        type: '', // 描画タイプ。平行 or 垂直
        flag: false, // フラグ
        lineType: '', // 対象の線のタイプ＝描画する線のタイプ
        lineIndex: '' // 上記タイプ中でのlayerインデックス
      },
      extendLine: { start: false, end: false, extend: false }, // 線に対する延長操作のバッファー
      onTheLine: false, // 頂点、辺にマウスオンしているかのフラグ → 平行・垂直描画用
      // #endregion 垂直・平行作図用データ=================================================
      // #region 条件作図用データ=================================================
      isConditionSelected: false, // ツール欄の中の条件作図ボタンについてのフラグ管理
      isAddVtxSelected: false, // ツール欄の中の頂点追加条件作図ボタンについてのフラグ管理
      conditionVertexes: [],
      conditionDrawingVtx: [],
      condition1: { index: 0, distance: 0 }, // 指定された頂点1
      condition2: { index: 0, distance: 0 }, // 指定された頂点2
      // #endregion 条件作図用データ=================================================
      // #region 合成処理用データ=================================================
      pasteFlag: false,
      pastingData: { // 合成処理のためのデータ
        paste: false, // 合成フラグ
        datas: [], // 合成のためのデータ。合成前の辺の傾きと、バックグラウンドの画像の原点を保存
        fromTo: [], // どの土地(to)をどの土地(from)に貼り付けるかを保存
        angle: '' // 使用しない
      },
      synthesisBuf: { indexFrom: '', startFrom: '', endFrom: '', indexTo: '', startTo: '', endTo: '' }, // 合成を実行する条件を保存するオブジェクト
      pastingBuffArr: [], // 完了した合成情報・条件を保存する
      // #endregion 合成処理用データ=================================================
      // #region 通路開設用データ=================================================
      addPlatLines: [], // モーダルでの路線ドロップダウンのオプション用バッファ
      addPlat: { // 通路開設に関する情報
        isPassageOpened: false, // フラグ
        vtx1: '', // 通路開設の始点１(頂点インデックスのみ)
        vtx2: '', // 通路開設の始点２(頂点インデックスのみ)
        whichSide: '', // 通路をどちらの頂点に接して開設するか(頂点インデックスのみ)
        whichSideVtx: { x: 0, y: 0 },
        width: '', // 通路幅
        line: '', // 通路実線
        area: 0, // 通路開設部の面積
        centroidBeforeAdd: { x: 0, y: 0 }, // 通路開設前の重心
        centroidAfterAdd: { x: 0, y: 0 }, // 通路開設後の重心
        diffCentroid: { x: 0, y: 0 }, // 通路開設前後の重心座標差
        passageVertexes: []
      },
      isOpeningPassage: false, // 通路開設モードのフラグ
      openingPassageway: undefined, // 通路開設情報
      // #endregion 通路開設用データ=================================================
      // #region 頂点追加（距離指定）用データ=================================================
      addVtxData: { // 頂点追加（距離指定）に関する情報
        vtx1: '', // 頂点追加（距離指定）の始点１(頂点インデックスのみ)
        vtx2: '', // 頂点追加（距離指定）の始点２(頂点インデックスのみ)
        distance: '' // 追加位置
      },
      isAddVtx: false, // 頂点追加（距離指定）モードのフラグ
      // #endregion 頂点追加（距離指定）用データ=================================================
      ranges: [],
      rangeVertexes: [],
      activeTabIndex: 0,
      helpUrl: '',
      helpUrlVideo: '',
      needPlayVideo: false,
      showPdfDropdown: true,
      dispVertexNum: true,
      dispDistance: true,
      pdfData: undefined,
      // #region マウスの動くスピードを監視=================================================
      mousePositionMonitor: [], // マウスポジションの移動速度をwatchで監視するためのバッファー
      tooFastMoving: false // 回転操作の際に早く動かしすぎると処理落ちして背景画像と頂点座標の位置関係がズレてしまう。true時は回転処理のスピードが遅くなるよう処理を書いてある
      // #endregion マウスの動くスピードを監視=================================================
    }
  },
  computed: {
    scale () {
      return 1 + this.scaleIndex * SCALE_STEP
    },
    scalePDF () {
      return 1 + this.scaleIndexPDF * SCALE_STEP
    },
    pixelsPerMeter () {
      return (100000 / 25.4)
    },
    scaledImageSize () {
      return {
        width: this.$refs.canvas.width / this.scale,
        height: this.$refs.canvas.height / this.scale
      }
    },
    scaledImageSizePDF () {
      return {
        width: this.$refs.canvas.width / this.scalePDF,
        height: this.$refs.canvas.height / this.scalePDF
      }
    },
    getCopyLayers () {
      return this.getCopyObject(this.layers)
    }
  },
  mounted () {
    this.$emit('loading', true)

    const username = this.$store.getters['user/user'].username
    const url = `/api/account/stripeExpiration/${username}`
    this.axios.post(url).then(res => {
      this.isExpiration = res.data.isExpiration
      this.$emit('loading', false)
    }).catch(error => {
      this.$emit('loading', false)
      if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
        this.validated(error.response.data)
      } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
        this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
      }
    })

    // 操作説明のURL取得
    this.axios.get('/api/master/search/help_urls').then(res => {
      const autoPlayedVideos = localStorage.getItem('autoPlayedVideos')
        ? JSON.parse(localStorage.getItem('autoPlayedVideos')) : []

      this.helpUrl = res.data.urls.step3
      this.helpUrlVideo = res.data.urls.step3_video

      if (this.helpUrlVideo && !autoPlayedVideos.includes(this.helpUrlVideo)) {
        this.needPlayVideo = true
        autoPlayedVideos.push(this.helpUrlVideo)
        localStorage.setItem('autoPlayedVideos', JSON.stringify(autoPlayedVideos))
      }
    }).catch(error => {
      this.errors = { error: `システムエラーが発生しました。${error}` }
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })

    this.unityScale = this.$route.query.unityScale !== undefined ? this.$route.query.unityScale : 0

    this.axios.get(`/api/kagechi/edit1/${this.$route.params.main_id}`).then(res => {
      let platIdx = 1
      let buildingIdx = 1
      this.pdfFiles = res.data.pdfs
      this.setShowPdfDropdownFlag()
      this.layers = []
      this.title = res.data.title
      this.scaleInput = res.data.scale
      this.unityScale = res.data.scale

      for (let index = 0; index < res.data.plats.length; index++) {
        const plat = res.data.plats[index]
        plat.type = 'plat'
        if (plat.fileName.startsWith('[土地]')) {
          plat.name = `土地${platIdx}`
          platIdx++
        } else if (plat.fileName.startsWith('[建物]')) {
          plat.name = `建物${buildingIdx}`
          buildingIdx++
        } else {
          plat.name = `土地${platIdx}`
          platIdx++
        }
        plat.showing = true
        plat.selected = false
        plat.angle = 0
        plat.imageObj = new Image()
        plat.imageObj.onload = () => {
          plat.imageLoaded = true
        }
        plat.imageObj.src = plat.image
        if (res.data.composed_vertexes) {
          plat.vertexes = res.data.composed_vertexes
          const max = plat.vertexes.length - 1
          plat.vertexes.forEach((v, i) => {
            const prev = i === 0 ? max : i - 1
            v.name = '頂点' + (i + 1)
            v.distance_meter = Math.sqrt(this.calcDistanceDot2Dot(v.x, v.y, plat.vertexes[prev].x, plat.vertexes[prev].y)) / this.pixelsPerMeter * this.scaleInput
          })
          plat.imageObj.src = res.data.composed_image
        }
        this.layers.push(plat)
        if (res.data.composed_vertexes) {
          break
        }
        // 合成のためのデータバッファを土地の数だけ用意
        this.pastingData.datas.push({ initDiffAngle: '', pictZeroPoint: { x: '', y: '' } })
        // 合成処理のためにバックグラウンドの画像のゼロ点を算出
        const zeropoint = this.calcZeroPoint(plat)
        this.reflectZeroPointToPastingdata(index, res.data.scale, zeropoint)
      }

      // すでに測定範囲が存在すれば初期化
      const plats = this.layers.filter(l => l.type === 'plat' && l.name.search('土地') >= 0)
      if (plats.length === 1 && res.data.ranges.length > 0) {
        this.rangeVertexes = plats[0].vertexes

        res.data.ranges.forEach((r, i) => {
          this.ranges.push({ vertexes: [], routePrices: [], setbacks: [], area: 0 })
          r.vertexes.forEach(rv => {
            this.rangeVertexes.forEach(rv2 => {
              if (rv2.name === rv.name) {
                this.ranges[i].vertexes.push(rv2)
              }
            })
            this.ranges[i].area = this.calcPlatArea(this.ranges[i].vertexes)
          })
        })
      }

      if (res.data.drewLayers !== null) {
        res.data.drewLayers.forEach(layer => {
          this.layers.push(layer)
        })
      }

      this.initCanvas()

      const resizeObserver = new ResizeObserver((entries) => {
        const loadingPlats = this.layers.filter(layer => layer.type === 'plat' && !layer.imageLoaded)
        if (loadingPlats.length === 0) {
          this.resizeWindow()
        }
        if (this.showPdfCanvas === true) {
          this.resizeWindow()
        }
      })

      if (this.$refs.box) {
        resizeObserver.observe(this.$refs.box)
      }

      if (res.data.opening_passageway) {
        this.openingPassageway = res.data.opening_passageway
        if (this.openingPassageway.isPassagewayOpened) {
          this.addPlat.isPassageOpened = true
          this.addPlat.passageVertexes = this.openingPassageway.passagewayVertexes
          this.addPlat.whichSideVtx.x = this.openingPassageway.whichSideVtx.x
          this.addPlat.whichSideVtx.y = this.openingPassageway.whichSideVtx.y
        }
      }

      this.$emit('loading', false)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeWindow)
  },
  watch: {
    bgReversed () {
      this.canvasUpdate()
    },
    selectingColor (value) {
      const selectedLayer = this.layers.filter(l => l.selected && l.type !== 'plat')
      if (selectedLayer.length > 0) {
        selectedLayer[0].color = colors.selectingColorRgba(this.bgReversed)
        this.canvasUpdate()
      }
    },
    activeTabIndex () {
      this.canvasUpdate()
    },
    getCopyLayers: {
      handler: function (newVal, oldVal) {
        // 頂点追加、通路開設、合成時に条件作図の描画にバグが出ないよう、条件作図のバッファのデータ更新
        const newPlats = newVal.filter(layer => layer.type === 'plat')
        const oldPlats = oldVal.filter(layer => layer.type === 'plat')
        if (newVal.length !== oldVal.length) {
          // 土地や作図が追加・削除された場合は処理を行わない
          return
        } else if (this.conditionDrawingVtx.length === 0) {
          // 条件作図が設定されていない場合は処理を行わない
          return
        }
        this.conditionDrawingVtx.forEach(c => {
          const newPlat = newPlats.filter(layer => layer.id === c.platID)
          const oldPlat = oldPlats.filter(layer => layer.id === c.platID)
          if (newPlat[0].vertexes.length === oldPlat[0].vertexes.length) {
            // 頂点の数が変わっていなかったら処理は行わない
            return
          }
          const oldVtx = oldPlat[0].vertexes[c.index]
          newPlat[0].vertexes.forEach((v, i) => {
            if (v.x === oldVtx.x && v.y === oldVtx.y) {
              c.index = i
            }
          })
        })
        // TODO: 一時的にコメントアウト
        // this.canvasUpdate()
      },
      deep: true,
      immediate: false
    },
    mousePositionMonitor: {
      handler: function (newVal, oldVal) {
        const [newX, newY] = newVal
        const [oldX, oldY] = oldVal
        // 変化量を考える
        const deltaX = Math.abs(newX - oldX)
        const deltaY = Math.abs(newY - oldY)
        const delta = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2))
        this.tooFastMoving = delta > 50 // 速さ判定
        if (this.tooFastMoving) {
        }
      },
      deep: true
    },
    addPlat: {
      handler: function (newVal, oldVal) {
        // 頂点指定が変わったら、基準点のドロップダウンのオプションを更新
        if (newVal.vtx1 !== oldVal.vtx1 || newVal.vtx2 !== oldVal.vtx2) {
          this.getOption4WhichSide()
        }
      },
      deep: true
    }
  },
  methods: {
    showToast (message, variant) {
      this.$bvToast.toast(message, {
        noCloseButton: true,
        toaster: 'b-toaster-bottom-center',
        variant: variant || 'secondary',
        autoHideDelay: 3000
      })
    },
    initCanvas () {
      if (this.$refs.box === undefined) {
        return
      }
      this.canvasWidth = this.$refs.box.offsetWidth - 4 // border分だけマイナスする
      this.canvasHeight = this.canvasWidth / 2

      if (this.$refs.canvas && this.$refs.canvas.width > 0 && this.$refs.box.offsetWidth > 0) {
        this.canvasContext = this.$refs.canvas.getContext('2d', { willReadFrequently: true })

        let allLoaded = false
        while (!allLoaded) {
          const loadingPlats = this.layers.filter(layer => layer.type === 'plat' && !layer.imageLoaded)
          if (loadingPlats.length === 0) {
            allLoaded = true
          }
        }

        setTimeout(() => {
          if (this.showPdfCanvas === false) {
            this.canvasUpdate()
          }
          if (this.showPdfCanvas === true && this.showTwoCanvas === false) {
            this.closeCanvasPDF()
            this.openCanvasPDF(this.pdfIndex)
            this.canvasUpdatePDF(this.pdfIndex)
            this.showTwoCanvas = true
          }
          this.canvasUpdate()
        }, 10)
      } else {
        setTimeout(() => {
          this.initCanvas()
        }, 500)
      }
    },
    toggleShowLayer (layer) {
      layer.showing = !layer.showing
      if (layer.type === 'solid') {
        this.toggleConditionDrawingVtx(layer)
      }
      this.canvasUpdate()
    },
    toggleConditionDrawingVtx (layer) {
      this.conditionDrawingVtx.forEach(function (vtx) {
        if (
          (
            (vtx.x2 === layer.points.fromX) &&
            (vtx.y2 === layer.points.fromY)
          ) ||
          (
            (vtx.x2 === layer.points.toX) &&
            (vtx.y2 === layer.points.toY)
          )
        ) {
          vtx.isShown = !vtx.isShown
        }
      })
    },
    getCanvasTopLeftPosition () {
      const rect = this.$refs.canvas.getBoundingClientRect()
      return [rect.left, rect.top]
    },
    getPointerPosition (event) {
      const [left, top] = this.getCanvasTopLeftPosition()
      // 移動速度を監視するため一度バッファーに入れる
      this.mousePositionMonitor = [
        event.clientX - left,
        event.clientY - top
      ]
      return this.mousePositionMonitor
    },
    selectChangeLayer (clickedLayer) {
      this.layers.forEach(layer => {
        if (layer === clickedLayer) {
          layer.selected = !layer.selected
        } else {
          layer.selected = false
        }
      })
      // 選択した土地の色反転を行うため、canvasUpdate() のコールを追加
      this.canvasUpdate()
    },
    draggableEnd () {
      this.canvasUpdate()
    },
    deleteLayer (deleteIndex) {
      const layers = []
      const counts = {}
      this.layers.forEach((layer, index) => {
        if (deleteIndex !== index) {
          if (layer.type === 'plat') {
            layers.push(layer)
          } else {
            if (!Object.hasOwnProperty.call(counts, layer.type)) {
              counts[layer.type] = 0
            }
            counts[layer.type] += 1
            layer.name = this.lineDefaultName[layer.type] + counts[layer.type]
            layers.push(layer)
          }
        } else {
          // 道路だった場合、道路面積を面積を更新
          if (layer.freehand === 'road') {
            const roadArea = this.calcPlatArea(layer.vertexes)
            this.roadAreaSum -= roadArea
          }
          if (layer.type === 'solid') {
            this.removeConditionDrawingVtx(layer)
          }
        }
      })

      // 合成のためのデータバッファを更新
      this.pastingData.datas.splice(deleteIndex, 1)

      this.layers = layers
      this.canvasUpdate()
    },
    removeConditionDrawingVtx (layer) {
      const vtxLayers = []
      this.conditionDrawingVtx.forEach(function (vtx) {
        if (
          (vtx.x2 !== layer.points.fromX) &&
          (vtx.x2 !== layer.points.toX) &&
          (vtx.y2 !== layer.points.fromY) &&
          (vtx.y2 !== layer.points.toY)
        ) {
          vtxLayers.push(vtx)
        }
      })

      this.conditionDrawingVtx = vtxLayers
    },
    next () {
      if (!this.checkPlatsAndBuildings()) {
        // 土地と建物に異常あり
        return
      }
      const plat = this.layers.filter(l => l.type === 'plat' && l.name.substr(0, 2) === '土地')
      // const building = this.layers.filter(l => l.type === 'plat' && l.name.substr(1, 2) === '建物')
      if (plat.length === 1) {
        if (this.ranges.length < 1) {
          let platArea = this.calcPlatArea(plat[0].vertexes)
          platArea = this.addPlat.isPassageOpened ? platArea - this.addPlat.area : platArea
          this.ranges.push({ vertexes: plat[0].vertexes, routePrices: [], setbacks: [], area: platArea })
        }
      } else if (plat.length === 0) {
        this.errors = { error: '測量対象の土地がありません。やりなおしてください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      } else {
        this.errors = { error: '測量対象の土地が複数あります。合成してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }
      this.$emit('loading', true)
      this.canvasUpdate(true)
      const plats = this.layers.filter(l => l.type === 'plat')
      const newPlats = []

      plats.forEach((plat) => {
        const vertexes = []
        plat.vertexes.forEach((vertex) => {
          vertexes.push({
            x: plat.relativePosition.x + vertex.x,
            y: plat.relativePosition.y + vertex.y,
            lat: vertex.lat,
            lon: vertex.lon
          })
        })
        if (plat.freehand) {
          newPlats.push({
            id: plat.id,
            vertexes: vertexes,
            relativePosition: plat.relativePosition,
            freehand: true
          })
        } else {
          newPlats.push({
            id: plat.id,
            vertexes: vertexes,
            relativePosition: plat.relativePosition,
            freehand: false
          })
        }
      })

      // 頂点番号に応じて並び変える（頂点追加の際に頂点番号順に並列に格納されていない現象対策）
      this.ranges.forEach((r) => {
        let vertexBufArray = []
        let hasVertexName = true
        r.vertexes.forEach((v) => {
          vertexBufArray.push('')
          if (v.name === undefined) {
            hasVertexName = false
          }
        })
        if (hasVertexName) {
          r.vertexes.forEach((v) => {
            vertexBufArray[v.name.split('頂点')[1] - 1] = v
          })
          vertexBufArray = vertexBufArray.filter(a => r.vertexes.includes(a))
          r.vertexes = vertexBufArray
        }
      })

      const data = {
        plats: newPlats,
        lines: this.layers.filter(l => l.type !== 'plat' && l.type !== 'dashed'),
        image: this.canvasContext.canvas.toDataURL(),
        ranges: this.ranges,
        relative: newPlats.relativePosition,
        scale: this.scaleInput,
        opening_passageway: this.openingPassageway
      }

      this.axios.post(`/api/kagechi/edit1/${this.$route.params.main_id}`, data).then(res => {
        this.$emit('loading', false)
        this.$router.push({ path: `/kagechi/edit2/${this.$route.params.main_id}`, query: { roadAreaSum: this.roadAreaSum, ranges: this.ranges } })
      }).catch(error => {
        this.$emit('loading', false)
        this.canvasUpdate()
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
      })
    },
    resizeWindow () {
      this.showTwoCanvas = false
      this.initCanvas()
    },
    getRoutePriceVertexOptions () {
      const options = []
      for (let i = 0; i < this.layers[0].vertexes.length; i++) {
        options.push({ value: i, text: `頂点${i + 1}` })
      }
      return options
    },
    fhModeChage () {
      this.fhVtxArray.splice(0)
      this.fhOnTheLine = false
      this.fhOnTheLineMouseDown = 0
      this.vh2Lines = { type: '', flag: false, lineType: '', lineIndex: '' }
      this.canvasUpdate()
    },
    meterScale () {
      const plats = this.layers.filter(l => l.showing && l.type === 'plat')
      const meterScale = plats[0].vertexes[1].distance_meter / Math.sqrt(this.calcDistanceDot2Dot(plats[0].vertexes[0].x, plats[0].vertexes[0].y, plats[0].vertexes[1].x, plats[0].vertexes[1].y))
      return meterScale
    },
    // #region 画面更新系処理=================================================
    /* 画面更新処理本体 */
    canvasUpdate (onlyPlatImage) {
      if (!this.canvasContext) {
        return
      }
      if (this.pastingData.paste === true) {
        // 合成の場合の描画処理
        this.drawPastePlats(onlyPlatImage)
      } else {
        // 合成でない場合の描画処理
        if (this.$refs.canvas === undefined) {
          return
        }
        // 真っ白にする
        this.canvasContext.fillStyle = 'white'
        this.canvasContext.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
        this.canvasContext.save()

        const plats = this.layers.filter(l => l.showing && l.type === 'plat')
        this.drawPlatImage(plats)

        if (onlyPlatImage) {
          return
        }

        if (this.bgReversed) {
          this.colorInversion(this.canvasContext, this.canvasWidth, this.canvasHeight)
        }

        plats.forEach(plat => {
          const fillColor = plat.selected ? `${colors.selectingLayerColorRgba(this.bgReversed)}` : `${colors.platColorRgba(this.bgReversed)}`
          this.drawPlat(this.canvasContext, plat.vertexes, this.scale, { x: -plat.relativePosition.x, y: -plat.relativePosition.y }, fillColor)
        })
      }

      // 作図
      const lines = this.layers.filter(l => l.showing && l.type !== 'plat')
      lines.forEach(line => {
        this.canvasContext.beginPath()
        this.canvasContext.moveTo(line.points.fromX, line.points.fromY)
        this.canvasContext.lineTo(line.points.toX, line.points.toY)
        this.canvasContext.lineWidth = line.lineWidth ? line.lineWidth : true
        let fillColor = colors.strokeColor1Rgba(this.bgReversed)
        if (line.type === 'dashed') {
          this.canvasContext.setLineDash([5, 5])
          fillColor = colors.assistLineColorRgba(this.bgReversed)
        } else if (line.type === 'dotted') {
          this.canvasContext.setLineDash([2, 3])
        } else if (line.type === 'solid') {
          this.canvasContext.setLineDash([0])
        } else if (line.type === 'assist') {
          this.canvasContext.setLineDash([5, 5])
        }
        if (line.selected) {
          fillColor = 'rgb(255, 0, 0)'
        }
        this.canvasContext.strokeStyle = fillColor

        this.canvasContext.closePath()
        this.canvasContext.stroke()
        this.canvasContext.setLineDash([0])
        this.canvasContext.strokeStyle = '#ffffff'

        const prevX = (line.points.fromX + line.points.toX) / 2
        const prevY = (line.points.fromY + line.points.toY) / 2
        const textX = prevX + 3
        const textY = prevY + 3
        const meterScale = this.meterScale()
        const meterDist = Math.sqrt(this.calcDistanceDot2Dot(line.points.fromX, line.points.fromY, line.points.toX, line.points.toY)) * meterScale
        const textValue = (Math.round(meterDist * 100) / 100) + ''

        const bgHeight = 9 * 1.5 + 5
        const bgWidth = this.canvasContext.measureText(textValue).width + 5
        const bgY = textY - bgHeight
        const bgX = textX - (bgWidth / 2)

        this.canvasContext.fillStyle = 'white'
        this.canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
        this.canvasContext.fillStyle = 'blue'
        this.canvasContext.fillText(textValue, textX, textY)
      })

      // 条件作図の距離を描画
      this.conditionDrawingVtx.forEach(v => {
        const layers = this.layers.filter(l => l.id === v.platID)
        const targetLayer = layers.length > 0 ? layers[0] : undefined
        if (targetLayer !== undefined && v.isShown) {
          this.drawLine(targetLayer.relativeVertexes[v.index].x,
            targetLayer.relativeVertexes[v.index].y,
            v.x2, v.y2, 2, 'rgb(255, 0, 0)', [5, 5])

          // 距離表示
          const dist = this.calcDistanceDot2Dot(targetLayer.relativeVertexes[v.index].x,
            targetLayer.relativeVertexes[v.index].y,
            v.x2, v.y2)
          // const dispDisntance = Math.sqrt(dist) * this.meterScale()
          this.displayDistance(targetLayer.relativeVertexes[v.index].x,
            targetLayer.relativeVertexes[v.index].y,
            v.x2, v.y2, dist, 0, 0)
        }
      })

      // フリーハンドの頂点
      if (this.opMode === 'freeHandPlot' || this.opMode === 'roadPlot') {
        for (let i = 0; i < this.fhVtxArray.length; i++) {
          this.drawRect(this.fhVtxArray[i].x + this.fhRelativePosition.x,
            this.fhVtxArray[i].y + this.fhRelativePosition.y,
            'rgb(0, 0, 255)')
          this.canvasContext.save()
          if (this.fhVtxArray.length > 1 && i > 0) {
            this.canvasContext.beginPath()
            this.canvasContext.moveTo(this.fhVtxArray[i].x + this.fhRelativePosition.x, this.fhVtxArray[i].y + this.fhRelativePosition.y)
            if (this.fhOnTheLineMouseDown === 2 && i === this.fhVtxArray.length) {
              this.canvasContext.lineTo(this.fhVtxArray[i - 1].x + this.fhRelativePosition.x, this.fhVtxArray[i - 1].y + this.fhRelativePosition.y)
              this.canvasContext.lineTo(this.fhVtxArray[0].x + this.fhRelativePosition.x, this.fhVtxArray[0].y + this.fhRelativePosition.y)
            } else {
              this.canvasContext.lineTo(this.fhVtxArray[i - 1].x + this.fhRelativePosition.x, this.fhVtxArray[i - 1].y + this.fhRelativePosition.y)
            }
            this.canvasContext.lineWidth = 1
            this.canvasContext.strokeStyle = 'rgb(0, 255, 0)'
            this.canvasContext.setLineDash([5, 2])
            this.canvasContext.closePath()
            this.canvasContext.stroke()

            // 距離表示
            const dist = this.calcDistanceDot2Dot(this.fhVtxArray[i].x, this.fhVtxArray[i].y, this.fhVtxArray[i - 1].x, this.fhVtxArray[i - 1].y)
            this.displayDistance(this.fhVtxArray[i].x,
              this.fhVtxArray[i].y,
              this.fhVtxArray[i - 1].x,
              this.fhVtxArray[i - 1].y,
              dist,
              this.fhRelativePosition.x,
              this.fhRelativePosition.y)
          }
        }
      }
      // 測定範囲の描画
      if (this.ranges.length > 0) {
        const layers = this.layers.filter(l => l.type === 'plat')
        const selectedLayer = layers.filter(l => l.name.search('土地') >= 0)
        this.activeTabIndex = this.activeTabIndex < 0 ? 0 : this.activeTabIndex
        const r = this.ranges[this.activeTabIndex]
        r.vertexes = r.vertexes.sort((a, b) => {
          const nameA = Number(a.name.replace('頂点', ''))
          const nameB = Number(b.name.replace('頂点', ''))
          if (nameA < nameB) {
            return -1
          } else if (nameA > nameB) {
            return 1
          } else {
            return 0
          }
        })
        r.vertexes.forEach((v, idx) => {
          const nextIdx = idx === r.vertexes.length - 1 ? 0 : idx + 1
          const nextVtx = r.vertexes[nextIdx]
          this.drawLine(
            (v.x + selectedLayer[0].relativePosition.x) * this.scale,
            (v.y + selectedLayer[0].relativePosition.y) * this.scale,
            (nextVtx.x + selectedLayer[0].relativePosition.x) * this.scale,
            (nextVtx.y + selectedLayer[0].relativePosition.y) * this.scale,
            1,
            colors.rangeOfMeasurementColorRgba(this.bgReversed),
            [0]
          )
        })
      }
    },
    /* 背景画像の描画 */
    drawPlatImage (plats) {
      let x = 30
      let y = 30
      let maxY = 0
      // 図面画像を書く
      plats.forEach((plat, index) => {
        if (!plat.relativePosition) {
          // 初回描画時
          plat.relativePosition = { x, y }
          plat.angle = 0
        }

        plat.relativeVertexes = []
        plat.vertexes.forEach(v => {
          plat.relativeVertexes.push({ x: v.x + plat.relativePosition.x, y: v.y + plat.relativePosition.y })
        })

        plat.relativeCentroid = this.calcCentroid(plat.relativeVertexes)

        this.canvasContext.save()

        const centroid = { x: plat.relativeCentroid.x - this.addPlat.diffCentroid.x, y: plat.relativeCentroid.y - this.addPlat.diffCentroid.y } // 通路開設がある場合は、重心の補正をしなければいけない。デフォルト値が0なので、条件を付けずに計算に入れている。
        this.canvasContext.translate(centroid.x, centroid.y)
        this.canvasContext.rotate(plat.angle)
        this.canvasContext.drawImage(
          plat.imageObj,
          plat.relativePosition.x - centroid.x,
          plat.relativePosition.y - centroid.y,
          plat.imageObj.width * this.scaleFactor,
          plat.imageObj.height * this.scaleFactor
        )

        this.canvasContext.restore()
        maxY = Math.max(maxY, y)
        if (plats[index + 1]) {
          // 次の土地がある
          if ((x + plat.imageObj.width + plats[index + 1].imageObj.width + 20) > this.canvasWidth) {
            // 右隣に次の土地を描画できない
            x = 20
            maxY = 0
            y = maxY + (50000 / this.scaleInput)
          } else {
            x += (plat.imageObj.width + 20)
          }
        }
      })
    },
    /* 合成した土地がある場合に使う画面更新処理 */
    drawSinglePlatImage (plat) {
      let x = this.defaultMarginBtwPlats_X
      const y = this.defaultMarginBtwPlats_Y
      // let maxY = 0

      // 図面画像を書く
      if (!plat.relativePosition) {
        // 初回描画時
        plat.relativePosition = { x, y }
        plat.angle = 0
      }
      plat.relativeVertexes = []
      plat.vertexes.forEach(v => {
        plat.relativeVertexes.push({ x: v.x + plat.relativePosition.x, y: v.y + plat.relativePosition.y })
      })
      plat.relativeCentroid = this.calcCentroid(plat.relativeVertexes)
      this.canvasContext.save()
      this.canvasContext.translate(plat.relativeCentroid.x, plat.relativeCentroid.y)
      this.canvasContext.rotate(plat.angle)
      // this.canvasContext.drawImage(plat.imageObj, -(plat.relativeCentroid.x - plat.relativePosition.x), -(plat.relativeCentroid.y - plat.relativePosition.y))
      this.canvasContext.drawImage(plat.imageObj,
        -(plat.relativeCentroid.x - plat.relativePosition.x),
        -(plat.relativeCentroid.y - plat.relativePosition.y),
        plat.imageObj.width * this.scaleFactor,
        plat.imageObj.height * this.scaleFactor)
      this.canvasContext.restore()

      // maxY = Math.max(maxY, y)
      if ((x + plat.imageObj.width * 2 + 10) > this.canvasWidth) {
        // 右隣に次の土地を描画できない
        x = 10
        // maxY = 0
        this.defaultMarginBtwPlats_Y = 10
      } else {
        this.defaultMarginBtwPlats_X = x + (plat.imageObj.width + 10)
      }
    },
    /* 合成した土地がある場合に使う背景画像を回転させて描画する処理 */
    rotatePlatImage (plat, platFrom, angleFrom, initAngle, zeroX, zeroY) {
      // 図面を回転する
      plat.relativePosition = platFrom.relativePosition
      plat.relativeVertexes = []
      plat.vertexes.forEach(v => {
        plat.relativeVertexes.push({ x: v.x + plat.relativePosition.x, y: v.y + plat.relativePosition.y })
      })
      plat.relativeCentroid = this.calcCentroid(plat.relativeVertexes)
      this.canvasContext.save()
      const minIdx = plat.minIdx
      // 画像描画
      this.canvasContext.translate(plat.relativeVertexes[minIdx].x, plat.relativeVertexes[minIdx].y)
      // this.canvasContext.translate(plat.relativeCentroid.x, plat.relativeCentroid.y)
      this.canvasContext.rotate(angleFrom + initAngle)
      const scale = this.scaleInput ? this.scaleInput : this.unityScale
      this.canvasContext.drawImage(plat.imageObj,
        -zeroX / scale,
        -zeroY / scale,
        plat.imageObj.width * this.scaleFactor,
        plat.imageObj.height * this.scaleFactor)
      this.canvasContext.rotate(-(angleFrom + initAngle))
      this.canvasContext.restore()
    },
    // #endregion 画面更新系処理=================================================
    // #region マウス系=================================================
    mouseDown (event) {
      // canvas内でのマウスポインタの位置
      const [x, y] = this.getPointerPosition(event)

      const layers = this.layers.filter(l => l.selected)
      const selectedLayer = layers.length > 0 ? layers[0] : undefined

      if (this.opMode === 'move' || this.opMode === 'pasting') {
        if (selectedLayer) {
          // 合成時に建物をつかんでいたらreturn
          if (selectedLayer.name.search('建物') > 0 && this.opMode === 'pasting') {
            this.errors = { error: '建物は合成できません。' }
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }
          // 選択中のレイヤーの移動
          this.drag = { dragging: 1, x, y }
          if (selectedLayer.type === 'plat') {
            this.drag.rx = selectedLayer.relativePosition.x
            this.drag.ry = selectedLayer.relativePosition.y
          } else {
            this.drag.points = { ...selectedLayer.points }
          }
        } else {
          this.showToast('移動対象の図形を選択してください。', 'danger')
        }
      } else if (this.opMode === 'rotate') {
        if (selectedLayer && ('angle' in selectedLayer) && ('relativeCentroid' in selectedLayer)) { /* TODO: プロパティが存在しない場合にエラーとなるため、判定条件を追加 */
          this.rotate.rotating = true
          this.rotate.startAngle = selectedLayer.angle

          this.rotate.downPointAngle = this.calcAngle(x - selectedLayer.relativeCentroid.x, y - selectedLayer.relativeCentroid.y)
          this.rotate.latestPoint = { x, y }
        } else {
          this.showToast('回転対象の図形を選択してください。', 'danger')
        }
      } else if (this.onTheLine) {
        this.drag = { dragging: 1, x, y }
      } else if (this.extendLine.start || this.extendLine.end) {
        this.extendLine.extend = true
        this.drag = { dragging: 1, x, y }
      } else if (this.opMode === 'condition') {
        // なにもしない
      } else if (this.opMode === 'freeHandPlot' || this.opMode === 'roadPlot') {
        // フリーハンド
        if (this.fhOnTheLine) {
          let plotX = 0
          let plotY = 0
          if (selectedLayer !== undefined) {
            if (this.fhOnTheLineMouseDown < 2) {
              const next = this.fhOnTheLineIndex === selectedLayer.vertexes.length - 1 ? 0 : this.fhOnTheLineIndex + 1
              const slope = this.calcTilt(selectedLayer.vertexes[this.fhOnTheLineIndex].x, selectedLayer.vertexes[this.fhOnTheLineIndex].y, selectedLayer.vertexes[next].x, selectedLayer.vertexes[next].y)
              if (slope.tilt > 4 || slope.tilt < -4) {
                plotX = (y - this.fhRelativePosition.y - slope.intcpt) / slope.tilt
                plotY = y - this.fhRelativePosition.y
              } else if (slope.tilt > 1) {
                plotX = (y - this.fhRelativePosition.y - slope.intcpt) / slope.tilt
                plotY = y - this.fhRelativePosition.y
              } else {
                plotX = x - this.fhRelativePosition.x
                plotY = slope.tilt * (x - this.fhRelativePosition.x) + slope.intcpt
              }
              this.fhVtxArray.push({ distance: '', distance_expected: '', distance_meter: '', name: `頂点${this.fhVtxArray.length + 1}`, x: plotX, y: plotY })
              this.fhOnTheLineMouseDown++
              this.canvasUpdate()
            } else if (this.fhOnTheLineMouseDown >= 2) {
            }
          }
        } else {
          this.fhVtxArray.push({ distance: '', distance_expected: '', distance_meter: '', name: `頂点${this.fhVtxArray.length + 1}`, x: x - this.fhRelativePosition.x, y: y - this.fhRelativePosition.y })
        }
      } else if (this.opMode === 'addPlot') {
        // 頂点追加
        if (this.fhOnTheLine) {
          // 押下された座標取得
          let plotX = 0
          let plotY = 0
          if (selectedLayer !== undefined) {
            const next = this.fhOnTheLineIndex === selectedLayer.vertexes.length - 1 ? 0 : this.fhOnTheLineIndex + 1
            if (selectedLayer.vertexes[this.fhOnTheLineIndex].x === selectedLayer.vertexes[next].x) {
              // 頂点を追加する辺の傾きが０だった場合
              plotX = selectedLayer.vertexes[this.fhOnTheLineIndex].x
              plotY = y - this.fhRelativePosition.y
            } else {
              // 頂点を追加する辺の傾きが０以外(普通)の場合
              const slope = this.calcTilt(selectedLayer.vertexes[this.fhOnTheLineIndex].x, selectedLayer.vertexes[this.fhOnTheLineIndex].y, selectedLayer.vertexes[next].x, selectedLayer.vertexes[next].y)
              // 傾きの大きさに応じて処理を切り分けて、誤差が大きくならないようにしている（傾き無限対策）
              if (slope.tilt > 4 || slope.tilt < -4) {
                plotX = (y - this.fhRelativePosition.y - slope.intcpt) / slope.tilt
                plotY = y - this.fhRelativePosition.y
              } else if (slope.tilt > 1) {
                plotX = (y - this.fhRelativePosition.y - slope.intcpt) / slope.tilt
                plotY = y - this.fhRelativePosition.y
              } else {
                plotX = x - this.fhRelativePosition.x
                plotY = slope.tilt * (x - this.fhRelativePosition.x) + slope.intcpt
              }
            }
            // 頂点を挿入
            this.layers.forEach(l => {
              if (l.id === this.addPlatBothEndsIndexes.platId) {
                // const meterScale = l.vertexes[0].distance_meter / l.vertexes[0].distance
                // 終点の情報の更新
                l.vertexes[this.addPlatBothEndsIndexes.end].distance = Math.sqrt(this.calcDistanceDot2Dot(l.vertexes[this.addPlatBothEndsIndexes.end].x, l.vertexes[this.addPlatBothEndsIndexes.end].y, plotX, plotY))
                const distEnd = l.vertexes[this.addPlatBothEndsIndexes.end].distance / this.pixelsPerMeter * this.scaleInput
                l.vertexes[this.addPlatBothEndsIndexes.end].distance_meter = Math.round(distEnd * 100) / 100 // 2桁に丸める
                // 頂点挿入
                const distStart = Math.sqrt(this.calcDistanceDot2Dot(l.vertexes[this.addPlatBothEndsIndexes.start].x, l.vertexes[this.addPlatBothEndsIndexes.start].y, plotX, plotY)) / this.pixelsPerMeter * this.scaleInput
                const template = {
                  distance: Math.sqrt(this.calcDistanceDot2Dot(l.vertexes[this.addPlatBothEndsIndexes.start].x, l.vertexes[this.addPlatBothEndsIndexes.start].y, plotX, plotY)),
                  distance_expected: '',
                  distance_meter: Math.round(distStart * 100) / 100, // 2桁に丸める
                  name: `頂点${l.vertexes.length + 1}`,
                  x: plotX,
                  y: plotY
                }
                if (this.addPlatBothEndsIndexes.end !== 0) {
                  l.vertexes.splice(this.addPlatBothEndsIndexes.start + 1, 0, template)
                } else {
                  l.vertexes.splice(0, 0, template)
                }
                // 頂点番号の振り直し
                l.vertexes.forEach((v, i) => {
                  v.name = `頂点${i + 1}`
                })
              }
            })
            this.fhVtxArray.push({ distance: '', distance_expected: '', distance_meter: '', name: `頂点${this.fhVtxArray.length + 1}`, x: plotX, y: plotY })
            this.fhOnTheLineMouseDown++
            this.canvasUpdate()
          }
        } else if (this.addOrDeleteVertex) {
          // 頂点削除
          selectedLayer.vertexes.splice(this.addOrDeleteVertexIndex, 1)
          selectedLayer.vertexes.forEach((v, i) => {
            const prev = i !== 0 ? i - 1 : selectedLayer.vertexes.length - 1
            const dist = this.calcDistanceDot2Dot(v.x, v.y, selectedLayer.vertexes[prev].x, selectedLayer.vertexes[prev].y)
            v.distance_meter = Math.sqrt(dist) / this.pixelsPerMeter * this.scaleInput
          })
          // 頂点番号振り直し
          selectedLayer.vertexes.forEach((v, i) => {
            v.name = `頂点${i + 1}`
          })
        }
      } else if (this.opMode === 'horizontal' || this.opMode === 'vertical') {
        if (this.vh2Lines.flag) {
          this.drag = { dragging: 1, x, y }
        }
      } else if (this.opMode === 'assist' && this.assistEdgeOn.edgeFlag && this.assistEdgeOn.movingFlag) {
        // アシスト線機能
        if (this.assistEdgeOn.pIdx === '') {
          // 頂点に当たってる場合
          const lines = this.layers.filter(l => l.type === 'dashed')
          const assistLineIdx = (lines !== undefined ? lines.length + 1 : 1)
          const sl = this.layers.filter(l => l.selected)
          if (sl !== undefined) {
            this.drawingLayer = {
              type: 'dashed',
              name: this.lineDefaultName.dashed + (assistLineIdx),
              selected: true,
              showing: true,
              points: { fromX: this.assistEdgeOn.start.x, fromY: this.assistEdgeOn.start.y, toX: this.assistEdgeOn.end.x, toY: this.assistEdgeOn.end.y },
              color: colors.selectingColorRgba(this.bgReversed) || 'rgba(0, 0, 0, 0)',
              lineWidth: 2
            }
            this.layers.forEach(layer => {
              layer.selected = false
            })
            this.layers.push(this.drawingLayer)
          }
        } else {
          // 辺に当たってる場合
          const lines = this.layers.filter(l => l.type === 'dashed')
          const assistLineIdx = (lines !== undefined ? lines.length + 1 : 1)
          const sl = this.layers.filter(l => l.selected)
          if (sl !== undefined) {
            this.drawingLayer = {
              type: 'dashed',
              name: this.lineDefaultName.dashed + (assistLineIdx),
              selected: true,
              showing: true,
              points: { fromX: this.assistEdgeOn.start.x, fromY: this.assistEdgeOn.start.y, toX: this.assistEdgeOn.end.x, toY: this.assistEdgeOn.end.y },
              color: colors.selectingColorRgba(this.bgReversed) || 'rgba(0, 0, 0, 0)',
              lineWidth: 2
            }
            this.layers.forEach(layer => {
              layer.selected = false
            })
            this.layers.push(this.drawingLayer)
          }
        }
        this.assistEdgeOn = { edgeFlag: false, movingFlag: false, vIdx: '', pIdx: '', start: { x: '', y: '' }, end: { x: '', y: '' } }
      } else if (this.opMode === 'assist' && this.assistEdgeOn.edgeFlag && !this.assistEdgeOn.movingFlag) {
        // アシスト線機能
        if (this.assistEdgeOn.pIdx === '') {
          // 頂点に当たってる場合
          const sl = this.layers.filter(l => l.selected)
          if (sl !== undefined) {
            const vx = sl[0].vertexes[this.assistEdgeOn.vIdx].x + sl[0].relativePosition.x
            const vy = sl[0].vertexes[this.assistEdgeOn.vIdx].y + sl[0].relativePosition.y
            this.assistEdgeOn.start.x = vx
            this.assistEdgeOn.start.y = vy
            this.assistEdgeOn.movingFlag = true
          }
        } else {
          // 辺に当たってる場合
          const sl = this.layers.filter(l => l.selected)
          if (sl[0] !== undefined) {
            const vx = sl[0].vertexes[this.assistEdgeOn.vIdx].x + sl[0].relativePosition.x
            const vy = sl[0].vertexes[this.assistEdgeOn.vIdx].y + sl[0].relativePosition.y
            const px = sl[0].vertexes[this.assistEdgeOn.pIdx].x + sl[0].relativePosition.x
            const py = sl[0].vertexes[this.assistEdgeOn.pIdx].y + sl[0].relativePosition.y
            // 傾き計算
            const slope = this.calcTilt(vx, vy, px, py)
            let startX, startY
            if (isFinite(slope.tilt)) {
              if (slope.tilt > 4 || slope.tilt < -4) {
                startX = (y - slope.intcpt) / slope.tilt
                startY = y
              } else if (slope.tilt > 1) {
                startX = (y - slope.intcpt) / slope.tilt
                startY = y
              } else {
                startX = x
                startY = slope.tilt * x + slope.intcpt
              }
            } else {
              startX = x
              startY = y
            }
            this.assistEdgeOn.start.x = startX
            this.assistEdgeOn.start.y = startY
            this.assistEdgeOn.movingFlag = true
          }
        }
      } else {
        if (this.opMode !== 'horizontal' && this.opMode !== 'vertical' && this.opMode !== 'assist') {
          // 作図
          const lines = this.layers.filter(l => l.type === this.opMode)
          this.drawingLayer = {
            type: this.opMode,
            name: this.lineDefaultName[this.opMode] + (lines.length + 1),
            selected: true,
            showing: true,
            points: { fromX: x, fromY: y },
            color: colors.selectingColorRgba(this.bgReversed) || 'rgba(0, 0, 0, 0)',
            lineWidth: 2
          }
          this.layers.forEach(layer => {
            layer.selected = false
          })
          this.layers.push(this.drawingLayer)
        }
      }
    },
    async mouseUp () {
      /* 平行・垂直作図処理 */
      if (this.onTheLine && this.drag.dragging > 0) {
        if (this.opMode !== 'vertical') {
          // 平行作図
          const lines = this.layers.filter(l => l.type === 'solid')
          this.drawingLayer = {
            type: 'solid',
            name: this.lineDefaultName.solid + (lines.length + 1),
            selected: true,
            showing: true,
            points: { fromX: this.horizontalDrawing.sx, fromY: this.horizontalDrawing.sy },
            color: colors.selectingColorRgba(this.bgReversed) || 'rgba(0, 0, 0, 0)',
            lineWidth: 2
          }
          this.layers.forEach(layer => {
            layer.selected = false
          })
          this.layers.push(this.drawingLayer)

          const layers = this.layers.filter(l => l.selected)
          const selectedLayer = layers.length > 0 ? layers[0] : undefined

          selectedLayer.points.fromX = this.horizontalDrawing.sx
          selectedLayer.points.fromY = this.horizontalDrawing.sy
          selectedLayer.points.toX = this.horizontalDrawing.ex
          selectedLayer.points.toY = this.horizontalDrawing.ey
        } else {
          // 垂直作図
          const lines = this.layers.filter(l => l.type === 'solid')
          this.drawingLayer = {
            type: 'solid',
            name: this.lineDefaultName.solid + (lines.length + 1),
            selected: true,
            showing: true,
            points: { fromX: this.verticalDrawing.sx, fromY: this.verticalDrawing.sy, toX: this.verticalDrawing.ex, toY: this.verticalDrawing.ey },
            color: colors.selectingColorRgba(this.bgReversed) || 'rgba(0, 0, 0, 0)',
            lineWidth: 2
          }
          this.layers.forEach(layer => {
            layer.selected = false
          })
          this.layers.push(this.drawingLayer)
        }
      } else if (this.vh2Lines.flag && this.drag.dragging > 0) {
        if (this.opMode !== 'vertical') {
          // 平行作図
          const lines = this.layers.filter(l => l.type === this.vh2Lines.lineType)
          this.drawingLayer = {
            type: this.vh2Lines.lineType,
            name: this.lineDefaultName[this.vh2Lines.lineType] + (lines.length + 1),
            selected: true,
            showing: true,
            points: { fromX: this.horizontalDrawing.sx, fromY: this.horizontalDrawing.sy },
            color: colors.selectingColorRgba(this.bgReversed) || 'rgba(0, 0, 0, 0)',
            lineWidth: 2
          }
          this.layers.forEach(layer => {
            layer.selected = false
          })
          this.layers.push(this.drawingLayer)

          const layers = this.layers.filter(l => l.selected)
          const selectedLayer = layers.length > 0 ? layers[0] : undefined

          selectedLayer.points.fromX = this.horizontalDrawing.sx
          selectedLayer.points.fromY = this.horizontalDrawing.sy
          selectedLayer.points.toX = this.horizontalDrawing.ex
          selectedLayer.points.toY = this.horizontalDrawing.ey
          this.vh2Lines = { type: '', flag: false, lineType: '', lineIndex: '' }
        } else {
          // 垂直作図
          const lines = this.layers.filter(l => l.type === this.vh2Lines.lineType)
          this.drawingLayer = {
            type: this.vh2Lines.lineType,
            name: this.lineDefaultName.solid + (lines.length + 1),
            selected: true,
            showing: true,
            points: { fromX: this.verticalDrawing.sx, fromY: this.verticalDrawing.sy, toX: this.verticalDrawing.ex, toY: this.verticalDrawing.ey },
            color: colors.selectingColorRgba(this.bgReversed) || 'rgba(0, 0, 0, 0)',
            lineWidth: 2
          }
          this.layers.forEach(layer => {
            layer.selected = false
          })
          this.layers.push(this.drawingLayer)
        }
        this.vh2Lines = { type: '', flag: false, lineType: '', lineIndex: '' }
        this.drag.dragging = 0
      }

      /* 合成処理（頂点合成処理） */
      if (this.pasteFlag) {
        this.pastingBuffArr.push(this.synthesisBuf) // この時点で合成用バッファをpushする(searchPasteCondition() から移動)
        this.applyPasteConditions() // 貼り付ける側と貼り付けられる側の情報を適応
        this.drawPastePlats(true) // 描画（回転して貼り付け）
        this.adjustPasteVertexes() // 頂点が重なり合うよう微調整
        this.synthVertexes() // 頂点、画像を合成
      }

      /* 初期化 */
      this.drag.dragging = 0
      this.rotate.rotating = false
      this.drawingLayer = {}
      this.onTheLine = false
      this.extendLine.start = false
      this.extendLine.end = false
      this.extendLine.extend = false
      this.vh2Lines = { type: '', flag: false, lineType: '', lineIndex: '' }
      this.pasteFlag = false
      this.pastingData.paste = false
      this.synthesisBuf = { indexFrom: '', startFrom: '', endFrom: '', indexTo: '', startTo: '', endTo: '' }
      this.pastingBuffArr = []
      // this.scaleFactor = 1 // 合成前に縮尺を変更して、合成すると表示が崩れ内容にscaleFactorをリセットする
      setTimeout(() => {
        this.canvasUpdate()
      }, 100)

      /* フリーハンド 現行で不要(2023/06/15) */
      if (this.fhOnTheLineMouseDown === 0 && !this.fhOnTheLine) {
        // なにもしない
      } else if (this.fhOnTheLineMouseDown === 2) {
        const plats = this.layers.filter(l => l.type === 'plat')
        const nextId = plats.length + 1
        if (this.fhVtxArray.length > 2) {
          if (this.opMode === 'freeHandPlot') {
            this.layers.push({ id: String(nextId), name: `土地${nextId}`, vertexes: [], relativePosition: this.fhRelativePosition, type: 'plat', visible: true, showing: true, selected: false, imageObj: this.layers[0].imageObj, image: this.layers[0].image, freehand: true, color: colors.freehandColorRgba(this.bgReversed) })
          } else if (this.opMode === 'roadPlot') {
            this.layers.push({ id: String(nextId), name: `土地${nextId}`, vertexes: [], relativePosition: this.fhRelativePosition, type: 'plat', visible: true, showing: true, selected: false, imageObj: this.layers[0].imageObj, image: this.layers[0].image, freehand: 'road', color: colors.roadColorRgba(this.bgReversed) })
          }
          this.fhVtxArray.forEach(v => {
            this.layers[this.layers.length - 1].vertexes.push(v)
          })
          this.fhPlatlist.push(plats.length)
          // 面積の記録
          if (this.opMode === 'roadPlot') {
            const roadArea = this.calcPlatArea(this.layers[this.layers.length - 1].vertexes)
            this.roadAreaSum += roadArea
          }

          this.fhOnTheLine = false
          this.fhOnTheLineIndex = 0
          this.fhRelativePosition = { x: '', y: '' }
          this.fhOnTheLineMouseDown = 0
          this.fhVtxArray.splice(0)
        } else {
          this.fhPlatlist.pop()
          this.fhModeChage()
        }
      }
    },
    mouseMove (event) {
      const [x, y] = this.getPointerPosition(event)
      const layers = this.layers.filter(l => l.selected)
      const selectedLayer = layers.length > 0 ? layers[0] : undefined
      const plats = this.layers.filter(l => l.showing && l.type === 'plat')

      // 対象が土地だった場合情報をバッファに入れておく
      const targetPlat = this.hvTarget
      if (selectedLayer !== undefined) {
        for (let i = 0; i < plats.length; i++) {
          if (plats[i].id === selectedLayer.id) {
            targetPlat.flag = true
            targetPlat.index = i
            targetPlat.type = 'plat'
          }
        }
      }

      /* 以下処理 */

      // #region ドラッグ中の処理=================================================
      if (this.drag.dragging > 0) {
        if (this.onTheLine && targetPlat.flag) { // 土地の辺に対する平行・垂直作図処理
          this.canvasUpdate()
          if (this.opMode === 'horizontal') {
            // 土地に対しての平行作図。選択した辺をドラッグして平行に移動するイメージ
            const target = plats[targetPlat.index]
            const next = this.onTheLineIndex === (target.vertexes.length - 1) ? 0 : this.onTheLineIndex + 1
            const mousePosition = { x: x, y: y }
            this.horizontalDraw(
              target.vertexes[this.onTheLineIndex],
              target.vertexes[next],
              target.relativePosition,
              mousePosition,
              this.drag
            )
          } else if (this.opMode === 'vertical') {
            const target = plats[targetPlat.index]
            const next = this.onTheLineIndex === target.vertexes.length - 1 ? 0 : this.onTheLineIndex + 1
            const mousePosition = { x: x, y: y }
            this.verticalDraw(
              target.vertexes[this.onTheLineIndex],
              target.vertexes[next],
              target.relativePosition,
              mousePosition,
              this.drag
            )
          }
        } else if (this.extendLine.extend) { // 既存の実線に対する長さの変更処理
          // 作図された実線を選択しているとき、両端の頂点をドラッグすると、実線が延長される
          this.drag.dragging += 1
          const mousePosition = { x: x, y: y }
          if (this.extendLine.start) {
            // 始点を延ばす
            const extend = this.extendLineByMouse(mousePosition, selectedLayer.points) // 延長する
            selectedLayer.points.fromX = extend.x
            selectedLayer.points.fromY = extend.y
          } else if (this.extendLine.end) {
            // 終点を延ばす
            const extend = this.extendLineByMouse(mousePosition, selectedLayer.points) // 延長する
            selectedLayer.points.toX = extend.x
            selectedLayer.points.toY = extend.y
          }
          this.canvasUpdate() // 画面更新
        } else if (this.vh2Lines.flag) { // 既存の実線に対する平行・垂直作図処理
          if (this.vh2Lines.type === 'horizontal') {
            // 実線に対しての平行作図。選択した実線をドラッグして平行に移動するイメージ
            this.canvasUpdate()
            this.drag.dragging += 1
            // 線に対しての平行作図
            const lines = this.layers.filter(l => l.type === 'solid' || l.type === 'dashed')
            const target = lines[this.vh2Lines.lineIndex]
            const fromVtx = { x: target.points.fromX, y: target.points.fromY }
            const toVtx = { x: target.points.toX, y: target.points.toY }
            const relativePosition = { x: 0, y: 0 }
            const mousePosition = { x: x, y: y }
            this.horizontalDraw(
              fromVtx,
              toVtx,
              relativePosition,
              mousePosition,
              this.drag
            )
          } else if (this.opMode === 'vertical') {
            this.canvasUpdate()
            this.drag.dragging += 1
            // 線に対しての垂直作図
            // this.vh2Lines.lineIndex は、this.layers.filter.type が solid, dashed で絞り込んだ後、indexOf() で 設定されている。
            // よって lines オブジェクトには solid, dashed 両方のデータが存在しなければ、存在しない配列のインデックスを参照してしまう。
            const lines = this.layers.filter(l => l.type === 'solid' || l.type === 'dashed')
            const target = lines[this.vh2Lines.lineIndex]
            const fromVtx = { x: target.points.fromX, y: target.points.fromY }
            const toVtx = { x: target.points.toX, y: target.points.toY }
            const mousePosition = { x: x, y: y }
            const relativePosition = { x: 0, y: 0 } // 線の場合は相対位置は計算に使わない
            this.verticalDraw(
              fromVtx,
              toVtx,
              relativePosition,
              mousePosition,
              this.drag
            )
          }
        } else { // 通常の移動処理 / その後合成のためのハイライト処理
          // ドラッグが開始されていればオブジェクトの座標を更新して再描画
          this.drag.dragging += 1
          if (selectedLayer) {
            if (selectedLayer.type === 'plat') {
              selectedLayer.relativePosition.x = x - this.drag.x + this.drag.rx
              selectedLayer.relativePosition.y = y - this.drag.y + this.drag.ry
            } else {
              selectedLayer.points.fromX = x - this.drag.x + this.drag.points.fromX
              selectedLayer.points.fromY = y - this.drag.y + this.drag.points.fromY
              selectedLayer.points.toX = x - this.drag.x + this.drag.points.toX
              selectedLayer.points.toY = y - this.drag.y + this.drag.points.toY
            }
          }
          this.canvasUpdate()

          // 合成の対象となる土地を位置地情報から探してきて、一番近い辺を赤くハイライトする → mouseUpで合成
          if (this.opMode === 'pasting') {
            this.searchPasteCondition()
          }
        }
      }
      // #endregion ドラッグ中の処理=================================================

      // #region 非ドラッグ時の処理=================================================
      // ドラッグしていないときのmouseMove処理
      if (this.drag.dragging <= 0) {
        const target = plats[targetPlat.index]

        // 各種ハイライト処理 → 実線やその両端の点、土地の辺などが赤くハイライトされる。線の延長、平行垂直描画、合成のために使う
        if (this.opMode === 'solid' || this.opMode === 'horizontal' || this.opMode === 'vertical' || this.opMode === 'pasting') {
          // 平行・垂直描画のためのハイライト処理。平行/垂直モード時に選択していると土地にマウスオーバーすると対象の直線が赤くハイライトされる
          this.canvasUpdate()

          // 土地に対してハイライト
          if (targetPlat.flag === true && (this.opMode === 'horizontal' || this.opMode === 'vertical')) {
            for (let i = 0; i < target.vertexes.length; i++) {
              const next = i === target.vertexes.length - 1 ? 0 : i + 1
              const distDot2Line = this.calcDistanceDot2Line(target.vertexes[i].x + target.relativePosition.x,
                target.vertexes[i].y + target.relativePosition.y,
                target.vertexes[next].x + target.relativePosition.x,
                target.vertexes[next].y + target.relativePosition.y,
                x,
                y)
              if (distDot2Line && distDot2Line < 200) {
                this.onTheLine = true
                this.onTheLineIndex = i

                // 選択した辺をハイライト
                this.drawLine(target.vertexes[i].x + target.relativePosition.x,
                  target.vertexes[i].y + target.relativePosition.y,
                  target.vertexes[next].x + target.relativePosition.x,
                  target.vertexes[next].y + target.relativePosition.y,
                  1, colors.highlightColorRgba(this.bgReversed), [0])
                break
              } else {
                this.onTheLine = false
                this.onTheLineIndex = 0
              }
            }
          }

          // 実線に対してハイライト
          if (selectedLayer !== undefined && selectedLayer.points !== undefined) {
            const stDist = this.calcDistanceDot2Dot(selectedLayer.points.fromX, selectedLayer.points.fromY, x, y)
            const endDist = this.calcDistanceDot2Dot(selectedLayer.points.toX, selectedLayer.points.toY, x, y)
            if (this.opMode !== 'horizontal' && this.opMode !== 'vertical') {
              // 既存実線の延長処理
              // 実線描画時に既存実線の両端にマウスオーバーすると両端が赤くハイライトされる
              if (stDist < 100) {
                // 辺の始点をハイライト
                this.canvasUpdate()
                this.drawRect(selectedLayer.points.fromX, selectedLayer.points.fromY, colors.highlightColorRgba(this.bgReversed))
                this.extendLine.start = true
              } else if (endDist < 100) {
                // 辺の終点をハイライト
                this.canvasUpdate()
                this.drawRect(selectedLayer.points.toX, selectedLayer.points.toY, colors.highlightColorRgba(this.bgReversed))
                this.extendLine.end = true
              } else {
                this.extendLine.start = false
                this.extendLine.end = false
              }
            } else {
              // 直線に対する平行／垂直作図
              // 平行・垂直描画のためのハイライト処理。平行/垂直モード時に選択していると既存の実線にマウスオーバーすると対象の直線が赤くハイライトされる
              this.canvasUpdate()
              const distDot2Line = this.calcDistanceDot2Line(selectedLayer.points.fromX, selectedLayer.points.fromY, selectedLayer.points.toX, selectedLayer.points.toY, x, y)
              if (distDot2Line && distDot2Line < 10) {
                this.vh2Lines.flag = true
                this.vh2Lines.type = this.opMode
                this.vh2Lines.lineType = selectedLayer.type
                const lines = this.layers.filter(l => l.type === 'solid' || l.type === 'dashed')
                const lineIndex = lines.indexOf(selectedLayer)
                this.vh2Lines.lineIndex = lineIndex
                // 選択した辺をハイライト
                this.drawLine(selectedLayer.points.fromX,
                  selectedLayer.points.fromY,
                  selectedLayer.points.toX,
                  selectedLayer.points.toY,
                  1, colors.highlightColorRgba(this.bgReversed), [0])
              }
            }
          }
        } else if (this.opMode === 'freeHandPlot' || this.opMode === 'roadPlot' || this.opMode === 'addPlot') {
          // 既存頂点のハイライト処理。現行では頂点追加時のみ(this.opMode === 'addPlot')使用される。(2023/06/15)
          // フリーハンドで頂点追加
          if (selectedLayer !== undefined && selectedLayer.type === 'plat') {
            // 図形の頂点でループ
            for (let i = 0; i < selectedLayer.vertexes.length; i++) {
              this.canvasUpdate()

              // ループ対象の頂点
              const targetVertex = {
                x: selectedLayer.vertexes[i].x + selectedLayer.relativePosition.x,
                y: selectedLayer.vertexes[i].y + selectedLayer.relativePosition.y
              }
              // 隣り合う頂点を取得
              const next = i !== selectedLayer.vertexes.length - 1 ? i + 1 : 0
              const nextVertex = {
                x: selectedLayer.vertexes[next].x + selectedLayer.relativePosition.x,
                y: selectedLayer.vertexes[next].y + selectedLayer.relativePosition.y
              }

              // ループ対象の辺とマウスポジションの距離
              const distDot2Line = this.calcDistanceDot2Line(
                targetVertex.x, targetVertex.y,
                nextVertex.x, nextVertex.y,
                x, y)
              // ループ対象の頂点とマウスポジションの距離
              const distDot2Vertex = this.calcDistanceDot2Dot(
                targetVertex.x, targetVertex.y,
                x, y)

              if (distDot2Vertex && distDot2Vertex < 100) {
                this.fhOnTheLine = false
                this.addOrDeleteVertex = true
                this.addOrDeleteVertexIndex = i

                // 選択した頂点をハイライト
                this.drawRect(
                  targetVertex.x, targetVertex.y,
                  colors.highlightColorRgba(this.bgReversed))

                break
              } else if (distDot2Line && distDot2Line < 10) {
                // マウスポジションと辺の位置の確認
                const whileX = ((targetVertex.x <= nextVertex.x) && (targetVertex.x - 5 <= x && x <= nextVertex.x + 5)) || ((targetVertex.x >= nextVertex.x) && (nextVertex.x - 5 <= x && x <= targetVertex.x + 5))
                const whileY = ((targetVertex.y <= nextVertex.y) && (targetVertex.y - 5 <= y && y <= nextVertex.y + 5)) || ((targetVertex.y >= nextVertex.y) && (nextVertex.y - 5 <= y && y <= targetVertex.y + 5))
                if (!whileX || !whileY) {
                  continue
                }

                this.fhOnTheLine = true
                this.addOrDeleteVertex = false
                this.fhOnTheLineIndex = i
                this.fhOnThePlatIndex = parseInt(selectedLayer.name.substr(2))
                this.fhRelativePosition.x = selectedLayer.relativePosition.x
                this.fhRelativePosition.y = selectedLayer.relativePosition.y

                // 選択した辺をハイライト
                this.addPlatBothEndsIndexes.platId = selectedLayer.id
                this.addPlatBothEndsIndexes.start = i
                this.addPlatBothEndsIndexes.end = next
                this.drawLine(
                  targetVertex.x, targetVertex.y,
                  nextVertex.x, nextVertex.y,
                  1, colors.highlightColorRgba(), [0])

                // 距離表示
                let sDist = this.calcDistanceDot2Dot(
                  targetVertex.x, targetVertex.y,
                  x, y)
                sDist = Math.round(sDist * 100) / 100 // 二桁表示にする
                this.displayDistance(
                  targetVertex.x, targetVertex.y,
                  x, y, sDist, 0, 0, true)

                let eDist = this.calcDistanceDot2Dot(
                  nextVertex.x, nextVertex.y,
                  x, y)
                eDist = Math.round(eDist * 100) / 100 // 二桁表示にする
                this.displayDistance(
                  nextVertex.x,
                  nextVertex.y,
                  x, y, eDist, 0, 0, true)

                break
              } else {
                this.fhOnTheLine = false
                this.addOrDeleteVertex = false
                this.fhOnTheLineIndex = 0
                this.addOrDeleteVertexIndex = 0
              }

              if (this.opMode !== 'addPlot' && this.fhVtxArray.length > 0) {
                // 始点を取得
                const basePlot = { x: this.fhVtxArray[this.fhVtxArray.length - 1].x, y: this.fhVtxArray[this.fhVtxArray.length - 1].y }

                // 現在のマウスポジションから法線描画
                const mx = x - selectedLayer.relativePosition.x
                const my = y - selectedLayer.relativePosition.y

                this.drawLine(
                  basePlot.x + this.fhRelativePosition.x,
                  basePlot.y + this.fhRelativePosition.y,
                  this.fhRelativePosition.x + mx,
                  this.fhRelativePosition.y + my,
                  1, 'rgb(0, 255, 0)', [5, 5])

                // 距離表示
                const dist = this.calcDistanceDot2Dot(basePlot.x, basePlot.y, mx, my)
                this.displayDistance(basePlot.x, basePlot.y, mx, my, dist, this.fhRelativePosition.x, this.fhRelativePosition.y)
              }
            }
          }
        } else if (this.opMode === 'assist') {
          // アシスト線処理のためのハイライト処理
          // アシスト線を選択時
          if (this.assistEdgeOn.edgeFlag && this.assistEdgeOn.movingFlag) {
            if (selectedLayer !== undefined && selectedLayer.type === 'plat') {
              // フラグオン
              // 画面更新
              this.canvasUpdate()
              // 始点描画
              this.drawRect(this.assistEdgeOn.start.x, this.assistEdgeOn.start.y, 'rgb(255, 0, 0)')

              // 終点描画（マウス位置）
              let endX, endY
              selectedLayer.vertexes.forEach((v, index) => {
                const vx = v.x + selectedLayer.relativePosition.x
                const vy = v.y + selectedLayer.relativePosition.y
                const prevIdx = (index === 0 ? selectedLayer.vertexes.length - 1 : index - 1)
                const px = selectedLayer.vertexes[prevIdx].x + selectedLayer.relativePosition.x
                const py = selectedLayer.vertexes[prevIdx].y + selectedLayer.relativePosition.y
                const vDist = this.calcDistanceDot2Dot(vx, vy, x, y)
                const lDist = this.calcDistanceDot2Line(vx, vy, px, py, x, y)
                if (vDist && vDist < 25) {
                  // 頂点をハイライト
                  endX = vx
                  endY = vy
                } else if (lDist && lDist < 20) {
                  // 選択した辺をハイライト
                  // 傾き計算
                  const slope = this.calcTilt(vx, vy, px, py)
                  if (slope.tilt > 4 || slope.tilt < -4) {
                    endX = (y - slope.intcpt) / slope.tilt
                    endY = y
                  } else if (slope.tilt > 1) {
                    endX = (y - slope.intcpt) / slope.tilt
                    endY = y
                  } else {
                    endX = x
                    endY = slope.tilt * x + slope.intcpt
                  }
                }
              })

              endX = endX !== undefined ? endX : x
              endY = endY !== undefined ? endY : y
              this.drawRect(endX, endY, 'rgb(255, 0, 0)')
              this.assistEdgeOn.end.x = endX
              this.assistEdgeOn.end.y = endY

              // 点線描画
              this.drawLine(this.assistEdgeOn.start.x, this.assistEdgeOn.start.y, endX, endY, 1, 'rgb(255, 0, 0)', [5, 5])

              // 距離表示
              const dist = this.calcDistanceDot2Dot(this.assistEdgeOn.start.x, this.assistEdgeOn.start.y, endX, endY)
              this.displayDistance(this.assistEdgeOn.start.x, this.assistEdgeOn.start.y, endX, endY, dist, 0, 0)
            }
          } else {
            // フラグオフ
            if (selectedLayer !== undefined && selectedLayer.type === 'plat') {
              // 画面更新
              this.canvasUpdate()
              selectedLayer.vertexes.forEach((v, index) => {
                const vx = v.x + selectedLayer.relativePosition.x
                const vy = v.y + selectedLayer.relativePosition.y
                const prevIdx = (index === 0 ? selectedLayer.vertexes.length - 1 : index - 1)
                const px = selectedLayer.vertexes[prevIdx].x + selectedLayer.relativePosition.x
                const py = selectedLayer.vertexes[prevIdx].y + selectedLayer.relativePosition.y
                const vDist = this.calcDistanceDot2Dot(vx, vy, x, y)
                const lDist = this.calcDistanceDot2Line(vx, vy, px, py, x, y)
                if (vDist && vDist < 15) {
                  // 頂点をハイライト
                  this.drawRect(vx, vy, colors.highlightColorRgba(this.bgReversed))
                  this.assistEdgeOn = { edgeFlag: true, movingFlag: false, vIdx: index, pIdx: '', start: { x: '', y: '' }, end: { x: '', y: '' } }
                } else if (lDist && lDist < 10) {
                  // 選択した辺をハイライト
                  this.drawLine(vx, vy, px, py, 1, colors.highlightColorRgba(this.bgReversed), [0])
                  this.assistEdgeOn = { edgeFlag: true, movingFlag: false, vIdx: index, pIdx: prevIdx, start: { x: '', y: '' }, end: { x: '', y: '' } }
                }
              })
            }
          }
        }
      }
      // #endregion 非ドラッグ時の処理=================================================

      // 条件作図
      if (this.drawingLayer.name && this.opMode !== 'condition') {
        this.drawingLayer.points.toX = x
        this.drawingLayer.points.toY = y
        this.canvasUpdate()
      }
      // 回転
      if (this.rotate.rotating) {
        if (this.tooFastMoving) {
          // TODO: マウスの挙動が早すぎると、確度計算が処理落ちして背景画像の描画がズレるので、処理をスキップする
        }
        if (selectedLayer) {
          let angle = this.calcAngle(x - selectedLayer.relativeCentroid.x, y - selectedLayer.relativeCentroid.y)
          selectedLayer.angle = this.rotate.downPointAngle - angle + this.rotate.startAngle
          const distance = { x: x - this.rotate.latestPoint.x, y: y - this.rotate.latestPoint.y }
          angle = this.calcAngle2({ x, y }, distance, selectedLayer.relativeCentroid)
          const cos = Math.cos(angle)
          const sin = Math.sin(angle)

          for (let i = 0; i < selectedLayer.vertexes.length; i++) {
            const vertex = selectedLayer.vertexes[i]
            const rx = vertex.x + selectedLayer.relativePosition.x - selectedLayer.relativeCentroid.x
            const ry = vertex.y + selectedLayer.relativePosition.y - selectedLayer.relativeCentroid.y
            vertex.x = (rx * cos - ry * sin) + selectedLayer.relativeCentroid.x - selectedLayer.relativePosition.x
            vertex.y = (ry * cos + rx * sin) + selectedLayer.relativeCentroid.y - selectedLayer.relativePosition.y
          }

          this.rotate.latestPoint = { x, y }

          this.canvasUpdate()
        }
      }
    },
    /* 実線の延長 */
    extendLineByMouse (mousuPosition, linePoint) {
      const slope = this.calcTilt(linePoint.fromX, linePoint.fromY, linePoint.toX, linePoint.toY)
      let extendPointX, extendPointY
      if (isFinite(slope.tilt)) {
        if (slope.tilt > 4 || slope.tilt < -4) {
          extendPointX = (mousuPosition.y - slope.intcpt) / slope.tilt
          extendPointY = mousuPosition.y
        } else if (slope.tilt > 1) {
          extendPointX = (mousuPosition.y - slope.intcpt) / slope.tilt
          extendPointY = mousuPosition.y
        } else {
          extendPointX = mousuPosition.x
          extendPointY = slope.tilt * mousuPosition.x + slope.intcpt
        }
      } else {
        // 実線の傾きが無現値(Infinity)になる場合
        extendPointX = mousuPosition.x
        extendPointY = mousuPosition.y
      }
      return { x: extendPointX, y: extendPointY }
    },
    /* 平行作図 */
    horizontalDraw (fromVtx, toVtx, relativePosition, mousePosition, drag) {
      const mousePositionCorrected = { // マウスポジションに位置情報の補正値をつけたもの
        x: mousePosition.x + relativePosition.x - drag.x,
        y: mousePosition.y + relativePosition.y - drag.y
      }

      // 描画する
      this.drawLine(
        fromVtx.x + mousePositionCorrected.x,
        fromVtx.y + mousePositionCorrected.y,
        toVtx.x + mousePositionCorrected.x,
        toVtx.y + mousePositionCorrected.y,
        1,
        'rgb(255, 0, 0)',
        [0]
      )

      // ドラッグしながら座標計算した結果を、mouseUpでcanvasに描画するときに使うデータバッファの中に保存しておく
      this.horizontalDrawing.sx = fromVtx.x + mousePositionCorrected.x
      this.horizontalDrawing.sy = fromVtx.y + mousePositionCorrected.y
      this.horizontalDrawing.ex = toVtx.x + mousePositionCorrected.x
      this.horizontalDrawing.ey = toVtx.y + mousePositionCorrected.y

      // 辺との距離を表示
      const fCntrX = (fromVtx.x + toVtx.x) / 2 + relativePosition.x
      const fCntrY = (fromVtx.y + toVtx.y) / 2 + relativePosition.y
      const dist = this.calcDistanceDot2Line(
        fromVtx.x + mousePositionCorrected.x,
        fromVtx.y + mousePositionCorrected.y,
        toVtx.x + mousePositionCorrected.x,
        toVtx.y + mousePositionCorrected.y,
        fCntrX,
        fCntrY
      )

      // 対象の線と今ドラッグしている線の中点同士を点線で結ぶ
      const tCntrX = fCntrX + mousePosition.x - this.drag.x
      const tCntrY = fCntrY + mousePosition.y - this.drag.y
      this.drawLine(fCntrX, fCntrY, tCntrX, tCntrY, 1, 'rgb(255, 0, 0)', [5, 5])

      // 距離表示
      this.displayDistance(fCntrX, fCntrY, tCntrX, tCntrY, dist, 0, 0)
    },
    /* 垂直作図 */
    verticalDraw (fromVtx, toVtx, relativePosition, mousePosition, drag) {
      // 基になる辺の始点終点を取得
      const baseLine = { sx: fromVtx.x, sy: fromVtx.y, ex: toVtx.x, ey: toVtx.y }

      // 元の辺と垂直方向の傾き／切片(元の辺の中点を通る)
      const tilt = this.calcTilt(baseLine.sx, baseLine.sy, baseLine.ex, baseLine.ey)
      const revTilt = -1 / tilt.tilt
      const middle = { x: (baseLine.sx + baseLine.ex) / 2, y: (baseLine.sy + baseLine.ey) / 2 }
      const intercept = (middle.y) - revTilt * (middle.x)

      // 現在のマウスポジションから法線描画
      let vx, vy
      const mx = middle.x + mousePosition.x - drag.x
      const my = middle.y + mousePosition.y - drag.y
      if (Math.abs(revTilt) === Infinity) {
        // 垂直方向
        vx = middle.x
        vy = my
      } else if (revTilt > 4 || revTilt < -4) {
        vx = (my - intercept) / revTilt
        vy = my
      } else if (revTilt > 1) {
        vx = (my - intercept) / revTilt
        vy = my
      } else {
        vx = mx
        vy = revTilt * mx + intercept
      }
      this.drawLine(
        middle.x + relativePosition.x,
        middle.y + relativePosition.y,
        relativePosition.x + vx,
        relativePosition.y + vy,
        1,
        'rgb(255, 0, 0)',
        [0])

      // 始点終点情報の保存
      this.verticalDrawing.sx = middle.x + relativePosition.x
      this.verticalDrawing.sy = middle.y + relativePosition.y
      this.verticalDrawing.ex = relativePosition.x + vx
      this.verticalDrawing.ey = relativePosition.y + vy

      // 距離表示
      const dist = this.calcDistanceDot2Dot(middle.x, middle.y, vx, vy)
      this.displayDistance(middle.x, middle.y, vx, vy, dist, relativePosition.x, relativePosition.y)
    },
    // #endregion マウス系=================================================
    // #region 計算系=================================================
    calcAngle (x, y) {
      const length = Math.sqrt(x * x + y * y)
      const theta = Math.acos(x / length)
      return (y > 0) ? -theta : theta
    },
    calcAngle2 (latest, distance, centroid) {
      const rx = latest.x - (distance.x * 0.5) - centroid.x
      const ry = latest.y - (distance.y * 0.5) - centroid.y
      return (rx * distance.y - ry * distance.x) / (rx * rx + ry * ry)
    },
    // #endregion 計算系=================================================
    // #region PDF表示canvas用処理=================================================
    openCanvasPDF (index) {
      this.canvasWidth = this.canvasWidth / 2
      this.pdfIndex = index
      this.showPdfCanvas = true
      setTimeout(() => {
        this.canvasUpdatePDF(index)
        // 元の画面を更新
        this.canvasUpdate()
        this.scaleIndex = this.scaleIndex + 1
        this.canvasUpdate()
        this.scaleIndex = Math.max(0, this.scaleIndex - 1)
        this.canvasUpdate()
        this.canvasUpdatePDF(index)
      }, 50)
    },
    canvasUpdatePDF (index) {
      this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
      const scaledSize = this.scaledImageSizePDF
      this.image.src = this.pdfFiles[index].image
      this.image.onload = () => {
        this.canvasContextPDF.drawImage(this.image, this.position.x, this.position.y, scaledSize.width, scaledSize.height,
          0, 0, scaledSize.width * this.scalePDF, scaledSize.height * this.scalePDF)
        this.canvasContextPDF.restore()
      }
    },
    closeCanvasPDF () {
      this.showPdfCanvas = false
      this.canvasWidth = this.canvasWidth * 2
      this.initCanvas()
      setTimeout(() => {
        this.canvasUpdate()
        this.scaleIndex = this.scaleIndex + 1
        this.canvasUpdate()
        this.scaleIndex = Math.max(0, this.scaleIndex - 1)
        this.canvasUpdate()
      }, 10)
    },
    mouseDownPDF () {
      this.pdfDragging = true
      const [x, y] = this.getPointerPosition(event)
      this.dragPDF = {
        dragging: 1,
        startImagePos: { x: this.position.x, y: this.position.y },
        startMousePos: { x: x - this.$refs.canvas.width, y },
        vertexIndexes: []
      }
    },
    mouseUpPDF () {
      this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
      this.canvasContextPDF.fillStyle = 'white'
      this.canvasContextPDF.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContextPDF.save()
      this.canvasUpdatePDF(this.pdfIndex)
      this.pdfDragging = false
    },
    mouseMovePDF () {
      // canvas内でのマウスポインタの位置
      if (this.pdfDragging) {
        this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
        this.canvasContextPDF.fillStyle = 'white'
        this.canvasContextPDF.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
        this.canvasContextPDF.save()
        const [x, y] = this.getPointerPosition(event)
        this.position.x = this.dragPDF.startImagePos.x + (this.dragPDF.startMousePos.x - (x - this.$refs.canvas.width)) / this.scalePDF
        this.position.y = this.dragPDF.startImagePos.y + (this.dragPDF.startMousePos.y - y) / this.scalePDF
        this.position.x = Math.max(0, this.position.x)
        this.position.y = Math.max(0, this.position.y)
      }
      this.canvasUpdatePDF(this.pdfIndex)
    },
    changeScalePDF (event) {
      const isZoom = event.wheelDelta > 0
      this.scaleIndexPDF = isZoom ? this.scaleIndexPDF + 1 : Math.max(-9, this.scaleIndexPDF - 1)
      // 真っ白にする
      this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
      this.canvasContextPDF.fillStyle = 'white'
      this.canvasContextPDF.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContextPDF.save()
      this.canvasUpdatePDF(this.pdfIndex)
    },
    // #endregion PDF表示canvas用処理=================================================
    // #region 測定範囲設定処理=================================================
    addRange () {
      if (!this.checkPlatsAndBuildings()) {
        // 土地と建物に異常あり
        return
      }
      const selectedLayer = this.layers.filter(l => l.selected && l.name.search('土地') >= 0)
      if (selectedLayer.length === 1) {
        // 面積の計算
        const platArea = this.calcPlatArea(selectedLayer[0].vertexes)
        this.ranges.push({ vertexes: selectedLayer[0].vertexes, routePrices: [], setbacks: [], area: platArea })
        if (this.rangeVertexes.length === 0 || this.rangeVertexes.length !== selectedLayer[0].vertexes.length) {
          this.rangeVertexes = selectedLayer[0].vertexes
        }
        this.canvasUpdate()
        this.errors = []
      } else {
        this.errors = { error: '測量対象の土地を選択してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    checkPlatsAndBuildings () {
      // 土地と建物の状態をチェックする
      // 1.土地が全て合成されているか
      const plats = this.layers.filter(l => l.type === 'plat' && l.name.search('土地') >= 0)
      if (plats.length > 1) {
        this.errors = { error: '測量対象の土地が複数あります。合成してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return false
      } else if (plats.length < 1) {
        this.errors = { error: '測量対象の土地がありません。やりなおしてください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }
      const plat = plats[0] // 土地は一つしかないはず
      // 全ての建物が土地の中に入っているかどうか
      const buildings = this.layers.filter(l => l.type === 'plat' && l.name.search('建物') > 0)
      for (const building of buildings) {
        for (const v of building.relativeVertexes) {
          const isIn = this.isInPolygon(
            plat.relativeVertexes,
            v.x,
            v.y
          )
          if (!isIn) {
            this.errors = { error: '土地の外側に建物があります。建物は全て土地に重ねてください。' }
            window.scrollTo({ top: 0, behavior: 'smooth' })
            return false
          }
        }
      }
      return true
    },
    deleteRange () {
      this.ranges.splice(this.activeTabIndex, 1)
    },
    // #endregion 測定範囲設定処理=================================================
    // #region 合成処理=================================================
    /* mouseMove中に合成の対象の土地とその辺を決める。最も距離が近いもの勝ち */
    searchPasteCondition () {
      const plats = this.layers.filter(l => l.showing && l.type === 'plat' && l.name.startsWith('土地')) // 合成には建物は使わない
      const layers = this.layers.filter(l => l.selected)
      const selectedLayer = layers.length > 0 ? layers[0] : undefined
      if (selectedLayer === undefined) {
        return
      }
      // 建物を合成しようとしていたらエラーで処理中断
      if (selectedLayer.fileName.startsWith('[建物]')) {
        this.errors = { error: '建物は合成できません。' }
        this.opMode = 'move'
        this.changeSelectedFlag()
        this.isConditionSelected = false
        return
      }
      // 選択した土地と他の土地で重心同士の距離を測って、一番近いところの土地をターゲットにする
      let nearestPlatIdx = ''
      let thretDist = 1500000 / this.scaleInput
      plats.forEach((p, index) => {
        const dist = (Math.sqrt(this.calcDistanceDot2Dot(p.relativeCentroid.x,
          p.relativeCentroid.y,
          selectedLayer.relativeCentroid.x,
          selectedLayer.relativeCentroid.y)))
        if (dist > 0 && thretDist > dist) {
          nearestPlatIdx = index
          thretDist = dist
        }
      })
      if (nearestPlatIdx !== '') {
        const targetPlat = plats[nearestPlatIdx]
        // 選択した土地の辺とターゲットの土地の辺の中点同士の距離を測って最短の組み合わせを取得
        let nearestVtxFromIdx = ''
        let nearestVtxFromIdxNext = ''
        let nearestVtxToIdx = ''
        let nearestVtxToIdxNext = ''
        let thretDistLine = 15000 / this.scaleInput
        for (let idxFrom = 0; idxFrom < selectedLayer.relativeVertexes.length; idxFrom++) {
          const nextIdx = idxFrom !== selectedLayer.relativeVertexes.length - 1 ? idxFrom + 1 : 0
          const xFrom = (selectedLayer.relativeVertexes[idxFrom].x + selectedLayer.relativeVertexes[nextIdx].x) / 2
          const yFrom = (selectedLayer.relativeVertexes[idxFrom].y + selectedLayer.relativeVertexes[nextIdx].y) / 2
          for (let idxTo = 0; idxTo < targetPlat.relativeVertexes.length; idxTo++) {
            const nextIdxTo = idxTo !== targetPlat.relativeVertexes.length - 1 ? idxTo + 1 : 0
            const xTo = (targetPlat.relativeVertexes[idxTo].x + targetPlat.relativeVertexes[nextIdxTo].x) / 2
            const yTo = (targetPlat.relativeVertexes[idxTo].y + targetPlat.relativeVertexes[nextIdxTo].y) / 2
            const dist = Math.sqrt(this.calcDistanceDot2Dot(xFrom, yFrom, xTo, yTo))
            if (dist > 0 && thretDistLine > dist) {
              nearestVtxFromIdx = idxFrom
              nearestVtxFromIdxNext = nextIdx
              nearestVtxToIdx = idxTo
              nearestVtxToIdxNext = nextIdxTo
              thretDistLine = dist
            }
          }
        }
        if (nearestVtxFromIdx !== '' && nearestVtxToIdx !== '') {
          if (!this.pasteFlag) {
            // FlagOnにする
            this.pasteFlag = true
          }

          // 始点終点を決める
          const distSelectVtxStart2TargetVtxStart = this.calcDistanceDot2Dot(selectedLayer.relativeVertexes[nearestVtxFromIdx].x,
            selectedLayer.relativeVertexes[nearestVtxFromIdx].y,
            targetPlat.relativeVertexes[nearestVtxToIdx].x,
            targetPlat.relativeVertexes[nearestVtxToIdx].y)
          const distSelectVtxStart2TargetVtxEnd = this.calcDistanceDot2Dot(selectedLayer.relativeVertexes[nearestVtxFromIdxNext].x,
            selectedLayer.relativeVertexes[nearestVtxFromIdxNext].y,
            targetPlat.relativeVertexes[nearestVtxToIdx].x,
            targetPlat.relativeVertexes[nearestVtxToIdx].y)

          // 合成対象辺のtargetのstart頂点に近いselectedの頂点が合わせる対象になる
          if (distSelectVtxStart2TargetVtxStart < distSelectVtxStart2TargetVtxEnd) {
            const buf = {
              indexFrom: plats.indexOf(targetPlat) + 1,
              startFrom: nearestVtxToIdx,
              endFrom: nearestVtxToIdxNext,
              indexTo: plats.indexOf(selectedLayer) + 1,
              startTo: nearestVtxFromIdx,
              endTo: nearestVtxFromIdxNext
            }
            // this.pastingBuffArr.push(buf)
            // 最終状態保存用オブジェクトと異なれば更新
            if (JSON.stringify(this.synthesisBuf) !== JSON.stringify(buf)) {
              this.synthesisBuf = buf
            }
          } else {
            const buf = {
              indexFrom: plats.indexOf(targetPlat) + 1,
              startFrom: nearestVtxToIdx,
              endFrom: nearestVtxToIdxNext,
              indexTo: plats.indexOf(selectedLayer) + 1,
              startTo: nearestVtxFromIdxNext,
              endTo: nearestVtxFromIdx
            }
            // this.pastingBuffArr.push(buf)
            // 最終状態保存用オブジェクトと異なれば更新
            if (JSON.stringify(this.synthesisBuf) !== JSON.stringify(buf)) {
              this.synthesisBuf = buf
            }
          }
          // 合成する辺をハイライト
          this.drawLine(selectedLayer.relativeVertexes[nearestVtxFromIdx].x,
            selectedLayer.relativeVertexes[nearestVtxFromIdx].y,
            selectedLayer.relativeVertexes[nearestVtxFromIdxNext].x,
            selectedLayer.relativeVertexes[nearestVtxFromIdxNext].y,
            1, colors.highlightColorRgba(this.bgReversed), [0])
          this.drawLine(targetPlat.relativeVertexes[nearestVtxToIdx].x,
            targetPlat.relativeVertexes[nearestVtxToIdx].y,
            targetPlat.relativeVertexes[nearestVtxToIdxNext].x,
            targetPlat.relativeVertexes[nearestVtxToIdxNext].y,
            1, colors.highlightColorRgba(this.bgReversed), [0])

          // くっつく頂点をハイライト
          this.drawRect(selectedLayer.relativeVertexes[this.synthesisBuf.startTo].x,
            selectedLayer.relativeVertexes[this.synthesisBuf.startTo].y,
            colors.highlightColorRgba(this.bgReversed))
          this.drawRect(targetPlat.relativeVertexes[this.synthesisBuf.startFrom].x,
            targetPlat.relativeVertexes[this.synthesisBuf.startFrom].y,
            colors.highlightColorRgba(this.bgReversed))
          this.drawRect(selectedLayer.relativeVertexes[this.synthesisBuf.endTo].x,
            selectedLayer.relativeVertexes[this.synthesisBuf.endTo].y,
            colors.highlightColorRgba(this.bgReversed))
          this.drawRect(targetPlat.relativeVertexes[this.synthesisBuf.endFrom].x,
            targetPlat.relativeVertexes[this.synthesisBuf.endFrom].y,
            colors.highlightColorRgba(this.bgReversed))
          // selectedの辺の中心からtargetのstart Vtxとの距離、end Vtxとの距離を計算
          const distFromSelected = this.calcDistFromSelected2Target(selectedLayer, this.synthesisBuf, targetPlat)
          // selectedの辺の長さとtargetの辺の長さを計算
          const sideLength = this.calcSideLength(selectedLayer, this.synthesisBuf, targetPlat)
          if (sideLength.target < sideLength.selected) { // target辺長 < selected辺長
            if (distFromSelected.toTargetStart > distFromSelected.toTargetEnd) {
              // start vtx同士をrangeOfMeasurementColor
              this.drawRect(selectedLayer.relativeVertexes[this.synthesisBuf.startTo].x,
                selectedLayer.relativeVertexes[this.synthesisBuf.startTo].y,
                colors.rangeOfMeasurementColorRgba(this.bgReversed))
              this.drawRect(targetPlat.relativeVertexes[this.synthesisBuf.startFrom].x,
                targetPlat.relativeVertexes[this.synthesisBuf.startFrom].y,
                colors.rangeOfMeasurementColorRgba(this.bgReversed))
            } else {
              // end vtx同士をrangeOfMeasurementColor
              this.drawRect(selectedLayer.relativeVertexes[this.synthesisBuf.endTo].x,
                selectedLayer.relativeVertexes[this.synthesisBuf.endTo].y,
                colors.rangeOfMeasurementColorRgba(this.bgReversed))
              this.drawRect(targetPlat.relativeVertexes[this.synthesisBuf.endFrom].x,
                targetPlat.relativeVertexes[this.synthesisBuf.endFrom].y,
                colors.rangeOfMeasurementColorRgba(this.bgReversed))
            }
          } else {
            if (distFromSelected.toTargetStart < distFromSelected.toTargetEnd) {
              // start vtx同士をrangeOfMeasurementColor
              this.drawRect(selectedLayer.relativeVertexes[this.synthesisBuf.startTo].x,
                selectedLayer.relativeVertexes[this.synthesisBuf.startTo].y,
                colors.rangeOfMeasurementColorRgba(this.bgReversed))
              this.drawRect(targetPlat.relativeVertexes[this.synthesisBuf.startFrom].x,
                targetPlat.relativeVertexes[this.synthesisBuf.startFrom].y,
                colors.rangeOfMeasurementColorRgba(this.bgReversed))
            } else {
              // end vtx同士をrangeOfMeasurementColor
              this.drawRect(selectedLayer.relativeVertexes[this.synthesisBuf.endTo].x,
                selectedLayer.relativeVertexes[this.synthesisBuf.endTo].y,
                colors.rangeOfMeasurementColorRgba(this.bgReversed))
              this.drawRect(targetPlat.relativeVertexes[this.synthesisBuf.endFrom].x,
                targetPlat.relativeVertexes[this.synthesisBuf.endFrom].y,
                colors.rangeOfMeasurementColorRgba(this.bgReversed))
            }
          }
        } else { // 合成相手の結合する辺がない場合
          this.pasteFlag = false
          this.pastingBuffArr = []
        }
      } else { // nearestPlatIdxない場合、つまり合成相手ない
        this.pasteFlag = false
        this.pastingBuffArr = []
      }
    },
    /* 貼り付ける側と貼り付けられる側の情報をバッファに格納 */
    applyPasteConditions () {
      if (this.pasteFlag) {
        // pastingDataの編集
        let duplicateFlag = false

        this.pastingBuffArr.forEach(buf => {
          for (let i = 0; i < this.pastingData.fromTo.length; i++) {
            if (this.pastingData.fromTo[i].indexTo === buf.indexTo) {
              this.pastingData.fromTo[i] = buf
              duplicateFlag = true
            }
          }
          if (!duplicateFlag) {
            this.pastingData.fromTo.push(buf)
          }
          this.pastingData.paste = true
        })
      }
    },
    /* 描画（回転して辺同士を重ね合わせる） */
    drawPastePlats (onlyPlatImage) {
      // 真っ白にする
      this.canvasContext.fillStyle = 'white'
      this.canvasContext.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContext.save()
      const plats = this.layers.filter(l => l.showing && l.type === 'plat')
      let pasteFlag = false
      let index = ''
      for (let i = 0; i < plats.length; i++) {
        pasteFlag = false
        index = ''
        for (let j = 0; j < this.pastingData.fromTo.length; j++) {
          if (i === this.pastingData.fromTo[j].indexTo - 1) {
            pasteFlag = true
            index = j
          }
        }
        if (!pasteFlag) {
          // 貼り付ける側でない土地を描画
          this.drawSinglePlatImage(plats[i])
          this.drawings.push({ v: plats[i].vertexes, s: this.scale, xy: { x: -plats[i].relativePosition.x, y: -plats[i].relativePosition.y }, c: plats[i].color })
        } else {
          // 貼り付けられる側の土地index
          const platFrom = plats[this.pastingData.fromTo[index].indexFrom - 1]
          // 貼り付ける側土地index
          const platTo = plats[this.pastingData.fromTo[index].indexTo - 1]
          const data = this.pastingData.fromTo[index]
          // 図面の傾きを計算
          let angleTo = Math.atan2(platFrom.vertexes[data.endFrom].y - platFrom.vertexes[data.startFrom].y, platFrom.vertexes[data.endFrom].x - platFrom.vertexes[data.startFrom].x)
          let angleFrom = Math.atan2(platTo.vertexes[data.endTo].y - platTo.vertexes[data.startTo].y, platTo.vertexes[data.endTo].x - platTo.vertexes[data.startTo].x)
          angleTo = isNaN(angleTo) ? Math.PI / 2 : angleTo
          angleFrom = isNaN(angleFrom) ? Math.PI / 2 : angleFrom
          let rotAngles = angleTo - angleFrom
          if (this.pastingData.datas[this.pastingData.fromTo[index].indexTo - 1].initDiffAngle === '') {
            this.pastingData.datas[this.pastingData.fromTo[index].indexTo - 1].initDiffAngle = rotAngles + platTo.angle - platFrom.angle
          }
          if (Math.abs(rotAngles) > 1.0e-15) {
            this.pastingData.angle = rotAngles
          } else if (isFinite(rotAngles) || isNaN(rotAngles)) {
            // 傾きマックス
            // 修正前は Math.PI / 2 を設定するようになっていたが、90°回転させてしまうことになるので修正
            // 合成時のズレ対策で、2π(360°) → 0 に修正
            this.pastingData.angle = 0
            rotAngles = 0
          } else {
            // 微調整用
            this.pastingData.angle = 0
          }
          platTo.angle = rotAngles + platFrom.angle
          // 頂点と辺を調整
          // 終点を重ねる(先に回転しておく)
          let rotX
          let rotY
          platTo.vertexes.forEach(v => {
            v.x = isNaN(v.x) ? 0 : v.x
            v.y = isNaN(v.y) ? 0 : v.y
            rotX = (Math.cos(rotAngles) * v.x) - (Math.sin(rotAngles) * v.y)
            rotY = (Math.sin(rotAngles) * v.x) + (Math.cos(rotAngles) * v.y)
            v.x = rotX
            v.y = rotY
          })
          // selectedの辺の中心からtargetのstart Vtxとの距離、end Vtxとの距離を計算
          const distFromSelected = this.calcDistFromSelected2Target(platTo, data, platFrom)
          // selectedの辺の長さとtargetの辺の長さを計算
          const sideLength = this.calcSideLength(platTo, data, platFrom)
          // 始点を重ねる
          let diffX, diffY
          if (sideLength.target < sideLength.selected) { // target辺長 < selected辺長
            if (distFromSelected.toTargetStart > distFromSelected.toTargetEnd) {
              // start vtx同士くっ付く
              diffX = platFrom.vertexes[data.startFrom].x - platTo.vertexes[data.startTo].x
              diffY = platFrom.vertexes[data.startFrom].y - platTo.vertexes[data.startTo].y
            } else {
              // end vtx同士くっ付く
              diffX = platFrom.vertexes[data.endFrom].x - platTo.vertexes[data.endTo].x
              diffY = platFrom.vertexes[data.endFrom].y - platTo.vertexes[data.endTo].y
            }
          } else {
            if (distFromSelected.toTargetStart < distFromSelected.toTargetEnd) {
              diffX = platFrom.vertexes[data.startFrom].x - platTo.vertexes[data.startTo].x
              diffY = platFrom.vertexes[data.startFrom].y - platTo.vertexes[data.startTo].y
            } else {
              diffX = platFrom.vertexes[data.endFrom].x - platTo.vertexes[data.endTo].x
              diffY = platFrom.vertexes[data.endFrom].y - platTo.vertexes[data.endTo].y
            }
          }
          // if (this.diff[data.indexTo].diffX === '') {
          //   // 値を保存
          //   this.diff[data.indexTo].diffX = diffX
          // }
          // if (this.diff[data.indexTo].diffY === '') {
          //   // 値を保存
          //   this.diff[data.indexTo].diffY = diffY
          // }
          platTo.vertexes.forEach(el => {
            el.x += diffX
            el.y += diffY
          })
          // 誤差の修正
          const tilt = this.calcTilt(platFrom.vertexes[data.startFrom].x,
            platFrom.vertexes[data.startFrom].y,
            platFrom.vertexes[data.endFrom].x,
            platFrom.vertexes[data.endFrom].y)

          // 傾き，切片が有限数の時のみ誤差の修正を行う
          // 無限値(Infinity) になると計算不可能、長方形の垂線方向の際にそのような状況になる
          if (isFinite(tilt.tilt) && isFinite(tilt.intcpt)) {
            if (Math.abs(sideLength.target - sideLength.selected) < 0.1) {
              platTo.vertexes[data.startTo].x = platFrom.vertexes[data.startFrom].x
              platTo.vertexes[data.endTo].x = platFrom.vertexes[data.endFrom].x
              platTo.vertexes[data.startTo].y = platFrom.vertexes[data.startFrom].y
              platTo.vertexes[data.endTo].y = platFrom.vertexes[data.endFrom].y
            } else if (sideLength.target > sideLength.selected) {
              if (distFromSelected.toTargetStart > distFromSelected.toTargetEnd) {
                platTo.vertexes[data.startTo].y = platTo.vertexes[data.startTo].x * tilt.tilt + tilt.intcpt
              } else {
                platTo.vertexes[data.endTo].y = platTo.vertexes[data.endTo].x * tilt.tilt + tilt.intcpt
              }
            } else {
              if (distFromSelected.toTargetStart > distFromSelected.toTargetEnd) {
                platTo.vertexes[data.endTo].y = platTo.vertexes[data.endTo].x * tilt.tilt + tilt.intcpt
              } else {
                platTo.vertexes[data.startTo].y = platTo.vertexes[data.startTo].x * tilt.tilt + tilt.intcpt
              }
            }
          }
          // 画像を回転
          // 始点終点の指定が順か逆か
          this.rotatePlatImage(plats[this.pastingData.fromTo[index].indexTo - 1],
            plats[this.pastingData.fromTo[index].indexFrom - 1],
            plats[this.pastingData.fromTo[index].indexFrom - 1].angle,
            this.pastingData.datas[this.pastingData.fromTo[index].indexTo - 1].initDiffAngle,
            this.pastingData.datas[this.pastingData.fromTo[index].indexTo - 1].pictZeroPoint.x,
            this.pastingData.datas[this.pastingData.fromTo[index].indexTo - 1].pictZeroPoint.y)
          // 領域を描画
          this.drawings.push({ v: platTo.vertexes, s: this.scale, xy: { x: -platFrom.relativePosition.x, y: -platFrom.relativePosition.y }, c: platTo.color })
        }
      }
      if (onlyPlatImage) {
        return
      }
      if (this.bgReversed) {
        this.colorInversion(this.canvasContext, this.canvasWidth)
      }
      for (let i = 0; i < this.drawings.length; i++) {
        this.drawPlat(this.canvasContext, this.drawings[i].v, this.drawings[i].s, this.drawings[i].xy, this.drawings[i].c)
      }
      this.drawings.splice(0)
    },
    /* 頂点が重なり合うよう微調整(これをしないとsynthVertexesができない) */
    adjustPasteVertexes () {
      const plats = this.layers.filter(l => l.type === 'plat')
      // 微調整の値(重心方向に微調整する)
      for (let i = 0; i < this.pastingData.fromTo.length; i++) {
        const data = this.pastingData.fromTo[i]
        const platFrom = plats[data.indexFrom - 1]
        const platTo = plats[data.indexTo - 1]
        const deltaXStart = (platFrom.relativeCentroid.x - platTo.relativeVertexes[data.startTo].x) / 10000000
        const deltaYStart = (platFrom.relativeCentroid.y - platTo.relativeVertexes[data.startTo].y) / 10000000
        const deltaXEnd = (platFrom.relativeCentroid.x - platTo.relativeVertexes[data.endTo].x) / 10000000
        const deltaYEnd = (platFrom.relativeCentroid.y - platTo.relativeVertexes[data.endTo].y) / 10000000
        plats[this.pastingData.fromTo[i].indexTo - 1].vertexes[data.startTo].x += deltaXStart
        plats[this.pastingData.fromTo[i].indexTo - 1].vertexes[data.startTo].y += deltaYStart
        plats[this.pastingData.fromTo[i].indexTo - 1].vertexes[data.endTo].x += deltaXEnd
        plats[this.pastingData.fromTo[i].indexTo - 1].vertexes[data.endTo].y += deltaYEnd
      }
      // 誤差範囲の修正
      plats.forEach((plat, index) => {
        plat.vertexes.forEach(vtx => {
          plats.forEach((p, idx) => {
            if (index !== idx) {
              p.vertexes.forEach(v => {
                if (Math.abs(v.x - vtx.x) < 0.001 && Math.abs(v.x - vtx.x) > 0) {
                  v.x = vtx.x
                }
                if (Math.abs(v.y - vtx.y) < 0.001 && Math.abs(v.y - vtx.y) > 0) {
                  v.y = vtx.y
                }
              })
            }
          })
        })
      })
    },
    /* 頂点、画像をバックエンドに送って合成する */
    synthVertexes () {
      if (this.pasteFlag) {
        // dataの用意
        let plats = this.layers.filter(l => l.type === 'plat')
        const IdxFrom = this.pastingData.fromTo[0].indexFrom - 1
        const platFrom = plats[IdxFrom] // 貼り付けられた側の土地
        const IdxTo = this.pastingData.fromTo[0].indexTo - 1
        const platTo = plats[IdxTo] // 貼り付ける側の土地
        const vtxArrayFrom = []
        platFrom.vertexes.forEach(v => {
          vtxArrayFrom.push({ x: v.x + platFrom.relativePosition.x, y: v.y + platFrom.relativePosition.y })
        })
        const elementFrom = {
          id: platFrom.id,
          relativePosition: platFrom.relativePosition,
          vertexes: vtxArrayFrom
        }
        const vtxArrayTo = []
        platTo.vertexes.forEach(v => {
          vtxArrayTo.push({ x: v.x + platTo.relativePosition.x, y: v.y + platTo.relativePosition.y })
        })
        const elementTo = {
          id: platTo.id,
          relativePosition: platTo.relativePosition,
          vertexes: vtxArrayTo
        }
        const platsData = []
        platsData.push(elementFrom)
        platsData.push(elementTo)
        const dataGet = {
          plats: platsData,
          lines: this.layers.filter(l => l.type !== 'plat'),
          image: this.canvasContext.canvas.toDataURL(),
          scaleFactor: this.scaleFactor
        }
        this.axios.post(`/api/kagechi/paste/${this.$route.params.main_id}`, dataGet).then(res => {
          // 貼り付けられた側の情報を更新
          platFrom.vertexes = [] // 初期化
          const searchRefernce = [] // 座標情報を検索するための参照先。貼り付けられる側と貼り付ける側の両方の座標を格納する
          elementFrom.vertexes.forEach(vtxFrom => {
            searchRefernce.push(vtxFrom)
          })
          elementTo.vertexes.forEach(vtxTo => {
            searchRefernce.push(vtxTo)
          })
          res.data.vertexes.forEach((vtxRes, idxRes) => {
            // データ作り
            const data = {
              distance: '',
              distance_expected: '',
              distance_meter: '',
              lat: '',
              lon: '',
              name: `頂点${idxRes + 1}`,
              x: vtxRes.x,
              y: vtxRes.y
            }
            platFrom.vertexes.push(data) // データ格納
          })
          platFrom.relativeVertexes = res.data.vertexes
          platFrom.relativePosition.x += res.data.left - platFrom.relativePosition.x // バックエンドの演算との誤差修正
          platFrom.relativePosition.y += res.data.top - platFrom.relativePosition.y // バックエンドの演算との誤差修正
          platFrom.vertexes.forEach((v, i) => {
            const prev = i !== 0 ? i - 1 : platFrom.vertexes.length - 1
            const dist = this.calcDistanceDot2Dot(v.x, v.y, platFrom.vertexes[prev].x, platFrom.vertexes[prev].y)
            v.distance_meter = Math.sqrt(dist) / this.pixelsPerMeter * this.scaleInput
          })
          platFrom.angle = 0
          platFrom.image = res.data.image
          platFrom.imageObj.src = res.data.image
          // 貼り付ける側を削除
          const bufID = platTo.id
          this.layers = this.layers.filter(l => l.id !== bufID)
          // 貼り付け情報初期化
          this.pastingData.fromTo = []
          this.pastingData.paste = false
          // 貼り付け情報初期化
          this.pastingData.datas.forEach(data => {
            data.pictZeroPoint.x = ''
            data.pictZeroPoint.y = ''
            data.initDiffAngle = ''
          })
          plats = this.layers.filter(l => l.type === 'plat')
          plats.forEach((p, i) => {
            const zeropoint = this.calcZeroPoint(p)
            this.reflectZeroPointToPastingdata(i, this.scaleInput, zeropoint)
          })
          if (plats.length === 1) {
            this.rangeVertexes = plats[0].vertexes
          }
        }).catch(error => {
          if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
            this.validated(error.response.data)
          } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
            this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
          }
        })
      }
    },
    /* 土地の背景画像の原点を計算する。土地の形が変わる操作(合成、通路開設)の後はこれを行なわなければならない */
    calcZeroPoint (plat) {
      // 画像を回転させる際の基準点を保管しておく(左上の点)
      const sum = []
      plat.vertexes.forEach(v => {
        sum.push(v.x + v.y)
      })
      const minIdx = sum.indexOf(Math.min(...sum))
      plat.minIdx = minIdx

      // 画像のゼロポイントから基準点までの座標距離（ｘ、ｙ表示）を算出
      const vtxsX = []
      const vtxsY = []
      const vtxsSum = []
      plat.vertexes.forEach(v => {
        vtxsX.push(v.x)
        vtxsY.push(v.y)
        vtxsSum.push(v.x + v.y)
      })
      // 基準点算出
      const minSumIdx = vtxsSum.indexOf(Math.min(...vtxsSum))
      // 画像を囲む長方形算出
      const minX = Math.min(...vtxsX)
      const minY = Math.min(...vtxsY)
      vtxsX.forEach(x => {
        x -= minX
      })
      vtxsY.forEach(y => {
        y -= minY
      })
      return { x: vtxsX[minSumIdx], y: vtxsY[minSumIdx] }
    },
    reflectZeroPointToPastingdata (index, scale, zeroPoint) {
      this.pastingData.datas[index].pictZeroPoint.x = zeroPoint.x * scale
      this.pastingData.datas[index].pictZeroPoint.y = zeroPoint.y * scale
    },
    /* 選択した土地の中点から合成ターゲット土地の始点・終点の距離を算出 */
    calcDistFromSelected2Target (selected, buf, target) {
      const toStart = Math.sqrt(this.calcDistanceDot2Dot(
        (selected.relativeVertexes[buf.startTo].x + selected.relativeVertexes[buf.endTo].x) / 2,
        (selected.relativeVertexes[buf.startTo].y + selected.relativeVertexes[buf.endTo].y) / 2,
        target.relativeVertexes[buf.startFrom].x,
        target.relativeVertexes[buf.startFrom].y
      ))
      const toEnd = Math.sqrt(this.calcDistanceDot2Dot(
        (selected.relativeVertexes[buf.startTo].x + selected.relativeVertexes[buf.endTo].x) / 2,
        (selected.relativeVertexes[buf.startTo].y + selected.relativeVertexes[buf.endTo].y) / 2,
        target.relativeVertexes[buf.endFrom].x,
        target.relativeVertexes[buf.endFrom].y
      ))
      return { toTargetStart: toStart, toTargetEnd: toEnd }
    },
    /* 選択した土地の辺と合成ターゲットの土地の辺の長さを比べる */
    calcSideLength (selected, buf, target) {
      const selectedSide = Math.sqrt(this.calcDistanceDot2Dot(
        selected.vertexes[buf.startTo].x,
        selected.vertexes[buf.startTo].y,
        selected.vertexes[buf.endTo].x,
        selected.vertexes[buf.endTo].y
      ))
      const targetSide = Math.sqrt(this.calcDistanceDot2Dot(
        target.vertexes[buf.startFrom].x,
        target.vertexes[buf.startFrom].y,
        target.vertexes[buf.endFrom].x,
        target.vertexes[buf.endFrom].y
      ))
      return { selected: selectedSide, target: targetSide }
    },
    // #endregion 合成処理=================================================
    // #region 描画系処理=================================================
    drawLine (sx, sy, ex, ey, width, color, lineDash) {
      this.canvasContext.beginPath()
      this.canvasContext.moveTo(sx, sy)
      this.canvasContext.lineTo(ex, ey)
      this.canvasContext.lineWidth = width
      this.canvasContext.strokeStyle = color
      this.canvasContext.setLineDash(lineDash)
      this.canvasContext.closePath()
      this.canvasContext.stroke()
    },
    drawRect (x, y, color) {
      this.canvasContext.fillStyle = color
      this.canvasContext.fillRect(x - 5, y - 5, 10, 10)
    },
    displayDistance (sx, sy, ex, ey, dist, relativeX, relativeY) {
      const prevX = (sx + ex) / 2 + relativeX
      const prevY = (sy + ey) / 2 + relativeY
      const textX = prevX + 3
      const textY = prevY + 3
      const meterScale = this.meterScale()
      const meterDist = Math.sqrt(dist) * meterScale
      const textValue = (Math.round(meterDist * 100) / 100) + ''
      const bgHeight = 9 * 1.5 + 5
      const bgWidth = this.canvasContext.measureText(textValue).width + 5
      const bgY = textY - bgHeight
      const bgX = textX - (bgWidth / 2)
      this.canvasContext.fillStyle = 'white'
      this.canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
      this.canvasContext.fillStyle = 'blue'
      this.canvasContext.fillText(textValue, textX, textY)
    },
    // #endregion 描画系処理=================================================
    // #region 縮尺系処理=================================================
    changeScale (event) {
      if (isNaN(this.scaleInput) && this.unityScale === 0) {
        this.errors = { error: '縮尺に数値を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      } else if (isNaN(this.scaleInput)) {
        this.scaleInput = this.unityScale
      } else if (this.drag.dragging > 0) {
        return
      }
      // 手入力があると縮尺のデータ方stringになってしまうのでキャストする
      this.scaleInput = Number(this.scaleInput)
      const isZoom = event.wheelDelta > 0
      this.scaleInput = isZoom ? Math.max(10, this.scaleInput - 10) : this.scaleInput + 10
      this.adaptScaleValue()
    },
    adaptScaleValue () {
      if (this.scaleInput < 1) {
        // マイナスの縮尺はNG
        return
      }
      if (isNaN(this.scaleInput) && this.unityScale === 0) {
        this.errors = { error: '縮尺に数値を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      } else if (isNaN(this.scaleInput)) {
        this.scaleInput = this.unityScale
      }
      // 縮尺の倍率を算出（画像の描画の場合はdrawImage内に組み込み済み）
      const prevFactor = this.scaleFactor
      this.scaleFactor = this.unityScale !== 0 ? this.unityScale / this.scaleInput : 1
      // this.postScaleVal = this.scaleInput / this.scale

      // 全てのLayerの座標を縮尺の倍率で変換
      const plats = this.layers.filter(l => l.showing && l.type === 'plat')
      const lines = this.layers.filter(l => l.showing && l.type !== 'plat')
      // 土地について座標変換
      plats.forEach(plat => {
        plat.vertexes.forEach(v => {
          v.x = v.x * this.scaleFactor / prevFactor
          v.y = v.y * this.scaleFactor / prevFactor
        })
        plat.relativeVertexes.forEach(v => {
          v.x = v.x * this.scaleFactor / prevFactor
          v.y = v.y * this.scaleFactor / prevFactor
        })
        plat.relativeCentroid.x = plat.relativeCentroid.x * this.scaleFactor / prevFactor
        plat.relativeCentroid.y = plat.relativeCentroid.y * this.scaleFactor / prevFactor
        plat.relativePosition.x = plat.relativePosition.x * this.scaleFactor / prevFactor
        plat.relativePosition.y = plat.relativePosition.y * this.scaleFactor / prevFactor
      })
      // 直線について座標変換
      lines.forEach(line => {
        line.points.fromX = line.points.fromX * this.scaleFactor / prevFactor
        line.points.fromY = line.points.fromY * this.scaleFactor / prevFactor
        line.points.toX = line.points.toX * this.scaleFactor / prevFactor
        line.points.toY = line.points.toY * this.scaleFactor / prevFactor
      })

      // 条件作図のインジケータ―を更新
      this.conditionDrawingVtx.forEach((cdtn) => {
        const plats = this.layers.filter(l => l.id === cdtn.platID)
        const plat = plats.length === 1 ? plats[0] : undefined
        if (plat !== undefined) {
          // cdtn.x2 -= plat.relativePosition.x // 相対位置を一度計算から除外
          // cdtn.y2 -= plat.relativePosition.y // 相対位置を一度計算から除外
          cdtn.x2 = cdtn.x2 * this.scaleFactor / prevFactor
          cdtn.y2 = cdtn.y2 * this.scaleFactor / prevFactor
          // cdtn.x2 += plat.relativePosition.x // 相対位置を足しこんで元に戻す
          // cdtn.y2 += plat.relativePosition.y // 相対位置を足しこんで元に戻す
        }
      })

      // アシスト線のインディケーターの更新
      this.assistEdgeOn.start.x = this.assistEdgeOn.start.x * this.scaleFactor / prevFactor
      this.assistEdgeOn.start.y = this.assistEdgeOn.start.y * this.scaleFactor / prevFactor
      this.assistEdgeOn.end.x = this.assistEdgeOn.end.x * this.scaleFactor / prevFactor
      this.assistEdgeOn.end.y = this.assistEdgeOn.end.y * this.scaleFactor / prevFactor

      // 画面更新
      this.canvasUpdate()
    },
    // #endregion 縮尺系処理=================================================
    // #region PDFダウンロード処理=================================================

    /* PDFダウンロード本体処理 */
    downloadPDF () {
      // const scale4Pdf = Math.round(SCALE4PDF * this.scaleInput)
      const scale4Pdf = this.scaleInput
      const data = {
        data: [],
        owner: this.owner,
        address: this.address,
        partName: '',
        textMemo: '',
        scale4Pdf: scale4Pdf,
        width: this.canvasWidth,
        height: this.canvasHeight
      }
      // 一度白背景黒字描画にする
      this.canvasBackgroundChange()
      data.data.push(this.canvasContext.canvas.toDataURL())
      this.axios.post(`/api/kagechi/edit1/download/${this.$route.params.main_id}`, data).then(res => {
        const link = document.createElement('a')
        link.href = res.data.base64Pdf
        link.download = res.data.pdfName
        link.click()
        this.$emit('loading', false)
        // 背景等を元に戻す
        this.canvasUpdate()
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
        this.$emit('loading', false)
        // 背景等を元に戻す
        this.canvasUpdate()
      })
    },
    /* PDFダウンロードのための画面更新処理。canvasUpdateとほぼ同内容だが、強制的に背景を白にして、頂点番号や距離の表示／非表示に対応している */
    canvasBackgroundChange () {
      if (!this.canvasContext) {
        return
      }
      // 背景を白にしておく
      if (!this.bgReversed) {
        this.colorInversion(this.canvasContext, this.canvasWidth)
      }
      if (this.pastingData.paste === true) {
        // 合成の場合の描画処理
        this.drawPastePlats()
      } else {
        // 合成でない場合の描画処理
        // 真っ白にする
        this.canvasContext.fillStyle = 'white'
        this.canvasContext.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
        this.canvasContext.save()

        const plats = this.layers.filter(l => l.showing && l.type === 'plat')
        this.drawPlatImage(plats)

        // 頂点番号を表示
        plats.forEach(plat => {
          const fillColor = plat.selected ? `${colors.selectingLayerColorRgba(this.bgReversed)}` : `${colors.platColorRgba(this.bgReversed)}`

          const lineStyle = 'solid'
          let needVertexRect = true

          if (!this.dispVertexNum) {
            needVertexRect = false
          }

          // 距離表示
          plat.vertexes.forEach((vertex) => {
            vertex.showDistance = this.dispDistance
          })

          this.drawPlat(this.canvasContext, plat.vertexes, this.scale, { x: -plat.relativePosition.x, y: -plat.relativePosition.y }, fillColor, lineStyle, needVertexRect)
        })
      }

      // 作図
      const plats = this.layers.filter(l => l.showing && l.type === 'plat')
      const lines = this.layers.filter(l => l.showing && l.type !== 'plat')
      lines.forEach(line => {
        this.canvasContext.beginPath()
        this.canvasContext.moveTo(line.points.fromX, line.points.fromY)
        this.canvasContext.lineTo(line.points.toX, line.points.toY)
        this.canvasContext.lineWidth = line.lineWidth ? line.lineWidth : true
        const fillColor = 'rgb(0, 0, 0)' // 全て黒で描画
        this.canvasContext.strokeStyle = fillColor
        if (line.type === 'dashed') {
          this.canvasContext.setLineDash([5, 5])
        } else if (line.type === 'dotted') {
          this.canvasContext.setLineDash([2, 3])
        } else if (line.type === 'solid') {
          this.canvasContext.setLineDash([0])
        } else if (line.type === 'assist') {
          this.canvasContext.setLineDash([5, 5])
        }

        this.canvasContext.closePath()
        this.canvasContext.stroke()
        this.canvasContext.setLineDash([0])
        this.canvasContext.strokeStyle = '#ffffff'

        // 距離表示
        if (this.dispDistance) {
          const prevX = (line.points.fromX + line.points.toX) / 2
          const prevY = (line.points.fromY + line.points.toY) / 2
          const textX = prevX + 3
          const textY = prevY + 3
          const meterScale = plats[0].vertexes[1].distance_meter / Math.sqrt(this.calcDistanceDot2Dot(plats[0].vertexes[0].x, plats[0].vertexes[0].y, plats[0].vertexes[1].x, plats[0].vertexes[1].y))
          const meterDist = Math.sqrt(this.calcDistanceDot2Dot(line.points.fromX, line.points.fromY, line.points.toX, line.points.toY)) * meterScale
          const textValue = (Math.round(meterDist * 100) / 100) + ''

          const bgHeight = 9 * 1.5 + 5
          const bgWidth = this.canvasContext.measureText(textValue).width + 5
          const bgY = textY - bgHeight
          const bgX = textX - (bgWidth / 2)

          this.canvasContext.fillStyle = 'white'
          this.canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
          this.canvasContext.fillStyle = 'blue'
          this.canvasContext.fillText(textValue, textX, textY)
        }
      })

      // 条件作図の距離を描画
      this.conditionDrawingVtx.forEach(v => {
        const layers = this.layers.filter(l => l.id === v.platID)
        const targetLayer = layers.length > 0 ? layers[0] : undefined
        if (targetLayer !== undefined && v.isShown) {
          this.drawLine(targetLayer.relativeVertexes[v.index].x,
            targetLayer.relativeVertexes[v.index].y,
            v.x2, v.y2, 2, 'rgb(0, 0, 0)', [5, 5])

          // 距離表示
          const dist = this.calcDistanceDot2Dot(targetLayer.relativeVertexes[v.index].x,
            targetLayer.relativeVertexes[v.index].y,
            v.x2, v.y2)
          const dispDisntance = Math.sqrt(dist) * this.meterScale()
          this.displayDistance(targetLayer.relativeVertexes[v.index].x,
            targetLayer.relativeVertexes[v.index].y,
            v.x2, v.y2, dispDisntance, 0, 0, true)
        }
      })

      // フリーハンドの頂点
      if (this.opMode === 'freeHandPlot' || this.opMode === 'roadPlot') {
        for (let i = 0; i < this.fhVtxArray.length; i++) {
          this.drawRect(this.fhVtxArray[i].x + this.fhRelativePosition.x,
            this.fhVtxArray[i].y + this.fhRelativePosition.y,
            'rgb(0, 0, 0)')
          this.canvasContext.save()
          if (this.fhVtxArray.length > 1 && i > 0) {
            this.canvasContext.beginPath()
            this.canvasContext.moveTo(this.fhVtxArray[i].x + this.fhRelativePosition.x, this.fhVtxArray[i].y + this.fhRelativePosition.y)
            if (this.fhOnTheLineMouseDown === 2 && i === this.fhVtxArray.length) {
              this.canvasContext.lineTo(this.fhVtxArray[i - 1].x + this.fhRelativePosition.x, this.fhVtxArray[i - 1].y + this.fhRelativePosition.y)
              this.canvasContext.lineTo(this.fhVtxArray[0].x + this.fhRelativePosition.x, this.fhVtxArray[0].y + this.fhRelativePosition.y)
            } else {
              this.canvasContext.lineTo(this.fhVtxArray[i - 1].x + this.fhRelativePosition.x, this.fhVtxArray[i - 1].y + this.fhRelativePosition.y)
            }
            this.canvasContext.lineWidth = 1
            this.canvasContext.strokeStyle = 'rgb(0, 0, 0)'
            this.canvasContext.setLineDash([5, 2])
            this.canvasContext.closePath()
            this.canvasContext.stroke()

            // 距離表示
            const dist = this.calcDistanceDot2Dot(this.fhVtxArray[i].x, this.fhVtxArray[i].y, this.fhVtxArray[i - 1].x, this.fhVtxArray[i - 1].y)
            this.displayDistance(this.fhVtxArray[i].x,
              this.fhVtxArray[i].y,
              this.fhVtxArray[i - 1].x,
              this.fhVtxArray[i - 1].y,
              dist,
              this.fhRelativePosition.x,
              this.fhRelativePosition.y)
          }
        }
      }
    },
    // #endregion PDFダウンロード処理=================================================
    // #region 通路開設処理=================================================

    /* 通路開設の本体処理 */
    async addRoad () {
      // 0. 入力チェック
      //       - 路線が存在するかのチェック
      //       - 入力に欠損がないか
      //       - 頂点重複チェック
      //       - 頂点が隣り合っているかチェック
      //       - 道幅異常値チェック

      // 1. 基準点から路線に向かって垂直線を降ろす
      //      - 路線の始点終点から、路線の式を出す
      //      - 路線に垂直な直線の式の傾きを出す
      //      - 路線に垂直かつ基準点を通る直線(垂直線1)の式を出す
      //      - 垂直線と路線の交点座標を出す。この交点を路線頂点１とする

      // 2. 路線に面した通路の頂点二つを求める
      //      - 路線上にある点で、頂点1から指定された路線幅の距離がある点(路線頂点2)の座標を導出する

      // 3. 土地に面した通路の頂点2つを求める
      //      - 2.で導出した路線頂点2を通り路線と垂直な直線の式(垂直線2)を求める
      //      - 垂直線2と指定された辺(始点終点が指定されている)が交点を持つかを調べる
      //           - 持たないなら道幅が大きすぎるというエラー
      //           - 持つなら交点を求め、これを土地頂点と呼ぶ（土地側のもう一つの頂点は基準点）

      // 4. 求めた頂点3つ（土地頂点、路線頂点1、2）を土地の頂点座標に挿入する
      //       - 基準点とそれ以外の頂点の頂点番号を比べる
      //            - 小さい場合は基準点後に挿入。順番は、基準点 → 路線頂点1 → 路線頂点2 → 土地頂点
      //            - 大きい場合はそれ以外の頂点の後に挿入。順番は、それ以外の頂点 → 土地頂点 → 路線頂点2 → 路線頂点1
      //       - 頂点名を振りなおす

      // 5. vuexの更新
      //       - 通路開設の面積を求める
      //       - 情報更新
      //            - 通路開設フラグオン
      //            - 通路の頂点インデックス
      //            - 通路の間口（指定された道幅）
      //            - 通路の面積

      // 6. 画面更新

      // 対象の土地を取得
      let selectedLayer = this.layers.filter(l => l.selected)
      selectedLayer = selectedLayer.length > 0 ? selectedLayer[0] : undefined // 対象の土地を取得
      // 通路開設前(土地変形前)の重心を記録しておく
      const platDataBeforeAdding = this.getCopyObject(selectedLayer)
      this.addPlat.centroidBeforeAdd = this.getCopyObject(platDataBeforeAdding.relativeCentroid)
      // 通路開設フラグオン
      this.addPlat.isPassageOpened = true

      // 対象の路線を取得
      const lines = this.layers.filter(l => l.type === 'solid')
      if (lines.length === 0) {
        this.errors = { error: '通路開設するための路線が存在しません。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }
      const line = lines[this.addPlat.line] // 対象の路線を取得
      const turnAround = Math.min(this.addPlat.vtx1, this.addPlat.vtx2) === 0 && Math.max(this.addPlat.vtx1, this.addPlat.vtx2) === selectedLayer.vertexes.length - 1 // 頂点番号が1周するか？
      const diffIndex = Math.abs(this.addPlat.vtx1 - this.addPlat.vtx2) // 指定された頂点番号の差

      // 0. 入力チェック2)
      if (this.addPlat.vtx1 === '' || this.addPlat.vtx2 === '') {
        this.errors = { error: '通路開設の頂点を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      } else if (this.addPlat.vtx1 === this.addPlat.vtx2) {
        this.errors = { error: '指定した頂点が重複しています。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      } else if ((!turnAround && diffIndex !== 1) || (turnAround && diffIndex !== selectedLayer.vertexes.length - 1)) {
        this.errors = { error: '隣り合った頂点を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      } else if (this.addPlat.whichSide === '') {
        this.errors = { error: '基準点を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      } else if (this.addPlat.line === '') {
        this.errors = { error: '路線を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      } else if (this.addPlat.width < 0 || isNaN(this.addPlat.width)) {
        this.errors = { error: '無効な通路幅が指定されました。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      // 対象の土地と路線のデータを取得
      const vtx1 = selectedLayer.vertexes[this.addPlat.vtx1] // 指定された頂点1
      const vtx2 = selectedLayer.vertexes[this.addPlat.vtx2] // 指定された頂点2
      const whichSideVtx = selectedLayer.vertexes[this.addPlat.whichSide] // 指定された基準点
      this.addPlat.whichSideVtx.x = whichSideVtx.x
      this.addPlat.whichSideVtx.y = whichSideVtx.y
      const anotherVtx = whichSideVtx === vtx1 ? vtx2 : vtx1 // 基準点じゃない方
      const lineTilt = this.calcTilt( // 対象路線の傾き
        line.points.fromX - selectedLayer.relativePosition.x,
        line.points.fromY - selectedLayer.relativePosition.y,
        line.points.toX - selectedLayer.relativePosition.x,
        line.points.toY - selectedLayer.relativePosition.y) // 土地の相対位置(relativePosition)を、指定された直線の始点終点から引いて直接交点を求められように合わせる
      const platLineTilt = this.calcTilt( // 土地の通路開設対象の辺の傾き
        vtx1.x,
        vtx1.y,
        vtx2.x,
        vtx2.y
      )

      // 1. 基準点から路線に向かって垂直線を降ろす
      // 路線に垂直な直線の式の傾きを出す
      const revTilt = -1 / lineTilt.tilt

      // 路線に垂直かつ基準点を通る直線(垂直線1)の式を出す
      const interceptWhichSideVtx = whichSideVtx.y - whichSideVtx.x * revTilt // 切片を求める

      // 垂直線と路線の交点座標を出す(路線頂点1)
      const lineVtx1 = { x: 0, y: 0 } // 路線頂点1
      lineVtx1.x = (interceptWhichSideVtx - lineTilt.intcpt) / (lineTilt.tilt - revTilt)
      lineVtx1.y = lineVtx1.x * lineTilt.tilt + lineTilt.intcpt

      // 上記と同様の計算で路線に垂直かつ基準点じゃない方を通る直線(垂直線1)の式を出す
      const interceptAnotherVtx = anotherVtx.y - anotherVtx.x * revTilt // 切片を求める
      const anotherXY = { x: 0, y: 0 }
      anotherXY.x = (interceptAnotherVtx - lineTilt.intcpt) / (lineTilt.tilt - revTilt)
      anotherXY.y = anotherXY.x * lineTilt.tilt + lineTilt.intcpt
      const signX = Math.sign(anotherXY.x - lineVtx1.x) // 路線頂点1から路線頂点2へのベクトルX成分の符号
      const signY = Math.sign(anotherXY.y - lineVtx1.y) // 路線頂点1から路線頂点2へのベクトルY成分の符号

      // 2. 路線に面した通路の頂点二つを求める
      // 路線上にある点で、頂点1から指定された路線幅の距離がある点(路線頂点2)の座標を導出する
      const distance = this.addPlat.width * (this.pixelsPerMeter / this.scaleInput) // 指定された幅をピクセル換算
      const deltaX = Math.abs(distance / Math.sqrt(Math.pow(lineTilt.tilt, 2) + 1)) // 傾きから考えたX方向の変化量
      const deltaY = Math.abs(deltaX * lineTilt.tilt) // 傾きから考えたY方向の変化量
      const lineVtx2 = { x: 0, y: 0 } // 路線頂点2
      lineVtx2.x = lineVtx1.x + deltaX * signX
      lineVtx2.y = lineVtx1.y + deltaY * signY

      if (this.calcDistanceDot2Dot(lineVtx1.x, lineVtx1.y, lineVtx2.x, lineVtx2.y) >
        this.calcDistanceDot2Dot(lineVtx1.x, lineVtx1.y, anotherXY.x, anotherXY.y)) {
        // 道路の幅を満たす路線頂点2がanotherXYより遠い場合、つまり土地の通路開設対象の辺幅いっぱい使っても道路の幅に足りない
        this.errors = { error: '通路幅が大きすぎます。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      // 3. 土地に面した通路の頂点2つを求める
      // 2.で導出した路線頂点2を通り路線と垂直な直線の式(垂直線2)を求める
      const interceptLineVtx2 = lineVtx2.y - lineVtx2.x * revTilt // 切片を求める

      // 垂直線2と指定された辺(始点終点が指定されている)が交点を持つかを調べる。持つならこれを土地頂点とする
      const platLineVtx = { x: 0, y: 0 } // 土地頂点
      if (isFinite(platLineTilt.intcpt)) {
        platLineVtx.x = (interceptLineVtx2 - platLineTilt.intcpt) / (platLineTilt.tilt - revTilt)
        platLineVtx.y = platLineVtx.x * revTilt + interceptLineVtx2
      } else {
        platLineVtx.x = whichSideVtx.x
        platLineVtx.y = (whichSideVtx.y < anotherVtx.y) ? whichSideVtx.y + distance : whichSideVtx.y - distance
      }

      // 平行の場合
      if (lineTilt.tilt === 0) {
        // 垂直線と路線の交点座標を出す(路線頂点1)
        lineVtx1.x = whichSideVtx.x
        lineVtx1.y = line.points.fromY - selectedLayer.relativePosition.y

        // 2. 路線に面した通路の頂点二つを求める
        // 路線上にある点で、頂点1から指定された路線幅の距離がある点(路線頂点2)の座標を導出する
        const signX = Math.sign(anotherVtx.x - lineVtx1.x)
        lineVtx2.x = lineVtx1.x + (distance * signX)
        lineVtx2.y = lineVtx1.y

        // 3. 土地に面した通路の頂点2つを求める
        platLineVtx.x = lineVtx2.x
        platLineVtx.y = platLineTilt.tilt * lineVtx2.x + platLineTilt.intcpt
      } else if (Math.abs(lineTilt.tilt) === Infinity) {
        // 垂直の場合
        // 垂直線と路線の交点座標を出す(路線頂点1)
        lineVtx1.x = line.points.fromX - selectedLayer.relativePosition.x
        lineVtx1.y = whichSideVtx.y

        // 2. 路線に面した通路の頂点二つを求める
        // 路線上にある点で、頂点1から指定された路線幅の距離がある点(路線頂点2)の座標を導出する
        const signY = Math.sign(anotherVtx.y - lineVtx1.y)
        lineVtx2.x = lineVtx1.x
        lineVtx2.y = lineVtx1.y + (distance * signY)

        // 3. 土地に面した通路の頂点2つを求める
        if (isFinite(platLineTilt.intcpt)) {
          platLineVtx.x = (lineVtx2.y - platLineTilt.intcpt) / platLineTilt.tilt
        } else {
          platLineVtx.x = anotherVtx.x
        }
        platLineVtx.y = lineVtx2.y
      }

      // 4. 求めた頂点3つ（土地頂点、路線頂点1、2）を土地の頂点座標に挿入する

      // 頂点挿入のためのデータ準備
      // 路線頂点1の頂点情報
      const templateLineVtx1 = {
        distance: '', // 後で一括で更新する
        distance_expected: '', // 後で一括で更新する
        distance_meter: '', // 後で一括で更新する
        name: '', // 後で一括で更新する
        x: lineVtx1.x,
        y: lineVtx1.y
      }
      // 路線頂点2の頂点情報
      const templateLineVtx2 = {
        distance: '', // 後で一括で更新する
        distance_expected: '',
        distance_meter: '', // 後で一括で更新する
        name: '', // 後で一括で更新する
        x: lineVtx2.x,
        y: lineVtx2.y
      }
      // 土地頂点の頂点情報
      const templatePlatLineVtx = {
        distance: '', // 後で一括で更新する
        distance_expected: '',
        distance_meter: '', // 後で一括で更新する
        name: '', // 後で一括で更新する
        x: platLineVtx.x,
        y: platLineVtx.y
      }
      const vtxDataArray = [templateLineVtx1, templateLineVtx2, templatePlatLineVtx]

      // 頂点挿入処理
      const isWhichSidebefore = this.addPlat.whichSide === Math.min(this.addPlat.vtx1, this.addPlat.vtx2) // 頂点挿入する順番を考える
      const insertBuffer = this.getCopyObject(selectedLayer.vertexes)
      const passageVertexes = []
      selectedLayer.vertexes = [] // 初期化
      // 頂点挿入をしなければならないインデックス
      const insertIdx = !turnAround ? Math.min(this.addPlat.vtx1, this.addPlat.vtx2) : Math.max(this.addPlat.vtx1, this.addPlat.vtx2)

      insertBuffer.forEach((v, i) => {
        selectedLayer.vertexes.push(v)
        if (i === insertIdx) {
          if (isWhichSidebefore ^ turnAround) {
            // 小さい場合は基準点後に挿入。順番は、路線頂点1 → 路線頂点2 → 土地頂点
            vtxDataArray.forEach(vd => {
              selectedLayer.vertexes.push(vd)
              if (this.ranges.length > 0 && this.ranges[this.activeTabIndex].vertexes.length > 0) {
                this.ranges[this.activeTabIndex].vertexes.push(vd)
              }
            })
          } else {
            // 大きい場合は基準点じゃない方の後に逆順挿入。順番は、土地頂点 → 路線頂点2 → 路線頂点1
            vtxDataArray.slice().reverse().forEach(vd => {
              selectedLayer.vertexes.push(vd)
              if (this.ranges.length > 0 && this.ranges[this.activeTabIndex].vertexes.length > 0) {
                this.ranges[this.activeTabIndex].vertexes.push(vd)
              }
            })
          }
        }
      })

      // 頂点名、頂点間距離を振りなおす
      selectedLayer.vertexes.forEach((v, i) => {
        const previousIdx = i === 0 ? selectedLayer.vertexes.length - 1 : i - 1
        const previousVtx = selectedLayer.vertexes[previousIdx]
        v.distance = Math.sqrt(this.calcDistanceDot2Dot(previousVtx.x, previousVtx.y, v.x, v.y))
        v.distance_meter = v.distance / (this.pixelsPerMeter / this.scaleInput)
        // 頂点名更新
        v.name = `頂点${i + 1}`
        if ((v.x === whichSideVtx.x && v.y === whichSideVtx.y) ||
          (v.x === lineVtx1.x && v.y === lineVtx1.y) ||
          (v.x === lineVtx2.x && v.y === lineVtx2.y) ||
          (v.x === platLineVtx.x && v.y === platLineVtx.y)) {
          passageVertexes.push(v)
        }
      })
      this.addPlat.passageVertexes = passageVertexes

      // 5. vuexの更新

      // バッファーに情報保存
      const frontageMeter = this.addPlat.width
      // 追加された部分の面積を計算しておく
      const vtxsInRoad = []
      vtxsInRoad.push({ x: whichSideVtx.x, y: whichSideVtx.y })
      vtxsInRoad.push({ x: lineVtx1.x, y: lineVtx1.y })
      vtxsInRoad.push({ x: lineVtx2.x, y: lineVtx2.y })
      vtxsInRoad.push({ x: platLineVtx.x, y: platLineVtx.y })
      this.addPlat.area = this.calcPlatArea(vtxsInRoad) / Math.pow(this.pixelsPerMeter / this.scaleInput, 2)
      this.openingPassageway = {
        isPassagewayOpened: true, // 通路開設フラグ
        passagewayVertexes: passageVertexes, // 通路の頂点インデックス
        frontage: frontageMeter, // 通路の間口
        area: this.addPlat.area, // 通路の面積
        whichSideVtx: whichSideVtx // 基準となった頂点
      }
      // 土地情報情報更新
      selectedLayer.relativeCentroid = this.calcCentroid(selectedLayer.relativeVertexes)
      const relativeVtxBuf = []
      selectedLayer.vertexes.forEach(v => {
        relativeVtxBuf.push({ x: v.x + selectedLayer.relativePosition.x, y: v.y + selectedLayer.relativePosition.y })
      })
      selectedLayer.relativeVertexes = relativeVtxBuf
      selectedLayer.relativeCentroid = this.calcCentroid(selectedLayer.relativeVertexes)
      // 土地の形が変わったので、背景画像を更新
      // 通路開設後のバックグラウンドの画像原点を計算
      const platDataAfterAdding = this.getCopyObject(selectedLayer)
      this.addPlat.centroidAfterAdd = this.getCopyObject(platDataAfterAdding.relativeCentroid)
      // 通路開設前後での原点の座標差を計算
      this.addPlat.diffCentroid = {
        x: this.addPlat.centroidAfterAdd.x - this.addPlat.centroidBeforeAdd.x,
        y: this.addPlat.centroidAfterAdd.y - this.addPlat.centroidBeforeAdd.y
      }

      const copySelectedLayer = this.getCopyObject(selectedLayer)
      copySelectedLayer.vertexes.forEach(v => {
        v.x = v.x / this.scaleFactor
        v.y = v.y / this.scaleFactor
      })

      // 測定範囲更新
      this.rangeVertexes = selectedLayer.vertexes
      const newRanges = []
      this.ranges.forEach(cr => {
        newRanges.push({ vertexes: [], routePrices: [], setbacks: [], area: 0 })
        cr.vertexes.forEach(cv => {
          this.rangeVertexes.forEach(rv => {
            if (cv.x === rv.x && cv.y === rv.y) {
              newRanges[newRanges.length - 1].vertexes.push(rv)
            }
          })
        })
      })
      this.ranges = newRanges

      new Promise((resolve) => {
        // 6.画面更新
        this.canvasUpdate(true) // 土地画像のみ描画（頂点番号等は全て描画しない）
        // バックグラウンドの画像更新
        this.updateBackgroundImage(copySelectedLayer) // 背景画像を更新して保存
        resolve()
      }).then(() => {
        this.canvasUpdate()
      })
    },
    /* バッファクリア */
    clearPassageOpeningBuffer () {
      let selectedLayer = this.layers.filter(l => l.selected)
      selectedLayer = selectedLayer.length > 0 ? selectedLayer[0] : undefined // 対象の土地を取得
      // 頂点削除
      for (let i = selectedLayer.vertexes.length - 1; i >= 0; i--) {
        for (let j = 0; j < this.addPlat.passageVertexes.length; j++) {
          if (!(this.addPlat.whichSideVtx.x === this.addPlat.passageVertexes[j].x && this.addPlat.whichSideVtx.y === this.addPlat.passageVertexes[j].y) &&
            (selectedLayer.vertexes[i].x === this.addPlat.passageVertexes[j].x && selectedLayer.vertexes[i].y === this.addPlat.passageVertexes[j].y)
          ) {
            selectedLayer.vertexes.splice(i, 1)
            break
          }
        }
      }
      // 測定範囲削除
      this.ranges.forEach(r => {
        for (let i = r.vertexes.length - 1; i >= 0; i--) {
          let deleteFlag = true
          this.rangeVertexes.forEach(rv => {
            if (r.vertexes[i].x === rv.x && r.vertexes[i].y === rv.y) {
              deleteFlag = false
            }
          })
          if (deleteFlag) {
            r.vertexes.splice(i, 1)
          }
        }
      })
      // 距離再計算
      selectedLayer.vertexes.forEach((v, i) => {
        const prev = i !== 0 ? i - 1 : selectedLayer.vertexes.length - 1
        const dist = this.calcDistanceDot2Dot(v.x, v.y, selectedLayer.vertexes[prev].x, selectedLayer.vertexes[prev].y)
        v.distance_meter = Math.sqrt(dist) / this.pixelsPerMeter * this.scaleInput
      })
      // 頂点番号振り直し
      selectedLayer.vertexes.forEach((v, i) => {
        v.name = `頂点${i + 1}`
      })

      // バッファクリア
      this.addPlat.isPassageOpened = false // フラグ
      this.addPlat.vtx1 = '' // 通路開設の始点１(頂点インデックスのみ)
      this.addPlat.vtx2 = '' // 通路開設の始点２(頂点インデックスのみ)
      this.addPlat.whichSide = '' // 通路をどちらの頂点に接して開設するか(頂点インデックスのみ)
      this.addPlat.whichSideVtx = { x: 0, y: 0 }
      this.addPlat.width = '' // 通路幅
      this.addPlat.line = '' // 通路実線
      this.addPlat.area = 0 // 通路開設部の面積
      this.addPlat.passageVertexes = []
      this.openingPassageway = undefined

      this.canvasUpdate()
    },
    /* 頂点追加（距離指定）メイン処理 */
    async addVtx () {
      // 対象の土地を取得
      let selectedLayer = this.layers.filter(l => l.selected)
      selectedLayer = selectedLayer.length > 0 ? selectedLayer[0] : undefined

      // 頂点番号が1周するか？
      const turnAround = Math.min(this.addVtxData.vtx1, this.addVtxData.vtx2) === 0 && Math.max(this.addVtxData.vtx1, this.addVtxData.vtx2) === selectedLayer.vertexes.length - 1
      // 指定された頂点番号の差
      const diffIndex = Math.abs(this.addVtxData.vtx1 - this.addVtxData.vtx2)

      if (this.addVtxData.vtx1 === '' || this.addVtxData.vtx2 === '') {
        this.errors = { error: '隣接する頂点を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.clearAddVtxBuffer() // バッファクリア
        return
      } else if (this.addVtxData.vtx1 === this.addVtxData.vtx2) {
        this.errors = { error: '指定した頂点が重複しています。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.clearAddVtxBuffer() // バッファクリア
        return
      } else if ((!turnAround && diffIndex !== 1) || (turnAround && diffIndex !== selectedLayer.vertexes.length - 1)) {
        this.errors = { error: '隣り合った頂点を指定してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.clearAddVtxBuffer() // バッファクリア
        return
      } else if (this.addVtxData.distance < 0 || isNaN(this.addVtxData.distance)) {
        this.errors = { error: '無効な追加位置が指定されました。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.clearAddVtxBuffer() // バッファクリア
        return
      }

      // 対象の土地と路線のデータを取得
      const vtx1 = selectedLayer.vertexes[this.addVtxData.vtx1] // 指定された頂点1（基準点）
      const vtx2 = selectedLayer.vertexes[this.addVtxData.vtx2] // 指定された頂点2
      const platLineTilt = this.calcTilt( // 頂点追加する辺の傾き
        vtx1.x,
        vtx1.y,
        vtx2.x,
        vtx2.y
      )
      const signX = Math.sign(vtx2.x - vtx1.x) // 路線頂点1から路線頂点2へのベクトルX成分の符号
      const signY = Math.sign(vtx2.y - vtx1.y) // 路線頂点1から路線頂点2へのベクトルY成分の符号

      // 路線上にある点で、頂点1から指定された路線幅の距離がある点(路線頂点2)の座標を導出する
      const distance = this.addVtxData.distance * (this.pixelsPerMeter / this.scaleInput) // 指定された幅をピクセル換算
      const deltaX = Math.abs(distance / Math.sqrt(Math.pow(platLineTilt.tilt, 2) + 1)) // 傾きから考えたX方向の変化量
      const deltaY = Math.abs(deltaX * platLineTilt.tilt) // 傾きから考えたY方向の変化量
      // 追加された座標取得
      let plotX = vtx1.x + deltaX * signX
      let plotY = vtx1.y + deltaY * signY
      if (platLineTilt.tilt === 0) {
        // 平行
        const sign = (vtx1.x >= vtx2.x) ? -1 : 1
        plotX = vtx1.x + (distance * sign)
        plotY = vtx1.y
      } else if (Math.abs(platLineTilt.tilt) === Infinity) {
        // 垂直
        plotX = vtx1.x
        const sign = (vtx1.y >= vtx2.y) ? -1 : 1
        plotY = vtx1.y + (distance * sign)
      }

      const isWithin = (plotX >= Math.min(vtx1.x, vtx2.x) && plotX <= Math.max(vtx1.x, vtx2.x)) &&
                        (plotY >= Math.min(vtx1.y, vtx2.y) && plotY <= Math.max(vtx1.y, vtx2.y))

      if (!isWithin) {
        this.errors = { error: '無効な追加位置が指定されました。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.clearAddVtxBuffer() // バッファクリア
        return
      }
      const startVtx = (this.addVtxData.vtx1 < this.addVtxData.vtx2) ? this.addVtxData.vtx1 : this.addVtxData.vtx2 // 指定された頂点のうち始点
      const endVtx = (this.addVtxData.vtx1 < this.addVtxData.vtx2) ? this.addVtxData.vtx2 : this.addVtxData.vtx1 // 指定された頂点のうち終点

      if (selectedLayer !== undefined) {
        // 頂点を挿入
        this.layers.forEach(l => {
          if (l.id === selectedLayer.id) {
            // 頂点挿入
            const distStart = Math.sqrt(this.calcDistanceDot2Dot(l.vertexes[startVtx].x, l.vertexes[startVtx].y, plotX, plotY)) / this.pixelsPerMeter * this.scaleInput
            const template = {
              distance: Math.sqrt(this.calcDistanceDot2Dot(l.vertexes[startVtx].x, l.vertexes[startVtx].y, plotX, plotY)),
              distance_expected: '',
              distance_meter: Math.round(distStart * 100) / 100, // 2桁に丸める
              name: `頂点${l.vertexes.length + 1}`,
              x: plotX,
              y: plotY
            }
            if (startVtx + 1 === endVtx) {
              l.vertexes.splice(startVtx + 1, 0, template)
            } else {
              l.vertexes.splice(0, 0, template)
            }
            // 頂点番号の振り直し
            l.vertexes.forEach((v, i) => {
              v.name = `頂点${i + 1}`
              var back = i - 1
              if (i === 0) {
                back = l.vertexes.length - 1
              }
              v.distance = Math.sqrt(this.calcDistanceDot2Dot(l.vertexes[back].x, l.vertexes[back].y, v.x, v.y))
              var dist = v.distance / this.pixelsPerMeter * this.scaleInput
              v.distance_meter = Math.round(dist * 100) / 100 // 2桁に丸める
            })
          }
        })

        this.clearAddVtxBuffer()
        this.canvasUpdate()
      }
    },
    /* バッファクリア */
    clearAddVtxBuffer () {
      this.addVtxData.vtx1 = '' // 通路開設の始点１(頂点インデックスのみ)
      this.addVtxData.vtx2 = '' // 通路開設の始点２(頂点インデックスのみ)
      this.addVtxData.distance = '' // 追加位置
    },
    /* 背景画像を更新して保存 */
    updateBackgroundImage (selectedLayer) {
      // dataの用意
      // const filteredBySelected = this.layers.filter(l => l.selected)
      // const selectedLayer = filteredBySelected.length > 0 ? filteredBySelected[0] : undefined
      if (selectedLayer === undefined) {
        return
      }
      const vtxArray = []
      selectedLayer.vertexes.forEach(v => {
        vtxArray.push({ x: v.x + selectedLayer.relativePosition.x, y: v.y + selectedLayer.relativePosition.y })
      })
      const element = {
        id: selectedLayer.id,
        relativePosition: selectedLayer.relativePosition,
        vertexes: vtxArray
      }
      const platsData = []
      platsData.push(element)
      const dataGet = {
        plats: platsData,
        lines: this.layers.filter(l => l.type !== 'plat'),
        image: this.canvasContext.canvas.toDataURL(),
        scaleFactor: this.scaleFactor
      }
      this.axios.post(`/api/kagechi/paste/${this.$route.params.main_id}`, dataGet).then(res => {
        // 土地の情報を更新
        selectedLayer.vertexes = res.data.vertexes
        selectedLayer.relativeVertexes = res.data.vertexes
        selectedLayer.relativePosition.x += res.data.left - selectedLayer.relativePosition.x // バックエンドの演算との誤差修正
        selectedLayer.relativePosition.y += res.data.top - selectedLayer.relativePosition.y // バックエンドの演算との誤差修正
        selectedLayer.vertexes.forEach((v, i) => {
          const prev = i !== 0 ? i - 1 : selectedLayer.vertexes.length - 1
          const dist = this.calcDistanceDot2Dot(v.x, v.y, selectedLayer.vertexes[prev].x, selectedLayer.vertexes[prev].y)
          v.distance_meter = Math.sqrt(dist) / this.pixelsPerMeter * this.scaleInput
        })
        selectedLayer.angle = 0
        selectedLayer.image = res.data.image
        selectedLayer.imageObj.src = res.data.image
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
      })
    },
    /* 通路開設をした際に土地の形が変形するので、重心が変わって背景画像の描画位置がズレてしまう。この現象に対応するための関数 */
    fixBackgroundImage (selectedLayer) {
      // dataの用意
      const data = {
        id: selectedLayer.id,
        relativePosition: selectedLayer.relativePosition,
        vertexes: selectedLayer.vertexes
      }
      const platsData = []
      platsData.push(data)
      const dataGet = {
        plats: platsData,
        lines: this.layers.filter(l => l.type !== 'plat'),
        image: this.canvasContext.canvas.toDataURL(),
        scaleFactor: this.scaleFactor
      }
      // 通路開設した部分も含めるかたちで、背景画像を保存しなおす
      this.axios.post(`/api/kagechi/paste/${this.$route.params.main_id}`, dataGet).then(res => {
        const plats = this.layers.filter(l => l.type === 'plat')
        plats.forEach((p, i) => {

        })
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
      })
    },

    /* 基準点を指定するドロップダウンのオプション。指定した頂点二つからしか選べないようにしている */
    getOption4WhichSide () {
      const options = []
      if (isNaN(this.addPlat.vtx1) || this.addPlat.vtx1 === '') {
        return
      }
      const min = Math.min(this.addPlat.vtx1, this.addPlat.vtx2)
      options.push({ value: min, text: `頂点${min + 1}` })
      if (isNaN(this.addPlat.vtx2) || this.addPlat.vtx2 === '') {
      }
      const max = Math.max(this.addPlat.vtx1, this.addPlat.vtx2)
      options.push({ value: max, text: `頂点${max + 1}` })
      return options
    },
    // #endregion 通路開設処理=================================================
    // #region 条件作図処理=================================================
    conditionDrawing () {
      const layers = this.layers.filter(l => l.selected)
      const selectedLayer = layers.length > 0 ? layers[0] : undefined
      if (this.condition1.index > this.condition2.index) {
        const change = this.getCopyObject(this.condition1)
        this.condition1 = this.getCopyObject(this.condition2)
        this.condition2 = change
      }
      if (selectedLayer !== undefined) {
        // １．最初の条件の頂点が始点になっている辺と垂直方向の傾き
        let sx = selectedLayer.vertexes[this.condition1.index].x
        let sy = selectedLayer.vertexes[this.condition1.index].y
        let ex = selectedLayer.vertexes[this.condition2.index].x
        let ey = selectedLayer.vertexes[this.condition2.index].y

        const tilt = this.calcTilt(sx, sy, ex, ey)
        const revTilt = -1 / tilt.tilt
        const meterScale = this.meterScale()

        // ２．条件作図が土地の内側に入り込むパターンはＮＧなので、内外判定を行う。
        // 始点から法線方向に向かって微小な距離を足しこんだ座標を想定し、その座標が土地の中に入っているかの判定を行う
        // 内外判定の結果、内側に入り込んでいる場合には、３以降の処理内での距離の符号を反転する
        const repeatTimes = 100 // 判定の繰り返し回数
        const delta = 0.1 // 足しこむ微小な距離の長さ
        let addDistance = delta
        let isInPlat = false // 内外判定の結果
        let isInPlatS = false // 内外判定の結果
        let isInPlatE = false // 内外判定の結果
        for (let index = 0; index < repeatTimes; index++) {
          // 法線方向に向かって微小距離移動した座標の計算
          const dist = addDistance / meterScale
          var dx = dist / Math.sqrt((revTilt * revTilt + 1) / revTilt * revTilt)
          var dy = dx * revTilt
          if (Math.abs(revTilt) === Infinity) {
            // 指定された辺が傾き0の場合
            dx = 0
            dy = addDistance
          } else if (revTilt === 0) {
            // 指定された辺が垂直の場合
            dx = addDistance
            dy = 0
          }
          // 判定
          isInPlatS = this.isInPolygon(
            selectedLayer.vertexes,
            sx + dx,
            sy + dy)
          isInPlatE = this.isInPolygon(
            selectedLayer.vertexes,
            ex + dx,
            ey + dy)
          if (isInPlatS || isInPlatE) {
            isInPlat = true
            break
          } else {
            addDistance += delta
          }
        }

        // ３．指定された座標から法線方向に指定された距離を足す
        // const meterIdx = selectedLayer.vertexes.length === this.condition2.index + 1 ? this.condition1.index : this.condition2.index
        // const meterScale = selectedLayer.vertexes[meterIdx].distance_meter / Math.sqrt(this.calcDistanceDot2Dot(sx, sy, ex, ey))
        let dist1 = this.condition1.distance / meterScale
        dist1 = isInPlat ? dist1 * -1 : dist1 // 内外判定の結果を反映
        var dx1 = dist1 / Math.sqrt((revTilt * revTilt + 1) / revTilt * revTilt)
        var dy1 = dx1 * revTilt
        // ４．距離表示用に土地の頂点番号と始点の座標を保存
        var vtxes1 = {
          platID: selectedLayer.id,
          index: this.condition1.index,
          x2: sx + dx1 + selectedLayer.relativePosition.x,
          y2: sy + dy1 + selectedLayer.relativePosition.y,
          isShown: true
        }
        sx = sx + dx1 + selectedLayer.relativePosition.x
        sy = sy + dy1 + selectedLayer.relativePosition.y
        let dist2 = this.condition2.distance / meterScale
        dist2 = isInPlat ? dist2 * -1 : dist2 // 内外判定の結果を反映
        var dx2 = dist2 / Math.sqrt((revTilt * revTilt + 1) / revTilt * revTilt)
        var dy2 = dx2 * revTilt

        // ５．距離表示用に土地の頂点番号と終点の座標を保存
        var vtxes2 = {
          platID: selectedLayer.id,
          index: this.condition2.index,
          x2: ex + dx2 + selectedLayer.relativePosition.x,
          y2: ey + dy2 + selectedLayer.relativePosition.y,
          isShown: true
        }
        ex = ex + dx2 + selectedLayer.relativePosition.x
        ey = ey + dy2 + selectedLayer.relativePosition.y

        if (Math.abs(revTilt) === Infinity) {
          // 指定された辺が傾き0の場合
          vtxes1.x2 = selectedLayer.vertexes[this.condition1.index].x + selectedLayer.relativePosition.x
          vtxes1.y2 = selectedLayer.vertexes[this.condition1.index].y + dist1 + selectedLayer.relativePosition.y
          vtxes2.x2 = selectedLayer.vertexes[this.condition2.index].x + selectedLayer.relativePosition.x
          vtxes2.y2 = selectedLayer.vertexes[this.condition2.index].y + dist2 + selectedLayer.relativePosition.y
          sx = vtxes1.x2
          sy = vtxes1.y2
          ex = vtxes2.x2
          ey = vtxes2.y2
        } else if (revTilt === 0) {
          // 指定された辺が垂直の場合
          vtxes1.x2 = selectedLayer.vertexes[this.condition1.index].x + dist1 + selectedLayer.relativePosition.x
          vtxes1.y2 = selectedLayer.vertexes[this.condition1.index].y + selectedLayer.relativePosition.y
          vtxes2.x2 = selectedLayer.vertexes[this.condition2.index].x + dist2 + selectedLayer.relativePosition.x
          vtxes2.y2 = selectedLayer.vertexes[this.condition2.index].y + selectedLayer.relativePosition.y
          sx = vtxes1.x2
          sy = vtxes1.y2
          ex = vtxes2.x2
          ey = vtxes2.y2
        }

        // ６．本体の描画
        this.drawLine(sx, sy, ex, ey, 2, 'rgb(255, 0, 0)', [0])
        const lines = this.layers.filter(l => l.type === 'solid')
        this.drawingLayer = {
          type: 'solid',
          name: this.lineDefaultName.solid + (lines.length + 1),
          selected: false,
          showing: true,
          points: { fromX: sx, fromY: sy, toX: ex, toY: ey },
          color: this.selectingColorRgba || 'rgb(255, 0, 0)',
          lineWidth: 2
        }
        this.layers.forEach(layer => {
          layer.selected = false
        })
        this.layers.push(this.drawingLayer)
        this.conditionDrawingVtx.push(vtxes1)
        this.conditionDrawingVtx.push(vtxes2)

        // ７．画面更新
        this.canvasUpdate()
        this.mouseUp()
      }
    },
    setConditionDrawingVtxes () {
      this.errors = { }
      this.conditionVertexes = []
      // 条件作図の頂点候補を追加する
      const layers = this.layers.filter(l => l.selected)
      const selectedLayer = layers.length > 0 ? layers[0] : undefined
      if (selectedLayer !== undefined) {
        for (let i = 0; i < selectedLayer.vertexes.length; i++) {
          this.conditionVertexes.push({ value: i, text: `頂点${i + 1}` })
        }
        this.opMode = ''
        this.showModal()
      } else {
        this.hideModal()
        this.changeConditionDrawingdFlag()
        this.errors = { error: 'レイヤーを選択してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    // #endregion 条件作図処理=================================================

    go2Trimming () {
      this.$router.push({ path: '/kagechi/upload' })
    },
    openInNewTab (url) {
      window.open(url, '_blank', 'noreferrer')
    },
    // 頂点追加（距離指定）
    setAddVtx () {
      this.errors = { }
      this.conditionVertexes = []
      const layers = this.layers.filter(l => l.type === 'plat')
      let selectedLayer = layers.filter(l => l.selected)
      selectedLayer = selectedLayer.length > 0 ? selectedLayer[0] : undefined
      if (selectedLayer !== undefined) {
        for (let i = 0; i < selectedLayer.vertexes.length; i++) {
          this.conditionVertexes.push({ value: i, text: `頂点${i + 1}` })
        }
        this.opMode = ''
        this.showAddVtxModal()
      } else {
        this.hideAddVtxModal()
        this.changeAddVtxFlag()
        this.errors = { error: '土地を選択してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    // 無道路地の道路開設の設定
    setAddPlatVtxes () {
      this.errors = {}
      this.conditionVertexes = []
      this.addPlatLines = []
      const layers = this.layers.filter(l => l.type === 'plat')
      let selectedLayer = layers.filter(l => l.selected)
      const lines = this.layers.filter(l => l.type === 'solid')
      selectedLayer = selectedLayer.length > 0 ? selectedLayer[0] : undefined
      if (selectedLayer === undefined) {
        this.errors = { error: '土地を選択してください。' }
      }
      if (lines.length === 0) {
        this.errors = { error: '道路の実線を引いてください。' }
      }
      if (this.errors.error) {
        this.hideAddPlatModal()
        this.changeOpeningPassageFlag()
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      if (this.addPlat.isPassageOpened) {
        this.showConfirmAddPlatModal()
        return
      }

      for (let i = 0; i < selectedLayer.vertexes.length; i++) {
        this.conditionVertexes.push({ value: i, text: `頂点${i + 1}` })
      }
      lines.forEach((l, i) => {
        this.addPlatLines.push({ value: i, text: l.name })
      })
      this.opMode = ''
      this.showAddPlatModal()
    },
    changeConditionDrawingdFlag () {
      this.isConditionSelected = !this.isConditionSelected
      if (this.opMode === '' && !this.isConditionSelected) {
        this.opMode = 'move'
      }
    },
    changeOpeningPassageFlag () {
      this.isOpeningPassage = !this.isOpeningPassage
      if (this.opMode === '' && !this.isOpeningPassage) {
        this.opMode = 'move'
      }
    },
    changeAddVtxFlag () {
      this.isAddVtx = !this.isAddVtx
      if (this.opMode === '' && !this.isAddVtx) {
        this.opMode = 'move'
      }
    },
    changeSelectedFlag () {
      this.opMode = 'move'
    },
    setShowPdfDropdownFlag () {
      const fileName = this.pdfFiles[0].filename
      this.showPdfDropdown = !(fileName.includes('FreeHand') || fileName.includes('.gpx'))
    },
    showModal () {
      this.$refs['conditional-modal'].show()
    },
    hideModal () {
      this.$refs['conditional-modal'].hide()
    },
    showAddPlatModal () {
      this.$refs['AddPlat-modal'].show()
    },
    hideAddPlatModal () {
      this.$refs['AddPlat-modal'].hide()
    },
    showConfirmAddPlatModal () {
      this.$refs['confirm-addplat-modal'].show()
    },
    hideConfirmAddPlatModal () {
      this.$refs['confirm-addplat-modal'].hide()
    },
    showAddVtxModal () {
      this.$refs['AddVtx-modal'].show()
    },
    hideAddVtxModal () {
      this.$refs['AddVtx-modal'].hide()
    },
    selectAll () {
      const selectedCount = this.ranges[this.activeTabIndex].vertexes.length
      this.ranges[this.activeTabIndex].vertexes = []
      if (this.rangeVertexes.length === selectedCount) {
        this.canvasUpdate()
        return
      }
      this.rangeVertexes.forEach(v => {
        this.ranges[this.activeTabIndex].vertexes.push(v)
      })
      this.canvasUpdate()
    },
    /* オブジェクト値渡しのための関数 */
    getCopyObject (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    resetPage () {
      location.reload()
    }
  }
}
</script>

<style scoped>
#toolbar .btn {
  width: 40px;
}
#toolbar .btn img {
  width: 100%;
}
#layer-list .list-group-item {
  padding: 0.25rem;
}
#layer-list .list-group-item.active {
  background-color: #6E91AD;
}
#layer-list .layer-icon img {
  width: 16px;
}
.icon {
  position: absolute;
  right: auto;
}
</style>
