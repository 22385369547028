<template>
  <div class="row">
    <div v-bind:class="maximizeWindow ? 'col-12' : 'col-8'">
      <div ref="box" class="operator-box">
        <div v-if="isCanvasShown">
          拡縮：{{ parseInt(scale * 100, 10) }}%
        </div>
        <div v-else>
          ズームレベル：{{ zoom }}&nbsp;/&nbsp;21
        </div>
        <b-btn-group>
          <b-icon-plus-circle
            v-show="isCanvasShown"
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="拡大"
            @click="scalePlus"
          ></b-icon-plus-circle>

          <b-icon-dash-circle
            v-show="isCanvasShown"
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="縮小"
            @click="scaleDash"
          ></b-icon-dash-circle>

          <b-icon-fullscreen
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="全画面"
            @click="maximizeWindowSize"
            v-bind:class="maximizeWindow ? 'd-none' : 'd-inline-block'"
          ></b-icon-fullscreen>

          <b-icon-fullscreen-exit
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="全画面終了"
            @click="maximizeWindowSize"
            v-bind:class="maximizeWindow ? 'd-inline-block' : 'd-none'"
          ></b-icon-fullscreen-exit>

          <b-icon-geo-alt
            v-show="isCanvasShown"
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="Google マップで表示"
            @click="toggleCanvasMap"
          ></b-icon-geo-alt>

          <b-icon-bounding-box-circles
            v-show="!isCanvasShown"
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="カンバスで表示"
            @click="toggleCanvasMap"
          ></b-icon-bounding-box-circles>
        </b-btn-group>
      </div>

      <GoogleMap
        ref="googleMap"
        style="height: 600px; width: 100%;"
        v-if="shouldRenderComponent"
        :isSearchShown="false"
        v-bind:class="isCanvasShown ? 'd-none' : 'd-block' "
      ></GoogleMap>

      <canvas
        ref="canvas"
        :width="canvasWidth"
        :height="canvasHeight"
        @mousedown="mouseDown"
        @mouseup="mouseUp"
        @mouseout="mouseUp"
        @mousemove="mouseMove"
        @wheel.prevent="changeScale"
        v-bind:class="isCanvasShown ? 'd-block' : 'd-none'"
      />
      <b-button v-b-toggle.description variant="dark" size="sm" class="mt-2"><b-icon-info-circle class="mr-1" />GPXの精度について</b-button>
        <b-collapse id="description">
          <b-card class="small" body-class="p-2" bg-variant="light" text-variant="dark">
            <ul class="mb-0 pl-3">
              <li>GPXデータを入力した時点ではGPXデータの有効な桁数は無制限です。</li>
              <li>一方でGoogleMap上で頂点の移動、追加を実行すると、GoogleMapの仕様上、GPXの精度は小数点6桁になります。（最大誤差10cm程度）</li>
            </ul>
          </b-card>
        </b-collapse>
    </div>
    <div class="col-4" v-if="!maximizeWindow">
      <div class="operator-box mb-2">
        <div>GPX座標リスト</div>
      </div>
      <div class="plat-box">
        <div class="accordion" role="tablist">
          <b-card v-for="(plat, index) in plats" :key="index" no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <div class="header-top-green float-left text-left ml-3 my-3">座標<b-badge class="ml-2" v-if="plat.visible" variant="secondary">編集中</b-badge></div>
              <div class="text-right">
                <b-btn variant="primary" v-b-tooltip.hover title="編集" @click="onSelectChangePlat(plat)" class="my-2 mr-2"><b-icon-chevron-up v-show="plat.visible" /><b-icon-chevron-down v-show="!plat.visible" />編集</b-btn>
              </div>
            </b-card-header>
            <b-collapse v-model="plat.visible" accordion="my-accordion" role="tabpanel">
              <b-card-body class="text-dark">
                <draggable id="layer-list" tag="ul" class="list-group" v-model="plat.vertexes" @end="draggableEnd">
                  <b-list-group-item button v-for="(vertex, vIdx) in plat.vertexes" :key="vIdx" @click="vSelect(vIdx)" @End="vSelect(vIdx)">
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="pl-2">{{ vertex.name }}</span>
                      <b-button size="sm" variant="danger" @click="$bvModal.show(`delete-confirm-${vIdx}`)">×</b-button>
                      <b-modal :id="`delete-confirm-${vIdx}`" hide-header @ok="deleteVertex(index, vIdx)" ok-variant="danger">
                        <div>座標の削除操作は元に戻せません。本当によろしいですか？</div>
                      </b-modal>
                    </div>
                  </b-list-group-item>
                </draggable>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
    <b-modal :id="`delete-confirm-${tabIndex}`" hide-header ok-variant="danger" @ok="deleteTreeNode">
      <div>選択中の土地または頂点を削除します。本当によろしいですか？</div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import GoogleMap from './GoogleMap'
import draggable from 'vuedraggable'
import CanvasMixin from '../mixins/canvas'
import colors from '../colors'
import GPX from '../mixins/gpx'

let SCALE_STEP = 0.1

export default {
  name: 'TrimmingImage',
  mixins: [CanvasMixin, GPX],
  components: {
    GoogleMap,
    draggable
  },
  props: {
    base64Image: String,
    fileIndex: {
      type: Number,
      required: true
    },
    tabIndex: String,
    pdfName: String
  },
  data: () => ({
    color: colors.color, // これを書いておかないと、colors 変数使っていないとのエラーになる
    canvasWidth: 0,
    canvasHeight: 600,
    canvasContext: undefined,
    image: new Image(),
    checkVertexes: [],
    position: { x: 0, y: 0 },
    scaleIndex: 0,
    drag: { dragging: 0, startImagePos: { x: 0, y: 0 }, startMousePos: { x: 0, y: 0 } },
    plats: [],
    selectedPlat: undefined,
    verticalPlotFlag: false,
    conditionPlotFlag: false,
    isVerticalPlot: true,
    conditionPlot: { start: 0, end: 0 },
    maximizeWindow: false,
    isCanvasShown: true,
    shouldRenderComponent: false,
    availablePlotWay: 0,
    dragPlotData: { flag: false, start: { flag: false, x: '', y: '' }, end: { flag: false, x: '', y: '' } },
    scaleVal: 1,
    tabIdx: '',
    selectedVertex: '',
    gs: [],
    vidx: 0
  }),
  watch: {
    checkVertexes () {
      this.canvasUpdate()
    },
    markers () {
      if (this.markers.length > 0 && this.isCanvasShown) {
        this.updatePlats()
      } else {
        this.addPlat()
      }
    }
  },
  computed: {
    ...mapState({
      markers: state => state.googleMap.markers,
      zoom: state => state.googleMap.zoom
    }),
    ...mapGetters('gpx', ['getVertexesByFileIndex']),

    vertexes: function () {
      return this.getVertexesByFileIndex(this.fileIndex)
    },
    scale () {
      return this.scaleVal
    },
    scaledImageSize () {
      return {
        width: this.$refs.canvas.width / this.scale,
        height: this.$refs.canvas.height / this.scale
      }
    }
  },
  mounted () {
    const resizeObserver = new ResizeObserver((entries) => {
      this.initCanvas()
    })
    if (this.$refs.box) {
      resizeObserver.observe(this.$refs.box)
    }
  },
  created () {
    this.initCanvas()
    this.setPlats()
    this.plats.forEach((p) => {
      p.visible = true
    })
    this.tabIdx = this.tabIndex
    document.addEventListener('keydown', this.verticalPlotOn)
    document.addEventListener('keyup', this.verticalPlotOff)
    this.refreshAvailablePlotWay()
    this.updatePlats()
  },
  methods: {
    ...mapActions('googleMap',
      [
        'deleteMarkers',
        'deleteGSCoordinates',
        'repopulateMarkers',
        'repopulateGSCoordinates',
        'reloadMap'
      ]
    ),
    initCanvas () {
      if (this.$refs.canvas && this.$refs.box.offsetWidth > 0) {
        this.canvasWidth = this.$refs.box.offsetWidth - 4 // border分だけマイナスする
        this.canvasContext = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
        this.canvasContext.lineCap = 'round'
        this.canvasContext.lineJoin = 'round'
        this.canvasContext.lineWidth = 5
        this.canvasContext.strokeStyle = '#000000'
        this.canvasContext.fillStyle = '#FFFFFF'
        this.canvasContext.fillRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)

        if (this.base64Image) {
          this.image.src = this.base64Image
          this.image.onload = () => {
            this.canvasUpdate()
          }
        }
      } else {
        setTimeout(() => {
          this.initCanvas()
        }, 500)
      }
    },
    setPlats () {
      if (this.vertexes) {
        let count = 0
        this.vertexes.forEach((vertex) => {
          if (vertex.length !== 0 && this.plats.length === 0) {
            count += 1
            if (this.pdfName !== undefined) {
              if (this.pdfName.substr(1, 2) === '土地') {
                this.plats.push({ id: String(count), name: `土地${count}`, vertexes: vertex, visible: true, measure: [] })
              } else {
                this.plats.push({ id: String(count), name: `建物${count}`, vertexes: vertex, visible: true, measure: [] })
              }
            } else {
              this.plats.push({ id: String(count), name: `土地${count}`, vertexes: vertex, visible: true, measure: [] })
            }
          }
        })
      }
    },
    addPlat () {
      if (this.plats.length > 0) {
        return
      }
      const nextId = this.plats.length + 1
      if (this.pdfName !== undefined) {
        if (this.pdfName.substr(1, 2) === '土地') {
          this.plats.push({ id: String(nextId), name: `土地${nextId}`, vertexes: [], visible: true, measure: [] })
        } else {
          this.plats.push({ id: String(nextId), name: `建物${nextId}`, vertexes: [], visible: true, measure: [] })
        }
      } else {
        this.plats.push({ id: String(nextId), name: `土地${nextId}`, vertexes: [], visible: true, measure: [] })
      }
    },
    async updatePlats () {
      this.gs = []
      this.vidx = 0
      this.loopVtx(this.markers).then(() => {
        this.gs = this.adjustXYs(this.gs)
        const vtxs = []
        Object.keys(this.gs).forEach((key, index) => {
          vtxs.push(this.gs[key])
        })
        const sumVrx = vtxs.map((o) => { return parseFloat(o.x) + parseFloat(o.y) })
        vtxs.forEach((v, index) => {
          this.gs[index].x = v.x * 400 / Math.max(...sumVrx) + 100
          this.gs[index].y = v.y * 400 / Math.max(...sumVrx) + 100
        })
        if (this.plats.length > 0) {
          // 土地情報を更新
          this.plats[0].vertexes = []
          this.gs.forEach((g, i) => {
            this.plats[0].vertexes.push({
              id: `0-${i + 1}`,
              name: `頂点${i + 1}`,
              x: g.x,
              y: g.y,
              lat: g.lat,
              lon: g.lon,
              distance_meter: ''
            })
          })
        }
        this.initCanvas()
        this.setPlats()
        this.canvasUpdate()
        // 距離情報更新
        this.calculateDistance()
      })
    },
    canvasUpdate () {
      this.refreshAvailablePlotWay()
      // 真っ白にする
      this.canvasContext.fillStyle = 'white'
      this.canvasContext.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContext.save()

      // 測量図画像を描画する
      if (this.maximizeWindow) {
        const dx = -Math.abs(this.position.x * this.scale)
        const dy = -Math.abs(this.position.y * this.scale)
        this.canvasContext.drawImage(this.image, dx, dy, this.image.width * this.scale, this.image.height * this.scale)
      } else {
        const scaledSize = this.scaledImageSize
        this.canvasContext.drawImage(this.image, this.position.x, this.position.y, scaledSize.width, scaledSize.height, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height)
      }
      this.canvasContext.restore()

      // 色反転
      this.colorInversion(this.canvasContext, this.canvasWidth, this.canvasHeight)
      // 土地を描画する
      this.canvasContext.strokeStyle = colors.platColorRgba()
      this.canvasContext.lineWidth = 1
      this.plats.forEach(plat => {
        this.drawPlatGPX(this.canvasContext, plat.vertexes, this.scale, this.position)
      })

      // 選択されている頂点をハイライト
      if (this.selectedVertex !== '' && this.selectedPlat.vertexes[this.selectedVertex] !== undefined) {
        const x = (this.selectedPlat.vertexes[this.selectedVertex].x - this.position.x) * this.scale
        const y = (this.selectedPlat.vertexes[this.selectedVertex].y - this.position.y) * this.scale
        // 頂点
        this.canvasContext.fillStyle = 'red'
        this.canvasContext.fillRect(x - 5, y - 5, 10, 10)
      }
    },
    changeScale (event) {
      const isZoom = event.wheelDelta > 0
      this.scaleIndex = isZoom ? this.scaleIndex + 1 : Math.max(-9, this.scaleIndex - 1)
      if (isZoom) {
        this.scaleVal += SCALE_STEP
      } else {
        if (this.scaleVal - SCALE_STEP > 0.1) {
          this.scaleVal -= SCALE_STEP
        }
      }

      if (this.scale >= 1) {
        SCALE_STEP = Math.round(this.scale) * 0.1
      }
      this.canvasUpdate()
    },
    scalePlus () {
      const event = { wheelDelta: 1, clientX: 0, clientY: 0 }
      this.changeScale(event)
    },
    scaleDash () {
      const event = { wheelDelta: -1, clientX: 0, clientY: 0 }
      this.changeScale(event)
    },
    /**
     * canvasの左上の座標位置を取得する
     * @returns {number[]}
     */
    getCanvasTopLeftPosition () {
      const rect = this.$refs.canvas.getBoundingClientRect()
      return [rect.left, rect.top]
    },
    /**
     * Canvas内のマウスポインターの座標位置を取得する
     * @param event
     * @returns {number[]}
     */
    getPointerPosition (event) {
      const [left, top] = this.getCanvasTopLeftPosition()
      return [
        event.clientX - left,
        event.clientY - top
      ]
    },
    mouseDown (event) {
      if (this.conditionPlotFlag) {
        this.conditionPlotFlag = false
      } else if (this.dragPlotData.flag) {
        const [x, y] = this.getPointerPosition(event)
        this.dragPlotData.start.flag = true
        this.dragPlotData.start.x = (x / this.scale) + this.position.x
        this.dragPlotData.start.y = (y / this.scale) + this.position.y

        this.drag = {
          dragging: 1,
          startImagePos: { x: this.position.x, y: this.position.y },
          startMousePos: { x, y },
          vertexIndexes: []
        }
      } else {
        // canvas内でのマウスポインタの位置
        const [x, y] = this.getPointerPosition(event)

        this.drag = {
          dragging: 1,
          startImagePos: { x: this.position.x, y: this.position.y },
          startMousePos: { x, y },
          vertexIndexes: []
        }

        // 座標が頂点の上かどうか
        // this.plats.forEach((plat, platIndex) => {
        //   plat.vertexes.forEach((vertex, vertexIndex) => {
        //     const rectStartX = vertex.x - 5
        //     const rectEndX = vertex.x + 5
        //     const rectStartY = vertex.y - 5
        //     const rectEndY = vertex.y + 5
        //     if (rectStartX <= relativeX && relativeX <= rectEndX && rectStartY <= relativeY && relativeY <= rectEndY) {
        //       this.drag.vertexIndexes = [platIndex, vertexIndex]
        //     }
        //   })
        // })
      }
    },
    mouseUp (event) {
      if (this.drag.dragging === 1 && this.selectedPlat) {
        if (this.conditionPlotFlag && this.selectedPlat.vertexes.length >= 2) {
          // 垂直描点
          // マウス位置取得
          const [x, y] = this.getPointerPosition(event)
          // 直近の2点から傾き算出
          const sx = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].x
          const sy = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].y
          const ex = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 2].x
          const ey = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 2].y
          const tilt = this.calcTilt(sx, sy, ex, ey).tilt
          // 法線方向の傾き算出
          const revTilt = -1 / tilt
          // 直近の点を通る法線の式の切片を求める
          const intercept = sy - revTilt * sx
          let dx, dy

          if (revTilt > 4 || revTilt < -4) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / revTilt
          } else if (revTilt > 1) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / revTilt
          } else {
            dx = (x / this.scale) + this.position.x
            dy = revTilt * dx + intercept
          }
          this.selectedPlat.vertexes.push({
            id: `${this.selectedPlat.id}-${this.selectedPlat.vertexes.length + 1}`,
            name: `頂点${this.selectedPlat.vertexes.length + 1}`,
            x: dx,
            y: dy,
            distance_meter: ''
          })
          this.inputScale()
          this.vertexesNum++
          this.conditionPlotFlag = false
          this.conditionPlot = { start: 0, end: 0 }
          this.canvasUpdate()
        } else {
          if (this.selectedPlat.visible) {
            // 選択中の土地に頂点を追加する
            // canvas内でのマウスポインタの位置
            const [x, y] = this.getPointerPosition(event)
            const delta = this.calculateLatLonDelta(this.selectedPlat) // 緯度経度のデルタを計算
            const vX = (x / this.scale) + this.position.x
            const vY = (y / this.scale) + this.position.y
            const standardVertex = this.selectedPlat.vertexes[0] // 緯度経度のデルタを足しこむ基準点(土地の最初の点)
            const lat = (vY - standardVertex.y) * delta.deltaLat + standardVertex.lat
            const lon = (vX - standardVertex.x) * delta.deltaLon + standardVertex.lon
            // 頂点追加
            this.selectedPlat.vertexes.push({
              id: `${this.selectedPlat.id}-${this.selectedPlat.vertexes.length + 1}`,
              name: `頂点${this.selectedPlat.vertexes.length + 1}`,
              x: vX,
              y: vY,
              lat: lat,
              lon: lon
            })
            // GoogleMapの頂点も更新
            this.deleteMarkers()
            this.deleteGSCoordinates()
            this.repopulateMarkers(this.selectedPlat.vertexes)
            this.repopulateGSCoordinates(this.selectedPlat.vertexes)
            // GoogleMapのリロード
            this.reloadMap(true)

            // 画面更新系処理
            // this.inputScale()
            this.canvasUpdate()
            this.calculateDistance()
          }
        }
      } else if (this.drag.dragging > 0 && this.dragPlotData.flag) {
        // マウス位置取得
        const [x, y] = this.getPointerPosition(event)
        // endの座標に現在地を入れる
        this.dragPlotData.end.flag = true
        this.dragPlotData.end.x = (x / this.scale) + this.position.x
        this.dragPlotData.end.y = (y / this.scale) + this.position.y
        const vtx = this.dragPlot(this.dragPlotData.start.x,
          this.dragPlotData.start.y,
          this.dragPlotData.end.x,
          this.dragPlotData.end.y)
        vtx.forEach(v => {
          this.selectedPlat.vertexes.push({
            id: `${this.selectedPlat.id}-${this.selectedPlat.vertexes.length + 1}`,
            name: `頂点${this.selectedPlat.vertexes.length + 1}`,
            x: v.x,
            y: v.y
          })
        })
        this.canvasUpdate()
        this.dragPlotData = { flag: false, start: { flag: false, x: '', y: '' }, end: { flag: false, x: '', y: '' } }
        this.drag = { dragging: 0, startImagePos: { x: 0, y: 0 }, startMousePos: { x: 0, y: 0 } }
      }
      this.drag.dragging = 0
      this.refreshAvailablePlotWay()
    },
    mouseMove (event) {
      // canvas内でのマウスポインタの位置
      const [x, y] = this.getPointerPosition(event)

      if (this.drag.dragging > 0) {
        if (this.dragPlotData.flag && this.dragPlotData.start.flag) {
          // 頂点のドラッグ指定モード
          // 画面更新
          this.canvasUpdate()
          this.drag.dragging += 1
          // マウス位置取得
          const [x, y] = this.getPointerPosition(event)
          const canvasContext = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
          canvasContext.lineWidth = 2
          canvasContext.strokeStyle = colors.draggingColorRgba()
          canvasContext.setLineDash([5, 5])
          const sx = (this.dragPlotData.start.x - this.drag.startImagePos.x) * this.scale
          const sy = (this.dragPlotData.start.y - this.drag.startImagePos.y) * this.scale
          // const dragWidth = (x / this.scale) + this.position.x - this.dragPlotData.start.x
          const dragWidth = x / this.scale + this.position.x - this.dragPlotData.start.x
          // const dragHeight = (y / this.scale) + this.position.y - this.dragPlotData.start.y
          const dragHeight = y / this.scale + this.position.y - this.dragPlotData.start.y
          canvasContext.strokeRect(sx,
            sy,
            dragWidth * this.scale,
            dragHeight * this.scale)
        } else {
          // ドラッグが開始されていればオブジェクトの座標を更新して再描画
          this.drag.dragging += 1

          if (this.drag.vertexIndexes.length === 0) {
            // 画像の移動
            this.position.x = this.drag.startImagePos.x + (this.drag.startMousePos.x - x) / this.scale
            this.position.y = this.drag.startImagePos.y + (this.drag.startMousePos.y - y) / this.scale
            // this.position.x = Math.max(0, this.position.x)
            // this.position.y = Math.max(0, this.position.y)
          } else {
            // 頂点の移動
            const [platIndex, vertexIndex] = this.drag.vertexIndexes
            this.plats[platIndex].vertexes[vertexIndex].x = (x / this.scale) + this.position.x
            this.plats[platIndex].vertexes[vertexIndex].y = (y / this.scale) + this.position.y
          }
          // 画面更新
          this.canvasUpdate()
        }
      } else if (this.conditionPlotFlag && this.selectedPlat.vertexes.length > 1) {
        if (this.isVerticalPlot) {
          // 垂直
          const platIndex = this.selectedPlat.id - 1
          const vertexIndex = this.plats[platIndex].vertexes.length - 1

          // 直近の2点から傾き算出
          const sx = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 2].x
          const sy = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 2].y
          const ex = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 3].x
          const ey = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 3].y
          const tilt = this.calcTilt(sx, sy, ex, ey).tilt
          // 法線方向の傾き算出
          const revTilt = -1 / tilt
          // 直近の点を通る法線の式の切片を求める
          const intercept = sy - revTilt * sx
          let dx, dy

          if (revTilt > 4 || revTilt < -4) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / revTilt
          } else if (revTilt > 1) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / revTilt
          } else {
            dx = (x / this.scale) + this.position.x
            dy = revTilt * dx + intercept
          }

          this.plats[platIndex].vertexes[vertexIndex].x = dx
          this.plats[platIndex].vertexes[vertexIndex].y = dy
        } else {
          // 平行
          const platIndex = this.selectedPlat.id - 1
          const vertexIndex = this.plats[platIndex].vertexes.length - 1

          // 指定の2点から傾き算出
          const sx = this.selectedPlat.vertexes[this.conditionPlot.start].x
          const sy = this.selectedPlat.vertexes[this.conditionPlot.start].y
          const ex = this.selectedPlat.vertexes[this.conditionPlot.end].x
          const ey = this.selectedPlat.vertexes[this.conditionPlot.end].y
          const tilt = this.calcTilt(sx, sy, ex, ey).tilt
          // 直前の点を通る平行式の切片を求める
          const intercept = this.plats[platIndex].vertexes[vertexIndex - 1].y - tilt * this.plats[platIndex].vertexes[vertexIndex - 1].x
          let dx, dy

          if (tilt > 4 || tilt < -4) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / tilt
          } else if (tilt > 1) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / tilt
          } else {
            dx = (x / this.scale) + this.position.x
            dy = tilt * dx + intercept
          }

          this.plats[platIndex].vertexes[vertexIndex].x = dx
          this.plats[platIndex].vertexes[vertexIndex].y = dy
        }
        this.canvasUpdate()
      }
    },
    onSelectChangePlat (targetPlat) {
      var clickPlatVisible = !targetPlat.visible
      this.plats.forEach(plat => {
        plat.visible = false
      })
      targetPlat.visible = clickPlatVisible
      this.selectedPlat = targetPlat
      this.checkVertexes = []
      this.refreshAvailablePlotWay()
    },
    deleteTreeNode () {
      const ids = this.selectedPlat.id.split('-')
      const platIndex = this.plats.findIndex(p => p.id === ids[0])
      if (ids.length === 1 && this.checkVertexes.length === 0) {
        // 図形ごと削除する
        this.plats.splice(platIndex, 1)
        this.selectedPlat = undefined

        // GoogleMapの頂点も削除
        this.deleteMarkers()
        this.deleteGSCoordinates()
      } else if (this.checkVertexes.length > 0) {
        // 頂点だけ削除する
        this.checkVertexes.forEach(checkVertex => {
          this.plats.forEach(plat => {
            const checkIndex = plat.vertexes.findIndex(p => p.id === checkVertex)
            if (checkIndex !== -1) {
              plat.vertexes.splice(checkIndex, 1)
            }
          })
        })
        this.checkVertexes = []

        // GoogleMapの頂点も更新
        this.deleteMarkers()
        this.deleteGSCoordinates()
        this.plats.forEach(plat => {
          this.repopulateMarkers(plat.vertexes)
          this.repopulateGSCoordinates(plat.vertexes)
        })
      }

      // GoogleMapのリロード
      this.reloadMap(true)

      // name, id 振り直し
      if (this.pdfName !== undefined) {
        const platOrBuilding = this.pdfName.substr(1, 2)
        this.plats.forEach((plat, platIndex) => {
          if (platOrBuilding === '土地') {
            plat.name = `土地${platIndex + 1}`
          } else {
            plat.name = `建物${platIndex + 1}`
          }
          plat.id = `${platIndex + 1}`
          plat.vertexes.forEach((vertex, vertexIndex) => {
            vertex.name = `頂点${vertexIndex + 1}`
            vertex.id = `${platIndex + 1}-${vertexIndex + 1}`
          })
        })
      } else {
        this.plats.forEach((plat, platIndex) => {
          plat.name = `土地${platIndex + 1}`
          plat.id = `${platIndex + 1}`
          plat.vertexes.forEach((vertex, vertexIndex) => {
            vertex.name = `頂点${vertexIndex + 1}`
            vertex.id = `${platIndex + 1}-${vertexIndex + 1}`
          })
        })
      }
      this.canvasUpdate()
    },
    plotCoordinates (gs) {
      const vtxArray = []
      const scaleVal = this.scale
      const positionVal = [this.position.x, this.position.y]
      Object.keys(gs).forEach(function (key) {
        const [x, y] = [gs[key].x, gs[key].y]
        // 緯度，経度 の項目を追加
        // 緯度経度が取得できないときは、データを 0 とする
        const [lat, lon] = [
          (typeof gs[key].lat === 'number') ? gs[key].lat : 0,
          (typeof gs[key].lon === 'number') ? gs[key].lon : 0
        ]
        vtxArray.push({
          id: `${key}-${parseInt(key) + 1}`,
          name: `頂点${parseInt(key) + 1}`,
          x: (x / scaleVal) + positionVal[0],
          y: (y / scaleVal) + positionVal[1],
          distance_meter: '',
          lat: lat,
          lon: lon
        })
      })
      this.selectedPlat.vertexes = vtxArray

      // Google Mapにも反映
      this.deleteMarkers()
      this.deleteGSCoordinates()
      this.repopulateMarkers(vtxArray)
      this.repopulateGSCoordinates(vtxArray)
      // GoogleMapのリロード
      this.reloadMap(true)

      this.canvasWidth = this.$refs.box.offsetWidth - 4 // border分だけマイナスする
      this.canvasContext = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
      this.canvasContext.lineCap = 'round'
      this.canvasContext.lineJoin = 'round'
      this.canvasContext.lineWidth = 5
      this.canvasContext.strokeStyle = '#000000'
      this.canvasContext.fillStyle = '#FFFFFF'
      this.canvasContext.fillRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)

      this.canvasUpdate()
      this.drag.dragging = 0
    },
    verticalPlotOn (event) {
      if (event.ctrlKey && !this.verticalPlotFlag) {
        this.verticalPlotFlag = true
      }
    },
    verticalPlotOff (event) {
      if (this.verticalPlotFlag) {
        this.verticalPlotFlag = false
      }
    },
    conditionPlotSet () {
      this.conditionPlotFlag = true
      if (this.isVerticalPlot) {
        if (this.conditionPlot.start === this.conditionPlot.end) {
          alert('始点と終点が同じ頂点です。')
        } else {
          if (this.conditionPlot.start > this.conditionPlot.end) {
            const swap = this.conditionPlot.start
            this.conditionPlot.start = this.conditionPlot.end
            this.conditionPlot.end = swap
          }
          this.selectedPlat.vertexes.push({
            id: `${this.selectedPlat.id}-${this.selectedPlat.vertexes.length + 1}`,
            name: `頂点${this.selectedPlat.vertexes.length + 1}`,
            x: this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].x,
            y: this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].y
          })
        }
      } else {
        if (this.conditionPlot.start === this.conditionPlot.end) {
          alert('始点と終点が同じ頂点です。')
        } else if (Math.abs(this.conditionPlot.start - this.conditionPlot.end) > 1) {
          alert('隣り合う頂点を指定してください。')
        } else if (this.conditionPlot.start === this.selectedPlat.vertexes.length - 1 || this.conditionPlot.start === this.selectedPlat.vertexes.length - 1) {
          alert('直近の頂点は指定できません。')
        } else {
          if (this.conditionPlot.start > this.conditionPlot.end) {
            const swap = this.conditionPlot.start
            this.conditionPlot.start = this.conditionPlot.end
            this.conditionPlot.end = swap
          }
          this.selectedPlat.vertexes.push({
            id: `${this.selectedPlat.id}-${this.selectedPlat.vertexes.length + 1}`,
            name: `頂点${this.selectedPlat.vertexes.length + 1}`,
            x: this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].x,
            y: this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].y
          })
        }
      }
    },
    getOptionOfConditionPlot (plat) {
      const options = []
      if (plat !== undefined) {
        for (let i = 0; i < plat.vertexes.length; i++) {
          options.push({ value: i, text: `頂点${i + 1}` })
        }
      } else {
        options.push({ value: 0, text: '' })
      }
      return options
    },
    maximizeWindowSize () {
      this.maximizeWindow = !this.maximizeWindow
      if (this.maximizeWindow) {
        this.initCanvas()
        this.canvasWidth = (this.canvasWidth + 10) * 1.5
        this.canvasHeight = document.documentElement.clientHeight
      } else {
        this.canvasWidth = (this.canvasWidth - 15) / 1.5
        this.canvasHeight = 600
      }
      setTimeout(() => {
        this.canvasUpdate()
        this.scaleIndex = this.scaleIndex + 1
        this.canvasUpdate()
        this.scaleIndex = Math.max(0, this.scaleIndex - 1)
        this.canvasUpdate()
      }, 10)
    },
    toggleCanvasMap () {
      this.isCanvasShown = !this.isCanvasShown
      if (!this.shouldRenderComponent) { this.shouldRenderComponent = true }
      if (this.isCanvasShown) { this.updatePlats() }
    },
    refreshAvailablePlotWay () {
      if (this.selectedPlat !== undefined) {
        if (this.selectedPlat.vertexes.length === 2) {
          this.availablePlotWay = 2
        } else if (this.selectedPlat.vertexes.length > 2) {
          this.availablePlotWay = 3
        } else {
          this.availablePlotWay = 0
        }
      } else {
        this.availablePlotWay = 0
      }
    },
    dragPlotMode () {
      // 頂点のドラッグ指定モード切り替え
      this.dragPlotData.flag = !this.dragPlotData.flag
      if (this.dragPlotData.flag) {
        if (this.selectedPlat !== undefined) {
          this.selectedPlat.vertexes = []
        }
      }
    },
    draggableEnd () {
      this.canvasUpdate()
    },
    deleteVertex (index, vIdx) {
      this.plats[index].vertexes.splice(vIdx, 1)

      // GoogleMapの頂点も更新
      this.deleteMarkers()
      this.deleteGSCoordinates()
      this.plats.forEach(plat => {
        this.repopulateMarkers(plat.vertexes)
        this.repopulateGSCoordinates(plat.vertexes)
      })

      // GoogleMapのリロード
      this.reloadMap(true)

      this.canvasUpdate()
    },
    vSelect (vIdx) {
      this.selectedVertex = vIdx
      this.canvasUpdate()
    },
    // 距離表示用
    calculateDistance () {
      this.plats.forEach(p => {
        // 基準の距離を計測
        const standardMeter = this.calcDist(p.vertexes[0].lat * (Math.PI / 180),
          p.vertexes[0].lon * (Math.PI / 180),
          p.vertexes[1].lat * (Math.PI / 180),
          p.vertexes[1].lon * (Math.PI / 180))
        const scaleDistance = Math.sqrt(this.calcDistanceDot2Dot(p.vertexes[0].x, p.vertexes[0].y, p.vertexes[1].x, p.vertexes[1].y)) / standardMeter
        if (p.vertexes.length > 1) {
          p.vertexes.forEach((v, index) => {
            const next = index !== p.vertexes.length - 1 ? index + 1 : 0
            const dist = Math.sqrt(this.calcDistanceDot2Dot(v.x, v.y, p.vertexes[next].x, p.vertexes[next].y))
            const meter = dist / scaleDistance
            p.vertexes[next].distance_meter = meter
          })
        }
      })
      this.canvasUpdate()
    },
    getVtx (markers) {
      return new Promise((resolve) => {
        if (markers[this.vidx].lng > 0 && markers[this.vidx].lat > 0) {
          this.axios.get(`/api/kagechi/gpx?lon=${markers[this.vidx].lng}&lat=${markers[this.vidx].lat}`).then(res => {
            this.gs.push({
              x: res.data.x,
              y: res.data.y,
              // 緯度，経度 のデータ取得を追加
              lat: markers[this.vidx].lat,
              lon: markers[this.vidx].lng
            })
            resolve()
          })
        }
      })
    },
    loopVtx (markers) {
      return new Promise((resolve) => {
        if (markers.length > this.vidx) {
          this.getVtx(markers).then(() => {
            this.vidx++
            this.loopVtx(markers).then(() => {
              resolve()
            })
          })
        } else {
          resolve()
        }
      })
    },
    calculateLatLonDelta (plat) {
      // 土地の最初の点から考えて1つ目の点の距離(x,y座標の差異)を緯度経度の差異に対応させる
      // これを新規で追加された頂点に当てはめて、GoogleMap上でのおおよその位置に対応させる
      const deltaLat = (plat.vertexes[1].lat - plat.vertexes[0].lat) / (plat.vertexes[1].y - plat.vertexes[0].y)
      const deltaLon = (plat.vertexes[1].lon - plat.vertexes[0].lon) / (plat.vertexes[1].x - plat.vertexes[0].x)
      return { deltaLat: deltaLat, deltaLon: deltaLon }
    }
  }
}
</script>

<style scoped>
</style>
