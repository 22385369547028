<template>
  <b-button v-b-modal.video-modal id="play-video-btn" variant="secondary" class="align-items-center d-flex">
    <span class="align-items-center bg-white d-flex mr-2 rounded-pill" style="width: 18px; height: 12px;">
      <b-icon-youtube variant="danger" />
    </span>
    簡易動画を見る
  </b-button>
</template>

<script>
export default {
  name: 'PlayVideoBtn'
}
</script>
