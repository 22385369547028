<template>
  <div class="d-flex flex-row align-items-center">
    <div v-if="isTitle"></div>

    <b-form-input v-model="localTitle" maxlength="50"></b-form-input>

    <div class="ml-2">
      <b-button variant="dark" @click="changeTitle(); edit = !edit" size="sm">
        <b-icon icon="check2"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import constants from '../constants'

export default {
  name: 'ChangeTitle',
  props: {
    value: String,
    isTitle: {
      type: Boolean,
      default: true
    },
    mainId: String
  },
  data: () => ({
    edit: false,
    titleErrors: {}
  }),
  computed: {
    localTitle: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    titleErrors (newValue) {
      this.$emit('change-title-error', newValue)
    }
  },
  methods: {
    showToast (message, variant) {
      this.$bvToast.toast(message, {
        noCloseButton: true,
        toaster: 'b-toaster-bottom-center',
        variant: variant || 'secondary',
        autoHideDelay: 1000
      })
    },
    changeTitle () {
      const data = {
        title: this.localTitle
      }
      if (this.mainId) { data.id = this.mainId }
      this.axios.post(`/api/kagechi/title/${this.$route.params.main_id}`, data).then(res => {
        this.showToast('タイトルを変更しました。', 'success')
        this.titleErrors = {}
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.titleErrors = error.response.data
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.titleErrors = { error: constants.MESSAGE.SYSTEM_ERROR }
        }
        this.edit = true
      })
    }
  }
}
</script>

<style scoped>

</style>
