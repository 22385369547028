<template>
  <div class="privacy-policy-text">
    <p>個人情報保護方針</p>
    <p>西本町会計合同会社（以下、当社といいます。）は、お客様とより密に関係を構築するため、 個人情報（個人情報の保護に関する法律2条1項に定義される意味を有するものとし、以下同様とします。）をお預かりしています。<br>
    これらの事業活動においてお預かりするすべての個人情報を適切に取り扱うため、当社の全従業者が遵守すべき行動基準として、以下の個人情報保護方針を定め、その遵守の徹底を図ります。</p>

    <ol>
      <li>法令・指針・規範の遵守<br>
      当社は個人情報の取り扱いに関する法令、国が定める指針その他の規範を遵守するため、個人情報保護マネジメントシステムを策定し、適切に運用します。</li>
      <li>個人情報の取得・利用・提供<br>
      個人情報の取得に際しては、その利用目的を特定し、利用目的の達成に必要な範囲を超えた個人情報の取り扱いを行わないものとします。また、取得した個人情報の適切な利用・提供のための措置を講じます。</li>
      <li>個人情報の外部提供<br>
      当社は、個人情報の取り扱いの全部または一部を外部に委託する場合には、十分な保護水準を満たした者を選定し、契約等により適切な措置を講じます。また、本人の同意がある場合または下記「個人情報の取り扱いについて」に記載する場合を除き、個人情報を第三者に提供することはありません。</li>
      <li>個人情報に対する安全管理措置<br>
      当社は、個人情報への不正アクセス、個人情報の漏えい、滅失、き損および改ざんの防止と是正のための措置を講じます。</li>
      <li>当社役員・従業員への教育・研修<br>
      当社は、当社役員および従業員に対して、当社が適切に個人情報を取り扱うための教育および研修を行います。</li>
      <li>個人情報に関するお問い合わせ<br>
      当社は、個人情報の取り扱いに関する苦情および相談への適切かつ迅速な対応に努めます。 また、当社が保有する開示対象個人情報の開示等の請求を受け付けます。開示等の請求の手続きについては、以下の「個人情報相談窓口」が行います。<br>
        <p>個人情報に関するお問い合わせはこちら<br>
        〒550-0005&nbsp;大阪府大阪市西区西本町1-12-19<br>
        西本町会計合同会社<br>
        06-6695-7624</p>
      </li>
      <li>継続的改善<br>
      当社は個人情報保護マネジメントシステムの継続的改善を行います。</li>
      <li>方針の公開<br>
      本方針は当社のウェブサイトに掲載し、どなたでも閲覧可能な状態を維持します。<br>
        <p>制定：2022年11月1日<br>
        最終改定：2022年11月1日<br>
        〒550-0005&nbsp;大阪府大阪市西区西本町1-12-19<br>
        西本町会計合同会社<br>
        代表社員&nbsp;出村恭祐</p>
      </li>
    </ol>

    <p>個人情報の取り扱いについて</p>
    <ol>
      <li>お客様のプライバシー<br>
      当社では、プライバシーの保護を重視しています。<br>
      以下に、当社のサービスのご利用の際に、お客様から提供される個人情報（個人情報の保護に関する法律2条1項に定義される意味を有するものとし、以下同様とします。）の保護に関するガイドラインを記載いたします。</li>
      <li>個人情報の取得<br>
      当社では、当社の商品・サービスのユーザー登録をしていただく場合を始めとして、お客様等よりご用命のありました情報を詳しくご提供するためなどに、お客様等に対して必要な個人情報の提供をお願いすることがあります。ただし、情報提供の是非は、お客様等ご自身に選択していただくものです。<br>
      たとえば、以下のような場合に情報提供をお願いしていますので、ご理解をお願いいたします。<br>
      <ol class="circled">
        <li>当社の商品・サービスのユーザー登録をしていただく場合</li>
        <li>各種商品・サービスのユーザー登録やお申込みをしていただく場合</li>
        <li>当社の商品・サービスに関するお問合せまたはご連絡をいただく場合</li>
        <li>各種資料請求をしていただく場合</li>
        <li>オンラインにてサービスをご購入・機材のレンタルをされる場合</li>
        <li>オンライン上のアンケート調査等においてご意見をお寄せいただく場合</li>
        <li>メールマガジンの購読をお申し込みいただく場合</li>
      </ol>
      (1)クレジットカード情報の取り扱い<br>
      当社がお客様からクレジットカード情報を取得する目的、ならびにクレジットカード情報の取得者名、提供先名および保存期間は次のとおりです。<br>
      <ol class="lower-alpha">
        <li>利用目的<br>
        当社の商品・サービスの代金として請求させていただいた金額をお支払いただくため</li>
        <li>取得者名<br>
        西本町会計合同会社</li>
        <li>>提供先<br>
        お客様が代金決済時に選択された決済方法に対応する各種決済事業者および決済代行業者</li>
        <li>保存期間<br>
        当社はクレジットカード情報の非保持化に対応しています。<br>
        「c.提供先」へ提供した後は、当社ではお客様のクレジットカード情報を一切保持いたしません。</li>
      </ol>
      <li>個人情報の利用目的<br>
      お客様等にご提供いただいた個人情報は、お客様等と当社との関係構築のため、または当社および当社の関係会社の業務、サービスの向上、その他以下の(1)から(3)に掲げる各目的のために利用されます。<br>
      お客様の個人情報ならびにお客様のご注文に関する情報は、違法・不当な行為を助長・誘発するおそれがある方法で利用されることは一切ありません。<br>
      <br>
      (1)ご購入・ご登録いただいたサービスのお申し込みの確認をする場合<br>
      <ol class="circled">
        <li>サービスなどのご購入の確認をする場合</li>
        <li>イベント/セミナーのお申し込みの確認や入場券等をお届けする場合</li>
        <li>電子メール配信サービスのお申し込みの確認やメールを配信する場合</li>
        <li>各種サービスへの登録の確認やサービスを提供する場合</li>
        <li>各種サービスに関するお客様への請求またはお客様のお支払い状況を確認する場合</li>
        <li>お客様からの各種サービスに関するご意見・ご要望・お問い合わせ等に対応する場合</li>
        <li>各種サービスに関するシステム・アプリケーション等の保守・運用をする場合</li>
        <li>各種サービスに関するシステム・アプリケーション等の障害・不具合・事故等の対応およびそれらの発生を予防する場合</li>
        <li>ご協力いただいた調査に対して謝礼などをお送りする場合</li>
        <li>ご応募いただいた懸賞などに対する景品等をお送りする場合</li>
        <li>各種サービスに関する各種資料などのお申し込みの確認や、当該資料などをお届けする場合</li>
      </ol>
      (2)ご利用いただいているサービスの提供・改良や、新たなサービスを開発する場合<br>
      <ol class="circled">
        <li>お客様の閲覧履歴や購買履歴等の情報を分析して、サービス、ウェブサイトなどの内容を②お客様がよりご満足いただけるよう改良する場合</li>
        <li>お客様の閲覧履歴や購買履歴等の情報を分析して、サービス、ウェブサイトなどの内容を④個々のお客様に合わせてカスタマイズする場合</li>
        <li>お客様が注文された情報やサービスなどに関する満足度を調査する場合</li>
        <li>お客様の閲覧履歴や購買履歴等の情報を統計的に処理した情報を集約し、調査結果として公表する場合</li>
        <li>お客様から提供された情報を分析して、当社または当社の関係会社によるオンライン関連サービスまたは業務支援サービスの提供、開発、改善に役立て、またはそれに関連して統計データとして利用する場合</li>
        <li>お客様の閲覧履歴や購買履歴等の情報を分析して、お客様の趣向に応じた新サービスに関する広告を表示・配信する場合</li>
        <li>お客様から提供された個人情報を利用して当社または当社の関係会社もしくは提携先（契約形態の如何にかかわらず当社とのパートナーシップを締結している企業もしくは団体を含み、以下同様とします。）がお客様にサービスを提供する場合</li>
      </ol>
      (3)提供しているサービスに関連した情報などをお届けする場合<br>
      各種サービス・キャンペーン・イベントなど（当社および当社の関係会社または提携先等の商品・サービスなどを含みます。）のご案内をお届けする場合<br>
      調査のご協力や各種イベントへのご参加をお願いしたり、その結果などをご報告する場合<br>
      商品、サービス、ウェブサイトなどの利用状況を把握し、お客様に有益と思われる企業・団体の情報・調査などをお届けする場合<br>
      当社の関係会社または提携先にお客様の当社サービスの利用状況を提供することにより、当社の関係会社または提携先を通じて当社サービスなどのサポートまたはご案内を行う場合<br>
      お客様が当社ホームページを訪れる前にクリックされている広告の情報（クリック日や広告掲載サイトなど）を第三者の運営するツールから取得し、広告の効果測定のため購買履歴等の情報と照合する場合<br>
      SNS等を含めたWeb媒体において、お客様に対する最適なサービス等の広告を配信する場合</li>
      <li>第三者提供について<br>
      お客様にご提供いただいた個人情報は、お客様の同意がある場合または下記の場合に第三者に開示・提供することがございます。それ以外の場合に、第三者に開示・提供することはありません。<br>
      <ul>
        <li>(1)上記「個人情報の利用目的」記載の目的の達成に必要な範囲内において個人情報の取り扱いの全部または一部を他社に委託する場合</li>
        <li>(2)商品等をご購入いただいた際のお支払いについて、銀行など金融機関に、必要な情報を提供する場合</li>
        <li>(3)お客様が、当社に損害を生じさせ、あるいはその虞があると当社が判断した場合</li>
        <li>(4)裁判所、弁護士会、警察、消費者センターなど公的機関から照会を受けた場合</li>
        <li>(5)その他、法令の定めのある場合<br>
        当社は、当社の提携先がお客様に対し、当社サービスのサポート、または当社サービスのご案内を行うために必要であると当社が認める場合に、当社から提携先に対しお客様の個人情報を提供することについて、お客様から事前に同意を得た上でかかる提供を行うものとします。</li>
      </ul>
      <br>
      また、当社は、SNS等を含めたWeb媒体において、Web媒体を運営する事業者がお客様に対する最適なサービス等の広告を配信する場合において、当社から当該Web媒体を運営する事業者に対し、直接又は広告代理店を通じてお客様の個人情報を提供することについて、お客様から事前に同意を得た上でかかる提供を行うものとします。<br>
      <br>
      なお、協力企業、広告主、その他の第三者に当社のサービスを説明する際、またその他の合法的な目的のために、お客様の統計情報を開示する場合があります。<br>
      但し、統計情報には、個人情報は含まれないものとします。</li>
      <li>個人情報の共同利用について<br>
      当社は、下記に規定する範囲で、お客様等にご提供いただいた個人情報を、当社の子会社との間で共同利用することがございます。<br>
      <ul>
        <li>(1)共同利用する者の範囲<br>
        当社の関係会社</li>
        <li>(2)共同利用する個人情報の項目<br>
        氏名、住所、メールアドレス等、当社のサービスのご利用の際に当社がお客様に関して取得する全ての個人情報</li>
        <li>(3)共同利用の目的<br>
        上記「個人情報の利用目的」記載の利用目的に準ずる</li>
        <li>(4)共同利用の管理責任者<br>
        〒550-0005&nbsp;大阪府大阪市西区西本町1-12-19<br>
        西本町会計合同会社</li>
      </ul>
      <li>個人情報の開示・利用停止・訂正・削除について<br>
      開示請求、利用停止等のご要望については、書面での受付を行います。<br>
      詳細は、以下の当社ウェブサイトをご参照ください。<br>
      <br>
      保有個人データの各種ご請求について<br>
      個人情報の開示については、ご希望に応じて書面または電磁的方法で行います。<br>
      ただし、下記のいずれかに該当する場合には、その全部または一部を開示しないことがあります。<br>
      <br>
      お客様または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br>
      当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br>
      その他法令に違反することとなる場合<br>
      また、開示に多額の費用を要する場合その他お客様から指定された開示方法による開示が困難な場合は、書面で開示します。<br>
      <br>
      内容が正確でないなどのお申し出があったときは、その内容を確認し、必要に応じて登録情報を訂正いたします。また、お客様が個人情報の利用や第三者提供を望まれない場合は、一定の条件下で、個人情報の利用停止その他必要かつ適切な措置を講じます。<br>
      ただし、利用停止等を行うと提供できないサービスが発生する場合があります。<br>
      <li>録音について<br>
      当社では、お客様からのお申し出やご要望に関する当社の対応の確認および今後のサービス向上に役立てることを目的として、お電話によるお問合わせ等の通話の録音をさせていたく場合があります。</li>
      <li>個人情報のセキュリティ<br>
      当社では、お客様の個人情報の安全管理を確実にし、個人情報が漏えい、滅失、き損もしくは改ざんすることがないよう、また、本来の目的外に使用されることがないよう万全の策を期しています。<br>
      当社が他社にサービスおよび調査等を委託する場合は、必要な情報のみを提供し、委託業務遂行以外の目的での使用を禁じています。<br>
      特に、当社のウェブサイトでは、利用者の個人情報を保護するために、原則的に暗号化（SSL/TLS）し通信します。</li>
      <br>
      <li>クッキー（Cookie）およびウェブビーコンについて<br>
      当社のウェブサイトには、「クッキー（Cookie）」、「ウェブビーコン（Web Beacon）」を利用したページがあります。<br>
      クッキーとは、ウェブサーバーからお客様のブラウザーに送信されるテキストデータのことをいい、ウェブサーバーが、クッキーを参照しお客様のコンピューターを識別することによりお客様は効率的に当社のウェブサイトを利用することができます。<br>
      また、ウェブビーコンとは、ウェブページに埋め込まれた小さな画像ファイルやタグのことをいい、特定のページにウェブビーコンを埋め込むことにより、お客様がそのページを閲覧されたことを確認できる仕組みです。<br>
      クッキーおよびウェブビーコンにより当社が取得する情報のうち、個人を特定できる情報、または他の情報と組み合わせることにより個人を特定できる情報については、本ガイドラインに沿って取り扱われます。<br>
      <br>
      クッキーおよびウェブビーコンは以下の目的で使用されます。<br>
      <br>
      お客様が当社のウェブサイト上のサービスにログインされるとき、保存されているお客様の登録情報を参照して、お客様ごとにカスタマイズされたサービスを提供できるようにするため<br>
      お客様が興味を持っている内容や、当社のウェブサイト上での利用状況をもとに、閲覧履歴や購買履歴等の情報を分析して、お客様の趣向に応じた新商品・サービスに関する広告をお客様が利用する他社ウェブサイト上で表示するため<br>
      当社のウェブサイトの利用者数やトラフィックを調査するため<br>
      当社のサービスを改善するため<br>
      セキュリティ保持のため、ご利用から一定の時間が経過したお客様に対してパスワードの再入力（再認証）を促すため<br>
      なお、当社は、当社の広告の配信を委託する第三者への委託に基づき、当該第三者を経由し、若しくは次項に示す第三者配信事業者が提供するサービスを利用して、当社のクッキーを保存し参照する場合があります。また、お客様のブラウザーの設定により、クッキーの設定を変更してクッキーの機能を無効にすることができますが、その結果当社のウェブサイト上のサービスの一部がご利用いただけなくなる場合があります</li>
      <li>第三者配信事業者のサービス利用について<br>
      当社は、当社のサービスのご利用状況をもとにしてお客様に最適な広告をお届けするため、第三者配信事業者によるサービスを利用することがあります。<br>
      第三者配信事業者によるサービスの利用停止をご希望の場合は、個人情報相談窓口経由で承ります。</li>
      <li>お客様のアクセスログについて<br>
      当社のウェブサイトでは、アクセスされた方の情報をアクセスログという形で記録しています。アクセスログには、アクセスされた方のアクセス日時、ドメイン名やIPアドレス、使用されているブラウザーの種類などが含まれますが、個人を特定できる情報は含まれません。アクセスログは利用状況に関する統計分析のために活用され、それ以外の目的で利用されることはありません。</li>
      <li>匿名加工情報の取り扱いについて<br>
      <ul>
        <li>(1)匿名加工情報の作成・取り扱い<br>
        当社は、お客様の個人情報をもとに匿名加工情報（個人情報の保護に関する法律2条6項に定義される意味を有するものとし、以下同様とします。）を作成し、または第三者から匿名加工情報を取得した上で、それを利用することがございます。匿名加工情報の取り扱いに関し、当社は、以下の各事項を遵守します。<br>
        匿名加工情報を作成する場合には、匿名加工情報から本人を識別できないよう、適切な範囲で情報を削除するとともに、加工後の情報から個人情報を復元できるような情報を残存させないようにします。<br>
        匿名加工情報の取り扱いに関する規程類の整備のほか、匿名加工情報の安全管理のために必要な措置を適切に講じます。<br>
        匿名加工情報を作成しまたは第三者から取得する場合には、当該匿名加工情報に含まれる個人に関する情報の項目を公表します。<br>
        匿名加工情報を取り扱うにあたっては、当該匿名加工情報の元となった個人情報に係る本人を識別する目的で、他の情報と照合することを行いません。</li>
        <li>(2)匿名加工情報の提供<br>
        当社は、匿名加工情報を第三者に提供する場合は、提供しようとする匿名加工情報に含まれる個人に関する情報の項目およびその提供の方法について公表するとともに、当該第三者に対して、提供する情報が匿名加工情報であることを明示します。</li>
      </ul>
      <li>仮名加工情報の取り扱いについて<br>
      当社は、お客様の個人情報をもとに仮名加工情報（個人情報の保護に関する法律2条5項に定義される意味を有するものとし、以下同様とします。）を作成し、または仮名加工情報を取得し、それを利用することがございます。仮名加工情報の取り扱いに関し、当社は、個人情報保護委員会規則で定める基準に従い、以下の各事項を遵守します。<br>
      <br>
      当社は、法令に基づく場合のほか、仮名加工情報（個人情報に該当するものを除きます。）を第三者に提供いたしません。<br>
      当社は、仮名加工情報を取り扱うにあたっては、仮名加工情報の作成に用いられた個人情報に係る本人を識別するために、仮名加工情報を他の情報と照合しません。<br>
      当社は、仮名加工情報である個人データ及び削除情報等を利用する必要がなくなったときは、当該個人データ及び削除情報等を遅滞なく消去するよう努めます。<br>
      仮名加工情報を取り扱うにあたっては、電話をかけ、郵便等により送付し、若しくは電磁的方法を用いて送信し、または住居を訪問するために、当該仮名加工情報に含まれる連絡先その他の情報を利用しません。<br>
      当社は、仮名加工情報の安全管理のために必要かつ適切な措置を講じます。</li>
      <li>安全管理措置について<br>
      当社は、取り扱う個人データ（個人番号および特定個人情報を含みます。）の漏えい、滅失または毀損の防止、その他個人データの安全管理のため、取扱規程等の整備および安全管理措置に係る実施体制の整備等、十分なセキュリティ対策を講じます。<br>
      安全管理措置に関するご質問については、下記「個人情報相談窓口」までご連絡ください。</li>
      <li>プライバシーポリシーの変更について<br>
      当社のプライバシーポリシーを大幅に変更する場合は、今後当社のウェブサイトで告知いたします。</li>
    </ol>

    <p>情報セキュリティ管理者（個人情報保護管理者）<br>
    開発本部&nbsp;本部長<br>
    連絡先：ご連絡は個人情報相談窓口経由で承ります。<br>
    <br>
    個人情報に関するお問い合わせ<br>
    西本町会計合同会社 カスタマーセンター 個人情報相談窓口<br>
    個人情報に関するお問い合わせについては、以下のリンク先にて、お問い合わせ下さい<br>
    <br>
    電話番号： 06-6695-7624<br>
    受付時間： 9:30～12:00/13:00～17:30(土・日・祝日、および弊社休業日を除きます)<br>
    <br>
    ※当社保有個人データの開示等の請求には、ご本人であることの確認が必要です。</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  props: {
    value: String,
    isTitle: {
      type: Boolean,
      default: true
    },
    mainId: String
  },
  data: () => ({
    edit: false
  }),
  computed: {
    localTitle: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    /* TODO: 必要な処理があれば記述 */
  }
}
</script>

<style scoped>

</style>
