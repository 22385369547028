export default Object.freeze({
  HTTP_RESPONSE_CODE: Object.freeze({
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    SYSTEM_ERROR: 500
  }),
  MESSAGE: Object.freeze({
    SESSION_TIMEOUT: '長時間操作が行われなかったか、ログインされていません。恐れ入りますが再度ログインから操作してください。',
    SYSTEM_ERROR: 'システムエラーが発生しました。恐れ入りますが、はじめからやり直してください。'
  })
})
