// const API_KEY = 'AIzaSyBQKqVJbVecrL0JzqMEu4u6csftovvYATs' 会社のAPIキー
const API_KEY = 'AIzaSyD5tU_fPusu8N_QKX5tLRUTenaIvzlZzxg'

const MAP_CONFIG = {
  rotateControl: false,
  fullscreenControl: false,
  clickableIcons: false,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: 'cooperative',
  zoomControl: true,
  minZoom: 7,
  maxZoom: 25,
  zoom: 20,
  mapTypeId: 'hybrid'
}

const POLYGON_CONFIG = {
  editable: true,
  draggable: true,
  strokeColor: 'rgba(0,83,250, 1)',
  strokeOpacity: 0.8,
  strokeWeight: 3,
  fillColor: 'rgba(0,0,255, 3)'
}

export default {
  API_KEY,
  MAP_CONFIG,
  POLYGON_CONFIG
}
