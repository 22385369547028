<template>
  <b-container>
    <h4 class="text-center my-4">パスワードリセット</h4>
    <b-card v-if="!registered">
      <p class="text-danger" v-if="errors.non_field_errors">{{ errors.non_field_errors[0] }}</p>
      <b-form @submit.prevent="onSubmit">
        <b-form-group label="パスワード" label-for="password">
          <b-form-input id="password" v-model="new_password1" required type="password" :state="isValid(['non_field_errors', 'new_password2'])" />
          <b-form-invalid-feedback v-if="errors.new_password2" :state="isValid(['non_field_errors', 'new_password2'])">{{ errors.new_password2[0] }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="パスワード（確認用）" label-for="confirmed_password">
          <b-form-input id="confirmed_password" v-model="new_password2" required type="password" :state="isValid(['non_field_errors', 'new_password2'])" />
          <b-form-invalid-feedback v-if="errors.new_password2" :state="isValid(['non_field_errors', 'new_password2'])">{{ errors.new_password2[0] }}</b-form-invalid-feedback>
        </b-form-group>

        <div class="text-center mb-3">
          <b-button type="submit" variant="primary" pill class="px-5">リセットする</b-button>
        </div>
      </b-form>
    </b-card>
    <b-alert :show="registered" variant="success">
      パスワードリセットが完了しました。
      <div class="text-center mb-3">
        <b-button variant="primary" pill class="px-5" to="/login">ログイン</b-button>
      </div>
    </b-alert>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import constants from '../../constants'

export default {
  name: 'PasswordResetForm2',
  mixins: [validation],
  data () {
    return {
      registered: false,
      new_password1: '',
      new_password2: ''
    }
  },
  methods: {
    onSubmit () {
      this.$emit('loading', true)
      const data = {
        new_password1: this.new_password1,
        new_password2: this.new_password2
      }
      this.axios.post(`/api/account/password_reset/${this.$route.params.id}/${this.$route.params.token}`, data).then(res => {
        this.$emit('loading', false)
        this.registered = true
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.$emit('loading', false)
          this.validated(error.response.data)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
