<template>
  <b-container id="kagechi-list" fluid>
    <div>
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>

    <div class="text-right" id="full-width-section">
      <div class="text-floating-left">測定結果一覧</div>
      <b-button variant="outline-secondary" class="px-5" @click="$router.push('/menu')">戻る</b-button>
    </div>

    <h5 class="text-center mt-5">閲覧する測定結果を選択してください。</h5>

    <div class="mt-5 col-10 mx-auto">
      <b-table sticky-header="428px" :items="mains" head-variant="dark" :fields="fields">
        <template #cell(title)="data">
          <titleForResultList ref="titleForResultList" v-model="data.item.title" :is-title="false" :main-id="data.item.id" @change-title-error="validated"></titleForResultList>
        </template>
        <template #cell(button)="data">
          <b-button variant="dark" v-if="data.item.step === 5" @click="next(data.item)">測定結果を表示する</b-button>
          <b-button variant="dark" v-else @click="next(data.item)" :disabled="!data.item.is_pdf && stripeExpiration">編集を再開する</b-button>
        </template>
        <template #cell(delete)="data">
          <b-form-checkbox :id="String(data.index)" :value="data.item.id" v-model="checkedValues"></b-form-checkbox>
        </template>
      </b-table>
    </div>

    <p class="text-center" v-if="mains.length == 0">まだ、測定データは作成されていません。</p>

    <div class="d-flex justify-content-around align-items-center pt-3">
      <b-pagination-nav
        v-if="pageCount > 1"
        :link-gen="linkGen"
        :number-of-pages="pageCount"
        :prev-text="`前の${prePageCount}件`"
        :next-text="`次の${prePageCount}件`"
        />
    </div>

    <div class="my-4 text-center">
      <b-button variant="danger" pill class="px-5" :disabled="checkedValues.length === 0" v-b-modal.delete-confirm>
        <b-icon icon="trash-fill" aria-hidden="true"></b-icon>削除
      </b-button>
    </div>

    <b-modal id="delete-confirm" hide-header ok-variant="danger" @ok="deleteMain">
      <div>現在選択中の測定情報を削除します。本当によろしいですか？</div>
    </b-modal>
  </b-container>
</template>

<script>
import constants from '../../constants'
import validation from '../../mixins/validation'
import titleForResultList from '../../components/TitleForResultList'

export default {
  name: 'KagechiList',
  mixins: [validation],
  components: { titleForResultList },
  data: () => {
    return {
      fields: [
        { key: 'title', label: '名前', class: 'align-middle text-white text-center' },
        { key: 'updated_at', label: '最終更新日時', class: 'align-middle text-white text-center' },
        { key: 'step_display', label: 'ステップ', class: 'align-middle text-white text-center' },
        { key: 'button', label: '', class: 'align-middle text-white text-center' },
        { key: 'delete', label: '削除', class: 'align-middle text-white text-center' }
      ],
      mains: [],
      checkedValues: [],
      prePageCount: 10,
      pageCount: 0,
      stripeExpiration: false
    }
  },
  mounted () {
    const username = this.$store.getters['user/user'].username
    const url = `/api/account/stripeExpiration/${username}`
    this.axios.post(url).then(res => {
      this.stripeExpiration = res.data.isExpiration
    })

    this.loadData()
  },
  watch: {
    $route () {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      this.$emit('loading', true)

      const params = {
        count: this.prePageCount,
        page: this.$route.query.page
      }
      this.axios.get('/api/kagechi/list', { params }).then(res => {
        this.mains = res.data.results
        this.pageCount = Math.ceil(res.data.count / this.prePageCount)
        this.$emit('loading', false)
      })
    },
    linkGen (pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    deleteMain () {
      this.axios.delete('/api/kagechi/list', { params: { deleteList: this.checkedValues } }).then(res => {
        // 削除成功時、チェックボックスの選択をリセットする
        if (res.status === 200) {
          this.checkedValues = []
        }
        this.loadData()
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
        this.loadData()
      })
    },
    async next (main) {
      const mainId = main.id
      let url = 'result'
      if (main.step === 1) {
        await this.axios.get(`/api/kagechi/trimming/${mainId}`).then(
          response => {
            const imageName = response.data.images[0].name
            url = this.getUrlFromFileName(imageName)
          }
        )
      } else if (main.step === 2) {
        url = 'correct'
      } else if (main.step === 3) {
        url = 'edit1'
      } else if (main.step === 4) {
        url = 'edit2'
      }
      this.$router.push(`/kagechi/${url}/${mainId}`)
    },
    getUrlFromFileName (name) {
      if ((name.indexOf('.pdf') !== -1) || name.indexOf('.PDF') !== -1) {
        return 'trimming'
      } else if (name.indexOf('.gpx') !== -1 || name.indexOf('.GPX') !== -1) {
        return 'trimmingGPX'
      } else if (name.indexOf('FreeHand') !== -1) {
        return 'trimmingFH'
      }
    }
  }
}
</script>

<style scoped>

</style>
