<template>
  <b-container class="text-center">
    <h4 class="my-4">料金プラン</h4>
    <b-alert show :variant="variant" class="col-md-6 mx-auto">
      <div v-if="confirmed">
        <div class="mt-4">
          決済手続きが完了しました！<br>
          「土地作図くん」へようこそ！<br>
          ログインしてご利用を開始ください。
        </div>
        <div class="my-4">
          <b-button to="/login" variant="primary">ログインする</b-button>
        </div>
      </div>
      <div v-else>
        <div class="mt-4">
          決済手続きが失敗しました。<br>
          お手数をおかけいたしますが、<br>
          再度決済手続きをおこなってください。
        </div>
      </div>
    </b-alert>
  </b-container>
</template>

<script>
import constants from '../../constants'

export default {
  name: 'SignupConfirm',
  data: () => ({
    confirmed: true,
    numbers: ['', '', '', '', ''],
    error_message: '',
    invalid_url: false
  }),
  computed: {
    variant () {
      if (this.error_message.length) {
        return 'danger'
      }

      return 'success'
    }
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search)
    const sessionId = urlParams.get('session_id')
    this.retieveSession(sessionId)
  },
  methods: {
    inputNumber (index) {
      if (this.$refs.inputs[index + 1]) {
        this.$refs.inputs[index + 1].focus()
      } else {
        this.confirmNumber()
      }
    },
    confirmNumber () {
      this.$emit('loading', true)

      const url = `/api/account/signup/${this.$route.params.id}/${this.$route.params.token}`
      this.axios.post(url, { code: this.numbers.join('') }).then(res => {
        this.confirmed = true
        this.error_message = ''
        this.$emit('loading', false)
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.$emit('loading', false)
          this.error_message = error.response.data.error
          this.invalid_url = error.response.data.invalid_url
        }
      })
    },
    retieveSession (sessionId) {
      this.$emit('loading', true)

      const url = `/api/account/retrieve/${this.$route.params.id}/${this.$route.params.token}/${sessionId}`
      this.axios.post(url).then(res => {
        this.confirmed = true
        this.error_message = ''
        this.$emit('loading', false)
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.$emit('loading', false)
          this.error_message = error.response.data.error
          this.invalid_url = error.response.data.invalid_url
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
