export default {
  data: () => ({ errors: {} }),
  methods: {
    validated (errors) {
      this.errors = errors
    },
    isValid (keys) {
      if (Object.keys(this.errors).length === 0) {
        return null
      }

      let invalid = false
      keys.forEach(key => {
        invalid = invalid || key in this.errors
      })

      return !invalid
    }
  }
}
