<template>
  <div class="row">
    <div v-bind:class="maximizeWindow ? 'col-12' : 'col-8'">
      <div ref="box" class="operator-box">
        <div v-if="isCanvasShown">
          拡縮：{{ parseInt(scale * 100, 10) }}%
        </div>
        <div v-else>
          ズームレベル：{{ zoom2 }}&nbsp;/&nbsp;21
        </div>
        <b-btn-group>
          <b-icon-plus-circle
            v-show="isCanvasShown"
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="拡大"
            @click="scalePlus"
          ></b-icon-plus-circle>

          <b-icon-dash-circle
            v-show="isCanvasShown"
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="縮小"
            @click="scaleDash"
          ></b-icon-dash-circle>

          <b-icon-fullscreen
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="全画面"
            @click="maximizeWindowSize"
            v-bind:class="maximizeWindow ? 'd-none' : 'd-inline-block'"
          ></b-icon-fullscreen>

          <b-icon-fullscreen-exit
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="全画面終了"
            @click="maximizeWindowSize"
            v-bind:class="maximizeWindow ? 'd-inline-block' : 'd-none'"
          ></b-icon-fullscreen-exit>

          <b-icon-geo-alt
            v-show="isCanvasShown"
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="Google マップで表示（β版）"
            @click="toggleCanvasMap"
          ></b-icon-geo-alt>

          <b-icon-bounding-box-circles
            v-show="!isCanvasShown"
            class="mx-1"
            role="button"
            font-scale="2"
            variant="white"
            title="カンバスで表示"
            @click="toggleCanvasMap"
          ></b-icon-bounding-box-circles>
        </b-btn-group>
      </div>
      <div>
        <GoogleMap
          ref="googleMap"
          style="height: 600px; width: 100%;"
          :isSearchShown="true"
          v-bind:class="isCanvasShown ? 'd-none' : 'd-block' "
        ></GoogleMap>

        <canvas
          ref="canvas"
          :width="canvasWidth"
          :height="canvasHeight"
          @mousedown="mouseDown"
          @mouseup="mouseUp"
          @mouseout="mouseUp"
          @mousemove="mouseMove"
          @wheel.prevent="changeScale"
          v-bind:class="isCanvasShown ? 'd-block' : 'd-none'"
        />
      </div>
    </div>
    <div class="col-4" v-if="!maximizeWindow">
      <b-tabs>
        <b-tab title="トリミング" class="p-3 border border-t-0">
          <div class="operator-box mb-2">
            <div>図形リスト</div>
          </div>
          <div class="plat-box">
            <div class="accordion" role="tablist">
              <b-card v-for="(plat, index) in plats" :key="index" no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <div class="header-top-green float-left text-left ml-3 my-3">{{ plat.name }}<b-badge class="ml-2" v-if="plat.visible" variant="secondary">編集中</b-badge></div>
                  <div class="text-right">
                    <b-btn variant="primary" v-b-tooltip.hover title="編集" @click="onSelectChangePlat(plat)" class="my-2 mr-2"><b-icon-chevron-up v-show="plat.visible" /><b-icon-chevron-down v-show="!plat.visible" />編集</b-btn>
                    <b-btn variant="danger" v-b-tooltip.hover title="削除" :disabled="!plat.visible" v-b-modal="`delete-confirm-${tabIndex}`" class="my-2 mr-2"><b-icon-dash />削除</b-btn>
                  </div>
                </b-card-header>
                <b-collapse v-model="plat.visible" accordion="my-accordion" role="tabpanel">
                  <b-card-body class="text-dark">
                    <p class="mb-1 text-white">頂点を選択してください。</p>
                    <p class="mb-1 text-white"><span class="small">近似整形地を作図する場合、次の【長方形選択モード】をご活用ください。</span><br>
                      長方形選択モード
                      <template>
                        <b-button class="ml-3 my-2 toggle-switch" @click="dragPlotMode" v-bind:variant="dragPlotData.flag ? 'info' : ''"></b-button>
                      </template>
                    </p>
                    <b-button
                      v-if="availablePlotWay > 1"
                      block
                      class="mb-3 d-none"
                      @click="$bvModal.show(`conditionPlot-${index}-${tabIndex}`)"
                      v-bind:variant="conditionPlotFlag ? 'info' : ''"
                      >
                      頂点の条件指定
                    </b-button><!-- TODO:非公開 -->
                    <b-modal :id="`conditionPlot-${index}-${tabIndex}`" title="頂点の条件指定" @ok="conditionPlotSet">
                      <b-form-radio-group class="mb-3" v-model="isVerticalPlot">
                        <b-form-radio :value="true">直角</b-form-radio>
                        <b-form-radio v-if="availablePlotWay > 2" :value="false">平行</b-form-radio>
                      </b-form-radio-group>
                      <table class="mb-3">
                        <tr>
                          <th width="100">始点</th>
                          <th width="100">終点</th>
                        </tr>
                        <tr>
                          <td>
                            <b-form-select v-model="conditionPlot.start" :options="getOptionOfConditionPlot(selectedPlat)"/>
                          </td>
                          <td>
                            <b-form-select v-model="conditionPlot.end" :options="getOptionOfConditionPlot(selectedPlat)"/>
                          </td>
                        </tr>
                      </table>
                    </b-modal>
                    <b-card-text class="mb-1 input-group" v-for="(vertex, index) in plat.vertexes" :key="index">
                      <b-checkbox class="mr-2" :value="vertex.id" v-model="checkVertexes">{{ vertex.name }}</b-checkbox>
                    </b-card-text>
                    <div class="text-right">
                      <b-btn variant="danger" v-b-tooltip.hover title="選択中の頂点を削除" :disabled="checkVertexes.length == 0" v-b-modal="`deleteVtx-confirm-${tabIndex}`"><b-icon-dash />選択中の頂点を削除</b-btn>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </b-tab>
        <template #tabs-end>
          <li class="grow border-3 border-b-3 border-solid border-dark-grey border-x-0 border-t-0"></li>
        </template>
      </b-tabs>
    </div>
    <b-modal :id="`delete-confirm-${tabIndex}`" hide-header ok-variant="danger" @ok="deleteTreeNode">
      <div>選択中の土地または頂点を削除します。本当によろしいですか？</div>
    </b-modal>
    <b-modal :id="`deleteVtx-confirm-${tabIndex}`" hide-header ok-variant="danger" @ok="deleteTreeNode">
      <div>選択中の頂点を削除します。本当によろしいですか？</div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import GoogleMap from './GoogleMap'
import CanvasMixin from '../mixins/canvas'
import GPX from '../mixins/gpx'
import colors from '../colors'

let SCALE_STEP = 0.1

export default {
  name: 'TrimmingImageFreeHand',
  mixins: [CanvasMixin, GPX],
  components: { GoogleMap },
  props: {
    base64Image: String,
    vertexes: Array,
    tabIndex: Number,
    pdfName: String,
    synthFlag: Boolean
  },
  data: () => ({
    color: colors.color, // これを書いておかないと、colors 変数使っていないとのエラーになる
    canvasWidth: 0,
    canvasHeight: 600,
    canvasContext: undefined,
    image: new Image(),
    checkVertexes: [],
    position: { x: 0, y: 0 },
    scaleIndex: 0,
    drag: { dragging: 0, startImagePos: { x: 0, y: 0 }, startMousePos: { x: 0, y: 0 } },
    plats: [],
    selectedPlat: undefined,
    verticalPlotFlag: false,
    conditionPlotFlag: false,
    isVerticalPlot: true,
    conditionPlot: { start: 0, end: 0 },
    maximizeWindow: false,
    isCanvasShown: false,
    availablePlotWay: 0,
    dragPlotData: { flag: false, start: { flag: false, x: '', y: '' }, end: { flag: false, x: '', y: '' } },
    scaleVal: 1,
    tabIdx: '',
    result_scale: '',
    assistEdgeOn: { edgeFlag: false, movingFlag: false, vIdx: '', pIdx: '', start: { x: '', y: '' }, end: { x: '', y: '' } },
    mousePosition: ['', ''],
    gs: [],
    vidx: 0,
    zoom2: 18
  }),
  watch: {
    checkVertexes () {
      this.canvasUpdate()
    },
    zoom () {
      this.zoom2 = this.zoom
    },
    markers () {
      if (this.markers.length > 0 && this.isCanvasShown) {
        this.updatePlats()
      } else {
        this.addPlat()
      }
    }
  },
  computed: {
    ...mapState({
      markers: state => state.googleMap.markers,
      gsCoordinates: state => state.googleMap.gsCoordinates,
      zoom: state => state.googleMap.zoom
    }),

    scale () {
      return this.scaleVal
    },
    scaledImageSize () {
      return {
        width: this.$refs.canvas.width / this.scale,
        height: this.$refs.canvas.height / this.scale
      }
    },
    pixelsPerMeter () {
      return (100000 / 25.4)
    }
  },
  mounted () {
    this.initCanvas()
    this.setPlats()
    this.tabIdx = this.tabIndex
    document.addEventListener('keydown', this.verticalPlotOn)
    document.addEventListener('keyup', this.verticalPlotOff)
    this.refreshAvailablePlotWay()
    this.addPlat()
    this.updatePlats()
    this.zoom = 18
    const resizeObserver = new ResizeObserver((entries) => {
      this.initCanvas()
    })
    if (this.$refs.box) {
      resizeObserver.observe(this.$refs.box)
    }
  },
  methods: {
    ...mapActions('googleMap',
      [
        'deleteMarkers',
        'deleteGSCoordinates',
        'repopulateMarkers',
        'repopulateGSCoordinates',
        'reloadMap'
      ]
    ),
    initCanvas () {
      if (this.$refs.canvas && this.$refs.box.offsetWidth > 0) {
        this.canvasWidth = this.$refs.box.offsetWidth - 4 // border分だけマイナスする
        this.canvasContext = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
        this.canvasContext.lineCap = 'round'
        this.canvasContext.lineJoin = 'round'
        this.canvasContext.lineWidth = 5
        this.canvasContext.strokeStyle = '#000000'
        this.canvasContext.fillStyle = '#FFFFFF'
        this.canvasContext.fillRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)

        if (this.base64Image) {
          this.image.src = this.base64Image
          this.image.onload = () => {
            this.canvasUpdate()
          }
        }
      } else {
        setTimeout(() => {
          this.initCanvas()
        }, 500)
      }
    },
    setPlats () {
      if (this.vertexes) {
        let count = 0
        this.vertexes.forEach((vertex) => {
          if (vertex.length !== 0 && this.plats.length === 0) {
            count += 1
            this.plats.push({ id: String(count), name: `土地${count}`, vertexes: vertex, visible: false, measure: [] })
          }
        })
      }
    },
    addPlat () {
      if (this.plats.length > 0) {
        return
      }
      const nextId = this.plats.length + 1
      const measure = {
        area: '',
        distance: '',
        way: '',
        start_vertex: '',
        end_vertex: '',
        scale: '',
        result_area: '',
        result_scale: ''
      }
      this.plats.push({ id: String(nextId), name: `土地${nextId}`, vertexes: [], visible: false, measure: measure })
    },
    updatePlats () {
      return new Promise((resolve, reject) => {
        this.gs = []
        this.vidx = 0
        this.loopVtx(this.markers).then(() => {
          this.gs = this.adjustXYs(this.gs)
          const vtxs = []
          Object.keys(this.gs).forEach((key, index) => {
            vtxs.push(this.gs[key])
          })
          const sumVrx = vtxs.map((o) => { return parseFloat(o.x) + parseFloat(o.y) })
          vtxs.forEach((v, index) => {
            this.gs[index].x = v.x * 400 / Math.max(...sumVrx) + 100
            this.gs[index].y = v.y * 400 / Math.max(...sumVrx) + 100
          })
          // 土地情報を更新
          this.plats[0].vertexes = []
          this.gs.forEach((g, i) => {
            this.plats[0].vertexes.push({
              id: `0-${i + 1}`,
              name: `頂点${i + 1}`,
              x: g.x,
              y: g.y,
              lat: g.lat,
              lon: g.lon,
              distance_meter: ''
            })
          })
          this.initCanvas()
          this.setPlats()
          this.canvasUpdate()
          // 距離情報更新
          if (this.markers.length >= 2) {
            this.calculateDistance()
          }
          resolve()
        })
      })
    },
    canvasUpdate (event) {
      this.refreshAvailablePlotWay()
      // 真っ白にする
      this.canvasContext.fillStyle = 'white'
      this.canvasContext.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContext.save()

      // 測量図画像を描画する
      if (this.maximizeWindow) {
        const dx = -Math.abs(this.position.x * this.scale)
        const dy = -Math.abs(this.position.y * this.scale)
        this.canvasContext.drawImage(this.image, dx, dy, this.image.width * this.scale, this.image.height * this.scale)
      } else {
        const scaledSize = this.scaledImageSize
        this.canvasContext.drawImage(this.image, this.position.x, this.position.y, scaledSize.width, scaledSize.height, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height)
      }
      this.canvasContext.restore()

      // 色反転
      this.colorInversion(this.canvasContext, this.canvasWidth, this.canvasHeight)
      // 土地を描画する
      this.canvasContext.strokeStyle = colors.strokeColor1Rgba()
      this.canvasContext.lineWidth = 1
      this.plats.forEach(plat => {
        this.drawPlat(this.canvasContext, plat.vertexes, this.scale, this.position)
      })

      // 選択されている頂点をハイライト
      this.checkVertexes.forEach(cv => {
        const vNo = Number(cv.split('-')[1]) - 1
        const x = (this.selectedPlat.vertexes[vNo].x - this.position.x) * this.scale
        const y = (this.selectedPlat.vertexes[vNo].y - this.position.y) * this.scale
        // 頂点
        this.canvasContext.fillStyle = colors.highlightColorRgba()
        this.canvasContext.fillRect(x - 5, y - 5, 10, 10)
      })
    },
    changeScale (event) {
      const isZoom = event.wheelDelta > 0
      this.scaleIndex = isZoom ? this.scaleIndex + 1 : Math.max(-9, this.scaleIndex - 1)
      if (isZoom) {
        this.scaleVal += SCALE_STEP
      } else {
        if (this.scaleVal - SCALE_STEP > 0.1) {
          this.scaleVal -= SCALE_STEP
        }
      }

      if (this.scale >= 1) {
        SCALE_STEP = Math.round(this.scale) * 0.1
      }
      this.canvasUpdate()
    },
    scalePlus () {
      const event = { wheelDelta: 1, clientX: 0, clientY: 0 }
      this.changeScale(event)
    },
    scaleDash () {
      const event = { wheelDelta: -1, clientX: 0, clientY: 0 }
      this.changeScale(event)
    },
    /**
     * canvasの左上の座標位置を取得する
     * @returns {number[]}
     */
    getCanvasTopLeftPosition () {
      const rect = this.$refs.canvas.getBoundingClientRect()
      return [rect.left, rect.top]
    },
    /**
     * Canvas内のマウスポインターの座標位置を取得する
     * @param event
     * @returns {number[]}
     */
    getPointerPosition (event) {
      const [left, top] = this.getCanvasTopLeftPosition()
      return [
        event.clientX - left,
        event.clientY - top
      ]
    },
    mouseDown (event) {
      if (this.conditionPlotFlag) {
        this.conditionPlotFlag = false
      } else if (this.dragPlotData.flag) {
        const [x, y] = this.getPointerPosition(event)
        this.dragPlotData.start.flag = true
        this.dragPlotData.start.x = (x / this.scale) + this.position.x
        this.dragPlotData.start.y = (y / this.scale) + this.position.y

        this.drag = {
          dragging: 1,
          startImagePos: { x: this.position.x, y: this.position.y },
          startMousePos: { x, y },
          vertexIndexes: []
        }
      } else {
        // canvas内でのマウスポインタの位置
        const [x, y] = this.getPointerPosition(event)

        this.drag = {
          dragging: 1,
          startImagePos: { x: this.position.x, y: this.position.y },
          startMousePos: { x, y },
          vertexIndexes: []
        }

        const relativeX = (x / this.scale) + this.position.x
        const relativeY = (y / this.scale) + this.position.y

        // 座標が頂点の上かどうか
        this.plats.forEach((plat, platIndex) => {
          plat.vertexes.forEach((vertex, vertexIndex) => {
            const rectStartX = vertex.x - 5
            const rectEndX = vertex.x + 5
            const rectStartY = vertex.y - 5
            const rectEndY = vertex.y + 5

            if (rectStartX <= relativeX && relativeX <= rectEndX && rectStartY <= relativeY && relativeY <= rectEndY) {
              this.drag.vertexIndexes = [platIndex, vertexIndex]
            }
          })
        })
      }
    },
    mouseUp (event) {
      if (this.drag.dragging === 1 && this.selectedPlat) {
        if (this.conditionPlotFlag && this.selectedPlat.vertexes.length >= 2) {
          // 垂直描点
          // マウス位置取得
          const [x, y] = this.getPointerPosition(event)
          // 直近の2点から傾き算出
          const sx = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].x
          const sy = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].y
          const ex = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 2].x
          const ey = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 2].y
          const tilt = this.calcTilt(sx, sy, ex, ey).tilt
          // 法線方向の傾き算出
          const revTilt = -1 / tilt
          // 直近の点を通る法線の式の切片を求める
          const intercept = sy - revTilt * sx
          let dx, dy

          if (revTilt > 4 || revTilt < -4) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / revTilt
          } else if (revTilt > 1) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / revTilt
          } else {
            dx = (x / this.scale) + this.position.x
            dy = revTilt * dx + intercept
          }
          this.selectedPlat.vertexes.push({
            id: `${this.selectedPlat.id}-${this.selectedPlat.vertexes.length + 1}`,
            name: `頂点${this.selectedPlat.vertexes.length + 1}`,
            x: dx,
            y: dy,
            distance_meter: ''
          })
          this.vertexesNum++
          this.conditionPlotFlag = false
          this.conditionPlot = { start: 0, end: 0 }
          this.canvasUpdate()
        } else {
          // 選択中の土地に頂点を追加する
          // canvas内でのマウスポインタの位置
          const [x, y] = this.getPointerPosition(event)
          const delta = this.calculateLatLonDelta(this.selectedPlat) // 緯度経度のデルタを計算
          const vX = (x / this.scale) + this.position.x
          const vY = (y / this.scale) + this.position.y
          const standardVertex = this.selectedPlat.vertexes[0] // 緯度経度のデルタを足しこむ基準点(土地の最初の点)
          const lat = (vY - standardVertex.y) * delta.deltaLat + standardVertex.lat
          const lon = (vX - standardVertex.x) * delta.deltaLon + standardVertex.lon
          // 頂点追加
          this.selectedPlat.vertexes.push({
            id: `${this.selectedPlat.id}-${this.selectedPlat.vertexes.length + 1}`,
            name: `頂点${this.selectedPlat.vertexes.length + 1}`,
            x: vX,
            y: vY,
            lat: lat,
            lon: lon,
            distance_meter: ''
          })
          // GoogleMapの頂点も更新
          this.deleteMarkers()
          this.deleteGSCoordinates()
          this.repopulateMarkers(this.selectedPlat.vertexes)
          this.repopulateGSCoordinates(this.selectedPlat.vertexes)
          // GoogleMapのリロード
          this.reloadMap(true)

          // 画面更新系処理
          this.canvasUpdate()
          this.calculateDistance()
        }
      } else if (this.drag.dragging > 0 && this.dragPlotData.flag) {
        // マウス位置取得
        const [x, y] = this.getPointerPosition(event)
        // endの座標に現在地を入れる
        this.dragPlotData.end.flag = true
        this.dragPlotData.end.x = (x / this.scale) + this.position.x
        this.dragPlotData.end.y = (y / this.scale) + this.position.y
        const vtx = this.dragPlot(this.dragPlotData.start.x,
          this.dragPlotData.start.y,
          this.dragPlotData.end.x,
          this.dragPlotData.end.y)
        vtx.forEach(v => {
          this.selectedPlat.vertexes.push({
            id: `${this.selectedPlat.id}-${this.selectedPlat.vertexes.length + 1}`,
            name: `頂点${this.selectedPlat.vertexes.length + 1}`,
            x: v.x,
            y: v.y,
            distance_meter: ''
          })
        })
        this.canvasUpdate()
        this.dragPlotData = { flag: false, start: { flag: false, x: '', y: '' }, end: { flag: false, x: '', y: '' } }
        this.drag = { dragging: 0, startImagePos: { x: 0, y: 0 }, startMousePos: { x: 0, y: 0 } }
      }
      this.drag.dragging = 0
      this.refreshAvailablePlotWay()
    },
    mouseMove (event) {
      // canvas内でのマウスポインタの位置
      const [x, y] = this.getPointerPosition(event)
      this.mousePosition = [x, y]

      if (this.drag.dragging > 0) {
        if (this.dragPlotData.flag && this.dragPlotData.start.flag) {
          // 頂点のドラッグ指定モード
          // 画面更新
          this.canvasUpdate()
          this.drag.dragging += 1
          // マウス位置取得
          const [x, y] = this.getPointerPosition(event)
          const canvasContext = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
          canvasContext.lineWidth = 2
          canvasContext.strokeStyle = colors.draggingColorRgba()
          canvasContext.setLineDash([5, 5])
          const sx = (this.dragPlotData.start.x - this.drag.startImagePos.x) * this.scale
          const sy = (this.dragPlotData.start.y - this.drag.startImagePos.y) * this.scale
          const dragWidth = x / this.scale + this.position.x - this.dragPlotData.start.x
          const dragHeight = y / this.scale + this.position.y - this.dragPlotData.start.y
          canvasContext.strokeRect(sx,
            sy,
            dragWidth * this.scale,
            dragHeight * this.scale)
        } else {
          // ドラッグが開始されていればオブジェクトの座標を更新して再描画
          this.drag.dragging += 1

          if (this.drag.vertexIndexes.length === 0) {
            // 画像の移動
            this.position.x = this.drag.startImagePos.x + (this.drag.startMousePos.x - x) / this.scale
            this.position.y = this.drag.startImagePos.y + (this.drag.startMousePos.y - y) / this.scale
          }
          // 画面更新
          this.canvasUpdate()
        }
      } else if (this.conditionPlotFlag && this.selectedPlat.vertexes.length > 1) {
        if (this.isVerticalPlot) {
          // 垂直
          const platIndex = this.selectedPlat.id - 1
          const vertexIndex = this.plats[platIndex].vertexes.length - 1

          // 直近の2点から傾き算出
          const sx = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 2].x
          const sy = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 2].y
          const ex = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 3].x
          const ey = this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 3].y
          const tilt = this.calcTilt(sx, sy, ex, ey).tilt
          // 法線方向の傾き算出
          const revTilt = -1 / tilt
          // 直近の点を通る法線の式の切片を求める
          const intercept = sy - revTilt * sx
          let dx, dy

          if (revTilt > 4 || revTilt < -4) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / revTilt
          } else if (revTilt > 1) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / revTilt
          } else {
            dx = (x / this.scale) + this.position.x
            dy = revTilt * dx + intercept
          }

          this.plats[platIndex].vertexes[vertexIndex].x = dx
          this.plats[platIndex].vertexes[vertexIndex].y = dy
        } else {
          // 平行
          const platIndex = this.selectedPlat.id - 1
          const vertexIndex = this.plats[platIndex].vertexes.length - 1

          // 指定の2点から傾き算出
          const sx = this.selectedPlat.vertexes[this.conditionPlot.start].x
          const sy = this.selectedPlat.vertexes[this.conditionPlot.start].y
          const ex = this.selectedPlat.vertexes[this.conditionPlot.end].x
          const ey = this.selectedPlat.vertexes[this.conditionPlot.end].y
          const tilt = this.calcTilt(sx, sy, ex, ey).tilt
          // 直前の点を通る平行式の切片を求める
          const intercept = this.plats[platIndex].vertexes[vertexIndex - 1].y - tilt * this.plats[platIndex].vertexes[vertexIndex - 1].x
          let dx, dy

          if (tilt > 4 || tilt < -4) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / tilt
          } else if (tilt > 1) {
            dy = (y / this.scale) + this.position.y
            dx = (dy - intercept) / tilt
          } else {
            dx = (x / this.scale) + this.position.x
            dy = tilt * dx + intercept
          }

          this.plats[platIndex].vertexes[vertexIndex].x = dx
          this.plats[platIndex].vertexes[vertexIndex].y = dy
        }
        this.canvasUpdate()
      } else if (!this.assistEdgeOn.edgeFlag && !this.assistEdgeOn.movingFlag && this.drawAssistFlag) {
        // アシスト線機能
        // 画面更新
        this.canvasUpdate()
        const relativeX = (x / this.scale) + this.position.x
        const relativeY = (y / this.scale) + this.position.y
        this.plats.forEach((p, pIdx) => {
          p.vertexes.forEach((v, vIdx) => {
            const vx = v.x
            const vy = v.y
            const prevIdx = (vIdx === 0 ? p.vertexes.length - 1 : vIdx - 1)
            const px = p.vertexes[prevIdx].x
            const py = p.vertexes[prevIdx].y
            const vDist = this.calcDistanceDot2Dot(vx, vy, relativeX, relativeY)
            const lDist = this.calcDistanceDot2Line(vx, vy, px, py, relativeX, relativeY)
            if (vDist && vDist <= 15) {
              // 頂点をハイライト
              this.canvasContext.beginPath()
              this.canvasContext.strokeStyle = colors.highlightColorRgba()
              this.canvasContext.fillStyle = colors.highlightColorRgba()
              this.canvasContext.fillRect((vx - this.position.x) * this.scale - 5, (vy - this.position.y) * this.scale - 5, 10, 10)
              this.canvasContext.setLineDash([0])
              this.canvasContext.closePath()
              this.canvasContext.stroke()
              // 始点が決定
              this.assistEdgeOn = { edgeFlag: true, movingFlag: false, vIdx: vIdx, pIdx: '', start: { x: vx, y: vy }, end: { x: '', y: '' } }
            } else if (vDist > 30 && lDist && lDist < 15) {
              if (vDist > 30) {
                // 選択した辺をハイライト
                this.canvasContext.beginPath()
                this.canvasContext.moveTo((vx - this.position.x) * this.scale, (vy - this.position.y) * this.scale)
                this.canvasContext.lineTo((px - this.position.x) * this.scale, (py - this.position.y) * this.scale)
                this.canvasContext.lineWidth = 1
                this.canvasContext.strokeStyle = colors.highlightColorRgba()
                this.canvasContext.setLineDash([0])
                this.canvasContext.closePath()
                this.canvasContext.stroke()
                const tilt = this.calcTilt(vx, vy, px, py)
                let dx, dy

                if (tilt.tilt > 4 || tilt.tilt < -4) {
                  dy = relativeY
                  dx = (dy - tilt.intcpt) / tilt.tilt
                } else if (tilt.tilt > 1) {
                  dy = relativeY
                  dx = (dy - tilt.intcpt) / tilt.tilt
                } else {
                  dx = relativeX
                  dy = tilt.tilt * dx + tilt.intcpt
                }
                // 始点が決定
                this.assistEdgeOn = { edgeFlag: true, movingFlag: false, vIdx: vIdx, pIdx: prevIdx, start: { x: dx, y: dy }, end: { x: '', y: '' } }
              }
            }
          })
        })
      } else if (this.assistEdgeOn.edgeFlag && this.assistEdgeOn.movingFlag && this.drawAssistFlag) {
        // アシスト線機能
        // 画面更新
        this.canvasUpdate()
        const relativeX = (x / this.scale) + this.position.x
        const relativeY = (y / this.scale) + this.position.y
        this.plats.forEach((p, pIdx) => {
          p.vertexes.forEach((v, vIdx) => {
            const vx = v.x
            const vy = v.y
            const prevIdx = (vIdx === p.vertexes.length - 1 ? 0 : vIdx + 1)
            const px = p.vertexes[prevIdx].x
            const py = p.vertexes[prevIdx].y
            const vDist = this.calcDistanceDot2Dot(vx, vy, relativeX, relativeY)
            const lDist = this.calcDistanceDot2Line(vx, vy, px, py, relativeX, relativeY)
            if (vDist && vDist <= 30) {
              // 頂点をハイライト
              this.canvasContext.beginPath()
              this.canvasContext.strokeStyle = colors.highlightColorRgba()
              this.canvasContext.fillStyle = colors.highlightColorRgba()
              this.canvasContext.fillRect((vx - this.position.x) * this.scale - 5, (vy - this.position.y) * this.scale - 5, 10, 10)
              this.canvasContext.setLineDash([0])
              this.canvasContext.closePath()
              this.canvasContext.stroke()
              // 終点が決定
              this.assistEdgeOn.end.x = vx
              this.assistEdgeOn.end.y = vy
            } else if (vDist > 30 && lDist && lDist < 30) {
              if (vDist > 30) {
                // 選択した辺をハイライト
                this.canvasContext.beginPath()
                this.canvasContext.moveTo((vx - this.position.x) * this.scale, (vy - this.position.y) * this.scale)
                this.canvasContext.lineTo((px - this.position.x) * this.scale, (py - this.position.y) * this.scale)
                this.canvasContext.lineWidth = 1
                this.canvasContext.strokeStyle = colors.highlightColorRgba()
                this.canvasContext.setLineDash([0])
                this.canvasContext.closePath()
                this.canvasContext.stroke()
                const tilt = this.calcTilt(vx, vy, px, py)
                let dx, dy

                if (tilt.tilt > 4 || tilt.tilt < -4) {
                  dy = relativeY
                  dx = (dy - tilt.intcpt) / tilt.tilt
                } else if (tilt.tilt > 1) {
                  dy = relativeY
                  dx = (dy - tilt.intcpt) / tilt.tilt
                } else {
                  dx = relativeX
                  dy = tilt.tilt * dx + tilt.intcpt
                }
                // 終点が決定
                this.assistEdgeOn.end.x = dx
                this.assistEdgeOn.end.y = dy
              }
            } else {
              // 終点が決定
              this.assistEdgeOn.end.x = relativeX
              this.assistEdgeOn.end.y = relativeY
            }
          })
        })
      } else if (this.drawAssistFlag && this.assistEdgeOn.edgeFlag && !this.assistEdgeOn.movingFlag && this.drag.dragging === 0) {
        // アシスト線機能
        // 頂点か辺にマウスオーバーしていない
        this.assistEdgeOn.edgeFlag = false
      }
    },
    onSelectChangePlat (targetPlat) {
      var clickPlatVisible = !targetPlat.visible
      this.plats.forEach(plat => {
        plat.visible = false
      })
      targetPlat.visible = clickPlatVisible
      this.selectedPlat = targetPlat
      this.checkVertexes = []
      this.refreshAvailablePlotWay()
    },
    deleteTreeNode () {
      const ids = this.selectedPlat.id.split('-')
      const platIndex = this.plats.findIndex(p => p.id === ids[0])
      if (ids.length === 1 && this.checkVertexes.length === 0) {
        // 図形ごと削除する
        this.plats.splice(platIndex, 1)
        this.selectedPlat = undefined

        // GoogleMapの頂点も削除
        this.deleteMarkers()
        this.deleteGSCoordinates()
      } else if (this.checkVertexes.length > 0) {
        // 頂点だけ削除する
        this.checkVertexes.forEach(checkVertex => {
          this.plats.forEach(plat => {
            const checkIndex = plat.vertexes.findIndex(p => p.id === checkVertex)
            if (checkIndex !== -1) {
              plat.vertexes.splice(checkIndex, 1)
            }
          })
        })
        this.checkVertexes = []

        // GoogleMapの頂点も更新
        this.deleteMarkers()
        this.deleteGSCoordinates()
        this.plats.forEach(plat => {
          this.repopulateMarkers(plat.vertexes)
          this.repopulateGSCoordinates(plat.vertexes)
        })
      }

      // GoogleMapのリロード
      this.reloadMap(true)

      // name, id 振り直し
      this.plats.forEach((plat, platIndex) => {
        plat.name = `土地${platIndex + 1}`
        plat.id = `${platIndex + 1}`
        plat.vertexes.forEach((vertex, vertexIndex) => {
          vertex.name = `頂点${vertexIndex + 1}`
          vertex.id = `${platIndex + 1}-${vertexIndex + 1}`
        })
      })
      this.canvasUpdate()
    },
    plotCoordinates (gs) {
      const vtxArray = []
      const scaleVal = this.scale
      const positionVal = [this.position.x, this.position.y]
      Object.keys(gs).forEach(function (key) {
        const [x, y] = [gs[key].x, gs[key].y]
        // 緯度，経度 の項目を追加
        // 緯度経度が取得できないときは、データを 0 とする
        const [lat, lon] = [
          (typeof gs[key].lat === 'number') ? gs[key].lat : 0,
          (typeof gs[key].lon === 'number') ? gs[key].lon : 0
        ]
        vtxArray.push({
          id: `${key}-${parseInt(key) + 1}`,
          name: `頂点${parseInt(key) + 1}`,
          x: (x / scaleVal) + positionVal[0],
          y: (y / scaleVal) + positionVal[1],
          distance_meter: '',
          lat: lat,
          lon: lon
        })
      })
      this.selectedPlat.vertexes = vtxArray

      // Google Mapにも反映
      this.deleteMarkers()
      this.deleteGSCoordinates()
      this.repopulateMarkers(vtxArray)
      this.repopulateGSCoordinates(vtxArray)
      // GoogleMapのリロード
      this.reloadMap(true)

      this.canvasWidth = this.$refs.box.offsetWidth - 4 // border分だけマイナスする
      this.canvasContext = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
      this.canvasContext.lineCap = 'round'
      this.canvasContext.lineJoin = 'round'
      this.canvasContext.lineWidth = 5
      this.canvasContext.strokeStyle = '#000000'
      this.canvasContext.fillStyle = '#FFFFFF'
      this.canvasContext.fillRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)

      this.canvasUpdate()
      this.drag.dragging = 0
    },
    verticalPlotOn (event) {
      if (event.ctrlKey && !this.verticalPlotFlag) {
        this.verticalPlotFlag = true
      }
    },
    verticalPlotOff (event) {
      if (this.verticalPlotFlag) {
        this.verticalPlotFlag = false
      }
    },
    conditionPlotSet () {
      if (this.conditionPlot.start === this.conditionPlot.end) {
        alert('始点と終点が同じ頂点です。')
      } else {
        this.conditionPlotFlag = true
        if (this.isVerticalPlot) {
          if (this.conditionPlot.start > this.conditionPlot.end) {
            const swap = this.conditionPlot.start
            this.conditionPlot.start = this.conditionPlot.end
            this.conditionPlot.end = swap
          }
          this.selectedPlat.vertexes.push({
            id: `${this.selectedPlat.id}-${this.selectedPlat.vertexes.length + 1}`,
            name: `頂点${this.selectedPlat.vertexes.length + 1}`,
            x: this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].x,
            y: this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].y,
            distance_meter: ''
          })
        } else {
          if (Math.abs(this.conditionPlot.start - this.conditionPlot.end) > 1) {
            alert('隣り合う頂点を指定してください。')
          } else if (this.conditionPlot.start === this.selectedPlat.vertexes.length - 1 || this.conditionPlot.start === this.selectedPlat.vertexes.length - 1) {
            alert('直近の頂点は指定できません。')
          } else {
            if (this.conditionPlot.start > this.conditionPlot.end) {
              const swap = this.conditionPlot.start
              this.conditionPlot.start = this.conditionPlot.end
              this.conditionPlot.end = swap
            }
            this.selectedPlat.vertexes.push({
              id: `${this.selectedPlat.id}-${this.selectedPlat.vertexes.length + 1}`,
              name: `頂点${this.selectedPlat.vertexes.length + 1}`,
              x: this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].x,
              y: this.selectedPlat.vertexes[this.selectedPlat.vertexes.length - 1].y,
              distance_meter: ''
            })
          }
        }
      }
    },
    getOptionOfConditionPlot (plat) {
      const options = []
      if (plat !== undefined) {
        for (let i = 0; i < plat.vertexes.length; i++) {
          options.push({ value: i, text: `頂点${i + 1}` })
        }
      } else {
        options.push({ value: 0, text: '' })
      }
      return options
    },
    maximizeWindowSize () {
      this.maximizeWindow = !this.maximizeWindow
      if (this.maximizeWindow) {
        this.initCanvas()
        this.canvasWidth = (this.canvasWidth + 10) * 1.5
        this.canvasHeight = document.documentElement.clientHeight
      } else {
        this.canvasWidth = (this.canvasWidth - 15) / 1.5
        this.canvasHeight = 600
      }
      setTimeout(() => {
        this.canvasUpdate()
        this.scaleIndex = this.scaleIndex + 1
        this.canvasUpdate()
        this.scaleIndex = Math.max(0, this.scaleIndex - 1)
        this.canvasUpdate()
      }, 10)
    },
    toggleCanvasMap () {
      this.isCanvasShown = !this.isCanvasShown
      if (this.isCanvasShown) { this.updatePlats() }
    },
    refreshAvailablePlotWay () {
      if (this.selectedPlat !== undefined) {
        if (this.selectedPlat.vertexes.length === 2) {
          this.availablePlotWay = 2
        } else if (this.selectedPlat.vertexes.length > 2) {
          this.availablePlotWay = 3
        } else {
          this.availablePlotWay = 0
        }
      } else {
        this.availablePlotWay = 0
      }
    },
    showConfirmModal () {
      this.$refs['confirm-drag-mode'].show()
    },
    // 距離表示用
    calculateDistance () {
      this.plats.forEach(p => {
        // 基準の距離を計測
        const standardMeter = this.calcDist(p.vertexes[0].lat * (Math.PI / 180),
          p.vertexes[0].lon * (Math.PI / 180),
          p.vertexes[1].lat * (Math.PI / 180),
          p.vertexes[1].lon * (Math.PI / 180))
        const scaleDistance = Math.sqrt(this.calcDistanceDot2Dot(p.vertexes[0].x, p.vertexes[0].y, p.vertexes[1].x, p.vertexes[1].y)) / standardMeter
        if (p.vertexes.length > 1) {
          p.vertexes.forEach((v, index) => {
            const next = index !== p.vertexes.length - 1 ? index + 1 : 0
            const dist = Math.sqrt(this.calcDistanceDot2Dot(v.x, v.y, p.vertexes[next].x, p.vertexes[next].y))
            const meter = dist / scaleDistance
            p.vertexes[next].distance_meter = meter
          })
        }
      })
      this.canvasUpdate()
    },
    getVtx (markers) {
      return new Promise((resolve) => {
        if (markers[this.vidx].lng > 0 && markers[this.vidx].lat > 0) {
          this.axios.get(`/api/kagechi/gpx?lon=${markers[this.vidx].lng}&lat=${markers[this.vidx].lat}`).then(res => {
            this.gs.push({
              x: res.data.x,
              y: res.data.y,
              // 緯度，経度 のデータ取得を追加
              lat: markers[this.vidx].lat,
              lon: markers[this.vidx].lng
            })
            resolve()
          })
        }
      })
    },
    loopVtx (markers) {
      return new Promise((resolve) => {
        if (markers.length > this.vidx) {
          this.getVtx(markers).then(() => {
            this.vidx++
            this.loopVtx(markers).then(() => {
              resolve()
            })
          })
        } else {
          resolve()
        }
      })
    },
    calculateLatLonDelta (plat) {
      // 土地の最初の点から考えて1つ目の点の距離(x,y座標の差異)を緯度経度の差異に対応させる
      // これを新規で追加された頂点に当てはめて、GoogleMap上でのおおよその位置に対応させる
      const deltaLat = (plat.vertexes[1].lat - plat.vertexes[0].lat) / (plat.vertexes[1].y - plat.vertexes[0].y)
      const deltaLon = (plat.vertexes[1].lon - plat.vertexes[0].lon) / (plat.vertexes[1].x - plat.vertexes[0].x)
      return { deltaLat: deltaLat, deltaLon: deltaLon }
    }
  }
}
</script>
