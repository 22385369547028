<template>
  <div class="d-flex flex-row align-items-center">
    <div v-if="edit">
      <b-form-input v-model="title" maxlength="50"></b-form-input>
    </div>
    <div v-else>
      {{ title.length > 5 ? title.substring(0, 5) + '...' : title }}
    </div>

    <div class="ml-2">
      <b-button v-if="edit" variant="dark" @click="changeTitle(); edit = !edit" size="sm">
        <b-icon icon="check2"></b-icon>
      </b-button>
      <b-button v-else variant="dark" @click="edit = !edit" size="sm">
        <b-icon icon="pencil"></b-icon>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ChangeTitle',
  props: {
    value: String,
    isTitle: {
      type: Boolean,
      default: true
    },
    mainId: String
  },
  data: () => ({
  }),
  computed: {
    ...mapState({
      titleErrors: state => state.gpx.titleErrorMessages
    }),
    title: {
      get () {
        return this.$store.state.gpx.title
      },
      set (value) {
        this.$store.commit('gpx/updateTitle', value)
      }
    },
    edit: {
      get () {
        return this.$store.state.gpx.edit
      },
      set (value) {
        this.$store.commit('gpx/setEdit', value)
      }
    }
  },
  watch: {
    titleErrors (newValue) {
      this.$emit('change-title-error', newValue)
    }
  },
  methods: {
    ...mapActions('gpx', ['saveTitle']),

    changeTitle () {
      const data = {
        mainId: this.$route.params.main_id,
        title: this.title
      }
      this.saveTitle(data)
    }
  }
}
</script>

<style scoped>

</style>
