import colors from '../colors'

export default {
  methods: {
    /**
     * 頂点A、Bの距離(ピクセル)を算出する
     * @param vertexA
     * @param vertexB
     * @returns {number}
     */
    calcDistance (vertexA, vertexB) {
      return Math.sqrt(Math.pow(vertexB.x - vertexA.x, 2) + Math.pow(vertexB.y - vertexA.y, 2))
    },
    /**
     * 土地画像と、多角形を描画する
     * @param canvasContext
     * @param vertexes
     * @param scale
     * @param relativePosition
     * @param fillColor
     * @param lineStyle
     * @param needVertexRect
     * @param isDefaultColor
     */
    drawPlat (canvasContext, vertexes, scale, relativePosition, fillColor, lineStyle, needVertexRect, strokeStyleColor, isDefaultColor = true) {
      if (!fillColor) {
        fillColor = `${colors.platColorRgba(isDefaultColor)}`
      }

      if (lineStyle === 'dash') {
        canvasContext.setLineDash([5, 2])
      } else {
        canvasContext.setLineDash([])
      }

      if (needVertexRect !== false) {
        needVertexRect = true
      }

      // 塗りつぶしする
      vertexes.forEach((vertex, vertexIndex) => {
        if (vertexIndex === 0) {
          canvasContext.beginPath()
        }

        const x = (vertex.x - relativePosition.x) * scale
        const y = (vertex.y - relativePosition.y) * scale

        if (vertexIndex === 0) {
          canvasContext.moveTo(x, y)
        } else {
          canvasContext.lineTo(x, y)
        }

        if ((vertexIndex + 1) === vertexes.length) {
          canvasContext.closePath()
          if (strokeStyleColor) {
            canvasContext.strokeStyle = strokeStyleColor
          } else {
            canvasContext.strokeStyle = `${colors.strokeColor2Rgba()}`
          }
          canvasContext.fillStyle = fillColor
          canvasContext.fill()
          canvasContext.stroke()
        }
      })

      // 頂点を打つ・番号表示
      if (needVertexRect) {
        canvasContext.fillStyle = `${colors.strokeColor2Rgba()}`
        canvasContext.font = '11pt Arial'
        canvasContext.textAlign = 'center'
        canvasContext.textBaseline = 'bottom'
        vertexes.forEach((vertex, vertexIndex) => {
          const x = (vertex.x - relativePosition.x) * scale
          const y = (vertex.y - relativePosition.y) * scale

          // 頂点番号背景
          const bgHeight = 9 * 1.5 + 5
          const bgWidth = canvasContext.measureText(vertexIndex + 1).width + 5
          // canvasContext.fillRect(x - bgWidth + 1, y - bgHeight - 3.5, bgWidth, bgHeight)
          canvasContext.beginPath()
          canvasContext.fillStyle = 'white'
          if (bgWidth > bgHeight) {
            if (vertexIndex + 1 < 10) {
              canvasContext.arc(x + bgWidth / 10 - bgWidth / 2, y - 5 - bgHeight / 2, bgWidth / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            } else {
              canvasContext.arc(x + bgWidth / 4 - bgWidth / 2, y - 5 - bgHeight / 2, bgWidth / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            }
            canvasContext.fill()
          } else {
            if (vertexIndex + 1 < 10) {
              canvasContext.arc(x + bgWidth / 10 - bgWidth / 2, y - 5 - bgHeight / 2, bgHeight / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            } else {
              canvasContext.arc(x + bgWidth / 4 - bgWidth / 2, y - 5 - bgHeight / 2, bgHeight / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            }
            canvasContext.fill()
          }
          // 頂点
          canvasContext.fillStyle = 'blue'
          canvasContext.fillRect(x - 5, y - 5, 10, 10)
          // 頂点番号
          canvasContext.fillText((vertexIndex + 1) + '', x - 5, y - 5)
        })
      }

      // 距離表示
      vertexes.forEach((vertex, vertexIndex) => {
        const x = (vertex.x - relativePosition.x) * scale
        const y = (vertex.y - relativePosition.y) * scale

        if (vertex.distance_meter && (vertex.showDistance === undefined || vertex.showDistance)) {
          const prevVertex = vertexes[(vertexIndex === 0) ? vertexes.length - 1 : vertexIndex - 1]
          const prevX = (prevVertex.x - relativePosition.x) * scale
          const prevY = (prevVertex.y - relativePosition.y) * scale
          const textX = (x + prevX) / 2
          const textY = (y + prevY) / 2
          const textValue = (Math.round(vertex.distance_meter * 100) / 100) + ''

          const bgHeight = 9 * 1.5 + 5
          const bgWidth = canvasContext.measureText(textValue).width + 5
          const bgY = textY - bgHeight
          const bgX = textX - (bgWidth / 2)

          canvasContext.fillStyle = 'white'
          canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
          canvasContext.fillStyle = 'blue'
          canvasContext.fillText(textValue, textX, textY)
        }
      })
    },
    /**
     * GPXの土地データを描画する
     * @param canvasContext
     * @param vertexes
     * @param scale
     * @param relativePosition
     * @param fillColor
     * @param lineStyle
     * @param needVertexRect
     * @param strokeStyleColor
     * @param isDefaultColor
     */
    drawPlatGPX (canvasContext, vertexes, scale, relativePosition, fillColor, lineStyle, needVertexRect, strokeStyleColor, isDefaultColor = true) {
      if (!fillColor) {
        fillColor = `${colors.platColorRgba(isDefaultColor)}`
      }

      if (lineStyle === 'dash') {
        canvasContext.setLineDash([5, 2])
      } else {
        canvasContext.setLineDash([])
      }

      if (needVertexRect !== false) {
        needVertexRect = true
      }

      // 塗りつぶしする
      vertexes.forEach((vertex, vertexIndex) => {
        if (vertexIndex === 0) {
          canvasContext.beginPath()
        }

        const x = (vertex.x - relativePosition.x) * scale
        const y = (vertex.y - relativePosition.y) * scale

        if (vertexIndex === 0) {
          canvasContext.moveTo(x, y)
        } else {
          canvasContext.lineTo(x, y)
        }

        if ((vertexIndex + 1) === vertexes.length) {
          canvasContext.closePath()
          if (strokeStyleColor) {
            canvasContext.strokeStyle = strokeStyleColor
          } else {
            canvasContext.strokeStyle = `${colors.strokeColor2Rgba()}`
          }
          canvasContext.fillStyle = fillColor
          canvasContext.fill()
          canvasContext.stroke()
        }
      })

      // 頂点を打つ・番号表示
      if (needVertexRect) {
        canvasContext.fillStyle = `${colors.strokeColor2Rgba()}`
        canvasContext.font = '11pt Arial'
        canvasContext.textAlign = 'center'
        canvasContext.textBaseline = 'bottom'
        vertexes.forEach((vertex, vertexIndex) => {
          const x = (vertex.x - relativePosition.x) * scale
          const y = (vertex.y - relativePosition.y) * scale

          // 頂点番号背景
          const text = vertex.id.split('-')[1]
          const bgHeight = 9 * 1.5 + 5
          const bgWidth = canvasContext.measureText(text).width + 5
          // canvasContext.fillRect(x - bgWidth + 1, y - bgHeight - 3.5, bgWidth, bgHeight)
          canvasContext.beginPath()
          canvasContext.fillStyle = 'white'
          if (bgWidth > bgHeight) {
            if (Number(text) < 10) {
              canvasContext.arc(x + bgWidth / 10 - bgWidth / 2, y - 5 - bgHeight / 2, bgWidth / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            } else {
              canvasContext.arc(x + bgWidth / 4 - bgWidth / 2, y - 5 - bgHeight / 2, bgWidth / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            }
            canvasContext.fill()
          } else {
            if (Number(text) < 10) {
              canvasContext.arc(x + bgWidth / 10 - bgWidth / 2, y - 5 - bgHeight / 2, bgHeight / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            } else {
              canvasContext.arc(x + bgWidth / 4 - bgWidth / 2, y - 5 - bgHeight / 2, bgHeight / 2, 0 * Math.PI / 180, 360 * Math.PI / 180, false)
            }
            canvasContext.fill()
          }
          // 頂点
          canvasContext.fillStyle = 'blue'
          canvasContext.fillRect(x - 5, y - 5, 10, 10)
          // 頂点番号
          canvasContext.fillText((text) + '', x - 5, y - 5)
        })
      }

      // 距離表示
      vertexes.forEach((vertex, vertexIndex) => {
        const x = (vertex.x - relativePosition.x) * scale
        const y = (vertex.y - relativePosition.y) * scale

        if (vertex.distance_meter) {
          const prevVertex = vertexes[(vertexIndex === 0) ? vertexes.length - 1 : vertexIndex - 1]
          const prevX = (prevVertex.x - relativePosition.x) * scale
          const prevY = (prevVertex.y - relativePosition.y) * scale
          const textX = (x + prevX) / 2
          const textY = (y + prevY) / 2
          const textValue = (Math.round(vertex.distance_meter * 100) / 100) + ''

          const bgHeight = 9 * 1.5 + 5
          const bgWidth = canvasContext.measureText(textValue).width + 5
          const bgY = textY - bgHeight
          const bgX = textX - (bgWidth / 2)

          canvasContext.fillStyle = 'white'
          canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
          canvasContext.fillStyle = 'blue'
          canvasContext.fillText(textValue, textX, textY)
        }
      })
    },
    /**
     * 多角形の面積を描画する(平方ピクセル)
     * @param vertexes
     * @returns {number}
     */
    calcPlatArea (vertexes) {
      let a = 0
      let a1
      let im1
      let ip1

      for (let i = 0; i < vertexes.length; i++) {
        im1 = ((i - 1) >= 0) ? i - 1 : vertexes.length - 1
        ip1 = ((i + 1) < vertexes.length) ? i + 1 : 0

        a1 = (vertexes[ip1].x - vertexes[im1].x) * vertexes[i].y
        a += a1
      }

      return Math.abs(a) / 2
    },
    /**
     * 多角形の重心座標を取得する
     * @param vertexes
     * @returns {{x: number, y: number}}
     */
    calcCentroid (vertexes) {
      const vertex1 = vertexes[0]
      let vertex2 = vertexes[1]
      let vertex
      let triangleArea
      let platArea = 0
      const gs = { x: 0, y: 0 }

      for (let i = 2; i < vertexes.length; i++) {
        vertex = vertexes[i]

        triangleArea = ((vertex2.x - vertex1.x) * (vertex.y - vertex1.y) - (vertex2.y - vertex1.y) * (vertex.x - vertex1.x)) / 2.0
        gs.x += triangleArea * (vertex1.x + vertex2.x + vertex.x) / 3.0
        gs.y += triangleArea * (vertex1.y + vertex2.y + vertex.y) / 3.0
        platArea += triangleArea
        vertex2 = { ...vertex }
      }

      gs.x /= platArea
      gs.y /= platArea
      return gs
    },
    /**
     * 座標(X,Y)を多角形の内外判定を行う
     * @param vertexes
     * @param x
     * @param y
     * @returns {boolean}
     */
    isInPolygon (vertexes, x, y) {
      let thetaSum = 0
      const n = vertexes.length
      const computeDegree = (x1, y1, x2, y2) => {
        const abs1 = Math.sqrt(x1 * x1 + y1 * y1)
        const abs2 = Math.sqrt(x2 * x2 + y2 * y2)
        let theta = Math.acos((x1 * x2 + y1 * y2) / (abs1 * abs2)) // 内積を使って角度を計算
        const sign = Math.sign(x1 * y2 - y1 * x2) // 外積を使って符号を計算
        theta *= sign
        return theta
      }

      // i-1 => 指定された点 =>  i の成す角度の和をthetaSumに足し込んでいく
      for (let i = 1; i < n; i++) {
        if (vertexes[i].x === x && vertexes[i].y === y) {
          // 指定された点が多角形の角の場合うまく角度が計算できないので、判明した時点でtrueを返す
          return true
        }

        const v1x = vertexes[i - 1].x - x
        const v1y = vertexes[i - 1].y - y
        const v2x = vertexes[i].x - x
        const v2y = vertexes[i].y - y
        thetaSum += computeDegree(v1x, v1y, v2x, v2y)
      }
      // 0とN番目の成す角度
      if (vertexes[0].x === x && vertexes[0].y === y) {
        return true
      }

      const v1x = vertexes[n - 1].x - x
      const v1y = vertexes[n - 1].y - y
      const v2x = vertexes[0].x - x
      const v2y = vertexes[0].y - y
      thetaSum += computeDegree(v1x, v1y, v2x, v2y)
      thetaSum = Math.abs(thetaSum)

      return thetaSum >= 0.1
    },
    /**
     * 座標(X,Y)を想定整形地の内外判定を行う
     * @param vertexes
     * @param x
     * @param y
     * @returns {boolean}
     */
    isInPolygonForAssumedRect (vertexes, x, y) {
      const adjust = 0.01
      if (vertexes.length !== 4) {
        return false
      }

      let maxx = vertexes[0].x
      let minx = vertexes[0].x
      let maxy = vertexes[0].y
      let miny = vertexes[0].y

      vertexes.forEach((v, index) => {
        if (v.x > maxx) maxx = v.x
        if (v.x < minx) minx = v.x
        if (v.y > maxy) maxy = v.y
        if (v.y < miny) miny = v.y
      })

      if (minx - adjust <= x && x <= maxx + adjust && miny - adjust <= y && y <= maxy + adjust) {
        return true
      }

      return false
    },
    /**
     * 縦横均等補正処理
     * @param baseVertex
     * @param plat
     * @param fixedVertexIndexes
     * @param correctScale
     * @param imageScale
     * @param correct
     * @returns {number}
     */
    correctionScale (baseVertex, plat, fixedVertexIndexes, correctScale, imageScale, correct) {
      const distanceVertex = { x: 0, y: 0 }

      // 均等補正の際の一辺の拡大縮小率を計算
      let valA = 0
      let valB = 0
      let valC = 0

      plat.vertexes.forEach((v, idx) => {
        const nextIdx = idx === plat.vertexes.length - 1 ? 0 : idx + 1

        // 重心、idx, nextIdxの三点からなる三角形の面積を計算
        const triangle = []
        triangle.push(baseVertex)
        triangle.push(v)
        triangle.push(plat.vertexes[nextIdx])
        const triangleArea = this.calcPlatArea(triangle) / Math.pow(this.pixelsPerMeter, 2) * Math.pow(this.measure.result_scale, 2)

        // 動く点が何個あるか
        let moveCount = 0
        moveCount = fixedVertexIndexes.includes(idx) ? moveCount : moveCount + 1
        moveCount = fixedVertexIndexes.includes(nextIdx) ? moveCount : moveCount + 1
        if (moveCount === 2) {
          valA += triangleArea
        } else if (moveCount === 1) {
          valB += triangleArea
        } else {
          valC += triangleArea
        }
      })

      const lineScale = valA > 0 ? Math.max(...this.quadraticEquation(valA, valB, valC - correct.area)) : (correct.area - valC) / valB
      if (isNaN(lineScale) || lineScale === Infinity || lineScale === undefined || lineScale === '') {
        return plat.measure.area
      }

      if (!Array.isArray(fixedVertexIndexes)) {
        fixedVertexIndexes = []
      }

      let initVertex
      for (let i = 0; i < plat.vertexes.length; i++) {
        initVertex = plat.vertexes[i]
        if (fixedVertexIndexes.indexOf(i) === -1) {
          distanceVertex.x = initVertex.x - baseVertex.x
          distanceVertex.y = initVertex.y - baseVertex.y
          plat.vertexes[i].x = distanceVertex.x * lineScale + baseVertex.x
          plat.vertexes[i].y = distanceVertex.y * lineScale + baseVertex.y
        } else {
          plat.vertexes[i].x = initVertex.x
          plat.vertexes[i].y = initVertex.y
        }
      }

      const pixelArea = this.calcPlatArea(this.selectingPlat.vertexes)
      let area = pixelArea / Math.pow(this.pixelsPerMeter, 2) * Math.pow(this.measure.result_scale, 2)
      let areaStr = (Math.round(area * 100) / 100).toString() // 現在の面積文字列化
      const correctAreaStr = (Math.round(correct.area * 100) / 100).toString() // 評価対象地の面積(目標の面積)文字列化
      const additionalValueBase = 0.1 // j増分のデフォルト値
      // 理想の面積になるまで、動かせる辺を拡大し続ける
      if (area <= correct.area) {
        let additionalValue = additionalValueBase
        let j = 1 + additionalValue // 初期設定の拡大縮小率
        let failedCount = 0 // 試行失敗回数 = このカウント数の分、j増分が半分にされる
        while (areaStr !== correctAreaStr && failedCount < 10) {
          const tempArea = this.scaleLength(plat, fixedVertexIndexes, distanceVertex, baseVertex, initVertex, j)
          if (tempArea > correct.area) {
            // 実際の面積 > 評価対象地の面積(目標の面積) のとき、最終のj増分を取消 → 面積の復元, j増分減らす, 試行失敗回数を加算
            this.scaleLength(plat, fixedVertexIndexes, distanceVertex, baseVertex, initVertex, (1 - additionalValue)) // 面積の復元
            j -= additionalValue // 最終のj増分を取消
            additionalValue /= 2 // j増分 半分 にする
            j += additionalValue // 新たなj増分を設定
            failedCount++ // 試行失敗回数を加算
          } else {
            // 実際の面積 <= 評価対象地の面積(目標の面積) のとき、実際の面積に反映。j増分は現在のままとする
            area = tempArea // 現在の面積を更新
            areaStr = (Math.round(tempArea * 100) / 100).toString() // 現在の面積文字列を更新
            failedCount = 0 // 試行失敗回数をリセット
          }
        }
        // 補正に失敗した場合、エラーメッセージを表示する
        if (areaStr !== correctAreaStr) {
          // 補正失敗の場合 return -1 とし、Correct.vue 側で判定 → エラー出力とする
          return -1
        }
      } else {
        for (let j = 1; area > correct.area; j -= 0.0000001) {
          area = this.scaleLength(plat, fixedVertexIndexes, distanceVertex, baseVertex, initVertex, j)
        }
      }
      let prevVertex
      plat.vertexes.forEach((vertex, index) => {
        prevVertex = (index === 0
          ? this.selectingPlat.vertexes[this.selectingPlat.vertexes.length - 1]
          : this.selectingPlat.vertexes[index - 1])

        vertex.distance = this.calcDistance(prevVertex, vertex)
        vertex.distance_meter = (vertex.distance / this.pixelsPerMeter) * imageScale
      })

      return area // m換算した面積を返す
    },
    /**
     * 二次方程式(ax^2+bx+c=0)
     * @param a
     * @param b
     * @param c
     * @returns {[number, number]}
     */
    quadraticEquation (a, b, c) {
      const plus = (Math.sqrt((b * b) - (4 * a * c)) - b) / (2 * a)
      const minus = -1 * (Math.sqrt((b * b) - (4 * a * c)) + b) / (2 * a)
      return [plus, minus]
    },
    /**
     * 頂点移動による面積計算
     * @param plat
     * @param fixedVertexIndexes
     * @param distanceVertex
     * @param baseVertex
     * @param initVertex
     * @param j
     * @returns {number}
     */
    scaleLength (plat, fixedVertexIndexes, distanceVertex, baseVertex, initVertex, j) {
      for (let i = 0; i < plat.vertexes.length; i++) {
        initVertex = plat.vertexes[i]
        if (fixedVertexIndexes.indexOf(i) === -1) {
          distanceVertex.x = initVertex.x - baseVertex.x
          distanceVertex.y = initVertex.y - baseVertex.y
          plat.vertexes[i].x = distanceVertex.x * j + baseVertex.x
          plat.vertexes[i].y = distanceVertex.y * j + baseVertex.y
        } else {
          plat.vertexes[i].x = initVertex.x
          plat.vertexes[i].y = initVertex.y
        }
      }
      const pixelArea = this.calcPlatArea(this.selectingPlat.vertexes)
      const area = pixelArea / Math.pow(this.pixelsPerMeter, 2) * Math.pow(this.measure.result_scale, 2)
      return area
    },
    /**
     * 辺の長さで補正する
     * @param vertexes
     * @param fixedVertexIndexes
     * @param measuredScale
     * @returns {number}
     */
    correctionLength (vertexes, fixedVertexIndexes, measuredScale) {
      let vertex
      let prevIndex
      let prevVertex
      let scale
      let distanceX
      let distanceY
      let centerX
      let centerY
      for (let i = 0; i < vertexes.length; i++) {
        vertex = vertexes[i]
        if (!vertex.distance_expected) {
          continue
        }

        prevIndex = (i === 0 ? vertexes.length - 1 : i - 1)
        prevVertex = vertexes[prevIndex]
        scale = vertex.distance_expected / vertex.distance_meter

        if (fixedVertexIndexes.indexOf(i) === -1 && fixedVertexIndexes.indexOf(prevIndex) === -1) {
          centerX = (vertex.x + prevVertex.x) / 2
          centerY = (vertex.y + prevVertex.y) / 2

          // 自分を伸ばす
          distanceX = vertex.x - centerX
          distanceY = vertex.y - centerY
          vertex.x = distanceX * scale + centerX
          vertex.y = distanceY * scale + centerY

          // 相手を伸ばす
          distanceX = prevVertex.x - centerX
          distanceY = prevVertex.y - centerY
          prevVertex.x = distanceX * scale + centerX
          prevVertex.y = distanceY * scale + centerY
        } else if (fixedVertexIndexes.indexOf(i) === -1) {
          // 自分を伸ばす
          distanceX = vertex.x - prevVertex.x
          distanceY = vertex.y - prevVertex.y
          vertex.x = distanceX * scale + prevVertex.x
          vertex.y = distanceY * scale + prevVertex.y
        } else if (fixedVertexIndexes.indexOf(prevIndex) === -1) {
          // 相手を伸ばす
          distanceX = prevVertex.x - vertex.x
          distanceY = prevVertex.y - vertex.y
          prevVertex.x = distanceX * scale + vertex.x
          prevVertex.y = distanceY * scale + vertex.y
        }

        // 頂点修正後に距離再計算
        for (let i = 0; i < vertexes.length; i++) {
          vertex = vertexes[i]
          prevIndex = (i === 0 ? vertexes.length - 1 : i - 1)
          prevVertex = vertexes[prevIndex]

          vertex.distance = this.calcDistance(prevVertex, vertex)
          vertex.distance_meter = (vertex.distance / this.pixelsPerMeter) * measuredScale
        }
      }

      const pixelArea = this.calcPlatArea(vertexes)
      const meterArea = pixelArea / Math.pow(this.pixelsPerMeter, 2) * Math.pow(measuredScale, 2)
      return meterArea
    },
    /**
     * セットバックを作成する
     *
     * @param vertexes
     * @param startIndex
     * @param endIndex
     * @param width
     * @returns {([{x, y},{x, y},{x, y},{x, y}])[]}
     */
    calcSetback (vertexes, startIndex, endIndex, width) {
      const startVertex = { ...vertexes[startIndex] }

      // 始点を(0, 0)に換算する
      vertexes.forEach(v => {
        v.x -= startVertex.x
        v.y -= startVertex.y
      })

      const angle = this.getAngleAndRotate(vertexes, startIndex, endIndex)

      // 始点<->終点からセットバック幅分離れた平行線を2本用意する
      const startPlusY = vertexes[startIndex].y + width
      const startMinusY = vertexes[startIndex].y - width
      const endPlusY = vertexes[endIndex].y + width
      const endMinusY = vertexes[endIndex].y - width

      // 平行線を図面の両端まで延長する
      const xList = vertexes.map(v => v.x)
      const minX = Math.min(...xList)
      const maxX = Math.max(...xList)

      const plusLine = [{ x: minX, y: startPlusY }, { x: maxX, y: endPlusY }]
      const minusLine = [{ x: maxX, y: endMinusY }, { x: minX, y: startMinusY }]

      // 各平行線と図面の辺の交点を取得する
      const plusCrossLine = []
      const minusCrossLine = []
      for (let i = 0; i < vertexes.length; i++) {
        const v1 = vertexes[i]
        const v2 = (i + 1) === vertexes.length ? vertexes[0] : vertexes[i + 1]

        const intersectionPlus = this.getIntersectionPoint(v1, v2, plusLine[0], plusLine[1])
        if (intersectionPlus) {
          plusCrossLine.push({ index: i + 0.5, point: intersectionPlus })
        }
        const intersectionMinus = this.getIntersectionPoint(v1, v2, minusLine[0], minusLine[1])
        if (intersectionMinus) {
          minusCrossLine.push({ index: i + 0.5, point: intersectionMinus })
        }
      }

      // セットバックの頂点を決める
      const crossLine = plusCrossLine.length >= 2 ? plusCrossLine : minusCrossLine
      crossLine.push({ index: startIndex, point: vertexes[startIndex] })
      crossLine.push({ index: endIndex, point: vertexes[endIndex] })

      // セットバックの間にある頂点を追加する
      const minY = Math.min(...crossLine.map(c => c.point.y))
      const maxY = Math.max(...crossLine.map(c => c.point.y))
      vertexes.forEach((v, i) => {
        if (minY <= v.y && v.y <= maxY && i !== startIndex && i !== endIndex) {
          crossLine.push({ index: i, point: v })
        }
      })

      let setbackVertexes = crossLine.sort((a, b) => b.index - a.index)
      setbackVertexes = setbackVertexes.map(s => s.point)

      // 元の座標に変換
      this.reRotate(setbackVertexes, angle, startVertex)
      return setbackVertexes
    },
    /**
     * 回転角度取得
     * @param vertexes
     * @param startIndex
     * @param endIndex
     * @returns {number}
     */
    getAngleAndRotate (vertexes, startIndex, endIndex) {
      let angle
      if (vertexes[endIndex].y !== vertexes[startIndex].y || vertexes[endIndex].x !== vertexes[startIndex].x) {
        // 基準線の傾きを算出する
        angle = -(Math.atan2(vertexes[endIndex].y - vertexes[startIndex].y, vertexes[endIndex].x - vertexes[startIndex].x))

        // 基準線を回転してX軸と並行にする
        let x
        let y
        vertexes.forEach(v => {
          x = (Math.cos(angle) * v.x) - (Math.sin(angle) * v.y)
          y = (Math.sin(angle) * v.x) + (Math.cos(angle) * v.y)
          v.x = x
          v.y = y
        })
      }

      return angle
    },
    /**
     * 再回転
     * @param vertexes
     * @param angle
     * @param startVertex
     */
    reRotate (vertexes, angle, startVertex) {
      if (typeof angle !== 'undefined') {
        vertexes.forEach(v => {
          const x = (Math.cos(-angle) * v.x) - (Math.sin(-angle) * v.y)
          const y = (Math.sin(-angle) * v.x) + (Math.cos(-angle) * v.y)
          v.x = x + startVertex.x
          v.y = y + startVertex.y
        })
      } else {
        vertexes.forEach(v => {
          v.x += startVertex.x
          v.y += startVertex.y
        })
      }
    },
    /**
     * 交点座標取得
     * @param v1
     * @param v2
     * @param v3
     * @param v4
     * @returns {[x, y]|number}
     */
    getIntersectionPoint (v1, v2, v3, v4) {
      const minusVertex = (va, vb) => {
        return { x: va.x - vb.x, y: va.y - vb.y }
      }
      const crossPoint = (va, vb) => {
        return va.x * vb.y - va.y * vb.x
      }

      const deno = crossPoint(minusVertex(v2, v1), minusVertex(v4, v3))
      if (deno === 0.0) {
        // 線分が平行
        return false
      }

      const s = crossPoint(minusVertex(v3, v1), minusVertex(v4, v3)) / deno
      const t = crossPoint(minusVertex(v2, v1), minusVertex(v1, v3)) / deno
      if (s < 0.0 || s >= 1.0 || t < 0.0 || t >= 1.0) {
        // 線分が交差していない
        return false
      }

      return { x: v1.x + s * minusVertex(v2, v1).x, y: v1.y + s * minusVertex(v2, v1).y }
    },
    /**
     * 想定整形地を作成する
     *
     * @param vertexes
     * @param startIndex
     * @param endIndex
     * @returns {([{x, y},{x, y},{x, y},{x, y}]|number)[]}
     */
    createAssumedRect (vertexes, startIndex, endIndex) {
      const replicateVertexes = []
      vertexes.forEach((v, i) => {
        replicateVertexes.push({ ...v })
      })

      const startVertex = { ...replicateVertexes[startIndex] }

      // 始点を(0, 0)に換算する
      replicateVertexes.forEach(v => {
        v.x -= startVertex.x
        v.y -= startVertex.y
      })

      const angle = this.getAngleAndRotate(replicateVertexes, startIndex, endIndex)

      // 多角形の頂点のMAX/MINを求める
      const max = { ...replicateVertexes[0] }
      const min = { ...replicateVertexes[0] }
      replicateVertexes.forEach(v => {
        if (max.x < v.x) {
          max.x = v.x
        }
        if (max.y < v.y) {
          max.y = v.y
        }
        if (min.x > v.x) {
          min.x = v.x
        }
        if (min.y > v.y) {
          min.y = v.y
        }
      })

      // 4つの頂点に変換する
      const rectVertexes = [
        { x: max.x, y: min.y },
        { x: max.x, y: max.y },
        { x: min.x, y: max.y },
        { x: min.x, y: min.y }
      ]

      // 間口(X軸の長さ)を取得する
      const frontageDistance = max.x - min.x

      // 奥行(Y軸の長さ)を取得する
      const depthDistance = max.y - min.y

      // 元の多角形を囲む長方形の座標に変換
      this.reRotate(rectVertexes, angle, startVertex)

      return [rectVertexes, depthDistance, frontageDistance]
    },
    /**
     * 色反転
     * @param canvasContent
     * @param canvasWidth
     * @param canvasHeight
     */
    colorInversion (canvasContent, canvasWidth, canvasHeight = 600) {
      const imageData = canvasContent.getImageData(0, 0, canvasWidth, canvasHeight)
      const d = imageData.data
      for (let i = 0; i < d.length; i += 4) {
        d[i] = 255 - d[i]
        d[i + 1] = 255 - d[i + 1]
        d[i + 2] = 255 - d[i + 2]
      }
      canvasContent.putImageData(imageData, 0, 0)
    },
    /**
     * 貼り合わせる辺をハイライト
     * @param canvasContext
     * @param vertexes
     * @param scale
     * @param relativePosition
     * @param fillColor
     * @param startPasteVtx
     * @param endPasteVtx
     * @param isDefaultColor
     */
    drawPastingPlat (canvasContext, vertexes, scale, relativePosition, fillColor, startPasteVtx, endPasteVtx, isDefaultColor = true) {
      if (!fillColor) {
        fillColor = `${colors.platColorRgba(isDefaultColor)}`
      }

      // 貼り合わせる辺をハイライト
      if (Number.isInteger(startPasteVtx) && Number.isInteger(endPasteVtx)) {
        canvasContext.beginPath()
        canvasContext.lineWidth = 2
        const startX = (vertexes[startPasteVtx].x - relativePosition.x) * scale
        const startY = (vertexes[startPasteVtx].y - relativePosition.y) * scale
        const endX = (vertexes[endPasteVtx].x - relativePosition.x) * scale
        const endY = (vertexes[endPasteVtx].y - relativePosition.y) * scale
        canvasContext.moveTo(startX, startY)
        canvasContext.lineTo(endX, endY)
        canvasContext.closePath()
        canvasContext.strokeStyle = `${colors.highlightColorRgba(isDefaultColor)}`
        canvasContext.stroke()
      }

      // 距離表示
      vertexes.forEach((vertex, vertexIndex) => {
        const x = (vertex.x - relativePosition.x) * scale
        const y = (vertex.y - relativePosition.y) * scale

        if (vertex.distance_meter) {
          const prevVertex = vertexes[(vertexIndex === 0) ? vertexes.length - 1 : vertexIndex - 1]
          const prevX = (prevVertex.x - relativePosition.x) * scale
          const prevY = (prevVertex.y - relativePosition.y) * scale
          const textX = (x + prevX) / 2
          const textY = (y + prevY) / 2
          const textValue = (Math.round(vertex.distance_meter * 100) / 100) + ''

          const bgHeight = 9 * 1.5 + 5
          const bgWidth = canvasContext.measureText(textValue).width + 5
          const bgY = textY - bgHeight
          const bgX = textX - (bgWidth / 2)

          canvasContext.fillStyle = 'white'
          canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
          canvasContext.fillStyle = 'blue'
          canvasContext.fillText(textValue, textX, textY)
        }
      })
    },
    /**
     * GPXデータによる距離計算
     * @param lat1
     * @param lon1
     * @param lat2
     * @param lon2
     * @returns number
     */
    calcDist (lat1, lon1, lat2, lon2) {
      const r = 6378137
      return r * Math.acos(Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1))
    },
    /**
     * 頂点座標による距離計算
     * @param sx
     * @param sy
     * @param ex
     * @param ey
     * @returns number
     */
    calcDistanceDot2Dot (sx, sy, ex, ey) {
      return (ex - sx) * (ex - sx) + (ey - sy) * (ey - sy)
    },
    /**
     * 直線の傾きと切片を計算
     * @param sx
     * @param sy
     * @param ex
     * @param ey
     * @returns {[tilt, intcpt]|number}
     */
    calcTilt (sx, sy, ex, ey) {
      const tilt = (ey - sy) / (ex - sx)
      const intercept = sy - sx * tilt
      return { tilt: tilt, intcpt: intercept }
    },
    /**
     * 頂点と直線の距離計算
     * @param sx
     * @param sy
     * @param ex
     * @param ey
     * @param dx
     * @param dy
     * @returns number
     */
    calcDistanceDot2Line (sx, sy, ex, ey, dx, dy) {
      const x1 = ex - sx
      const x2 = dx - sx
      const y1 = ey - sy
      const y2 = dy - sy
      const num = x1 * y2 - y1 * x2
      const result = num * num / (x1 * x1 + y1 * y1)
      return result
    },
    /**
     * 長方形選択モードによる頂点作成
     * @param sx
     * @param sy
     * @param ex
     * @param ey
     * @returns {[{x, y},{x, y},{x, y},{x, y}]|number}
     */
    dragPlot (sx, sy, ex, ey) {
      // 始点終点から長方形の各頂点の座標を算出して返す
      const vertexes = []
      vertexes.push({ x: sx, y: sy })
      vertexes.push({ x: ex, y: sy })
      vertexes.push({ x: ex, y: ey })
      vertexes.push({ x: sx, y: ey })
      return vertexes
    }
  }
}
