import axios from 'axios'

const state = {
  loadingStatus: false,
  mainId: null,
  shouldReloadMap: false,
  markers: [],
  isGoogleMapUsed: false,
  gsCoordinates: [],
  zoom: 18
}

const getters = {
  getMarkers (state) {
    return state.markers
  },
  getCoordinates (state) {
    return state.gsCoordinates
  },
  getZoomLevel (state) {
    return state.zoom
  }
}

const mutations = {
  setLoadingStatus (state, data) {
    state.loadingStatus = data
  },
  setMainId (state, data) {
    state.mainId = data
  },
  addMarker (state, data) {
    state.markers.push(data)
  },
  addGSCoordinates (state, data) {
    state.gsCoordinates.push(data)
  },
  updateMarker (state, data) {
    state.markers.splice(data.index, 1, {
      lat: data.lat,
      lng: data.lng
    })
  },
  updateGSCoordinates (state, data) {
    state.gsCoordinates.splice(data.index, 1, {
      x: data.x,
      y: data.y,
      lat: data.lat,
      lon: data.lon
    })
  },
  insertMarker (state, data) {
    if (data.index !== -1) {
      state.markers.splice(data.index, 0, {
        lat: data.lat,
        lng: data.lng
      })
    }
  },
  insertGSCoordinates (state, data) {
    if (data.index !== -1) {
      state.gsCoordinates.splice(data.index, 0, {
        x: data.x,
        y: data.y,
        lat: data.lat,
        lon: data.lon
      })
    }
  },
  setMarkers (state, data) {
    state.markers.push(data)
  },
  setGSCoordinates (state, data) {
    state.gsCoordinates.push(data)
  },
  clearMarkers (state) {
    state.markers = []
  },
  clearGSCoordinates (state) {
    state.gsCoordinates = []
  },
  updateReloadFlag (state, status) {
    state.shouldReloadMap = status
  },
  updateGoogleMapDisplayFlag (state, status) {
    state.isGoogleMapUsed = status
  },
  updateZoomLevel (state, status) {
    state.zoom = status
  }
}

const actions = {
  setMainId (context, data) {
    context.commit('setMainId', data)
  },
  updateResetFlag (context, state) {
    context.commit('clearMarkers')
    context.commit('updateResetFlag', false)
  },
  async addMarker (context, data) {
    context.commit('setLoadingStatus', true)
    const response = await axios.get('/api/kagechi/gpx', {
      params: {
        lon: data.latLng.toJSON().lng,
        lat: data.latLng.toJSON().lat
      }
    })
    const gsData = {
      x: response.data.x,
      y: response.data.y,
      lat: data.latLng.toJSON().lat,
      lon: data.latLng.toJSON().lng
    }
    context.commit('addGSCoordinates', gsData)
    context.commit('addMarker', {
      lat: data.latLng.toJSON().lat,
      lng: data.latLng.toJSON().lng
    })
    context.commit('setLoadingStatus', false)
  },
  async setGSCoordinates (context, data) {
    context.commit('setLoadingStatus', true)
    context.commit('clearGSCoordinates')
    for (const row of data) {
      const response = await axios.get('/api/kagechi/gpx', {
        params: {
          lon: row.lng,
          lat: row.lat
        }
      })
      const gsData = {
        x: response.data.x,
        y: response.data.y,
        lat: row.lat,
        lon: row.lng
      }
      context.commit('setGSCoordinates', gsData)
    }
    context.commit('setLoadingStatus', false)
  },
  async updateMarker (context, data) {
    context.commit('setLoadingStatus', true)
    const response = await axios.get('/api/kagechi/gpx', {
      params: {
        lon: data.latLng.lng,
        lat: data.latLng.lat
      }
    })
    const gsData = {
      index: data.index,
      x: response.data.x,
      y: response.data.y,
      lat: data.latLng.lat,
      lon: data.latLng.lng
    }
    context.commit('updateGSCoordinates', gsData)
    context.commit('updateMarker', {
      index: data.index,
      lat: data.latLng.lat,
      lng: data.latLng.lng
    })
    context.commit('setLoadingStatus', false)
  },
  async insertMarker (context, data) {
    context.commit('setLoadingStatus', true)
    const response = await axios.get('/api/kagechi/gpx', {
      params: {
        lon: data.latLng.lng,
        lat: data.latLng.lat
      }
    })
    const gsData = {
      index: data.index,
      x: response.data.x,
      y: response.data.y,
      lat: data.latLng.lat,
      lon: data.latLng.lng
    }
    context.commit('insertGSCoordinates', gsData)
    context.commit('insertMarker', {
      index: data.index,
      lat: data.latLng.lat,
      lng: data.latLng.lng
    })
    context.commit('setLoadingStatus', false)
  },
  async repopulateMarkers (context, data) {
    for (const row of data) {
      context.commit('setMarkers', {
        lat: row.lat,
        lng: row.lon
      })
    }
  },
  async repopulateGSCoordinates (context, data) {
    context.commit('setLoadingStatus', true)
    for (const row of data) {
      const gsData = {
        x: row.x,
        y: row.y,
        lat: row.lat,
        lon: row.lon
      }
      context.commit('setGSCoordinates', gsData)
    }
    context.commit('setLoadingStatus', false)
  },
  deleteMarkers (context) {
    context.commit('clearMarkers')
  },
  deleteGSCoordinates (context) {
    context.commit('clearGSCoordinates')
  },
  reloadMap (context, data) {
    context.commit('updateReloadFlag', data)
  },
  updateReloadFlag (context, data) {
    context.commit('updateReloadFlag', data)
  },
  updateGoogleMapDisplayFlag (context, data) {
    context.commit('updateGoogleMapDisplayFlag', data)
  },
  updateZoomLevel (context, data) {
    context.commit('updateZoomLevel', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
