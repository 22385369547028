<template>
  <div>
    <div class="text-right" id="full-width-section">
      <div class="text-floating-left">かげ地計算</div>
      <b-button variant="outline-secondary" class="px-5" @click="$router.push('/menu')">戻る</b-button>
      <b-button variant="primary" v-if="(files.length === 0) && mainId" class="px-5 ml-4" @click="startUpload">アップロード済みのファイルで進む</b-button>
      <b-button variant="primary" v-else-if="mainId" class="px-5 ml-4" :disabled="files.length === 0 && files_buildings.length === 0" v-b-modal.restart-confirm>決定</b-button>
      <b-button variant="primary" v-else class="px-5 ml-4" :disabled="files.length === 0 && files_buildings.length === 0" v-b-modal.register_title_plat>決定</b-button>
    </div>

    <div class="mt-3">
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>

    <b-container id="kagechi-upload">
      <h5 class="text-left header-top-green">公図、測量図など土地書類のPDFをアップロード</h5>

      <b-alert variant="danger" :show="!!errors.files && errors.files.includes('[土地]')">
        <p>ファイルが保存できませんでした。以下のメッセージを確認し、再度操作してください。</p>
        <div class="pl-4">{{ errors.files }}</div>
      </b-alert>

      <b-list-group @dragenter="onDragEnter" @dragleave="onDragLeave" @dragover.prevent="onDragEnter" @drop.prevent="selectedFile" :class="{ dragging: files.length > 0 }">
        <b-list-group-item v-if="files.length === 0" class="empty">
          <div class="top-spacing"><img class="upload-icon" src="../../assets/images/uploading.png"></div>
          ここにファイルをドロップ
        </b-list-group-item>
        <div class="text-center">
          <b-button v-if="files.length === 0" variant="secondary" class="my-4 px-5" @click="onSelectFile">＋ またはファイルを選択</b-button>
          <input type="file" style="display: none;" multiple ref="file" @change="selectedFile" />
        </div>
        <b-list-group-item v-for="(f, index) in files" :key="index" class="d-flex justify-content-between align-items-center" :variant="getValiant(index)">
          <div>
            <div>{{ f.name }}({{ (f.size / 1024 / 1024).toFixed(2) }}MB)</div>
            <div class="text-danger" v-if="results[index] && !results[index].result">{{ results[index].message }}</div>
          </div>
          <div>
            <b-button variant="light" @click="deleteFile(index)"><b-icon icon="file-earmark-x" aria-hidden="true"/></b-button>
          </div>
        </b-list-group-item>
      </b-list-group>

      <b-modal id="restart-confirm" hide-header @ok="startUpload">
        <div>前回アップロードされたファイルは削除され、現在選択中のファイルのみで測定を進めます。よろしいですか？</div>
      </b-modal>

      <b-modal id="register_title_plat" hide-header @ok="startUpload">
        <div>タイトルを入力してください。<br>入力がない場合は「名称未設定-現在時刻」が適用されます。</div>
        <b-form-input v-model="title" maxlength="50"></b-form-input>
      </b-modal>
    </b-container>

    <b-container id="kagechi-upload">
      <h5 class="text-left header-top-green">建物等の重ねる画像のPDFをアップロード</h5>

      <b-alert variant="danger" :show="!!errors.files && errors.files.includes('[建物]')">
        <p>ファイルが保存できませんでした。以下のメッセージを確認し、再度操作してください。</p>
        <div class="pl-4">{{ errors.files }}</div>
      </b-alert>

      <b-list-group @dragenter="onDragEnter" @dragleave="onDragLeave" @dragover.prevent="onDragEnter" @drop.prevent="selectedFile_buildings" :class="{ dragging: files_buildings.length > 0 }">
        <b-list-group-item v-if="files_buildings.length === 0" class="empty">
          <div class="top-spacing"><img class="upload-icon" src="../../assets/images/uploading.png"></div>
          ここにファイルをドロップ
        </b-list-group-item>
        <div class="text-center">
          <b-button v-if="files_buildings.length === 0" variant="secondary" class="my-4 px-5" @click="onSelectFile_buildings">＋ またはファイルを選択</b-button>
          <input type="file" style="display: none;" multiple ref="file_buildings" @change="selectedFile_buildings" />
        </div>
        <b-list-group-item v-for="(f, index) in files_buildings" :key="index" class="d-flex justify-content-between align-items-center" :variant="getValiant(index)">
          <div>
            <div>{{ f.name }}({{ (f.size / 1024 / 1024).toFixed(2) }}MB)</div>
            <div class="text-danger" v-if="results[index] && !results[index].result">{{ results[index].message }}</div>
          </div>
          <div>
            <b-button variant="light" @click="deleteFile_buildings(index)"><b-icon icon="file-earmark-x" aria-hidden="true"/></b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <b-modal id="register_title_buildings" hide-header @ok="startUpload">
        <div>タイトルを入力してください。<br>入力がない場合は「名称未設定-現在時刻」が適用されます。</div>
        <b-form-input v-model="title" maxlength="50"></b-form-input>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import validation from '../../mixins/validation'
import constants from '../../constants'

export default {
  props: ['mainId'],
  name: 'Upload',
  mixins: [validation],
  data: () => ({ allFiles: [], files: [], files_buildings: [], results: [], dragging: false, title: '' }),
  methods: {
    onDragEnter (e) {
      this.dragging = true
      e.currentTarget.classList.add('dragging')
    },
    onDragLeave (e) {
      this.dragging = false
      e.currentTarget.classList.remove('dragging')
    },
    onSelectFile () {
      this.$refs.file.click()
    },
    onSelectFile_buildings () {
      this.$refs.file_buildings.click()
    },
    selectedFile (event) {
      let noError = true
      const files = event.target.files || event.dataTransfer.files

      this.onDragLeave(event)

      for (let i = 0; i < files.length; i++) {
        if (!files[i].name.toLowerCase().endsWith('.pdf')) {
          // 拡張子チェック
          noError = false
          this.errors = { error: 'アップロードできるファイルはPDFファイルのみです。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
        } else if (files[i].size === 0) {
          // 空ファイルチェック
          noError = false
          this.errors = { error: '空のファイルはアップロードできません。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      }

      if (noError) {
        for (let i = 0; i < files.length; i++) {
          // リネーム
          const fileName = '[土地]' + files[i].name
          const blob = files[i].slice(0, files[i].size, files[i].type)
          const renamedFile = new File([blob], fileName, { type: files[i].type })
          this.files.push(renamedFile)
        }
      }
    },
    selectedFile_buildings (event) {
      let noError = true
      const files = event.target.files || event.dataTransfer.files

      this.onDragLeave(event)

      for (let i = 0; i < files.length; i++) {
        if (!files[i].name.toLowerCase().endsWith('.pdf')) {
          // 拡張子チェック
          noError = false
          this.errors = { error: 'アップロードできるファイルはPDFファイルのみです。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
        } else if (files[i].size === 0) {
          // 空ファイルチェック
          noError = false
          this.errors = { error: '空のファイルはアップロードできません。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      }

      if (noError) {
        for (let i = 0; i < files.length; i++) {
          // リネーム
          const fileName = '[建物]' + files[i].name
          const blob = files[i].slice(0, files[i].size, files[i].type)
          const renamedFile = new File([blob], fileName, { type: files[i].type })
          this.files_buildings.push(renamedFile)
        }
      }
    },
    deleteFile (index) {
      this.files.splice(index, 1)
    },
    deleteFile_buildings (index) {
      this.files_buildings.splice(index, 1)
    },
    startUpload () {
      this.allFiles.splice(0)
      this.$emit('loading', true)
      const formData = new FormData()
      // this.files.forEach((f, index) => {
      //   formData.append(`files_${index}`, f)
      // })
      // this.files_buildings.forEach((f, index) => {
      //   formData.append(`files_${index}`, f)
      // })
      this.files_buildings.forEach(f => {
        this.allFiles.push(f)
      })
      this.files.forEach(f => {
        this.allFiles.push(f)
      })
      this.allFiles.forEach((f, index) => {
        formData.append(`files_${index}`, f)
      })

      if (this.files.length > 10) {
        this.errors = { error: 'アップロードできるPDFの枚数は10枚までです。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.$emit('loading', false)
        return
      }

      const config = { headers: { 'content-type': 'multipart/form-data' } }
      const mainId = this.mainId ? this.mainId : ''
      const title = this.title ? this.title : ''
      if (mainId !== '') { formData.append('main_id', mainId) }
      if (title !== '') { formData.append('title', title) }
      this.axios.post('/api/kagechi/upload', formData, config).then(res => {
        this.$emit('loading', false)
        this.$router.push('/kagechi/trimming/' + res.data.main_id)
      }).catch(error => {
        this.$emit('loading', false)
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
      })
    },
    getValiant (index) {
      if (!this.results[index]) {
        return ''
      }

      if (this.results[index].result) {
        return 'success'
      }

      return 'danger'
    }
  }
}
</script>

<style scoped>
</style>
