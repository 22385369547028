<template>
  <div>
    <div class="position-relative">

      <div
        v-if="isSearchShown"
        class="d-flex justify-content-end position-absolute z-5"
        style="right: 10px; top: 10px;">
        <b-form @submit.prevent="searchLocation">
          <div class="d-flex">
            <input
              size="sm"
              type="text"
              ref="addressInput"
              placeholder="Googleマップを検索する"
              class="border-0 form-control rounded-r-none w-220"
            />
            <b-button
              type="submit"
              variant="primary"
              size="sm"
              class="px-4 rounded-l-none"
            >検索
            </b-button>
          </div>
        </b-form>
      </div>

      <div
        class="google-map"
        ref="googleMap"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import GoogleMapsApiLoader from 'google-maps-api-loader'
import mapSettings from '../map_settings'

export default {
  name: 'GoogleMap',

  props: {
    isSearchShown: Boolean
  },

  data () {
    return {
      center: { lat: 35.685785160098504, lng: 139.75277627645426 },
      google: null,
      map: null,
      polyline: null,
      polygon: null,
      geocoder: null
    }
  },

  async mounted () {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: mapSettings.API_KEY
    })
    this.google = googleMapApi

    this.updateReloadFlag(false)

    this.initializeMap()
  },

  computed: {
    ...mapState({
      status: state => state.googleMap.shouldReloadMap
    }),

    markers () {
      return this.$store.getters['googleMap/getMarkers']
    },

    mapConfig () {
      return {
        ...mapSettings.MAP_CONFIG,
        center: this.mapCenter
      }
    },

    mapCenter () {
      if (this.markers.length === 0) {
        return new this.google.maps.LatLng(this.center.lat, this.center.lng)
      } else {
        return new this.google.maps.LatLng(this.markers[0].lat, this.markers[0].lng)
      }
    },

    polygonConfig () {
      return {
        ...mapSettings.POLYGON_CONFIG,
        map: this.map
      }
    }
  },

  watch: {
    status (value) {
      if (value) {
        this.initializeMap()
        this.updateReloadFlag(!value)
      }
    },
    markers () {
      if (this.markers.length === 1) {
        this.reloadMap(true)
      }
    }
  },

  methods: {
    ...mapActions('googleMap',
      [
        'updateReloadFlag',
        'addMarker',
        'updateMarker',
        'insertMarker',
        'reloadMap',
        'updateZoomLevel'
      ]
    ),

    initializeMap () {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig)

      if (this.markers.length === 0) {
        this.map.setZoom(18)
        this.map.setTilt(0)
      }

      // デファルトの45°傾きの無効する
      this.map.setTilt(0)

      // 検索ボックス
      this.geocoder = new this.google.maps.Geocoder()

      var polygonOptions = {
        ...this.polygonConfig,
        path: this.markers
      }

      if (this.markers.length > 0) {
        this.polygon = new this.google.maps.Polygon(polygonOptions)

        // ポリゴンを中央に合わせるためにポリゴンの境界を作成
        const bounds = new this.google.maps.LatLngBounds()

        this.polygon.getPath().forEach(function (path, index) {
          bounds.extend(path)
        })

        this.map.fitBounds(bounds)

        this.google.maps.event.addListener(this.map, 'click', (event) => {
          this.addLatLngToPolygon(event)
        })

        this.google.maps.event.addListener(this.map, 'zoom_changed', (event) => {
          this.updateZoomLevel(this.map.zoom)
        })

        this.addListenerSetAtToPolygonPath(this.polygon)

        this.google.maps.event.addListener(this.polygon, 'dragstart', () => {
          // set_atイベントをクリア
          this.google.maps.event.clearListeners(this.polygon.getPath(), 'set_at')
        })

        this.google.maps.event.addListener(this.polygon, 'dragend', () => {
          // drag完了した時点すべての頂点を更新
          const polygonPathCount = this.polygon.getPath().length ?? 0
          for (let i = 0; i < polygonPathCount; i++) {
            this.moveMarker(this.polygon.getPath().getArray(), i)
          }
          // set_atイベントをaddListener
          this.addListenerSetAtToPolygonPath(this.polygon)
        })

        this.google.maps.event.addListener(this.polygon.getPath(), 'insert_at', (event) => {
          if (event <= this.markers.length) {
            this.insertMarkerAt(event)
          }
        })
      } else {
        this.polyline = new this.google.maps.Polyline(polygonOptions)

        this.google.maps.event.addListener(this.map, 'click', (event) => {
          this.addLatLng(event)
        })

        this.google.maps.event.addListener(this.map, 'zoom_changed', (event) => {
          this.updateZoomLevel(this.map.zoom)
        })
      }
    },

    searchLocation () {
      const addressInput = this.$refs.addressInput

      this.geocoder
        .geocode({ address: addressInput.value })
        .then((result) => {
          const { results } = result

          this.map.setCenter(results[0].geometry.location)
          return results
        })
        .catch((e) => {
          alert('Geocode was not successful for the following reason: ' + e)
        })
    },

    async addLatLng (event) {
      if (this.markers.length === 0) {
        await this.addMarker(event)
      }
    },

    addLatLngToPolygon (event) {
      const path = this.polygon.getPath()
      path.push(event.latLng)
      if (this.markers.length === 0) {
        this.addMarker(event)
      }
    },

    moveMarker (polygonPath, vertex) {
      if (vertex != null) {
        const data = {
          index: vertex,
          latLng: polygonPath[vertex].toJSON()
        }
        this.updateMarker(data)
      }
    },

    insertMarkerAt (vertex) {
      const polygonPoints = this.polygon.getPath().getArray()
      const data = {
        index: vertex,
        latLng: polygonPoints[vertex].toJSON()
      }
      this.insertMarker(data)
    },

    addListenerSetAtToPolygonPath (polygonArea) {
      this.google.maps.event.addListener(polygonArea.getPath(), 'set_at', (event) => {
        this.moveMarker(polygonArea.getPath().getArray(), event)
      })
    }
  }
}
</script>

<style scoped>
.google-map {
  width: 100%;
  min-height: 600px;
}
.z-5 {
  z-index: 5;
}
.bg-black-transparent {
  background-color: rgba(0,0,0,.2);
}
.w-220 {
  width: 220px;
}
.rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
</style>
