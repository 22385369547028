<template>

  <section id="news" class="bg-grey  [ padding-top--48  padding-top-medium--88 ]  [ padding-bottom--48  padding-bottom-medium--88 ]">
    <h2 class="[ text-center  text-3xl font-semibold  uppercase ]  margin-bottom--16">News</h2>
    <div class="container">

      <ul class="bg-white  [ list  list--bordered ]  [ padding-top--48  padding--24 ]  [ margin-left-medium--80  margin-right-medium--80 ]  overflow-auto  max-h--180">
        <template v-if="news.length === 0">
          <p class="[ text-sm  leading-6 font-semibold  text-center ]">現在、ニュースはありません。</p>
        </template>

        <template v-else>
          <li v-for="(post, index) in news" v-bind:key="index">
              <p class="[ border-bottom  border-radius-0 ]  [ text-sm  leading-6 font-semibold ]  padding-bottom--8">
                <span class="padding-right--16">{{ post.updated_at }}</span>
                {{ post.title }}
              </p>
              <div class="[ padding-top--8  padding-top-medium--0 ]  font-normal  border-bottom-0  [ text-xs  leading-6 ]" v-html="post.content"></div>
          </li>
        </template>
      </ul>

    </div>
  </section>

</template>

<script>
export default {
  name: 'NewsSection',

  data () {
    return {
      news: []
    }
  },

  mounted () {
    this.getNews()
  },

  methods: {
    getNews () {
      this.axios.get('/api/news/index').then(response => {
        this.news = response.data
      })
    }
  }
}
</script>

<style>
.bg-grey { background-color: #f4f4f4; }

.padding--24 { padding: 24px; }

.padding-right--16 { padding-right: 16px;}

.padding-top--48 { padding-top: 48px; }

.padding-bottom--8 { padding-bottom: 8px; }

.padding-bottom--48 { padding-bottom: 48px; }

.margin-bottom--8 { margin-bottom: 8px; }

.margin-bottom--16 { margin-bottom: 16px; }

.max-h--180 { max-height: 180px; }

.overflow-auto { overflow: auto; }

.list { list-style: none; }

.list--bordered li {
  border-top: solid 1px #D3D4D5;
  padding: 0;
  padding-top: 1em;
}

.list--bordered li:first-child {
  border-top: 0;
  padding-top: 0;
}

.border-radius-0 { border-radius: 0; }

.border-bottom { border-bottom: 1px solid #D3D4D5; }

.border-left-0 { border-left: 0 !important; }

.text-xs { font-size: .75rem; }

.text-sm { font-size: .875rem; }

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.leading-6 { line-height: 1.5rem; }

.font-normal { font-weight: 400; }

.font-semibold { font-weight: 600; }

.uppercase { text-transform: uppercase; }

@media (min-width: 768px) {
  .padding-top-medium--88 { padding-top: 88px; }

  .padding-bottom-medium--88 { padding-bottom: 88px; }

  .margin-left-medium--80 { margin-left: 80px; }

  .margin-right-medium--80 { margin-right: 80px; }
}
</style>
