import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue from 'vue'

import vue from '../main'
import constants from '../constants'
import store from '../store'

Vue.use(VueAxios, axios)

axios.defaults.headers.common['X-CsrfToken'] = document.querySelector(
  'input[name="csrfmiddlewaretoken"]').getAttribute('value')

const gotoLogin = () => {
  store.dispatch('alert/show', { variant: 'danger', message: constants.MESSAGE.SESSION_TIMEOUT })
  vue.$router.push({ path: '/login', query: { redirect: vue.$route.path } })
}

axios.interceptors.response.use(response => {
  return response
}, error => {
  // エラー時の処理
  if (error.response.status === constants.HTTP_RESPONSE_CODE.UNAUTHORIZED) {
    store.dispatch('user/logout')
    gotoLogin()
  }

  return Promise.reject(error)
})
