<template>
  <div>
    <h4 class="text-center my-4">パスワードリセット</h4>
    <div class="d-flex justify-content-center">
      <div class="col-md-6" v-if="!done">
        <b-card>
          <p class="text-danger" v-if="errors.non_field_errors">{{ errors.non_field_errors[0] }}</p>

          <b-form @submit.prevent="onSubmit">
            <b-form-group label="ユーザID/メールアドレス" label-for="username">
              <b-form-input id="username" v-model="username" required :state="isValid(['non_field_errors', 'username'])" autofocus />
              <b-form-invalid-feedback v-if="errors.username" :state="isValid(['non_field_errors', 'username'])">
                {{ errors.username[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

            <div class="text-center mb-3">
              <b-button type="submit" variant="primary" pill class="px-4">メールを送信する</b-button>
            </div>
          </b-form>
        </b-card>
      </div>
      <b-alert :show="done" variant="success">
        アカウントが存在する場合、パスワードの設定手順をメールで送信しました。 <br>
        メールが届かない場合は、登録したアドレスを入力したことを確認し、スパムフォルダーを確認してください。
      </b-alert>
    </div>
  </div>
</template>

<script>
import Validation from '../../mixins/validation'
import constants from '../../constants'

export default {
  name: 'PasswordResetForm1',
  mixins: [Validation],
  data () {
    return {
      username: '',
      done: false
    }
  },
  methods: {
    onSubmit () {
      this.$emit('loading', true)
      const form = { username: this.username }
      this.axios.post('/api/account/password_reset', form).then(res => {
        this.$emit('loading', false)
        this.done = true
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.$emit('loading', false)
          this.validated(error.response.data)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
