<template>
  <div id="menu">
    <h4 class="text-center menu-title mt-5">MENU</h4>
    <div class="d-flex flex-wrap justify-content-center my-3">
      <div>
        <b-button variant="primary" @click="$bvModal.show(`plotMenu`)" class="mx-4">
          <b-modal :id="`plotMenu`" title="土地情報の取り込み／作図" hide-footer>
            <b-button
              class="w-100 my-2"
              size="lg"
              v-for="(menu, index) in menuOption"
              v-bind:key="index"
              variant="outline-primary"
              @click="menuPlotFlag = menu.value;menuPlotSet();"
              :disabled="menu.disabled"
              >
              <b-icon-award v-if="menu.disabled" />
              {{ menu.text }}
            </b-button>
          </b-modal>
          <img src="../assets/images/menu_kagechi.png">
        </b-button>
        <p class="menu-caption text-center mt-4 mx-4">かげ地計算</p>
      </div>
      <div>
        <b-button variant="primary" href="/kagechi/list" class="mx-4">
          <img src="../assets/images/menu_list.png">
        </b-button>
        <p class="menu-caption text-center mt-4 mx-4">測定結果</p>
      </div>
      <div v-b-tooltip.hover :title="tooltipTitle">
        <b-button :disabled="isExpiration" @click="gotoGPXEdit" variant="primary" class="mx-4 icon-btn" :style="{ 'pointer-events': isExpiration ? 'none' : 'auto' }">
          <img src="../assets/images/menu_gpxEdit.png">
          <b-icon-award-fill v-if="isExpiration" class="freemium-icon icon" font-scale="2"/>
        </b-button>
        <p class="menu-caption text-center mt-4 mx-4">GPX編集</p>
      </div>
      <div v-b-tooltip.hover :title="tooltipTitle">
        <b-button :disabled="isExpiration" @click="gotoColorSetting" variant="primary" class="mx-4 icon-btn" :style="{ 'pointer-events': isExpiration ? 'none' : 'auto' }">
          <img src="../assets/images/color.png">
          <b-icon-award-fill v-if="isExpiration" class="freemium-icon icon" font-scale="2"/>
        </b-button>
        <p class="menu-caption text-center mt-4 mx-4">配色設定</p>
      </div>
      <div>
        <b-button @click="gotoCustomerPortal" variant="primary" class="mx-4 icon-btn">
          <img src="../assets/images/menu_payment.png">
        </b-button>
        <p class="menu-caption text-center mt-4 mx-4">決済情報</p>
      </div>
    </div>

    <news-section></news-section>

    <div class="footer-image">
      <img src="../assets/images/TOP_footer.png">
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import constants from '../constants'
import NewsSection from '../components/NewsSection'
import colors from '../colors'

export default {
  name: 'Menu',

  components: {
    NewsSection
  },

  data: () => ({
    color: colors.color, // これを書いておかないと、colors 変数使っていないとのエラーになる
    menuOption: [
      {
        value: 0,
        text: 'PDFファイルを取り込む',
        disabled: false
      },
      {
        value: 1,
        text: 'GPXデータを取り込む',
        disabled: true
      },
      {
        value: 2,
        text: 'フリーで作図する',
        disabled: true
      },
      {
        value: 3,
        text: 'Google Mapで作図する（β版）',
        disabled: true
      }
    ],
    menuPlotFlag: 0,
    background: [],
    files: [],
    isExpiration: false,
    tooltipTitle: ''
  }),
  mounted () {
    const username = this.$store.getters['user/user'].username
    const url = `/api/account/stripeExpiration/${username}`
    this.axios.post(url).then(res => {
      this.confirmed = true
      this.error_message = ''
      this.isExpiration = res.data.isExpiration
      this.menuOption[1].disabled = this.isExpiration
      this.menuOption[2].disabled = this.isExpiration
      this.menuOption[3].disabled = this.isExpiration
      if (this.isExpiration) {
        this.tooltipTitle = '有料版でご利用になれます'
      }
      this.$emit('loading', false)
    }).catch(error => {
      if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
        this.$emit('loading', false)
        this.error_message = error.response.data.error
        this.invalid_url = error.response.data.invalid_url
      }
    })
  },
  methods: {
    ...mapActions('googleMap', ['updateGoogleMapDisplayFlag']),
    menuPlotSet () {
      if (this.menuPlotFlag === 0) {
        // PDFをアップ
        this.$router.push('/kagechi/upload')
      } else if (this.menuPlotFlag === 1) {
        // GPXをアップ
        this.$router.push('/kagechi/uploadGPX')
      } else if (this.menuPlotFlag === 2 || this.menuPlotFlag === 3) {
        let isGoogleMapUsed
        if (this.menuPlotFlag === 3) {
          isGoogleMapUsed = true
        } else {
          isGoogleMapUsed = false
        }
        // フリーハンドで作図
        // IDの発行等を済まして遷移
        const makeRequest = (options) => {
          return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            xhr.open(options.method, options.url, true)
            xhr.responseType = 'arraybuffer'
            xhr.onload = () => {
              if (xhr.status === 200) {
                resolve(xhr.response)
              } else {
                reject(new Error(xhr.statusText))
              }
            }
            xhr.onerror = () => {
              reject(new Error(xhr.statusText))
            }
            xhr.send()
          })
        }

        makeRequest({
          method: 'GET',
          url: '/static/wht_background.pdf'
        })
          .then((response) => {
            const result = new Blob([response], { type: 'application/pdf' })
            const backgroundPDF = new File([result], 'FreeHand', { type: 'application/pdf' })
            this.background.push(backgroundPDF)

            const formData = new FormData()

            this.background.forEach((f, index) => {
              formData.append(`files_${index}`, f)
            })

            const config = { headers: { 'content-type': 'multipart/form-data' } }
            this.axios.post('/api/kagechi/upload', formData, config).then(res => {
              this.$emit('loading', false)
              console.log(`isGoogleMapUsed : ${isGoogleMapUsed}`)
              this.updateGoogleMapDisplayFlag(this.menuPlotFlag === 3)
              this.$router.push({ path: '/kagechi/trimmingFH/' + res.data.main_id })
            }).catch(error => {
              this.$emit('loading', false)
              if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
                this.validated(error.response.data)
              } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
                this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
              }
            })
          })
          .catch(() => {
            this.error_message = 'エラーが発生しました。再度操作してください。'
          })
      }
    },
    gotoCustomerPortal () {
      const username = this.$store.getters['user/user'].username
      const url = `/api/account/customerPortal/${username}`
      this.axios.post(url).then(res => {
        this.confirmed = true
        this.error_message = ''
        this.$emit('loading', false)
        window.location.href = res.data[0]
      }).catch(error => {
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.$emit('loading', false)
          this.error_message = error.response.data.error
          this.invalid_url = error.response.data.invalid_url
          this.$router.push({ path: 'checkout' })
        }
      })
    },
    gotoGPXEdit () {
      this.$router.push({ path: '/kagechi/uploadEditGPX' })
    },
    gotoColorSetting () {
      this.$router.push({ path: '/kagechi/colorSetting' })
    }
  }
}
</script>

<style scoped>
.icon {
    position: absolute;
    top: -10px;
    right: 0;
    border-color: #ffffff;
    border: solid 2px;
}

</style>
