// STEP4で使う路線価情報を扱うVuexです。
const state = {

  // 測定範囲情報(Measurement Range 略してMR)のリスト(測定範囲それぞれに路線価情報のリストが保持されている)
  listMR: [],

  // 測定範囲情報の1レコードに相当
  recordMR: {
    index: '',
    vertexes: [], // 頂点
    area: '', // 面積
    listRLP: [] // 路線価(Roadside Land Prices 略してRLP)情報のリスト recordRLPを格納
  },

  // 路線価情報の1レコードに相当
  recordRLP: {
    isMax: false, // 登録された路線価の中で最も高い場合にTrue

    // 路線価情報本体
    info: {
      // 不整形地情報
      irregularLand: {
        frontage: '', // 不整形地の間口
        calculatedDepth: '', // 計算上の奥行（面積/間口）
        area: '', // 不整形地の面積
        inputArea: '', // 入力された不整形地の面積
        vertexes: [] // 不整形地の座標
      },
      // 想定整形地情報
      assumedLand: {
        frontage: '', // 想定整形地の間口
        depth: '', // 想定整形地の奥行
        area: '', // 想定整形地の面積
        vertexes: [] // 想定整形地の座標
      },
      // 路線価情報
      RLP: {
        districtDivision: 0, // 地区区分
        frontage: '', // 採用された間口
        depth: '', // 採用された奥行
        depthPriceCorrectionRate: '', // 奥行き価格補正率
        inputRLP: '', // 入力された路線価
        calculatedRLP: '', // 計算結果としての路線価
        frontageVtx: { start: '', end: '' } // 正面路線の頂点情報
      }
    },

    // 無道路地オプション
    noneAdjoining: {
      activate: false, // フラグ
      routeIndex: '', // 正面路線の直線インデックス
      isPassagewayOpened: false, // 通路開設フラグ
      passagewayFrontage: 2, // 通路間口
      passagewayVertexes: [] // 通路の頂点インデックス
    },

    // 角切オプション
    cornerCompletion: {
      activate: false, // フラグ
      targetVtx: { start: { index: '' }, end: { index: '' } }, // 正面路線の座標情報
      sideVtx1: { start: { index: '' }, end: { index: '' } }, // 側方路線１の座標情報
      sideVtx2: { start: { index: '' }, end: { index: '' } }, // 側方路線２の座標情報
      displayVtx: { side1: '', side1From: '', side1To: '', side2: '', side2From: '', side2To: '' }, // 画面描写用の座標情報
      frontage: '' // 角切を適応した結果の間口
    },

    // 屈折路オプション
    refracting: {
      activate: false, // フラグ
      refractNumber: 1, // 屈折回数
      innerOrOuter: true, // 内接か外接か。内接でTrue。
      possibleRLPs: [], // 可能な正面路線と想定整形地の組み合わせを全て格納する配列 / state => recordRLP => info を可能な数だけ格納する
      chosenOne: [], // 選択された正面路線と想定整形地の組み合わせ１つを格納する配列
      maxOne: [] // 路線価が最大となる正面路線と想定整形地の組み合わせ１つを格納する配列
    }
  },

  templateRefractingRLPs: {
    index: '', // possibleRLPs内でのインデックス
    startVtx: '', // 始点の頂点インデックス
    endVtx: '', // 終点の頂点インデックス
    priceInput: '', // 入力された路線価
    info: {
      // 不整形地情報
      irregularLand: {
        frontage: '', // 不整形地の間口
        calculatedDepth: '', // 計算上の奥行（面積/間口）
        calculatedArea: '', // 不整形地の面積
        inputArea: '', // 入力された不整形地の面積
        vertexes: [] // 不整形地の座標
      },
      // 想定整形地情報
      assumedLand: {
        frontage: '', // 想定整形地の間口
        depth: '', // 想定整形地の奥行
        area: '', // 想定整形地の面積
        vertexes: [] // 想定整形地の座標
      },
      // 路線価情報
      RLP: {
        districtDivision: 0, // 地区区分
        frontage: '', // 採用された間口
        depth: '', // 採用された奥行
        depthPriceCorrectionRate: '', // 奥行き価格補正率
        inputRLP: '', // 入力された路線価
        calculatedRLP: '', // 計算結果としての路線価
        frontageVtx: { start: '', end: '' } // 正面路線の頂点インデックス
      }
    }
  }
}

const getters = {
  getListMR (state) {
    return JSON.parse(JSON.stringify(state.listMR))
  },
  getRecordMR (state) {
    return JSON.parse(JSON.stringify(state.recordMR))
  },
  getRecordRLP (state) {
    return JSON.parse(JSON.stringify(state.recordRLP))
  },
  getTemplateRefractingRLPs (state) {
    return JSON.parse(JSON.stringify(state.templateRefractingRLPs))
  }
}

const mutations = {
  setRecordMR (state, data) {
    state.listMR.push(JSON.parse(JSON.stringify(data)))
  },
  addRecordMR (state, data) {
    // データセット
    state.recordMR.index = data.index
    state.recordMR.vertexes = data.vertexes
    state.recordMR.area = data.area
    // 追加
    state.listMR.push(state.recordMR)
    // クリア
    state.recordMR = { index: '', vertexes: [], area: '', listRLP: [] } // クリア
  },
  clearListMR (state) {
    state.listMR = []
  },
  clearRecordMR (state) {
    state.recordMR = { index: '', vertexes: [], area: '', listRLP: [] } // クリア
  },
  addRecordRLP (state, data) {
    if (state.listMR.length - 1 < data.index) {
      return
    }
    state.listMR[data.index].listRLP.push(JSON.parse(JSON.stringify(data.record)))
  },
  clearRecordRLP (state) {
    state.recordRLP = {
      isMax: false, // 登録された路線価の中で最も高い場合にTrue

      // 路線価情報本体
      info: {
        // 不整形地情報
        irregularLand: {
          frontage: '', // 不整形地の間口
          calculatedDepth: '', // 計算上の奥行（面積/間口）
          area: '', // 不整形地の面積
          inputArea: '', // 入力された不整形地の面積
          vertexes: [] // 不整形地の座標
        },
        // 想定整形地情報
        assumedLand: {
          frontage: '', // 想定整形地の間口
          depth: '', // 想定整形地の奥行
          area: '', // 想定整形地の面積
          vertexes: [] // 想定整形地の座標
        },
        // 路線価情報
        RLP: {
          districtDivision: 0, // 地区区分
          frontage: '', // 採用された間口
          depth: '', // 採用された奥行
          depthPriceCorrectionRate: '', // 奥行き価格補正率
          inputRLP: '', // 入力された路線価
          calculatedRLP: '', // 計算結果としての路線価
          frontageVtx: { start: '', end: '' } // 正面路線の頂点情報
        }
      },

      // 無道路地オプション
      noneAdjoining: {
        activate: false, // フラグ
        routeIndex: '', // 正面路線の直線インデックス
        isPassagewayOpened: false, // 通路開設フラグ
        passagewayVertexes: [] // 通路の頂点インデックス
      },

      // 角切オプション
      cornerCompletion: {
        activate: false, // フラグ
        targetVtx: { start: { index: '' }, end: { index: '' } }, // 正面路線の座標情報
        sideVtx1: { start: { index: '' }, end: { index: '' } }, // 側方路線１の座標情報
        sideVtx2: { start: { index: '' }, end: { index: '' } }, // 側方路線２の座標情報
        displayVtx: { side1: '', side1From: '', side1To: '', side2: '', side2From: '', side2To: '' }, // 画面描写用の座標情報
        frontage: '' // 角切を適応した結果の間口
      },

      // 屈折路オプション
      refracting: {
        activate: false, // フラグ
        refractNumber: 1, // 屈折回数
        innerOrOuter: true, // 内接か外接か。内接でTrue。
        possibleRLPs: [], // 可能な正面路線と想定整形地の組み合わせを全て格納する配列 / state => recordRLP => info を可能な数だけ格納する
        chosenOne: [], // 選択された正面路線と想定整形地の組み合わせ１つを格納する配列
        maxOne: [] // 路線価が最大となる正面路線と想定整形地の組み合わせ１つを格納する配列
      }
    }
  },
  clearListRLP (state, indexMR) {
    if (state.listMR.length - 1 < indexMR) {
      return
    }
    state.listMR[indexMR].listRLP = []
  }
}

const actions = {
  setRecordMR (context, data) {
    context.commit('setRecordMR', data)
  },
  addRecordMR (context, data) {
    context.commit('addRecordMR', data)
  },
  clearListMR (context) {
    context.commit('clearListMR')
  },
  addRecordRLP (context, data) {
    context.commit('addRecordRLP', data)
  },
  clearRecordRLP (context) {
    context.commit('clearRecordRLP')
  },
  clearListRLP (context, indexMR) {
    context.commit('clearListRLP', indexMR)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
