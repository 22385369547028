<template>
  <b-container fluid>
    <div id="full-width-section" class="align-items-center d-flex justify-content-between mb-4 row">
      <div class="text-floating-left">配色設定</div>
      <div class="text-right">
        <b-btn variant="outline-secondary" class="px-5 mr-3" @click="$router.push('/menu')">戻る</b-btn>
        <b-btn variant="primary" class="px-5" @click="setColorSetting">決定</b-btn>
      </div>
    </div>

    <div>
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>
<!--
    <div class="mb-3">
      <a v-b-toggle.description variant="outline-secondary" size="sm" class="mt-2" :href="helpUrl"><b-icon-info-circle class="mr-1" />詳細説明を見る</a>

    </div>
-->
    <div class="row">
      <b-form inline class="pl-3 mb-4" style="width: 100%;">
        <b-form-radio-group v-model="bgReversed">
          <b-form-radio :value="true" class="mr-5">ダークモード</b-form-radio>
          <b-form-radio :value="false" class="mr-5">ライトモード</b-form-radio>
        </b-form-radio-group>
        <b-form-radio-group v-model="platSelectingMode">
          <b-form-radio v-model="platSelectingMode" :value="true" class="mr-5">土地非選択時</b-form-radio>
          <b-form-radio v-model="platSelectingMode" :value="false" class="mr-5">土地選択時</b-form-radio>
        </b-form-radio-group>
      </b-form>

      <div class="col-xl-8 col-lg-6 col-12">
        <div ref="box">
          <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight"/>
        </div>
      </div>

      <div class="col-xl-4 col-lg-6 col-12">
        <div class="row section-panel mx-2 p-3">
          <div class="mb-1">色設定</div>
          <div class="d-flex justify-content-between col-12">
            <span class="col-6"></span>
            <span class="col-3 text-center">ダーク</span>
            <span class="col-3 text-center">ライト</span>
          </div>
          <div class="d-flex justify-content-between col-12 pt-2">
            <span class="col-6 my-2">頂点／土地領域の色</span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(true, 'platColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': platColorRgba(true) }" class="btn-inner"></div>
              </b-button>
            </span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(false, 'platColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': platColorRgba(false) }" class="btn-inner"></div>
              </b-button>
            </span>
          </div>
          <div>
            <swatches-picker v-model="color.darkMode.platColor" v-if="showingColorPicker.darkMode.platColor" />
            <swatches-picker v-model="color.lightMode.platColor" v-if="showingColorPicker.lightMode.platColor" />
          </div>
          <div class="d-flex justify-content-between col-12 pt-2">
            <span class="col-6 my-2">土地選択時の色</span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(true, 'selectingLayerColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': selectingLayerColorRgba(true) }" class="btn-inner"></div>
              </b-button>
            </span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(false, 'selectingLayerColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': selectingLayerColorRgba(false) }" class="btn-inner"></div>
              </b-button>
            </span>
          </div>
          <div>
            <swatches-picker v-model="color.darkMode.selectingLayerColor" v-if="showingColorPicker.darkMode.selectingLayerColor" />
            <swatches-picker v-model="color.lightMode.selectingLayerColor" v-if="showingColorPicker.lightMode.selectingLayerColor" />
          </div>
          <div class="d-flex justify-content-between col-12 pt-2">
            <span class="col-6 my-2">作図(実線)の色</span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(true, 'strokeColor1')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': strokeColor1Rgba(true) }" class="btn-inner"></div>
              </b-button>
            </span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(false, 'strokeColor1')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': strokeColor1Rgba(false) }" class="btn-inner"></div>
              </b-button>
            </span>
          </div>
          <div>
            <swatches-picker v-model="color.darkMode.strokeColor1" v-if="showingColorPicker.darkMode.strokeColor1" />
            <swatches-picker v-model="color.lightMode.strokeColor1" v-if="showingColorPicker.lightMode.strokeColor1" />
          </div>
          <div class="d-flex justify-content-between col-12 pt-2">
            <span class="col-6 my-2">アシスト線の色</span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(true, 'assistLineColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': assistLineColorRgba(true) }" class="btn-inner"></div>
              </b-button>
            </span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(false, 'assistLineColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': assistLineColorRgba(false) }" class="btn-inner"></div>
              </b-button>
            </span>
          </div>
          <div>
            <swatches-picker v-model="color.darkMode.assistLineColor" v-if="showingColorPicker.darkMode.assistLineColor" />
            <swatches-picker v-model="color.lightMode.assistLineColor" v-if="showingColorPicker.lightMode.assistLineColor" />
          </div>
          <div class="d-flex justify-content-between col-12 pt-2">
            <span class="col-6 my-2">合成時のハイライト色</span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(true, 'highlightColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': highlightColorRgba(true) }" class="btn-inner"></div>
              </b-button>
            </span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(false, 'highlightColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': highlightColorRgba(false) }" class="btn-inner"></div>
              </b-button>
            </span>
          </div>
          <div>
            <swatches-picker v-model="color.darkMode.highlightColor" v-if="showingColorPicker.darkMode.highlightColor" />
            <swatches-picker v-model="color.lightMode.highlightColor" v-if="showingColorPicker.lightMode.highlightColor" />
          </div>
          <div class="d-flex justify-content-between col-12 pt-2">
            <span class="col-6 my-2">想定整形地の囲み(点線)の色</span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(true, 'assumedPlatsColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': assumedPlatsColorRgba(true) }" class="btn-inner"></div>
              </b-button>
            </span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(false, 'assumedPlatsColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': assumedPlatsColorRgba(false) }" class="btn-inner"></div>
              </b-button>
            </span>
          </div>
          <div>
            <swatches-picker v-model="color.darkMode.assumedPlatsColor" v-if="showingColorPicker.darkMode.assumedPlatsColor" />
            <swatches-picker v-model="color.lightMode.assumedPlatsColor" v-if="showingColorPicker.lightMode.assumedPlatsColor" />
          </div>
          <div class="d-flex justify-content-between col-12 pt-2">
            <span class="col-6 my-2">測定範囲の色</span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(true, 'rangeOfMeasurementColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': rangeOfMeasurementColorRgba(true) }" class="btn-inner"></div>
              </b-button>
            </span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(false, 'rangeOfMeasurementColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': rangeOfMeasurementColorRgba(false) }" class="btn-inner"></div>
              </b-button>
            </span>
          </div>
          <div>
            <swatches-picker v-model="color.darkMode.rangeOfMeasurementColor" v-if="showingColorPicker.darkMode.rangeOfMeasurementColor" />
            <swatches-picker v-model="color.lightMode.rangeOfMeasurementColor" v-if="showingColorPicker.lightMode.rangeOfMeasurementColor" />
          </div>
          <div class="d-flex justify-content-between col-12 pt-2">
            <span class="col-6 my-2">道路の色</span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(true, 'roadColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': roadColorRgba(true) }" class="btn-inner"></div>
              </b-button>
            </span>
            <span class="col-3 text-center">
              <b-button variant="outline-secondary" @click.stop="showColorPicker(false, 'roadColor')" class="btn-colorselect p-1 mb-2">
                <div :style="{ 'background-color': roadColorRgba(false) }" class="btn-inner"></div>
              </b-button>
            </span>
          </div>
          <div>
            <swatches-picker v-model="color.darkMode.roadColor" v-if="showingColorPicker.darkMode.roadColor" />
            <swatches-picker v-model="color.lightMode.roadColor" v-if="showingColorPicker.lightMode.roadColor" />
          </div>
          <div class="d-flex justify-content-between col-12 pt-2">
            <span class="col-6 my-2"></span>
            <span class="col-6 text-center">
              <b-btn variant="danger" size="sm" v-b-modal="`setting-reset-confirm`" class="px-5 py-2 mt-3 mb-2">設定リセット</b-btn>
            </span>
          </div>
          <b-modal id="setting-reset-confirm" hide-header ok-variant="danger" @ok="resetColorSetting">
            <div>全ての色設定をリセットします。本当によろしいですか？</div>
          </b-modal>
        </div>
      </div>
    </div>
    <b-modal v-for="(pdf, index) in pdfFiles" :key="index" :id="`pdf-${index}`" hide-footer body-class="p-0" dialog-class="pdf-image" :title="`${pdf.filename} (${pdf.page}ページ)`">
      <img :src="pdf.image" style="width: 100%;" />
    </b-modal>
  </b-container>
</template>

<script>
import CanvasMixin from '../../mixins/canvas'
import { Swatches } from 'vue-color'
import validation from '../../mixins/validation'
import colors from '../../colors'

const SCALE_STEP = 0.1

export default {
  name: 'ColorSetting',
  mixins: [CanvasMixin, validation],
  components: {
    'swatches-picker': Swatches
  },
  data: () => {
    return {
      color: colors.color, // これを書いておかないと、colors 変数使っていないとのエラーになる
      showingColorPicker: { darkMode: {}, lightMode: {} },
      canvasWidth: 1225,
      canvasHeight: 600,
      canvasContext: undefined,
      // ここまで必要
      drag: { dragging: 0, x: 0, y: 0 },
      rotate: { rotating: false, startAngle: 0, downPointAngle: 0 },
      pdfFiles: undefined,
      bgReversed: true,
      opMode: 'move',
      layers: [],
      drawingLayer: {},
      lineDefaultName: { solid: '実線', dashed: '破線', dotted: '点線', assist: 'アシスト線' },
      title: '',
      onTheLine: false,
      onTheLineIndex: 0,
      lineDraggFrom: { x: 0, y: 0 },
      lineDraggS2E: { sx: 0, sy: 0, ex: 0, ey: 0 },
      onTheEdge: { start: false, end: false, extend: false },
      conditionVertexes: [],
      condition1: { index: 0, distance: 0 },
      condition2: { index: 0, distance: 0 },
      scaleIndex: 0,
      diff: { 1: { diffX: '', diffY: '' }, 2: { diffX: '', diffY: '' }, 3: { diffX: '', diffY: '' }, 4: { diffX: '', diffY: '' }, 5: { diffX: '', diffY: '' }, 6: { diffX: '', diffY: '' }, 7: { diffX: '', diffY: '' }, 8: { diffX: '', diffY: '' }, 9: { diffX: '', diffY: '' }, 10: { diffX: '', diffY: '' } },
      defaultMarginBtwPlats_X: 10,
      defaultMarginBtwPlats_Y: 10,
      drawings: [],
      fhOnTheLine: false,
      fhOnTheLineIndex: 0,
      fhOnTheVertex: false,
      fhOnTheVertexIndex: 0,
      fhOnThePlatIndex: 0,
      fhRelativePosition: { x: '', y: '' },
      fhOnTheLineMouseDown: 0,
      fhVtxArray: [],
      hvTarget: { flag: false, index: 0, type: '' },
      vFromTo: { sx: '', sy: '', ex: '', ey: '' },
      fhPlatlist: [],
      roadAreaSum: 0,
      vh2Lines: { type: '', flag: false, lineType: '', lineIndex: '' },
      devideCanvas: false,
      image: new Image(),
      position: { x: 0, y: 0 },
      dragPDF: { dragging: 0, startImagePos: { x: 0, y: 0 }, startMousePos: { x: 0, y: 0 } },
      pdfDragging: false,
      pdfIndex: '',
      scaleInput: 0,
      scaleFactor: 1,
      conditionDrawingVtx: [],
      addPlatBothEndsIndexes: { platId: '', start: '', end: '' },
      assistFlag: false,
      assistEdgeOn: { edgeFlag: false, movingFlag: false, vIdx: '', pIdx: '', start: { x: '', y: '' }, end: { x: '', y: '' } },
      pasteFlag: false,
      pastingBuffArr: [],
      pastingData: {
        paste: false,
        1: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        2: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        3: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        4: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        5: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        6: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        7: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        8: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        9: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        10: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        11: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        12: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        13: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        14: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        15: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        16: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        17: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        18: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        19: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        20: { start_vertex: '', end_vertex: '', initDiffAngle: '', pictZeroPoint: { x: '', y: '' } },
        fromTo: [],
        angle: ''
      },
      /* 合成、最終の buf 状態を保存するオブジェクト */
      synthesisBuf: { indexFrom: '', startFrom: '', endFrom: '', indexTo: '', startTo: '', endTo: '' },
      ranges: [],
      rangeVertexes: [],
      activeTabIndex: 0,
      helpUrl: '',
      platSelectingMode: true
    }
  },
  computed: {
    scale () {
      return 1 + this.scaleIndex * SCALE_STEP
    },
    pixelsPerMeter () {
      return (100000 / 25.4)
    },
    scaledImageSize () {
      return {
        width: this.$refs.canvas.width / this.scale,
        height: this.$refs.canvas.height / this.scale
      }
    }
  },
  mounted () {
    this.$emit('loading', true)

    // 操作説明のURL取得
    // TODO: この画面用の操作説明URLは準備できていないはずなので注釈化している
    /*
    this.axios.get('/api/master/search/help_urls').then(res => {
      this.helpUrl = res.data.urls.step3
    }).catch(error => {
      this.errors = { error: `システムエラーが発生しました。${error}` }
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })
    */
    // 色指定の復元(ローカルストレージから取得した色指定をオブジェクトに保存)
    this.color = colors.getLocalStorageColorData()

    // カラーピッカーの開閉フラグを全て false に設定
    this.showingColorPicker = colors.getAllShowingColorPickerState()

    // テスト描画用のJSONを読込む
    this.layers = require('../../assets/testLayersDataForColorSetting.json')

    // canvas初期化
    this.initCanvas()

    // リサイズ検知
    const resizeObserver = new ResizeObserver((entries) => {
      this.resizeWindow()
    })
    if (this.$refs.box) {
      resizeObserver.observe(this.$refs.box)
    }

    this.$emit('loading', false)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeWindow)
  },
  watch: {
    bgReversed () {
      this.canvasUpdate()
    },
    platSelectingMode () {
      this.canvasUpdate()
    },
    activeTabIndex () {
      this.canvasUpdate()
    },
    color: {
      handler (value) {
        // 土地の色は透明度を付ける
        colors.color.darkMode.platColor.rgba.a = 0.3
        colors.color.darkMode.selectingLayerColor.rgba.a = 0.3
        colors.color.lightMode.platColor.rgba.a = 0.3
        colors.color.lightMode.selectingLayerColor.rgba.a = 0.3
        this.canvasUpdate()
      },
      deep: true
    }
  },
  methods: {
    documentClick (event) {
      if (!event.target.closest('.vc-chrome')) {
        // picker以外の場所がクリックされたらpickerを閉じる
        this.hideColorPicker()
      }
    },
    showColorPicker (isDefaultColorMode, target) {
      document.addEventListener('click', this.documentClick)
      const colorMode = colors.getColorMode(isDefaultColorMode)
      this.showingColorPicker[colorMode][target] = true
      for (var hideColorMode in this.showingColorPicker) {
        for (var hideTarget in this.showingColorPicker[hideColorMode]) {
          if (colorMode !== hideColorMode || target !== hideTarget) {
            this.showingColorPicker[hideColorMode][hideTarget] = false
          }
        }
      }
    },
    hideColorPicker () {
      document.removeEventListener('click', this.documentClick)
      for (var colorMode in this.showingColorPicker) {
        for (var target in this.showingColorPicker[colorMode]) {
          this.showingColorPicker[colorMode][target] = false
        }
      }
    },
    initCanvas () {
      if (this.$refs.box === undefined) {
        return
      }
      this.canvasWidth = this.$refs.box.offsetWidth - 4 // border分だけマイナスする

      if (this.$refs.canvas && this.$refs.canvas.width > 0 && this.$refs.box.offsetWidth > 0) {
        this.canvasContext = this.$refs.canvas.getContext('2d', { willReadFrequently: true })

        let allLoaded = false
        while (!allLoaded) {
          const loadingPlats = this.layers.filter(layer => layer.type === 'plat' && !layer.imageLoaded)
          if (loadingPlats.length === 0) {
            allLoaded = true
          }
        }

        setTimeout(() => {
          this.canvasUpdate()
          this.canvasUpdate()
        }, 10)
      } else {
        setTimeout(() => {
          this.initCanvas()
        }, 500)
      }
    },
    drawPlatImage (plats) {
      let x = 10
      let y = 10
      let maxY = 0
      // 図面画像を書く
      plats.forEach((plat, index) => {
        if (this.fhPlatlist.length) {
          for (let i = 0; i < this.fhPlatlist.length; i++) {
            if (plat.freehand === undefined) {
              if (!plat.relativePosition) {
                // 初回描画時
                plat.relativePosition = { x, y }
                plat.angle = 0
              }

              plat.relativeVertexes = []
              plat.vertexes.forEach(v => {
                plat.relativeVertexes.push({ x: v.x + plat.relativePosition.x, y: v.y + plat.relativePosition.y })
              })

              plat.relativeCentroid = this.calcCentroid(plat.relativeVertexes)
              this.canvasContext.save()
              this.canvasContext.translate(plat.relativeCentroid.x, plat.relativeCentroid.y)
              this.canvasContext.rotate(plat.angle)

              this.canvasContext.drawImage(plat.imageObj,
                0,
                0,
                plat.imageObj.width,
                plat.imageObj.height,
                -(plat.relativeCentroid.x - plat.relativePosition.x),
                -(plat.relativeCentroid.y - plat.relativePosition.y),
                plat.imageObj.width * this.scaleFactor,
                plat.imageObj.height * this.scaleFactor)
              this.canvasContext.restore()

              maxY = Math.max(maxY, y)
              if (plats[index + 1]) {
                // 次の土地がある
                if ((x + plat.imageObj.width + plats[index + 1].imageObj.width + 10) > this.canvasWidth) {
                  // 右隣に次の土地を描画できない
                  x = 10
                  maxY = 0
                  y = maxY + (50000 / this.scaleInput)
                } else {
                  x += (plat.imageObj.width + 10)
                }
              }
            }
          }
        } else {
          if (!plat.relativePosition) {
            // 初回描画時
            plat.relativePosition = { x, y }
            plat.angle = 0
          }

          plat.relativeVertexes = []
          plat.vertexes.forEach(v => {
            plat.relativeVertexes.push({ x: v.x + plat.relativePosition.x, y: v.y + plat.relativePosition.y })
          })
          plat.relativeCentroid = this.calcCentroid(plat.relativeVertexes)

          plat.imageObj = new Image() // 単独実行時、この設定が必要だった

          this.canvasContext.save()
          this.canvasContext.translate(plat.relativeCentroid.x, plat.relativeCentroid.y)
          this.canvasContext.rotate(plat.angle)
          // this.canvasContext.drawImage(plat.imageObj, -(plat.relativeCentroid.x - plat.relativePosition.x), -(plat.relativeCentroid.y - plat.relativePosition.y))
          this.canvasContext.drawImage(plat.imageObj, -(plat.relativeCentroid.x - plat.relativePosition.x), -(plat.relativeCentroid.y - plat.relativePosition.y), plat.imageObj.width * this.scaleFactor, plat.imageObj.height * this.scaleFactor)
          this.canvasContext.restore()
          maxY = Math.max(maxY, y)
          if (plats[index + 1]) {
            // 次の土地がある
            if ((x + plat.imageObj.width + plats[index + 1].imageObj.width + 10) > this.canvasWidth) {
              // 右隣に次の土地を描画できない
              x = 10
              maxY = 0
              y = maxY + (50000 / this.scaleInput)
            } else {
              x += (plat.imageObj.width + 10)
            }
          }
        }
      })
    },
    drawSinglePlatImage (plat) {
      let x = this.defaultMarginBtwPlats_X
      const y = this.defaultMarginBtwPlats_Y
      // let maxY = 0

      // 図面画像を書く
      if (!plat.relativePosition) {
        // 初回描画時
        plat.relativePosition = { x, y }
        plat.angle = 0
      }
      plat.relativeVertexes = []
      plat.vertexes.forEach(v => {
        plat.relativeVertexes.push({ x: v.x + plat.relativePosition.x, y: v.y + plat.relativePosition.y })
      })
      plat.relativeCentroid = this.calcCentroid(plat.relativeVertexes)
      this.canvasContext.save()
      this.canvasContext.translate(plat.relativeCentroid.x, plat.relativeCentroid.y)
      this.canvasContext.rotate(plat.angle)
      // this.canvasContext.drawImage(plat.imageObj, -(plat.relativeCentroid.x - plat.relativePosition.x), -(plat.relativeCentroid.y - plat.relativePosition.y))
      this.canvasContext.drawImage(plat.imageObj,
        -(plat.relativeCentroid.x - plat.relativePosition.x),
        -(plat.relativeCentroid.y - plat.relativePosition.y),
        plat.imageObj.width * this.scaleFactor,
        plat.imageObj.height * this.scaleFactor)
      this.canvasContext.restore()

      // maxY = Math.max(maxY, y)
      if ((x + plat.imageObj.width * 2 + 10) > this.canvasWidth) {
        // 右隣に次の土地を描画できない
        x = 10
        // maxY = 0
        this.defaultMarginBtwPlats_Y = 10
      } else {
        this.defaultMarginBtwPlats_X = x + (plat.imageObj.width + 10)
      }
    },
    canvasUpdate (onlyPlatImage) {
      if (!this.canvasContext) {
        return
      }
      if (this.pastingData.paste === true) {
        // 合成の場合の描画処理
        this.drawPastePlats(onlyPlatImage)
      } else {
        // 合成でない場合の描画処理
        // 真っ白にする
        this.canvasContext.fillStyle = 'white'
        this.canvasContext.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
        this.canvasContext.save()

        const plats = this.layers.filter(l => l.showing && l.type === 'plat')

        this.drawPlatImage(plats)

        if (onlyPlatImage) {
          return
        }

        if (this.bgReversed) {
          this.colorInversion(this.canvasContext, this.canvasWidth)
        }
        plats.forEach(plat => {
          const fillColor = !this.platSelectingMode ? `${colors.selectingLayerColorRgba(this.bgReversed)}` : `${colors.platColorRgba(this.bgReversed)}`
          this.drawPlat(this.canvasContext, plat.vertexes, this.scale, { x: -plat.relativePosition.x, y: -plat.relativePosition.y }, fillColor)
        })
      }

      // 作図
      const plats = this.layers.filter(l => l.showing && l.type === 'plat')
      const lines = this.layers.filter(l => l.showing && l.type !== 'plat')
      lines.forEach(line => {
        this.canvasContext.beginPath()
        this.canvasContext.moveTo(line.points.fromX, line.points.fromY)
        this.canvasContext.lineTo(line.points.toX, line.points.toY)
        this.canvasContext.lineWidth = line.lineWidth ? line.lineWidth : true
        this.canvasContext.strokeStyle = line.color
        if (line.type === 'dashed') {
          this.canvasContext.setLineDash([5, 5])
        } else if (line.type === 'dotted') {
          this.canvasContext.setLineDash([2, 3])
        } else if (line.type === 'solid') {
          this.canvasContext.setLineDash([0])
        } else if (line.type === 'assist') {
          this.canvasContext.setLineDash([5, 5])
        }

        this.canvasContext.closePath()
        this.canvasContext.stroke()
        this.canvasContext.setLineDash([0])
        this.canvasContext.strokeStyle = '#ffffff'

        const prevX = (line.points.fromX + line.points.toX) / 2
        const prevY = (line.points.fromY + line.points.toY) / 2
        const textX = prevX + 3
        const textY = prevY + 3
        const meterScale = plats[0].vertexes[1].distance_meter / Math.sqrt(this.calcDistanceDot2Dot(plats[0].vertexes[0].x, plats[0].vertexes[0].y, plats[0].vertexes[1].x, plats[0].vertexes[1].y))
        const meterDist = Math.sqrt(this.calcDistanceDot2Dot(line.points.fromX, line.points.fromY, line.points.toX, line.points.toY)) * meterScale
        const textValue = (Math.round(meterDist * 100) / 100) + ''

        const bgHeight = 9 * 1.5 + 5
        const bgWidth = this.canvasContext.measureText(textValue).width + 5
        const bgY = textY - bgHeight
        const bgX = textX - (bgWidth / 2)

        this.canvasContext.fillStyle = 'white'
        this.canvasContext.fillRect(bgX, bgY, bgWidth, bgHeight)
        this.canvasContext.fillStyle = 'blue'
        this.canvasContext.fillText(textValue, textX, textY)
      })

      // 条件作図の距離を描画
      this.conditionDrawingVtx.forEach(v => {
        const layers = this.layers.filter(l => l.id === v.platID)
        const targetLayer = layers.length > 0 ? layers[0] : undefined
        if (targetLayer !== undefined) {
          this.drawLine(targetLayer.relativeVertexes[v.index].x,
            targetLayer.relativeVertexes[v.index].y,
            v.x2, v.y2, 2, 'rgb(255, 0, 0)', [5, 5])

          // 距離表示
          const dist = this.calcDistanceDot2Dot(targetLayer.relativeVertexes[v.index].x,
            targetLayer.relativeVertexes[v.index].y,
            v.x2, v.y2)
          this.displayDistance(targetLayer.relativeVertexes[v.index].x,
            targetLayer.relativeVertexes[v.index].y,
            v.x2, v.y2, dist, 0, 0)
        }
      })

      // フリーハンドの頂点
      if (this.opMode === 'freeHandPlot' || this.opMode === 'roadPlot') {
        for (let i = 0; i < this.fhVtxArray.length; i++) {
          this.drawRect(this.fhVtxArray[i].x + this.fhRelativePosition.x,
            this.fhVtxArray[i].y + this.fhRelativePosition.y,
            'rgb(0, 0, 255)')
          this.canvasContext.save()
          if (this.fhVtxArray.length > 1 && i > 0) {
            this.canvasContext.beginPath()
            this.canvasContext.moveTo(this.fhVtxArray[i].x + this.fhRelativePosition.x, this.fhVtxArray[i].y + this.fhRelativePosition.y)
            if (this.fhOnTheLineMouseDown === 2 && i === this.fhVtxArray.length) {
              this.canvasContext.lineTo(this.fhVtxArray[i - 1].x + this.fhRelativePosition.x, this.fhVtxArray[i - 1].y + this.fhRelativePosition.y)
              this.canvasContext.lineTo(this.fhVtxArray[0].x + this.fhRelativePosition.x, this.fhVtxArray[0].y + this.fhRelativePosition.y)
            } else {
              this.canvasContext.lineTo(this.fhVtxArray[i - 1].x + this.fhRelativePosition.x, this.fhVtxArray[i - 1].y + this.fhRelativePosition.y)
            }
            this.canvasContext.lineWidth = 1
            this.canvasContext.strokeStyle = 'rgb(0, 255, 0)'
            this.canvasContext.setLineDash([5, 2])
            this.canvasContext.closePath()
            this.canvasContext.stroke()

            // 距離表示
            const dist = this.calcDistanceDot2Dot(this.fhVtxArray[i].x, this.fhVtxArray[i].y, this.fhVtxArray[i - 1].x, this.fhVtxArray[i - 1].y)
            this.displayDistance(this.fhVtxArray[i].x,
              this.fhVtxArray[i].y,
              this.fhVtxArray[i - 1].x,
              this.fhVtxArray[i - 1].y,
              dist,
              this.fhRelativePosition.x,
              this.fhRelativePosition.y)
          }
        }
      }
      // 測定範囲の描画
      if (this.ranges.length > 0) {
        const selectedLayer = this.layers.filter(l => l.selected)
        this.activeTabIndex = this.activeTabIndex < 0 ? 0 : this.activeTabIndex
        const r = this.ranges[this.activeTabIndex]
        r.vertexes.forEach((v, idx) => {
          const nextIdx = idx === r.vertexes.length - 1 ? 0 : idx + 1
          const nextVtx = r.vertexes[nextIdx]
          this.drawLine((v.x + selectedLayer[0].relativePosition.x) * this.scale,
            (v.y + selectedLayer[0].relativePosition.y) * this.scale,
            (nextVtx.x + selectedLayer[0].relativePosition.x) * this.scale,
            (nextVtx.y + selectedLayer[0].relativePosition.y) * this.scale,
            1, 'rangeOfMeasurementColorRgba(this.bgReversed)', [0])
        })
      }

      // 描画処理の仮記述
      // 合成時のハイライト色用の線を描く
      // const vertexes1 = this.layers[0].vertexes
      // const rel1X = this.layers[0].relativePosition.x
      // const rel1Y = this.layers[0].relativePosition.y
      // this.drawLine(vertexes1[1].x + rel1X, vertexes1[1].y + rel1Y, vertexes1[2].x + rel1X, vertexes1[2].y + rel1Y, 1, colors.highlightColorRgba(this.bgReversed), [0])
      // const vertexes2 = this.layers[1].vertexes
      // const rel2X = this.layers[1].relativePosition.x
      // const rel2Y = this.layers[1].relativePosition.y
      // this.drawLine(vertexes2[0].x + rel2X, vertexes2[0].y + rel2Y, vertexes2[4].x + rel2X, vertexes2[4].y + rel2Y, 1, colors.highlightColorRgba(this.bgReversed), [0])
      // TODO: 作図(実線)
      this.drawLine(125, 353.39999771118164, 591, 435.3999977111816, 1, colors.strokeColor1Rgba(this.bgReversed), [0])
      // TODO: アシスト線
      this.drawLine(643, 238, 591, 435.3999977111816, 1, colors.assistLineColorRgba(this.bgReversed), [5, 5])
      // TODO: 想定整形地の囲み(点線) ← 破線にならない
      this.drawLineRelative(391.23450308244696, -31.03370367861254, 419, 136, 1, colors.assumedPlatsColorRgba(this.bgReversed), [5, 5], 224, 102)
      this.drawLineRelative(419, 136, 27.76549691755302, 201.03370367861257, 1, colors.assumedPlatsColorRgba(this.bgReversed), [5, 5], 224, 102)
      this.drawLineRelative(27.76549691755302, 201.03370367861257, 7.105427357601002e-15, 34.00000000000003, 1, colors.assumedPlatsColorRgba(this.bgReversed), [5, 5], 224, 102)
      this.drawLineRelative(7.105427357601002e-15, 34.00000000000003, 391.23450308244696, -31.03370367861254, 1, colors.assumedPlatsColorRgba(this.bgReversed), [5, 5], 224, 102)
      // TODO: 測定範囲
      this.drawLineRelative(0, 34, 40, 199, 1, colors.rangeOfMeasurementColorRgba(this.bgReversed), [0], 224, 102)
      this.drawLineRelative(40, 199, 419, 136, 1, colors.rangeOfMeasurementColorRgba(this.bgReversed), [0], 224, 102)
      this.drawLineRelative(419, 136, 0, 34, 1, colors.rangeOfMeasurementColorRgba(this.bgReversed), [0], 224, 102)
    },
    resizeWindow () {
      this.initCanvas()
    },
    drawLine (sx, sy, ex, ey, width, color, lineDash) {
      this.canvasContext.beginPath()
      this.canvasContext.moveTo(sx, sy)
      this.canvasContext.lineTo(ex, ey)
      this.canvasContext.lineWidth = width
      this.canvasContext.strokeStyle = color
      this.canvasContext.setLineDash(lineDash)
      this.canvasContext.closePath()
      this.canvasContext.stroke()
    },
    drawLineRelative (sx, sy, ex, ey, width, color, lineDash, rx, ry) {
      this.canvasContext.beginPath()
      this.canvasContext.moveTo(sx + rx, sy + ry)
      this.canvasContext.lineTo(ex + rx, ey + ry)
      this.canvasContext.lineWidth = width
      this.canvasContext.strokeStyle = color
      this.canvasContext.setLineDash(lineDash)
      this.canvasContext.closePath()
      this.canvasContext.stroke()
    },
    drawRect (x, y, color) {
      this.canvasContext.fillStyle = color
      this.canvasContext.fillRect(x - 5, y - 5, 10, 10)
    },
    // 色設定
    platColorRgba (isDefaultColor) {
      colors.color = this.color
      return colors.platColorRgba(isDefaultColor)
    },
    selectingLayerColorRgba (isDefaultColor) {
      colors.color = this.color
      return colors.selectingLayerColorRgba(isDefaultColor)
    },
    strokeColor1Rgba (isDefaultColor) {
      colors.color = this.color
      return colors.strokeColor1Rgba(isDefaultColor)
    },
    assistLineColorRgba (isDefaultColor) {
      colors.color = this.color
      return colors.assistLineColorRgba(isDefaultColor)
    },
    highlightColorRgba (isDefaultColor) {
      colors.color = this.color
      return colors.highlightColorRgba(isDefaultColor)
    },
    assumedPlatsColorRgba (isDefaultColor) {
      colors.color = this.color
      return colors.assumedPlatsColorRgba(isDefaultColor)
    },
    rangeOfMeasurementColorRgba (isDefaultColor) {
      colors.color = this.color
      return colors.rangeOfMeasurementColorRgba(isDefaultColor)
    },
    roadColorRgba (isDefaultColor) {
      colors.color = this.color
      return colors.roadColorRgba(isDefaultColor)
    },
    // 色設定の確定
    setColorSetting () {
      // colorオブジェクトを、ローカルストレージに書き出す
      const dataRgba = {}
      for (var colorMode in this.color) {
        dataRgba[colorMode] = {}
        for (var key in this.color[colorMode]) {
          if (key === 'platColor' || key === 'selectingLayerColor') {
            this.color[colorMode][key].rgba.a = 0.3
          }
          dataRgba[colorMode][key] = { rgba: this.color[colorMode][key].rgba }
        }
      }
      const colorsJson = JSON.stringify({ color: dataRgba })
      localStorage.setItem('colors', colorsJson)
      // TODO: この位置でAPI経由でDBにデータ(colorsJson)を書き出す

      this.$router.push('/menu')
    },
    // 色設定のリセット
    resetColorSetting () {
      // ローカルストレージに保存されているデータを削除する
      localStorage.removeItem('colors')
      this.$router.go({ path: this.$router.currentRoute.path, force: true })
    }
  }
}
</script>

<style scoped>
</style>
