export default {
  methods: {
    /**
     * 各ピクセル座標の値のうち共通している桁数を調べて、その部分を除去する
     * @param gs
     * @returns {{x: number, y: number}}
     */
    adjustPlatSize_BK (gs) {
      const arrayX = []
      const arrayY = []
      for (const key in gs) {
        arrayX.push(gs[key].x)
        arrayY.push(gs[key].y)
      }
      // X座標に関して共通している桁数を調べる
      let lgthTrimX = 0
      let diffFlag = false
      for (let i = 0; i < arrayX.length; i++) {
        arrayX[i] = String(arrayX[i])
        arrayY[i] = String(arrayY[i])
      }
      while (!diffFlag) {
        if (arrayX[0].length >= lgthTrimX + 1) {
          for (let i = 1; i < arrayX.length; i++) {
            if (arrayX[0].substr(0, lgthTrimX + 1) !== arrayX[i].substr(0, lgthTrimX + 1)) {
              diffFlag = true
              break
            }
          }
          if (!diffFlag) {
            lgthTrimX++
          }
        } else {
          diffFlag = true
        }
      }
      // Y座標に関して共通している桁数を調べる
      let lgthTrimY = 0
      diffFlag = false
      while (!diffFlag) {
        if (arrayY[0].length >= lgthTrimY + 1) {
          for (let i = 1; i < arrayX.length; i++) {
            if (arrayY[0].substr(0, lgthTrimY + 1) !== arrayY[i].substr(0, lgthTrimY + 1)) {
              diffFlag = true
              break
            }
          }
          if (!diffFlag) {
            lgthTrimY++
          }
        } else {
          diffFlag = true
        }
      }
      // 共通桁の除去
      for (let i = 0; i < arrayX.length; i++) {
        arrayX[i] = arrayX[i].substr(lgthTrimX, arrayX[i].length - lgthTrimX)
      }
      for (let i = 0; i < arrayY.length; i++) {
        arrayY[i] = arrayY[i].substr(lgthTrimY, arrayY[i].length - lgthTrimY)
      }
      // 座標を補正する
      for (let i = 0; i < arrayX.length; i++) {
        arrayX[i] = Number(arrayX[i])
        arrayY[i] = Number(arrayY[i])
      }
      const minX = Math.min(...arrayX)
      const minY = Math.min(...arrayY)
      for (let i = 0; i < arrayX.length; i++) {
        arrayX[i] = arrayX[i] - minX
        arrayY[i] = arrayY[i] - minY
      }
      // 戻り値作成
      const retObj = {}
      for (let i = 0; i < arrayX.length; i++) {
        retObj[i] = {
          x: String(arrayX[i]),
          y: String(arrayY[i])
        }
      }
      return retObj
    },
    adjustPlatSize (gs) {
      const arrayX = []
      const arrayY = []
      // 緯度，経度 の項目を追加
      const arrayLat = []
      const arrayLon = []
      let isExistLatAndLon = false
      for (const key in gs) {
        arrayX.push(gs[key].x)
        arrayY.push(gs[key].y)
        if ('lat' in gs[key]) {
          arrayLat.push(gs[key].lat)
          isExistLatAndLon = true
        } else {
          isExistLatAndLon = false
        }
        if ('lon' in gs[key]) {
          arrayLon.push(gs[key].lon)
          isExistLatAndLon = true
        } else {
          isExistLatAndLon = false
        }
      }
      // 座標を補正する
      for (let i = 0; i < arrayX.length; i++) {
        arrayX[i] = Number(arrayX[i])
        arrayY[i] = Number(arrayY[i])
      }
      const minX = Math.min(...arrayX)
      const minY = Math.min(...arrayY)
      for (let i = 0; i < arrayX.length; i++) {
        arrayX[i] = arrayX[i] - minX
        arrayY[i] = arrayY[i] - minY
      }
      // 戻り値作成
      const retObj = {}
      for (let i = 0; i < arrayX.length; i++) {
        if (isExistLatAndLon) {
          retObj[i] = {
            x: String(arrayX[i]),
            y: String(arrayY[i]),
            lat: Number(arrayLat[i]),
            lon: Number(arrayLon[i])
          }
        } else {
          retObj[i] = {
            x: String(arrayX[i]),
            y: String(arrayY[i])
          }
        }
      }
      return retObj
    },
    adjustXYs (gs) {
      const arrayX = []
      const arrayY = []
      const arrayLat = []
      const arrayLon = []
      gs.forEach(g => {
        arrayX.push(g.x)
        arrayY.push(g.y)
        arrayLat.push(g.lat)
        arrayLon.push(g.lon)
      })

      // 座標を補正する
      for (let i = 0; i < arrayX.length; i++) {
        arrayX[i] = Number(arrayX[i])
        arrayY[i] = Number(arrayY[i])
      }
      const minX = Math.min(...arrayX)
      const minY = Math.min(...arrayY)
      for (let i = 0; i < arrayX.length; i++) {
        arrayX[i] = arrayX[i] - minX
        arrayY[i] = arrayY[i] - minY
      }
      // 戻り値作成
      const retObj = []
      for (let i = 0; i < arrayX.length; i++) {
        retObj.push({
          x: String(arrayX[i]),
          y: String(arrayY[i]),
          lat: Number(arrayLat[i]),
          lon: Number(arrayLon[i])
        })
      }
      return retObj
    }
  }
}
