export default {
  // 色マスタ
  // ここに記述している色指定は、標準状態のもの。 メニューで色指定をDBより取得し、このデータを書き換える
  color: Object({
    darkMode: {
      strokeColor1: { rgba: { r: 0, g: 0, b: 255, a: 1 } }, // TrimmingImage.vue / canvasUpdate() で使われている色指定
      strokeColor2: { rgba: { r: 0, g: 83, b: 250, a: 1 } }, // canvas.js 内で描画時に多く使われている色指定
      platColor: { rgba: { r: 0, g: 0, b: 255, a: 0.3 } }, // 土地の色指定
      assistLineColor: { rgba: { r: 255, g: 0, b: 0, a: 1 } }, // アシスト線の色指定
      selectingLayerColor: { rgba: { r: 0, g: 255, b: 0, a: 0.3 } }, // 選択した土地の色指定
      selectingColor: { rgba: { r: 255, g: 0, b: 0, a: 1 } }, // 選択時の色指定
      draggingColor: { rgba: { r: 255, g: 0, b: 0, a: 1 } }, // ドラッグ時の色指定
      highlightColor: { rgba: { r: 255, g: 0, b: 0, a: 1 } }, // ハイライトの色指定
      freehandColor: { rgba: { r: 0, g: 255, b: 0, a: 0.3 } }, // TODO: フリーハンド描画時の色指定
      roadColor: { rgba: { r: 255, g: 174, b: 0, a: 0.3 } }, // TODO: 道路の色指定
      rangeOfMeasurementColor: { rgba: { r: 232, g: 242, b: 7, a: 1 } }, // TODO: 測定範囲
      assumedPlatsColor: { rgba: { r: 239, g: 60, b: 60, a: 1 } }, // TODO: 想定整形地
      setBackColor: { rgba: { r: 65, g: 105, b: 225, a: 0.8 } } // TODO: セットバック
    },
    lightMode: {
      strokeColor1: { rgba: { r: 0, g: 0, b: 255, a: 1 } }, // TrimmingImage.vue / canvasUpdate() で使われている色指定
      strokeColor2: { rgba: { r: 0, g: 83, b: 250, a: 1 } }, // canvas.js 内で描画時に多く使われている色指定
      platColor: { rgba: { r: 0, g: 0, b: 255, a: 0.3 } }, // 土地の色指定
      assistLineColor: { rgba: { r: 255, g: 0, b: 0, a: 1 } }, // アシスト線の色指定
      selectingLayerColor: { rgba: { r: 0, g: 255, b: 0, a: 0.3 } }, // 選択した土地の色指定
      selectingColor: { rgba: { r: 255, g: 0, b: 0, a: 1 } }, // 選択時の色指定
      draggingColor: { rgba: { r: 255, g: 0, b: 0, a: 1 } }, // ドラッグ時の色指定
      highlightColor: { rgba: { r: 255, g: 0, b: 0, a: 1 } }, // ハイライトの色指定
      freehandColor: { rgba: { r: 0, g: 255, b: 0, a: 0.3 } }, // TODO: フリーハンド描画時の色指定
      roadColor: { rgba: { r: 255, g: 174, b: 0, a: 0.3 } }, // TODO: 道路の色指定
      rangeOfMeasurementColor: { rgba: { r: 0, g: 121, b: 107, a: 1 } }, // TODO: 測定範囲
      assumedPlatsColor: { rgba: { r: 239, g: 60, b: 60, a: 1 } }, // TODO: 想定整形地
      setBackColor: { rgba: { r: 65, g: 105, b: 225, a: 0.8 } } // TODO: セットバック
    }
  }),
  // デフォルトは ダークモード とする
  getColorMode: function (isDefault) {
    // カラーモードを返す(rgba 取得の際に使用する)
    return (isDefault ? 'darkMode' : 'lightMode')
  },
  // 'rgba(xxx, xxx, xxx, x.x)' を返す
  strokeColor1Rgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'strokeColor1')
  },
  strokeColor2Rgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'strokeColor2')
  },
  platColorRgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'platColor')
  },
  assistLineColorRgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'assistLineColor')
  },
  selectingLayerColorRgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'selectingLayerColor')
  },
  selectingColorRgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'selectingColor')
  },
  draggingColorRgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'draggingColor')
  },
  highlightColorRgba (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'highlightColor')
  },
  freehandColorRgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'freehandColor')
  },
  roadColorRgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'roadColor')
  },
  rangeOfMeasurementColorRgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'rangeOfMeasurementColor')
  },
  assumedPlatsColorRgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'assumedPlatsColor')
  },
  setBackColorRgba: function (isDefault) {
    return this.colorRgbaBase(this.getColorMode(isDefault), 'setBackColor')
  },
  // 色替えのベース処理
  colorRgbaBase: function (colorMode, objName) {
    let ret = false
    if (colorMode in this.color && objName in this.color[colorMode]) {
      if (!this.color[colorMode][objName].rgba) {
        return ''
      }
      const rgba = this.color[colorMode][objName].rgba
      if (rgba.a < 1) {
        ret = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
      } else {
        // 不透明度 = 1 のときは、rgb(...) を返す
        ret = `rgb(${rgba.r}, ${rgba.g}, ${rgba.b})`
      }
    }
    // DEBUG
    if (ret === false) {
    }

    return ret
  },
  // 色マスタのデータを書き換える
  setColor: function (colorMode, objName, r, g, b, a) {
    let ret = false
    if (colorMode in this.color && objName in this.color) {
      this.color[colorMode][objName].rgba.r = r
      this.color[colorMode][objName].rgba.g = g
      this.color[colorMode][objName].rgba.b = b
      //  土地は全て透過させる
      if (objName === 'selectingLayerColor' || objName === 'platColor') {
        this.color[colorMode][objName].rgba.a = 0.3
      }
      this.color[colorMode][objName].rgba.a = a
      ret = true
    }
    return ret
  },
  // 色マスタの全データを取得する
  getAllColor: function () {
    const ret = {}
    for (var colorMode in this.color) {
      ret[colorMode] = {}
      for (var key in this.color[colorMode]) {
        const color = this.colorRgbaBase(colorMode, key)
        ret[colorMode][key] = `${color}`
      }
    }
    return ret
  },
  // ローカルストレージにデータが存在すれば、this.color に上書きする & オブジェクトを返す
  getLocalStorageColorData: function () {
    const colorsJson = localStorage.getItem('colors')
    if (colorsJson !== null) {
      this.color = (JSON.parse(colorsJson)).color
    }
    return this.color
  },
  // 色マスタの全showingColorPickerをfalseで返す(色設定画面カラーピッカー開閉判定用)
  getAllShowingColorPickerState: function () {
    const ret = {}
    for (var colorMode in this.color) {
      ret[colorMode] = {}
      for (var key in this.color[colorMode]) {
        ret[colorMode][key] = false
      }
    }
    return ret
  }
}
