<template>
  <b-container fluid id="correct">
    <div id="full-width-section" class="align-items-center d-flex justify-content-between mb-4 row">
      <change-title v-model="title" @change-title-error="validated"></change-title>

      <div class="ml-5 col-md-6">
        <ul class="stepFlow">
          <li class="completed">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 1</p>トリミング</span>
          </li>
          <li class="active">
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 2</p>評価対象地の測定</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 3</p>評価対象地の編集</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 4</p>路線価の設定</span>
          </li>
          <li>
            <span class="bubble"></span>
            <span class="text"><p class="title">STEP 5</p>測定結果</span>
          </li>
        </ul>
      </div>

      <div class="text-right">
        <b-btn variant="outline-secondary" class="px-5 mr-3" @click="back()">戻る</b-btn>
        <b-btn variant="primary" class="px-4" @click="checkInputData(rIndex)">
          <div class="btn-2rows text-left">測定を完了し<br>編集を開始する</div>
        </b-btn>
      </div>
    </div>

    <div>
      <b-alert variant="danger" :show="!!errors.error">
        <div>{{ errors.error }}</div>
      </b-alert>
    </div>

    <div class="align-items-center d-flex mb-4">
      <b-button v-if="helpUrl != ''" v-b-toggle.description variant="secondary" @click="openInNewTab(helpUrl)" class="align-items-center d-flex mr-2">
        <span class="align-items-center bg-white d-flex mr-2 rounded-pill" style="width: 18px; height: 12px;">
          <b-icon-book-fill variant="info"/>
        </span>
        詳細説明を見る
      </b-button>

      <play-video-btn v-if="helpUrlVideo"></play-video-btn>

      <b-dropdown variant="secondary" class="mx-2" v-if="showPdfDropdown">
        <template #button-content><b-icon-files class="mr-1" />PDFの確認</template>
        <b-dropdown-item-button @click="closeCanvasPDF" class="small">なし</b-dropdown-item-button>
        <b-dropdown-item-button v-for="(pdf, index) in pdfFiles" :key="index" @click="openCanvasPDF(index)" class="small">{{pdf.filename}} ({{pdf.page}}ページ)</b-dropdown-item-button>
      </b-dropdown>
    </div>

    <div class="row">
      <div class="col-xl-8 col-lg-6 col-12">
        <div ref="box" class="operator-box">
          <div>
            拡縮：{{ parseInt(scale * 100, 10) }}%
          </div>
          <b-btn-group>
            <b-icon-plus-circle role="button" font-scale="2" variant="white" title="拡大"  @click="scalePlus"></b-icon-plus-circle>
            <b-icon-dash-circle class="ml-1" role="button" font-scale="2" variant="white" title="縮小" @click="scaleDash"></b-icon-dash-circle>
          </b-btn-group>
        </div>
        <div style="display: flex;">
          <canvas ref="canvasPDF" v-show="showPdfCanvas" :width="canvasWidth" :height="canvasHeight"
                  @mouseup="mouseUpPDF" @mousedown="mouseDownPDF" @mouseout="mouseUpPDF" @wheel.prevent="changeScalePDF" @mousemove="mouseMovePDF"/>
          <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight"
                  @mouseup="mouseUp" @mousedown="mouseDown" @mouseout="mouseUp" @wheel.prevent="changeScale" @mousemove="mouseMove"/>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-12">
        <b-tabs>
          <b-tab title="測定">
            <b-form @submit.prevent="measureArea">
              <p class="pt-3 px-3">以下のいずれかの方法で各辺の距離及び面積を測定してください。</p>

              <div class="section-panel p-3">
                <b-form-radio class="col-12 mb-2" v-model="measure.way" name="measure_way" value="1">面積をもとに測定</b-form-radio>

                <div class="pl-4">
                  <b-input-group class="col-9">
                    <b-input-group-prepend>
                      <b-input-group-text>評価対象地の面積：</b-input-group-text>
                    </b-input-group-prepend>

                    <b-form-input type="number" min="0" step="0.01" v-model="measure.area" @focus="changedValue(1)" autocomplete="off" />

                    <b-input-group-append>
                      <b-input-group-text>㎡</b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <div class="section-panel p-3">
                <b-form-radio class="mb-2 col-12" v-model="measure.way" name="measure_way" value="2">距離をもとに測定</b-form-radio>

                <div class="d-flex flex-wrap pl-4">
                  <b-input-group class="col-6">
                    <b-input-group-prepend>
                      <b-input-group-text>始点：</b-input-group-text>
                    </b-input-group-prepend>

                    <b-form-select v-model="measure.start_vertex" :options="selectingVertexOptions" @input="changedValue(2)" />
                  </b-input-group>

                  <b-input-group class="col-6">
                    <b-input-group-prepend>
                      <b-input-group-text>終点：</b-input-group-text>
                    </b-input-group-prepend>

                    <b-form-select v-model="measure.end_vertex" :options="selectingVertexOptions" @input="changedValue(2)"/>
                  </b-input-group>

                  <b-input-group class="col-7 mt-3">
                    <b-input-group-prepend>
                      <b-input-group-text>距離：</b-input-group-text>
                    </b-input-group-prepend>

                    <b-form-input type="number" min="0" step="0.01" v-model="measure.distance" @focus="changedValue(2)"  autocomplete="off" />

                    <b-input-group-append>
                      <b-input-group-text>m</b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <div v-bind:class="isExpiration ? 'section-panel p-3 text-muted' : 'section-panel p-3'">
                <b-form-radio :disabled="isExpiration" class="col-12 mb-2" v-model="measure.way" name="measure_way" value="3">縮尺をもとに測定</b-form-radio>

                <div class="pl-4" v-b-tooltip.hover :title="tooltipTitle">
                  <b-input-group class="col-7">
                    <b-input-group-prepend>
                      <b-input-group-text :style="{ 'color': isExpiration ? '#6c757d' : '' }">縮尺：１／</b-input-group-text>
                    </b-input-group-prepend>

                    <b-form-input :disabled="isExpiration" type="number" max="2000" min="0" v-model="measure.scale" :style="{ 'pointer-events': isExpiration ? 'none' : 'auto' }" @focus="changedValue(3)" autocomplete="off" />
                  </b-input-group>

                  <p class="col-12 mt-3 mb-0">※縮尺率を変更された図面に対しては使用できません</p>
                </div>
              </div>

              <div class="section-panel p-3">
                <b-btn variant="primary" class="mr-3 px-5 py-2" type="submit">測定</b-btn>
              </div>
            </b-form>

            <p class="pt-3 px-3">測定結果</p>

            <div class="section-panel p-3">
              <div class="row">
                <div class="col-lg-10 col-6 form-group mb-0">
                  <p class="py-1 px-2">
                    縮尺：
                    <span class="ml-2">1</span>
                    <span class="ml-2">/</span>
                    <span class="ml-2">{{ measure.result_scale ? Math.round(measure.result_scale) : '-' }}</span>
                  </p>
                </div>
                <div class="col-lg-10 col-6 form-group mb-0">
                  <p class="py-1 px-2 mb-0">
                    面積：
                    <span class="ml-2" v-if="isCompleteCorrect">{{ correct.result_area ? Math.round(correct.result_area * 100) / 100 : '-' }}</span>
                    <span class="ml-2" v-else>{{ measure.result_area ? measure.result_area : '-' }}</span>
                    <span class="ml-2">㎡</span>
                  </p>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab :disabled="isExpiration">
            <template #title>
              <span>補正</span>
              <b-icon-award-fill v-if="isExpiration" class="freemium-icon ml-2" font-scale="1" />
            </template>
            <p class="pt-3 px-3">以下のいずれかの方法で補正ができます。</p>

            <div class="section-panel p-3">
              <b-form-radio v-model="correct.way" name="correct_way" value="1" class="mb-2">面積で補正</b-form-radio>
              <b-form-radio v-model="correct.way" name="correct_way" value="2" class="mb-2">辺の長さで補正</b-form-radio>
              <b-form-radio v-model="correct.way" name="correct_way" value="3">一辺の長さで補正</b-form-radio>

              <template v-if="correct.way === '1'">
                <p class="mt-3">評価対象地の面積を入力してください。</p>
                <b-input-group class="pl-4 mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text>評価対象地の面積：</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="number" min="0" v-model="correct.area" autocomplete="off" />
                  <b-input-group-append>
                    <b-input-group-text>㎡</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </template>

              <template v-if="correct.way === '2'">
                <p class="mt-3">評価対象地の面積を入力してください。</p>
                <b-input-group class="pl-4 mb-3">
                  <b-input-group-prepend>
                    <b-input-group-text>評価対象地の面積：</b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="number" min="0" v-model="correct.area" autocomplete="off" />
                  <b-input-group-append>
                    <b-input-group-text>㎡</b-input-group-text>
                  </b-input-group-append>
                </b-input-group>

                <p>辺の長さを入力してください。</p>
                <div class="row mb-2">
                  <div class="col-4"></div>
                  <div class="col-4">測定時の長さ(m)</div>
                  <div class="col-4">補正後の長さ(m)</div>
                </div>
                <div v-for="n in selectingPlat.vertexes.length" :key="n" class="row mb-2">
                  <div class="col-4 d-flex justify-content-end align-items-center">
                    <template v-if="n > 0">{{ selectingPlat.vertexes[n - 1].name }}</template>
                    <template v-else>{{ selectingPlat.vertexes[selectingPlat.vertexes.length - 1].name }}</template>－
                    <template v-if="n === selectingPlat.vertexes.length">{{ selectingPlat.vertexes[0].name }}</template>
                    <template v-else>{{ selectingPlat.vertexes[n].name }}</template>
                  </div>
                  <div class="col-4 d-flex justify-content-end align-items-center">
                    <template v-if="n === selectingPlat.vertexes.length && selectingPlat.vertexes[0].distance_meter">{{ Math.round(selectingPlat.vertexes[0].distance_meter * 100) / 100 }}</template>
                    <template v-else-if="selectingPlat.vertexes[n].distance_meter">{{ Math.round(selectingPlat.vertexes[n].distance_meter * 100) / 100 }}</template>
                  </div>
                  <b-input-group class="col-4">
                    <template v-if="n === selectingPlat.vertexes.length">
                      <b-form-input type="number" min="0" v-model="selectingPlat.vertexes[0].distance_expected" autocomplete="off" />
                    </template>
                    <template v-else>
                      <b-form-input type="number" min="0" v-model="selectingPlat.vertexes[n].distance_expected" autocomplete="off" />
                    </template>
                  </b-input-group>
                </div>
              </template>

              <template v-if="correct.way === '3'">
                <p class="mt-3">辺の長さを入力してください。</p>
                <div class="row mb-2">
                  <div class="col-4"></div>
                  <div class="col-4">測定時の長さ(m)</div>
                  <div class="col-4">補正後の長さ(m)</div>
                </div>
                <div class="row mb-2">
                  <b-input-group class="col-4">
                    <b-form-select class="d-flex justify-content-end align-items-center" v-model="selectingSideIndex">
                      <b-form-select-option v-for="n in selectingPlat.vertexes.length" :key="n" :value="n - 1">
                        <template v-if="n > 0">{{ selectingPlat.vertexes[n - 1].name }}</template>
                        <template v-else>{{ selectingPlat.vertexes[selectingPlat.vertexes.length - 1].name }}</template>－
                        <template v-if="n === selectingPlat.vertexes.length">{{ selectingPlat.vertexes[0].name }}</template>
                        <template v-else>{{ selectingPlat.vertexes[n].name }}</template>
                      </b-form-select-option>
                    </b-form-select>
                  </b-input-group>
                  <div class="col-4 d-flex justify-content-end align-items-center">
                    <template v-if="selectingPlat.vertexes[(selectingSideIndex + 1 < selectingPlat.vertexes.length ? selectingSideIndex + 1 : 0)].distance_meter">{{ Math.round(selectingPlat.vertexes[(selectingPlat.vertexes.length > selectingSideIndex + 1 ? selectingSideIndex + 1 : 0)].distance_meter * 100) / 100 }}</template>
                  </div>
                  <b-input-group class="col-4">
                    <b-form-input type="number" min="0" v-model="selectingPlat.vertexes[(selectingSideIndex + 1 < selectingPlat.vertexes.length ? selectingSideIndex + 1 : 0)].distance_expected" autocomplete="off" />
                  </b-input-group>
                </div>
              </template>
            </div>

            <div class="section-panel p-3">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <span>固定しておく頂点を選択してください。</span>
              </div>
              <div class="pl-4 mb-3">
                <b-checkbox @change="selectAll" v-model="correct.isSelectAll">全て選択</b-checkbox>
                <b-checkbox-group v-if="selectingPlat.vertexes" v-model="correct.fixedVertexes">
                  <b-checkbox v-for="(v, i) in selectingPlat.vertexes" :key="i" :value="i">{{ v.name }}</b-checkbox>
                </b-checkbox-group>
                <div v-else>処理対象の図形が選択されていません。</div>
              </div>
            </div>

            <div class="section-panel p-3">
              <b-btn variant="primary" class="mr-3 px-5 py-2" @click="doCorrectArea">補正</b-btn>
              <b-btn variant="outline-secondary" class="p-2" @click="measureArea">クリア</b-btn>
            </div>

            <p class="pt-3 px-3" v-if="!correct.result_area">測定結果</p>

            <div class="section-panel p-3" v-if="!correct.result_area">
              <div class="row">
                <div class="col-lg-10 col-6 form-group mb-0">
                  <p class="py-1 px-2">
                    縮尺：
                    <span class="ml-2">1</span>
                    <span class="ml-2">/</span>
                    <span class="ml-2">{{ measure.result_scale ? Math.round(measure.result_scale) : '-' }}</span>
                  </p>
                </div>
                <div class="col-lg-10 col-6 form-group mb-0">
                  <p class="py-1 px-2 mb-0">
                    面積：
                    <span class="ml-2" v-if="isCompleteCorrect">{{ correct.result_area ? Math.round(correct.result_area * 100) / 100 : '-' }}</span>
                    <span class="ml-2" v-else>{{ measure.result_area ? measure.result_area : '-' }}</span>
                    <span class="ml-2">㎡</span>
                  </p>
                </div>
              </div>
            </div>

            <p class="pt-3 px-3">補正後の測定結果</p>

            <div class="section-panel p-3">
              <p class="py-1 px-2 mb-0">
                面積：
                <span class="ml-2">{{ correct.result_area ? Math.round(correct.result_area * 100) / 100 : '-' }}</span>
                <span class="ml-2">㎡</span>
              </p>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div ref="dummyMeter" style="width: 100cm;"></div>

    <b-modal v-for="(pdf, index) in pdfFiles" :key="index" :id="`pdf-${index}`" hide-footer body-class="p-0" dialog-class="pdf-image" :title="`${pdf.filename} (${pdf.page}ページ)`">
      <img :src="pdf.image" style="width: 100%;" />
    </b-modal>

    <b-modal :ref="`inputUnityScale-${rIndex}`" :id="`inputUnityScale-${rIndex}`" title="縮尺の指定" @ok="validateUnityScale">
      <div class="form-group">
        <label>統一の縮尺率を指定してください。</label>
        <div>
          こちらで指定した縮尺率を画面上での基準縮尺率とします。
        </div>
        <div>
          以降の画面でも縮尺率の変更は可能です。
        </div>
        <div class="mb-2">
          ※複数の図面がある場合にこちらで指定した縮尺率に大きさを揃えることができます。
        </div>
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text class="text-dark">１／</b-input-group-text>
          </b-input-group-prepend>
          <b-form-input v-model="inputUnityScale"/>
        </b-input-group>
      </div>
    </b-modal>

    <video-modal v-if="helpUrlVideo" :url="helpUrlVideo"></video-modal>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CanvasMixin from '../../mixins/canvas'
import ChangeTitle from '../../components/ChangeTitle'
import VideoModal from '../../components/VideoModal'
import PlayVideoBtn from '../../components/PlayVideoBtn'
import validation from '../../mixins/validation'
import videoWatch from '../../mixins/video_watch'
import constants from '../../constants'
import colors from '../../colors'

const SCALE_STEP = 0.1

export default {
  name: 'Correct',
  mixins: [CanvasMixin, validation, videoWatch],
  props: {
    base64Image: String,
    vertexes: Array
  },
  components: {
    ChangeTitle,
    VideoModal,
    PlayVideoBtn
  },
  data: () => {
    return {
      color: colors.color, // これを書いておかないと、colors 変数使っていないとのエラーになる
      resizeTimeout: undefined,
      canvasWidth: 0,
      canvasHeight: 600,
      canvasContext: undefined,
      canvasContextPDF: undefined,
      plats: [],
      pdfFiles: [],
      measure: { way: '', area: '', start_vertex: '', end_vertex: '', distance: '', scale: '', result_scale: '', result_area: '' },
      correct: { way: '', area: '', fixedVertexes: [] },
      isCompleteCorrect: false, // 補正完了かどうかのフラグ (true: 完了している, false: まだ補正を行なっていない)
      unityScale: '',
      scaleIndex: 0,
      scaleIndexPDF: 0,
      drag: { dragging: 0, x: 0, y: 0 },
      title: '',
      platFrom: 0,
      platTo: 0,
      showTwoCanvas: false,
      showPdfCanvas: false,
      image: new Image(),
      position: { x: 0, y: 0 },
      dragPDF: { dragging: 0, startImagePos: { x: 0, y: 0 }, startMousePos: { x: 0, y: 0 } },
      pdfDragging: false,
      pdfIndex: '',
      inputUnityScale: '',
      gpxFlag: false,
      freeHandFlag: false,
      areaScale: undefined,
      helpUrl: '',
      showPdfDropdown: true,
      helpUrlVideo: '',
      needPlayVideo: false,
      isExpiration: false,
      tooltipTitle: '',
      selectingSideIndex: 0
    }
  },
  computed: {
    ...mapState({
      isGoogleMapUsed: state => state.googleMap.isGoogleMapUsed
    }),
    pixelsPerMeter () {
      return (100000 / 25.4)
    },
    selectingPlat () {
      const selectedPlat = this.plats.filter(p => p.selected)
      if (selectedPlat.length > 0) {
        return selectedPlat[0]
      }

      return {}
    },
    selectingVertexOptions () {
      const options = []
      if (!this.selectingPlat.vertexes) {
        return options
      }

      this.selectingPlat.vertexes.forEach((vertex, index) => {
        options.push({ value: index, text: vertex.name })
      })

      return options
    },
    scale () {
      return 1 + this.scaleIndex * SCALE_STEP
    },
    scalePDF () {
      return 1 + this.scaleIndexPDF * SCALE_STEP
    },
    scaledImageSize () {
      return {
        width: this.$refs.canvas.width / this.scale,
        height: this.$refs.canvas.height / this.scale
      }
    },
    scaledImageSizePDF () {
      return {
        width: this.$refs.canvas.width / this.scalePDF,
        height: this.$refs.canvas.height / this.scalePDF
      }
    }
  },
  mounted () {
    this.$emit('loading', true)

    const username = this.$store.getters['user/user'].username
    const url = `/api/account/stripeExpiration/${username}`
    this.axios.post(url).then(res => {
      this.isExpiration = res.data.isExpiration
      if (this.isExpiration) {
        this.tooltipTitle = '有料版でご利用になれます'
      }
      this.$emit('loading', false)
    }).catch(error => {
      this.$emit('loading', false)
      if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
        this.validated(error.response.data)
      } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
        this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
      }
    })

    // 操作説明のURL取得
    this.axios.get('/api/master/search/help_urls').then(res => {
      const autoPlayedVideos = localStorage.getItem('autoPlayedVideos')
        ? JSON.parse(localStorage.getItem('autoPlayedVideos')) : []

      if (this.isGoogleMapUsed) {
        this.helpUrl = res.data.urls.step2_FreehandWithGoogleMap
        this.helpUrlVideo = res.data.urls.step2_FreehandWithGoogleMap_video
      } else {
        this.helpUrl = res.data.urls.step2
        this.helpUrlVideo = res.data.urls.step2_video
      }
      if (this.helpUrlVideo && !autoPlayedVideos.includes(this.helpUrlVideo)) {
        this.needPlayVideo = true
        autoPlayedVideos.push(this.helpUrlVideo)
        localStorage.setItem('autoPlayedVideos', JSON.stringify(autoPlayedVideos))
      }
    }).catch(error => {
      this.errors = { error: `システムエラーが発生しました。${error}` }
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })

    this.axios.get(`/api/kagechi/correct/${this.$route.params.main_id}`).then(res => {
      this.pdfFiles = res.data.pdfs
      this.title = res.data.title
      this.unityScale = res.data.unityScale ? String(res.data.unityScale) : ''
      this.plats = res.data.plats
      this.plats.forEach(plat => {
        plat.imageObj = new Image()
        plat.imageObj.onload = () => {
          plat.imageLoaded = true
        }
        if (this.$route.query.image !== undefined) {
          plat.imageObj.src = this.$route.query.image
        } else {
          plat.imageObj.src = plat.image
        }
        plat.init_vertexes = []
        plat.vertexes.forEach(v => {
          plat.init_vertexes.push({ ...v })
        })
      })
      this.measure = res.data.plats[0].measure

      this.setShowPdfDropdownFlag()
      this.initCanvas()

      setTimeout(() => {
        let areaVal = ''
        let measureWay = ''

        if (this.pdfFiles[0].filename.endsWith('.gpx')) {
          const standardDist = this.calcDist(
            this.plats[0].vertexes[0].lat * (Math.PI / 180),
            this.plats[0].vertexes[0].lon * (Math.PI / 180),
            this.plats[0].vertexes[1].lat * (Math.PI / 180),
            this.plats[0].vertexes[1].lon * (Math.PI / 180)
          )
          const dist = Math.sqrt(
            this.calcDistanceDot2Dot(
              this.plats[0].vertexes[0].x,
              this.plats[0].vertexes[0].y,
              this.plats[0].vertexes[1].x,
              this.plats[0].vertexes[1].y
            )
          )
          this.areaScale = Math.pow((standardDist / dist), 2)
          areaVal = Math.round(this.calcPlatArea(this.plats[0].vertexes) * this.areaScale * 100) / 100
          this.gpxFlag = true
          measureWay = '1'
        } else if (this.pdfFiles[0].filename === 'FreeHand') {
          if (this.$route.query.areaVal !== undefined) {
            areaVal = this.$route.query.areaVal
          } else {
            const standardDist = this.calcDist(
              this.plats[0].vertexes[0].lat * (Math.PI / 180),
              this.plats[0].vertexes[0].lon * (Math.PI / 180),
              this.plats[0].vertexes[1].lat * (Math.PI / 180),
              this.plats[0].vertexes[1].lon * (Math.PI / 180)
            )
            const dist = Math.sqrt(
              this.calcDistanceDot2Dot(
                this.plats[0].vertexes[0].x,
                this.plats[0].vertexes[0].y,
                this.plats[0].vertexes[1].x,
                this.plats[0].vertexes[1].y
              )
            )
            this.areaScale = Math.pow((standardDist / dist), 2)
            if (this.measure.area) {
              areaVal = this.measure.area
            } else {
              areaVal = Math.round(this.calcPlatArea(this.plats[0].vertexes) * this.areaScale * 100) / 100
            }
          }
          this.freeHandFlag = true
          measureWay = '1'
        }

        this.plats[0].measure = {
          way: measureWay,
          area: areaVal,
          start_vertex: '',
          end_vertex: '',
          distance: '',
          scale: '',
          result_scale: '',
          result_area: ''
        }

        // 1番目の土地を選択
        this.$set(this.plats[0], 'selected', true)
      }, 50)

      const resizeObserver = new ResizeObserver((entries) => {
        const loadingPlats = this.plats.filter(plat => !plat.imageLoaded)
        if (loadingPlats.length === 0) {
          this.resizeWindow()
        }
      })

      if (this.$refs.box) {
        resizeObserver.observe(this.$refs.box)
      }

      setTimeout(() => {
        if (this.pdfFiles[0].filename.endsWith('.gpx') || this.measure.area) {
          this.measureArea()
        }
      }, 500)

      this.$emit('loading', false)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeWindow)
  },
  watch: {
    selectingPlat: {
      deep: true,
      handler (plat, beforePlat) {
        const defaultMeasure = {
          way: '',
          area: '',
          start_vertex: '',
          end_vertex: '',
          distance: '',
          scale: '',
          result_scale: '',
          result_area: ''
        }
        const defaultCorrect = {
          way: '',
          area: '',
          fixedVertexes: []
        }

        if (!plat || plat.id !== beforePlat.id) {
          // 今まで選択されていた図形の情報を保管しておく
          beforePlat.measure = { ...this.measure }
          beforePlat.correct = { ...this.correct }

          // 次に選択される図形の情報を復元する
          if (plat) {
            this.measure = { ...defaultMeasure, ...plat.measure }
            this.correct = { ...defaultCorrect, ...plat.correct }
            if (!plat.correct && plat.vertexes) {
              plat.vertexes.forEach((v, i) => {
                this.correct.fixedVertexes.push(i)
              })
            }
          }
        }

        if (this.measure.result_scale !== '') {
          this.inputUnityScale = parseInt(Math.round(this.measure.result_scale * 100) / 100).toString()
        }
      }
    }
  },
  methods: {
    ...mapActions('gpx', ['saveCoordinates']),

    initCanvas () {
      if (this.$refs.box === undefined) {
        return
      }
      this.canvasWidth = this.$refs.box.offsetWidth - 4 // border分だけマイナスする

      if (this.$refs.canvas && this.$refs.canvas.width > 0 && this.$refs.box.offsetWidth > 0) {
        this.canvasContext = this.$refs.canvas.getContext('2d', { willReadFrequently: true })
        this.canvasTopLeftPosition = {
          x: this.$refs.canvas.getBoundingClientRect().left,
          y: this.$refs.canvas.getBoundingClientRect().top
        }

        let allLoaded = false
        while (!allLoaded) {
          const loadingPlats = this.plats.filter(plat => !plat.imageLoaded)
          if (loadingPlats.length === 0) {
            allLoaded = true
          }
        }

        setTimeout(() => {
          if (this.showPdfCanvas === false) {
            this.canvasUpdate()
          }
          if (this.showPdfCanvas === true && this.showTwoCanvas === false) {
            this.closeCanvasPDF()
            this.openCanvasPDF(this.pdfIndex)
            this.showTwoCanvas = true
          }
          this.canvasUpdate()
        }, 10)
      } else {
        setTimeout(() => {
          this.initCanvas()
        }, 500)
      }
    },
    canvasUpdate () {
      // 真っ白にする
      this.canvasContext.fillStyle = 'white'
      this.canvasContext.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContext.save()

      let x = 30
      let y = 30
      let maxY = 0
      let fillColor

      this.plats.forEach((plat, index) => {
        if (!plat.relativePosition) {
          // 初回描画時
          plat.relativePosition = { x: x, y: y }
        }
        this.canvasContext.drawImage(
          plat.imageObj,
          plat.relativePosition.x * this.scale,
          plat.relativePosition.y * this.scale,
          plat.imageObj.width * this.scale,
          plat.imageObj.height * this.scale
        )

        maxY = Math.max(maxY, y)

        if (this.plats[index + 1]) {
          // 次の土地がある
          if ((x + plat.imageObj.width + this.plats[index + 1].imageObj.width + 20) > this.canvasWidth) {
            // 右隣に次の土地を描画できない
            x = 20
            maxY = 0
            y = maxY + 200
          } else {
            x += (plat.imageObj.width + 20)
          }
        }
      })
      // 色反転
      this.colorInversion(this.canvasContext, this.canvasWidth)

      this.plats.forEach((plat) => {
        // 画像の上に多角形を描画する
        fillColor = plat.selected ? `${colors.selectingLayerColorRgba(true)}` : `${colors.platColorRgba(true)}` // カラーモード切替が存在しないので、ダークモードとする
        this.drawPlat(this.canvasContext, plat.vertexes, this.scale, { x: -plat.relativePosition.x, y: -plat.relativePosition.y }, fillColor)
      })
    },
    changeScale (event) {
      const isZoom = event.wheelDelta > 0
      this.scaleIndex = isZoom ? this.scaleIndex + 1 : Math.max(-9, this.scaleIndex - 1)

      this.canvasUpdate()
    },
    scalePlus () {
      const event = { wheelDelta: 1, clientX: 0, clientY: 0 }
      this.changeScale(event)
    },
    scaleDash () {
      const event = { wheelDelta: -1, clientX: 0, clientY: 0 }
      this.changeScale(event)
    },
    /**
     * canvasの左上の座標位置を取得する
     * @returns {number[]}
     */
    getCanvasTopLeftPosition () {
      const rect = this.$refs.canvas.getBoundingClientRect()
      return [rect.left, rect.top]
    },
    /**
     * Canvas内のマウスポインターの座標位置を取得する
     * @param event
     * @returns {number[]}
     */
    getPointerPosition (event) {
      const invertedScaleX = 1 / this.scale
      const invertedScaleY = 1 / this.scale

      const transformedX = invertedScaleX * event.offsetX - invertedScaleX * this.scale
      const transformedY = invertedScaleY * event.offsetY - invertedScaleY * this.scale

      return [
        transformedX,
        transformedY
      ]
    },
    selectAll () {
      if (!this.correct.isSelectAll) {
        this.correct.fixedVertexes = []
        return
      }

      this.selectingPlat.vertexes.forEach((v, i) => {
        this.correct.fixedVertexes.push(i)
      })
    },
    mouseDown (event) {
      // canvas内でのマウスポインタの位置
      const [x, y] = this.getPointerPosition(event)

      // 座標が図形の中かどうか
      this.plats.forEach(plat => {
        this.$set(plat, 'selected',
          this.isInPolygon(plat.vertexes, x - plat.relativePosition.x,
            y - plat.relativePosition.y))
        if (plat.selected) {
          this.drag = { dragging: 1, x: x, y: y, rx: plat.relativePosition.x, ry: plat.relativePosition.y }
        }
      })

      this.canvasUpdate()
    },
    mouseMove (event) {
      const [x, y] = this.getPointerPosition(event)
      if (this.drag.dragging > 0) {
        // ドラッグが開始されていればオブジェクトの座標を更新して再描画
        this.drag.dragging += 1

        const beforeSelectedPlat = this.plats.filter(p => p.selected)
        if (beforeSelectedPlat.length > 0) {
          beforeSelectedPlat[0].relativePosition.x = x - this.drag.x + this.drag.rx
          beforeSelectedPlat[0].relativePosition.y = y - this.drag.y + this.drag.ry
        }
        this.canvasUpdate()
      }
    },
    mouseUp (event) {
      this.canvasUpdate()
      this.drag.dragging = 0
    },
    // 測定
    measureArea () {
      this.errors = {}
      if (!this.selectingPlat.vertexes) {
        this.errors = { error: '測定対象の土地を選択してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }
      const vertexes = []
      this.selectingPlat.before_correct_vertexes.forEach((v, i) => {
        vertexes.push({ ...v })
      })
      this.selectingPlat.vertexes = vertexes
      this.correct = { way: '', area: '', fixedVertexes: [] }
      this.selectingPlat.vertexes.forEach((v, i) => {
        this.correct.fixedVertexes.push(i)
      })
      this.selectingPlat.correct = null
      const pixelArea = this.calcPlatArea(this.selectingPlat.vertexes)
      const meterArea = pixelArea / Math.pow((this.pixelsPerMeter), 2)
      if (this.measure.way === '1' && this.measure.area) {
        // 面積をもとに測定
        const areaScale = this.measure.area / meterArea
        this.$set(this.measure, 'result_area', Math.round(meterArea * areaScale * 100) / 100)
        this.$set(this.measure, 'result_scale', Math.sqrt(areaScale))

        const convertToMilli = v => {
          return v * 25.4 / 100 // 200はPDFを画像に変換するときのDPI値
        }
        this.selectingPlat.vertexes.forEach(vertex => {
          vertex.distance_meter = convertToMilli(vertex.distance) / 1000 * this.measure.result_scale
        })

        this.isCompleteCorrect = false // 補正完了フラグを false に設定
        this.canvasUpdate()
      } else if (this.measure.way === '2' &&
        (this.measure.start_vertex || this.measure.start_vertex === 0) &&
        (this.measure.end_vertex || this.measure.end_vertex === 0) &&
        this.measure.distance) {
        // 距離を元に測定
        const pixelDistance = this.calcDistance(
          this.selectingPlat.vertexes[this.measure.start_vertex], this.selectingPlat.vertexes[this.measure.end_vertex])
        const convertToMilli = v => {
          return v * 25.4 / 100 // 200はPDFを画像に変換するときのDPI値
        }
        const scale = this.measure.distance / convertToMilli(pixelDistance) * 1000

        this.$set(this.measure, 'result_area', Math.round(meterArea * Math.pow(scale, 2) * 100) / 100)
        this.$set(this.measure, 'result_scale', scale)

        this.selectingPlat.vertexes.forEach(vertex => {
          vertex.distance_meter = convertToMilli(vertex.distance) / 1000 * this.measure.result_scale
        })

        this.isCompleteCorrect = false // 補正完了フラグを false に設定
        this.canvasUpdate()
      } else if (this.measure.way === '3' && this.measure.scale) {
        // 用紙サイズと縮小率をもとに測定
        this.$set(this.measure, 'result_area', parseFloat(Math.round(meterArea * Math.pow(this.measure.scale, 2) * 100) / 100))
        this.$set(this.measure, 'result_scale', parseInt(this.measure.scale))

        this.selectingPlat.vertexes.forEach(vertex => {
          vertex.distance_meter = vertex.distance / this.pixelsPerMeter * this.measure.scale
        })

        this.isCompleteCorrect = false // 補正完了フラグを false に設定
        this.canvasUpdate()
      } else {
        this.errors = { error: '測定方法と必要事項を入力してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    // 補正
    doCorrectArea () {
      // 指定条件チェック
      this.errors = {}

      if (this.correct.way === '' || this.correct.way === undefined) {
        this.errors = { error: '補正方法を選択してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      if (!this.selectingPlat.vertexes) {
        this.errors = { error: '補正対象の土地を選択してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      if (!this.measure.result_area || !this.measure.result_scale) {
        this.errors = { error: '先に「測定」を行ってください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      const setArea = (this.correct.area !== '' && this.correct.area !== undefined)
      if (this.correct.way === '1') {
        if (!setArea) {
          this.errors = { error: '面積を入力してください。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return
        } else if (Math.round(this.correct.result_area * 100) / 100 === Math.round(this.correct.area * 100) / 100) {
          // 面積指定補正で既に同じ面積の場合 = 補正完了
          this.isCompleteCorrect = true
          return
        }
      } else if (this.correct.way === '2') {
        let checkDistanceInputs = false
        for (let i = 0; i < this.selectingPlat.vertexes.length; i++) {
          if (this.selectingPlat.vertexes[i].distance_expected !== '' && this.selectingPlat.vertexes[i].distance_expected !== undefined) {
            checkDistanceInputs = true
            if (isNaN(this.selectingPlat.vertexes[i].distance_expected) || Math.sign(this.selectingPlat.vertexes[i].distance_expected) <= 0) {
              this.errors = { error: '補正後の辺の長さが異常です。' }
              window.scrollTo({ top: 0, behavior: 'smooth' })
              return
            }
          }
        }
        if (!checkDistanceInputs) {
          this.errors = { error: '補正後の辺の長さを入力してください。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return
        }
      } else if (this.correct.way === '3') {
        const sideInput = this.selectingPlat.vertexes[(this.selectingSideIndex + 1 < this.selectingPlat.vertexes.length ? this.selectingSideIndex + 1 : 0)].distance_expected
        if (sideInput === '' || sideInput === undefined) {
          this.errors = { error: '補正後の辺の長さを入力してください。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return
        } else if (isNaN(sideInput) || Math.sign(sideInput) <= 0) {
          this.errors = { error: '補正後の辺の長さが異常です。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          return
        }
      }

      const check = this.judgeMovingVertex(this.correct.fixedVertexes, this.selectingPlat.vertexes.length)
      if (check === 0) {
        this.errors = { error: '動かしてよい点を選択してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      if (this.correct.way === '2' && setArea && check === 1) {
        this.errors = { error: '長さを変更する辺の頂点以外で動かしてよい点を選択してください。' }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }

      // 補正ループ処理
      this.doCorrectAreaLoop(setArea)

      this.isCompleteCorrect = true
    },
    doCorrectAreaLoop (setArea) {
      let count = 0
      while (!this.isExpected(this.selectingPlat.vertexes, setArea) && count < 9) {
        this.correctArea(setArea)
        count++
      }
    },
    isExpected (vertexes, setArea) {
      let judge = true

      if (this.correct.way === 1) {
        // 面積指定補正の場合、面積一致でtrue
        if (Math.round(this.correct.result_area * 100) / 100 !== Math.round(this.correct.area * 100) / 100) {
          judge = false
        }
      } else {
        // 辺指定補正の場合、面積指定一致かつ全指定辺一致でtrue
        if (setArea) {
          if (Math.round(this.correct.result_area * 100) / 100 !== Math.round(this.correct.area * 100) / 100) {
            judge = false
          }
        }

        vertexes.forEach(v => {
          if ((v.distance_expected !== '' || v.distance_expected !== undefined) &&
            Math.round(v.distance_expected * 100) / 100 !== Math.round(v.distance_meter * 100) / 100) {
            judge = false
          }
        })
      }

      return judge
    },
    correctArea (setArea) {
      this.errors = {}

      if (this.correct.way === '1') {
        // 面積指定補正の場合、縦横均等補正
        const centroid = this.calcCentroid(this.selectingPlat.init_vertexes)
        const scale = Math.sqrt(this.correct.area / this.measure.result_area)
        const meterArea = this.correctionScale(
          centroid,
          this.selectingPlat,
          this.correct.fixedVertexes,
          scale,
          this.measure.result_scale,
          this.correct)
        this.$set(this.correct, 'result_area', meterArea)
        if (meterArea === -1) {
          this.errors = { error: '補正に失敗しました。評価対象地の面積を再入力し、再度実行して下さい。' }
          window.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
          this.canvasUpdate()
        }
      } else {
        // 辺指定補正の場合、辺の長さの調整
        const meterArea = this.correctionLength(
          this.selectingPlat.vertexes,
          this.correct.fixedVertexes,
          this.measure.result_scale)
        this.$set(this.correct, 'result_area', meterArea)

        if (setArea) {
          // 面積が指定されている場合は面積指定で再補正

          // 指定面積と辺指定補正結果面積の条件チェック
          let inputSideCount = 0
          this.selectingPlat.vertexes.forEach(v => {
            if (v.distance_expected !== '' && v.distance_expected !== undefined) {
              inputSideCount++
            }
          })
          if (this.selectingPlat.vertexes.length === inputSideCount &&
            ((Math.round(meterArea * 100) / 100 < (Math.round(this.correct.area * 100) / 100) - 1) ||
            (Math.round(meterArea * 100) / 100 > (Math.round(this.correct.area * 100) / 100) + 1))) {
            this.errors = { error: '補正に失敗しました。補正条件を再入力し、再度実行して下さい。' }
            window.scrollTo({ top: 0, behavior: 'smooth' })
            return
          }

          const centroid = this.calcCentroid(this.selectingPlat.vertexes)
          const scale = Math.sqrt(this.correct.area / this.measure.result_area)
          const tempResultArea = this.correctionScale(
            centroid,
            this.selectingPlat,
            this.correct.fixedVertexes,
            scale,
            this.measure.result_scale,
            this.correct)

          if (tempResultArea === -1) {
            this.errors = { error: '補正に失敗しました。補正条件を再入力し、再度実行して下さい。' }
            window.scrollTo({ top: 0, behavior: 'smooth' })
            return
          } else {
            this.correct.result_area = tempResultArea
          }
        }

        this.canvasUpdate()
      }
    },
    next () {
      this.$emit('loading', true)
      const plats = this.makePlats()

      const data = {
        plats,
        scale: this.unityScale
      }

      this.axios.post(`/api/kagechi/correct/${this.$route.params.main_id}`, data).then(res => {
        this.$emit('loading', false)
        this.$router.push({ path: `/kagechi/edit1/${this.$route.params.main_id}`, query: { unityScale: this.unityScale, paste: this.pasteMeasure } })
      }).catch(error => {
        this.$emit('loading', false)
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
      })
    },
    complementKeysInMeasurement (measurement) {
      // 測定情報に欠けている情報があれば補完する
      if (!('start_vertex' in measurement)) {
        measurement.start_vertex = ''
      }
      if (measurement.start_vertex === undefined) {
        measurement.start_vertex = ''
      }
      if (!('end_vertex' in measurement)) {
        measurement.end_vertex = ''
      }
      if (measurement.end_vertex === undefined) {
        measurement.end_vertex = ''
      }
    },
    resizeWindow () {
      this.showTwoCanvas = false
      this.initCanvas()
    },
    vertexesInPlats (indexPlats) {
      const options = []
      options.push({ value: '', text: '-' })
      this.plats[indexPlats - 1].vertexes.forEach((vertex, index) => {
        options.push({ value: index, text: vertex.name })
      })

      return options
    },
    indexesInPlats () {
      const options = []

      this.plats.forEach((plat, index) => {
        options.push({ value: index, text: `土地${index + 1}` })
      })

      return options
    },
    openCanvasPDF (index) {
      this.showPdfCanvas = true
      this.canvasWidth = this.canvasWidth / 2
      this.pdfIndex = index
      setTimeout(() => {
        this.canvasUpdatePDF(index)
        // 元の画面を更新
        this.canvasUpdate()
        this.scaleIndex = this.scaleIndex + 1
        this.canvasUpdate()
        this.scaleIndex = Math.max(0, this.scaleIndex - 1)
        this.canvasUpdate()
        this.canvasUpdatePDF(index)
      }, 50)
    },
    canvasUpdatePDF (index) {
      // 描画
      this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
      const scaledSize = this.scaledImageSizePDF
      this.image.src = this.pdfFiles[index].image
      this.image.onload = () => {
        this.canvasContextPDF.drawImage(this.image, this.position.x, this.position.y, scaledSize.width, scaledSize.height,
          0, 0, scaledSize.width * this.scalePDF, scaledSize.height * this.scalePDF)
        this.canvasContextPDF.restore()
      }
    },
    closeCanvasPDF () {
      this.showPdfCanvas = false
      this.canvasWidth = this.canvasWidth * 2
      this.initCanvas()
      setTimeout(() => {
        this.canvasUpdate()
        this.scaleIndex = this.scaleIndex + 1
        this.canvasUpdate()
        this.scaleIndex = Math.max(0, this.scaleIndex - 1)
        this.canvasUpdate()
      }, 10)
    },
    mouseDownPDF () {
      this.pdfDragging = true
      const [x, y] = this.getPointerPosition(event)
      this.dragPDF = {
        dragging: 1,
        startImagePos: { x: this.position.x, y: this.position.y },
        startMousePos: { x: x - this.$refs.canvas.width, y },
        vertexIndexes: []
      }
    },
    mouseUpPDF () {
      // 真っ白にする
      this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
      this.canvasContextPDF.fillStyle = 'white'
      this.canvasContextPDF.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContextPDF.save()
      this.canvasUpdatePDF(this.pdfIndex)
      this.pdfDragging = false
    },
    mouseMovePDF () {
      // canvas内でのマウスポインタの位置
      if (this.pdfDragging) {
        // 真っ白
        this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
        this.canvasContextPDF.fillStyle = 'white'
        this.canvasContextPDF.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
        this.canvasContextPDF.save()
        const [x, y] = this.getPointerPosition(event)
        this.position.x = this.dragPDF.startImagePos.x + (this.dragPDF.startMousePos.x - (x - this.$refs.canvas.width)) / this.scalePDF
        this.position.y = this.dragPDF.startImagePos.y + (this.dragPDF.startMousePos.y - y) / this.scalePDF
        this.position.x = Math.max(0, this.position.x)
        this.position.y = Math.max(0, this.position.y)
      }
      this.canvasUpdatePDF(this.pdfIndex)
    },
    validateUnityScale () {
      if (this.inputUnityScale > 0) {
        this.unityScale = this.inputUnityScale
        this.next()
      } else {
        this.errors = { error: '適切な値が入力されませんでした。' }
      }
    },
    changeScalePDF (event) {
      const isZoom = event.wheelDelta > 0
      this.scaleIndexPDF = isZoom ? this.scaleIndexPDF + 1 : Math.max(-9, this.scaleIndexPDF - 1)
      // 真っ白にする
      this.canvasContextPDF = this.$refs.canvasPDF.getContext('2d', { willReadFrequently: true })
      this.canvasContextPDF.fillStyle = 'white'
      this.canvasContextPDF.fillRect(0, 0, this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.canvasContextPDF.save()
      this.canvasUpdatePDF(this.pdfIndex)
    },
    judgeMovingVertex (fixedVertexes, vtxLength) {
      // 関数の戻り値
      let retVal = 0

      // 長さを変更する辺を取得
      const lineIndex = []
      this.selectingPlat.vertexes.forEach((v, index) => {
        if (v.distance_expected) {
          lineIndex.push(index)
        }
      })
      // 動かせる頂点を探す
      const moveVtx = []
      for (let i = 0; i < vtxLength; i++) {
        let moneVtxMatch = false
        fixedVertexes.forEach(v => {
          if (i === v) {
            moneVtxMatch = true
          }
        })
        if (!moneVtxMatch) {
          moveVtx.push(i)
        } else {
        }
      }
      if (moveVtx.length === 0) {
        return 0
      }
      let lineMatchCount = 0
      const lineVtx = []
      lineIndex.forEach(i => {
        if (i === 0) {
          moveVtx.some(v => {
            if (v === i || v === vtxLength - 1) {
              lineMatchCount++
              if (!lineVtx.includes(v)) {
                lineVtx.push(v)
              }
              return true
            }
          })
        } else {
          moveVtx.some(v => {
            if (v === i || v === i - 1) {
              lineMatchCount++
              if (!lineVtx.includes(v)) {
                lineVtx.push(v)
              }
              return true
            }
          })
        }
      })
      // 長さを返る辺に含まれる頂点が動かしても良い頂点に含まれているかチェック
      if (lineMatchCount === lineIndex.length) {
        // 長さを返る辺に含まれる頂点群に含まれない頂点が、動かしても良い頂点に含まれているかチェック
        if (moveVtx.length > lineVtx.length) {
          retVal = 2
        } else {
          retVal = 1
        }
      }
      return retVal
    },
    back () {
      if (this.gpxFlag) {
        const resultCoordinate = {}
        this.plats.forEach(plat => {
          plat.vertexes.forEach((vertex, index) => {
            resultCoordinate[0 + '_' + index] = {
              fileIndex: 0,
              crdnIndex: index,
              coordinates: {
                lat: vertex.lat,
                lon: vertex.lon
              }
            }
          })
        })
        this.saveCoordinates(resultCoordinate)
        this.$router.push({ path: `/kagechi/trimmingGPX/${this.$route.params.main_id}` })
      } else if (this.freeHandFlag) {
        this.$router.push({ path: `/kagechi/trimmingFH/${this.$route.params.main_id}` })
      } else {
        this.$router.push({ path: `/kagechi/trimming/${this.$route.params.main_id}` })
      }
    },
    setShowPdfDropdownFlag () {
      const fileName = this.pdfFiles[0].filename
      this.showPdfDropdown = !(fileName.includes('FreeHand') || fileName.includes('.gpx'))
    },
    openInNewTab (url) {
      window.open(url, '_blank', 'noreferrer')
    },
    checkInputData (index) {
      const plats = this.makePlats()
      const data = {
        plats,
        scale: this.unityScale
      }

      this.axios.post(`/api/kagechi/correct/check/${this.$route.params.main_id}`, data).then(res => {
        // エラーがなければモーダル表示
        this.$refs[`inputUnityScale-${index}`].show()
      }).catch(error => {
        this.$emit('loading', false)
        if (error.response.status === constants.HTTP_RESPONSE_CODE.BAD_REQUEST) {
          this.validated(error.response.data)
        } else if (error.response.status === constants.HTTP_RESPONSE_CODE.SYSTEM_ERROR) {
          this.validated({ error: constants.MESSAGE.SYSTEM_ERROR })
        }
      })
    },
    makePlats () {
      const plats = []
      let meterDistances
      this.plats.forEach(plat => {
        if (plat === this.selectingPlat) {
          plat.measure = { ...this.measure }
          plat.correct = { ...this.correct }
        }

        meterDistances = []
        plat.vertexes.forEach(v => {
          meterDistances.push(v.distance_meter)
        })

        if (!plat.measure) {
          plat.measure = { way: '', area: '', start_vertex: '', end_vertex: '', distance: '', scale: '', result_scale: '', result_area: '' }
        }
        // 測定結果の項目チェック＆欠損部分補完
        this.complementKeysInMeasurement(plat.measure)
        plats.push({
          id: plat.id,
          corrected_vertexes: plat.vertexes,
          meter_distances: meterDistances,
          scale: plat.measure ? plat.measure.result_scale : this.measure.result_scale,
          area: (plat.correct && plat.correct.result_area !== undefined) ? plat.correct.result_area : plat.measure.result_area,
          measure: plat.measure,
          correct: plat.correct
        })
      })

      return plats
    },
    // 測定タブ、変更された箇所のラジオボタンをチェックする
    changedValue (way) {
      if (isFinite(way) && !isNaN(way)) {
        this.measure.way = way.toString() // 文字列として設定しないと、ラジオボタンのチェックとみなされない
      }
    }
  }
}
</script>

<style scoped lang="scss">
.input-group {
  & > .form-control, & > .custom-select {
    border-radius: 0.25rem !important;
  }
}
.input-group-text {
  background-color: transparent;
  border: none;
  color: white;
}
.operator-box {
  padding: 4px 8px;
  border: solid 2px #000;
  background-color: #eee;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
}
</style>
