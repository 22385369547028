<template>
  <div class="terms-of-service-text">
    <p>「土地作図くん」基本利用規約</p>

    <p>第１条（目的）</p>
    <ol>
      <li>当社「土地作図くん」基本利用規約（以下「本規約」といいます。）は、当社及び本規約に基づいて本サイト上で提供する本サービスを利用するお客様に対して適用されます。</li>
      <li>本規約は、当社が提供する本サービスに関し、当社とお客様との基本的な権利義務関係を定めることを目的とします。そのうち個別のサービスについては、本規約に加えて、当該サービスに関する個別の利用規約が適用され、本規約と個別の利用規約の内容が異なる場合には、当該個別の利用規約の内容が優先して適用されます。</li>
      <li>お客様は本規約及びお客様が利用申込みをした個別のサービスの利用規約の内容を本サービスの全ての利用者に対して通知し、遵守させなければなりません。</li>
    </ol>

    <p>第２条（定義）</p>
    <ol>
      <li>「利用契約」とは、本規約及び個別のサービスの利用規約（当社が本サイト上で掲載するルール、諸規程及び当社とお客様の個別の合意を含み、以下においても同様とします。）により、当社とお客様の間で締結する、本サービスの利用にかかる契約をいいます。</li>
      <li>「当社」とは、西本町会計合同会社をいいます。</li>
      <li>「お客様」とは、当社と利用契約を締結する個人事業者又は法人をいいます。</li>
      <li>「利用者」とは、お客様が当社より受けた許諾に基づき、本規約及び個別のサービスの利用規約の条件及び条項に従って、本サービスにアクセスし又は本サービスを利用する個人をいいます。</li>
      <li>「本サービス」とは、お客様が自らの事業及び業務を円滑に進めることを目的として、当社及び当社提携先事業者が本規約及び個別の利用規約に基づいて本サイト上で提供するサービス（土地作図くん）をいいます。また、お客様が最初に本サービスのライセンスを取得した後で当社によって提供される本サービスのアップデート、アドオン、コンポーネント、ウェブサービス、スマートフォンアプリ及び追加機能も本サービスに含まれます（これらについて別途、使用許諾契約書、利用規約又は利用条項等が添付されている場合はその規定が本規約に優先するものとします。）。なお、お客様が個別の利用規約に規定するサポート・保守サービス等を利用することができる場合においては、これらのサービスも本サービスに含まれるものとします。</li>
      <li>「利用者データ」とは、お客様又は利用者が、本サービスを用いて入力・作成するデータ及び本サービスの利用に必要なお客様又は利用者の登録情報をいいます。</li>
      <li>「本サイト」とは、当社の本サービス専用ウェブサイト（当社が指定する当社の関係会社のウェブサイトを含み、当該ウェブサイトのドメイン又は内容が変更された場合には、当該変更後のウェブサイトを含みます。）をいいます。</li>
      <li>「ライセンス」とは、当社が、お客様に対し、第4条に基づいて、本サービスへのアクセス又は利用を許諾したことによりお客様が取得する、本サービスにアクセスし、又は本サービスを利用する権利をいいます。</li>
    </ol>

    <p>第３条（数値情報に関する免責事項）</p>
    <ol>
      <li>本サービスは、数値や統計データを提供するための情報源として利用されますが、提供される数値はあくまで参考情報として提供されます。お客様は、自己の責任において提供された数値を慎重に評価し、その利用に関する判断を行うものとします。</li>
      <li>当社は、数値の正確性と信頼性に最大限の注意を払っていますが、提供される数値には誤差や情報の不完全性が含まれる場合があります。お客様は、数値の正確性や信頼性に関して独自の調査や確認を行い、必要な場合は専門家の助言を求めることを推奨します。</li>
      <li>お客様は、提供された数値を自己の責任で利用するものとし、その利用によって生じる一切の結果や損失について当社は責任を負いません。お客様は、数値の利用にあたり、自身の能力や専門知識を適切に活用し、判断を行うことを責任とします。</li>
      <li>お客様は、提供された数値を自身の状況や目的に応じて解釈し、利用する責任を負います。数値の解釈や応用は、お客様自身の判断と専門知識に基づいて行われるべきであり、当社はその結果に関与しません。</li>
      <li>当社は、提供された数値の利用によって得られる結果や効果について、明示的または黙示的な保証を行いません。数値の利用による特定の結果や目的の達成については、お客様の責任と判断に委ねられます。</li>
      <li>当社は、数値情報の利用によって生じるいかなる損害や損失に対しても責任を負いません。これには、直接的な損害、間接的な損害、付随的な損害、逸失利益、データの損失などが含まれます。</li>
      <li>当社は、利用規約を随時変更する権利を有します。変更があった場合は、当社のウェブサイトやアプリケーション上で通知を行います。お客様は、変更後の利用規約に従うものとします。</li>
    </ol>

    <p>第４条（利用許諾内容）</p>
    <p>お客様が本規約及び個別のサービスの利用規約の定めに従うことを条件として、当社はお客様に対し、以下の各号に定める内容で本サービスにアクセス又は利用することを許諾します。</p>
    <ol>
      <li>本サービスを利用するためには、お客様が利用を希望する個別のサービス毎に、当社所定の申込み及び個別の利用規約への同意が必要となります。</li>
      <li>本サービスを利用するためには、当社所定の手続きにより、利用者において、登録していただくことが必要となります。お客様は、利用者に、利用規約を遵守させる責任を負うものとします。</li>
      <li>本サービスの利用契約を締結された場合、お客様は評価データを作成することができます。複数の利用者が、１つのIDを使用することにより、本サービスを同時に利用することはできません。複数の利用者が同時に本サービスを利用する場合には、その利用者数に応じた数のライセンスが必要となります。</li>
      <li>当社は、本サービスに不正な手段によりアクセスし若しくは不正な手段によって本サービスを利用した者、又は本規約若しくは個別のサービスの利用規約に違反する態様で本サービスにアクセス若しくは本サービスを利用した者に対して、いかなる場合においても、本サービスへのアクセス又は本サービスの利用のいずれも許諾しません。</li>
      <li>お客様は、本サービスの利用権の全部又は一部を第三者へ譲渡（有償又は無償を問いません。）、貸与、リース、名義変更し、又は質権その他担保に供することはできず、かつ、第三者に対して再利用許諾することはできません。</li>
      <li>当社とお客様との個別の契約内容により、本サービスの一部の利用が制限される場合があります。</li>
      <li>お客様が、本サービスの内容を第三者に対して提供することにより利益を得る行為は、いかなる場合も認められません。お客様自身が当該行為を行った場合又は当該行為を第三者に行わせた場合、当社は、それらの行為を差し止める権利及びそれらの行為によってお客様が得た利益相当額を請求する権利を有します。</li>
      <li>お客様は本サービスを本規約及び個別のサービスの利用規約に定める目的のためにのみ利用することができます。</li>
      <li>本サービス及び個別のサービスは、著作権法及び著作権に関する条約、その他知的財産権に関する法律及び条約によって保護されています。本サービス及び個別のサービスは、利用許諾されるもので、販売されるものではありません。</li>
    </ol>

    <p>第５条（登録情報変更の届出）</p>
    <ol>
      <li>お客様は、当社に届け出たお客様の住所、電話番号、氏名又は名称、支払手段その他の登録情報に変更があった場合は、当社に対し、速やかに変更の届出をするものとします。</li>
      <li>当社は、前項の届出がなかったことにより、お客様又は第三者が被る損害に対して、一切責任を負わないものとします。</li>
    </ol>

    <p>第６条（新たな利用契約の申込みの禁止）</p>
    <p>以下の各号のいずれかに該当する場合、お客様は新たな利用契約の申込みをすることはできないものとします。但し、第３号について、休業していたお客様が事業を再開し、当社が事業再開を確認した場合には、お客様は、新たな利用契約の申込みをすることができるものとします。</p>
    <ol>
      <li>第５条第１項の登録情報変更の届出がなされず、お客様の変更後の住所、電話番号、氏名又は名称、支払手段その他の変更後の各登録情報が当社に対して明らかにされない場合</li>
      <li>当社が、お客様又は利用者が第２７条第１項各号のいずれかに該当すると判断した場合</li>
      <li>お客様の事業が休業中の場合</li>
    </ol>

    <p>第７条（コンピュータ機器及び環境等）</p>
    <ol>
      <li>お客様は、本サービスを利用するために必要なコンピュータ機器及び環境等を、自己の費用と責任をもって準備し（必要なアプリケーションのインストールを含みます。）、管理するものとします。</li>
      <li>お客様は、自己の本サービスの利用環境に応じて、コンピュータウイルスの感染の防止、不正アクセス及び情報流出・漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。</li>
      <li>お客様のコンピュータ機器若しくは環境等の不具合又は不十分なセキュリティ対策等により、本サービスの利用が滞り、又は利用不可能になった場合であっても、当社は、一切責任を負担しないものとします。</li>
    </ol>

    <p>第８条（パスワード等）</p>
    <ol>
      <li>本サービスの利用にあたり、お客様は、当社所定の手続きに従い、当社からID及び当社パスワード（以下「パスワード等」といいます。）の発行を受ける必要があります。</li>
      <li>お客様は、自己の責任の下、パスワード等を秘密として厳重に管理し、第三者に貸与又は開示してはならないものとします。なお、お客様は、当社パスワードについて、類推し易いものを避け、不正アクセスの未然防止に努めるものとします。</li>
      <li>当社は、ログイン画面に入力されたパスワード等を当社の記録と照合し、これらの一致を確認して取り扱った場合には、ログアウト前の一連の通信は、当該パスワード等を登録したお客様によって行われているものとみなします。この際、パスワード等の偽造、盗用、不正使用、無権限使用その他の事故があった場合でも、当社はかかる事由により生じた損害につき、一切責任を負担しないものとします。</li>
      <li>お客様は、パスワード等が盗まれたり、本サービスの利用に関して不正アクセス、情報流出・漏洩等又はそのおそれが生じたと判断した場合（他人に不正使用若しくは無権限使用されたことが判明した場合又はその懸念がある場合を含みます。）には当社に対して直ちにその旨を通知するとともに、当社の指示に従うものとします。</li>
      <li>お客様は、お客様がパスワード等を第三者に開示し又は漏洩したことにより当社に損害が生じた場合、当社に対し、その全ての損害（合理的な弁護士費用を含みますがこれに限られません。）を直ちに賠償する責任を負うものとします。</li>
    </ol>

    <p>第９条（利用料金の算定・支払い）</p>
    <ol>
      <li>当社は本サービスに関する利用契約について、契約プラン、利用料金の算定方法、支払方法、並びに支払時期その他利用料金の算定及び支払方法に関する事項を本サイト又は当社の定める方法にて開示するものとします。</li>
      <li>当社は、お客様が本サービスの利用契約について、利用申込時に選択したサービスの内容に基づき、利用料金を算定し、申込みの際の本サイトにて、利用料金、契約期間及び支払時期等を提示するものとします。</li>
      <li>お客様は、前項で定められた利用料金を、第１項において当社が開示した支払方法のうち、お客様の選択した方法で支払うものとします。</li>
      <li>当社は、いかなる場合でも利用契約に基づきお客様が当社に支払った料金の返金は行いません。なお、契約期間中に、利用契約プランの変更その他の事由により、利用料金、契約期間及び支払時期等が変更された場合であっても同様とし、お客様の当社に対する債務がある場合は、利用料金、契約期間及び支払時期等が変更された後も、当社への債務全額の支払義務が存続するものとします。</li>
      <li>当社は、本サービスウェブサイトでの掲示、又はその他の方法で請求内容を提示することをもって請求書発行の代わりとし、書面による請求書は発行しないものとします。</li>
      <li>口座振替の方法によるお支払いについては、次条の定めが適用されるものとします。</li>
      <li>クレジットカードによるお支払いについては、お客様の指定するクレジットカード会社の規約に従うものとします。クレジットカード会社から当社にカード無効による売上否認の通知があった場合、お客様は当社が別途定める方法により支払いを行うものとします。</li>
      <li>お客様とクレジットカード会社との間で支払いに係る紛争が生じたときは、当該当事者双方で解決するものとし、当社は一切の責任を負わないものとします。</li>
    </ol>

    <p>第１０条（口座振替の手続き等）</p>
    <ol>
      <li>本サービスの利用契約について、お客様が口座振替の方法によるお支払いを選択して利用契約の申込みを行い、口座振替依頼書の書面での手続きを希望した場合、当社はお客様に対して口座振替依頼書を提供し、お客様は口座振替依頼書の書面での手続きを希望した日から２週間以内に必要事項を記入した口座振替依頼書を当社に返送するものとします。口座振替依頼のインターネットでの手続きを希望した場合、お客様は、利用契約の申込み後２週間以内に、当社所定の手続きに従い、当社が提携する収納代行会社のサイトで口座情報を登録するものとします。</li>
      <li>前項に定める利用契約の申込日が属する月の翌々月末日までに、口座振替依頼書が当社に返送されない場合若しくはインターネット上で必要な口座情報の登録がなされない場合又は当社に返送された口座振替依頼書の記載に不備がある等により当社システムへの登録が完了しない場合、当社は、お客様への事前の通知若しくは催告を要することなく、当該利用契約について申込日が属する月の翌々々月１日から当該利用契約にかかる本サービスの提供を制限（停止又は無料プランと同範囲のサービス提供を含み、以下「停止等」といいます。）することができるものとします。この場合において、お客様から必要事項を記入した口座振替依頼書が利用契約の契約期間内に当社に返送され又はインターネット上で必要な口座情報の登録がなされ、かつ、当社システムへの登録が完了したときは、当社は当該利用契約にかかる本サービスの提供の停止等を解除し、お客様に当該本サービスの提供を開始するものとします。</li>
      <li>お客様が支払手段として口座振替を選択して利用契約を申込み、当社がお客さまへ利用料金の請求を行った日（以下「請求日」という。）が属する月の翌々月末までに、利用料金の入金を確認できない場合、当社は、お客様に対し、クレジットカードによる利用料金の支払いを依頼するものとします。</li>
      <li>支払手段として口座振替を選択して利用契約を申込み、請求日が属する月の翌々月末までに入金が確認できない場合、お客様は新たな利用契約の申込みをすることはできず、かつ、当社は、お客様への事前の通知若しくは催告を要することなく、当該利用契約にかかる本サービスの提供を、請求日が属する月の翌々月末の翌１日より停止等することができるものとします。この場合において、お客様から未払いの利用料金が支払われ、当社で入金を確認できたときは、お客様は前条第１項の支払方法により新たな利用契約の申込みをすることができ、又は、利用契約の契約期間内である場合には、当社は当該利用契約にかかる本サービスの提供の停止等を解除し、お客様に当該本サービスの提供を開始するものとします。</li>
      <li>第２項又は前項の定めによりお客様が本サービスを利用できない期間についても、お客様は利用料金を支払う義務を負うものとします。また、第２項又は前項の定めに基づいて本サービスの提供が停止等されたことによりお客様に損害が生じた場合であっても、当社は当該損害を一切賠償する責任を負いません。</li>
    </ol>

    <p>第１１条（後払いの利用料金の請求・決済）</p>
    <p>後払いの利用料金（年契約を締結している場合の従量利用料金及び月契約を締結している場合の利用料金）の請求・決済は、原則として以下の方法に従い行うものとします。但し、当社が別途定める規定がある場合は当該規定によるものとします。</p>
    <ol>
      <li>当社は、お客様に対し、月末時に、未請求の利用料金を請求するものとします</li>
      <li>第１号の規定にかかわらず、利用契約が終了した場合、当社は、お客様に対し、未請求の利用料金を全額請求するものとします</li>
      <li>第１号、２号にかかわらず、当社で必要であると判断した場合、未請求の利用料金の累計額を算定し、当該利用料金を請求するものとします</li>
    </ol>

    <p>第１２条（その他のペナルティ措置）</p>
    <ol>
      <li>決済方法にかかわらず、請求日が属する月の翌々月末までに、利用料金の入金が確認できない場合、お客様は新たな利用契約の申込みをすることはできず、かつ、当社は、お客様への事前の通知若しくは催告を要することなく、本サービスの利用の停止等ができるものとします。この場合において、お客様から未払いの利用料金が支払われ、当社で入金を確認できたときは、お客様は第10条第１項の決済方法により新たな利用契約の申込みをすることができ、又は、利用契約の契約期間内である場合には、当社は本サービスの利用制限を解除し、お客様に利用契約プランに従った本サービスの提供を開始するものとします。</li>
      <li>前項の定めによりお客様が本サービスの利用を制限される期間についても、お客様は契約期間内にある利用契約に基づく利用料金を支払う義務を負うものとします。また、前項の定めに基づいて本サービスの利用が制限されたことによりお客様に損害が生じた場合であっても、当社は当該損害を一切賠償する責任を負いません。</li>
      <li>利用料金について、請求日が属する月の翌々月末までに入金が確認できない場合、当社はお客様に対して書面又は電子メールにより、利用料金の支払いの督促をする場合があり、また、請求日が属する月から６ヶ月間入金が確認できない場合、当社はお客様に対して内容証明郵便により利用料金の支払いの催告をする場合があります。お客様について内容証明郵便による支払いの催告の履歴が累計で２回なされた場合、その後お客様から未払いの利用料金が支払われた場合であっても、お客様はクレジットカード以外の支払方法で新たな利用契約の申込み、利用契約の更新、又は利用契約の変更をすることはできないものとします。本項は本規約の他の条項に優先するものとします。</li>
    </ol>

    <p>第１３条（情報・データの取得と利用）</p>
    <ol>
      <li>お客様は、本サービスを利用する上でお客様が入力した各種情報が、当社又は当社提携先事業者において管理するサーバ上に保存され、若しくは当社提携先事業者において管理するサーバ上を経由して当社のサーバに保存されることにつき予め承諾するものとします。</li>
      <li>当社は、インターネットを通じて、お客様が本サービスにアクセスし又は本サービスを利用する際に、お客様が本サービスを利用するコンピュータのOSの種類、バージョン情報、お客様のインターネット接続状態、当社サービスの利用機能の履歴、当社サービス利用時のエラーメッセージに関する情報等の情報・データを取得することがあります。これは、当社による本サービスに関する情報のお客様への通知及びサポートサービスの提供等を目的として当社が収集するものであり、お客様は、当社がこれらの情報・データを取得することに同意するものとします。</li>
      <li>当社は、お客様が本サービスの利用に関連して当社に提供した情報・データ（本サービスの利用に必要なお客様の登録情報及び前２項の情報・データを含みますがこれらに限られません。以下「お客様データ」といいます。）を、①お客様に対するサービスの提供、②サービスの改善・開発、③上記①②に関連した統計データとしての利用、④その他当社若しくは当社関係会社のオンラインサービス関連事業若しくは業務支援サービス事業に係る上記①ないし③の態様による利用、又は⑤お客様に対するサービス等（当社のものに限らず、当社の提携先のサービス等を含み、以下においても同様とします。）のご案内を目的に、お客様の承諾を得ることなく、当社及び当社の委託先が利用し、また当社子会社が当該データの提供を受けて利用することができるものとします。なお、当社が、これらの目的以外の目的でお客様の情報を収集し利用する場合は、予めお客様の同意を得るものとします。</li>
      <li>お客様は、当社がお客様に本サービスを提供するにあたり、第三者に対し、お客様データのうち、以下の各号の情報を提供することに予め同意するものとします。<br>
        <ol>
          <li>当社から本サービスに関する業務の委託を受けた者が当該業務を遂行するために必要な情報</li>
          <li>当社が本サービスその他当社のサービスを第三者に説明するために必要な情報</li>
        </ol>
      </li>
      <li>当社は、個人情報以外のお客様データについては、当社の判断により第三者に提供することができるものとします。</li>
      <li>お客様データのうち個人情報に関する取扱いについては、本規約に定めるほか、当社が別途定めるプライバシーポリシーに定めるとおりとします。ただし、個別のサービスに関する個別規約において別途規定がある場合には、当該規定の内容に従うものとします。</li>
      <li>当社は、お客様の承諾を得ることなく、お客様データを、バックアップの目的で複製することができるものとします。但し、当社は、お客様データを複製する義務は負わないものとし、当社がお客様データを保持し続けなかったこと等によりお客様が被った損害又は損失等について一切の責任を負わないものとします。</li>
    </ol>

    <p>第１４条（知的財産権）</p>
    <ol>
      <li>本サービスにかかわるプログラム、本サイト上に表示される一切の情報及び表現等の著作権その他一切の知的財産権は、当社その他の権利者に帰属し、お客様は、これらを本サービスの利用のためだけに、かつ正当な使用方法に従ってのみ利用することができ、これ以外の目的及び使用方法では利用できないものとします。</li>
      <li>お客様が、本サービスに関し当社に帰属する著作権、特許権等の知的財産権その他の権利を侵害したことにより当社に損害を与えた場合、当社に対して、その全ての損害（合理的な弁護士費用を含みますがこれに限られません。）を直ちに賠償する責任を負うものとします。</li>
    </ol>

    <p>第１５条（保証）</p>
    <ol>
      <li>当社は、本サービスにより取得するお客様の情報等の完全性又は正確性に関して、一切保証しないものとし、当社が本サービスにより取得するお客様の情報等が不正確又は不完全であったことによるお客様の一切の損害について、責任を負わないものとします。</li>
      <li>当社は、第三者の責に帰すべき事由（セキュリティ対策の不備、システム管理の不備、内部役職員の不正行為及び顧客保護体制の不備を含みますが、これらに限られません。）によりお客様が被った損害又は損失等について、一切の責任を負わないものとします。</li>
      <li>当社は、お客様が、本サービス及び本サービスにより当社製品と連携する第三者のサービスの利用に関連して他のお客様を含む第三者に与えた損害又は損失等について、一切の責任を負わないものとします。</li>
      <li>当社は、当社の管理外である通信回線や当社設備に属さない設備の状態について、一切の責任を負わないものとします。</li>
      <li>本規約の他の規定にかかわらず、当社は、本サービスの遅滞、停止、変更、中止、廃止又はお客様データの喪失に関連してお客様が被った損害又は損失等について、一切の責任を負わないものとします。</li>
      <li>当社は、お客様が本サービスを利用しなかったことに関連して被った損害又は損失等について、一切の責任を負わないものとします。</li>
    </ol>

    <p>第１６条（委託）</p>
    <p>当社は、お客様の承諾を得ることなく、本サービスに関する業務の全部又は一部を第三者に委託することができるものとします。</p>

    <p>第１７条（損害賠償）</p>
    <p>本規約に特に定めるほか、お客様は、本規約若しくは個別のサービスの利用規約に違反したことにより、又は故意若しくは過失により当社に損害を与えた場合は、当社に対し、その全ての損害（合理的な弁護士費用を含みますがこれに限られません。）を直ちに賠償する責任を負うものとします。</p>

    <p>第１８条（権利義務の譲渡）</p>
    <p>お客様は、本規約及び個別のサービスの利用規約に基づいて当社との間で締結した全ての契約について、その契約上の地位及びこれにより生じる権利義務の一部又は全部を、当社の書面による事前の承諾なく譲渡することはできません。</p>

    <p>第１９条（禁止事項）</p>
    <ol>
      <li>お客様は、本サービスの利用に関し、以下の行為を行ってはならないものとします。<br>
        <ol>
          <li>本サービスの運営を妨げ、その他本サービスの提供に支障をきたすおそれのある行為</li>
          <li>本サービスの内容や本サービスにより利用し得る情報を改ざん又は消去する行為</li>
          <li>お客様が当社への書類・資料等の提出に際し、虚偽の事項を記載又は申告する行為</li>
          <li>当社の書面又は当社所定の方法による承諾なく、第三者に本サービスを利用させる行為</li>
          <li>当社、他のお客様その他の第三者の著作権、商標権などの知的財産権、プライバシーその他の権利を侵害する行為、又はそれらのおそれのある行為</li>
          <li>公序良俗に反する行為、法令に違反する行為、若しくは第三者に不利益を与える行為、又はそれらのおそれのある行為</li>
          <li>当社、他のお客様その他の第三者を差別若しくは誹謗中傷し、又はその名誉若しくは信用を毀損する行為</li>
          <li>詐欺等の犯罪に結びつく又は結びつくおそれがある行為</li>
          <li>他のお客様その他の第三者になりすまして本サービスを利用する行為</li>
          <li>ウイルス等の有害なコンピュータプログラム等を送信又は掲載する行為</li>
          <li>本サービス用設備等の利用若しくは運営に支障を与える行為、又は与えるおそれのある行為</li>
          <li>その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する態様又は目的でリンクを貼る行為</li>
          <li>前各号に準ずる行為</li>
          <li>その他当社が不適当と判断する行為</li>
        </ol>
      </li>
      <li>お客様が前項各号に掲げる行為その他本規約又は個別のサービスの利用規約に違反する行為を行った場合、当社は、以下の措置を講ずることができるものとします。当社は、本項に基づき当社が行った措置によりお客様に生じた損害について一切の責任を負いません。<br>
        <ol>
          <li>本サービスの全部又は一部を停止又は終了すること</li>
          <li>前項各号に該当する行為に関連する情報を削除すること</li>
        </ol>
      </li>
    </ol>

    <p>第２０条（広告掲載）</p>
    <p>当社は、本サービスやソフトウェアに、当社の裁量で、当社又は第三者の広告を掲載できるものとします。また、当社が別途承諾した場合には、当社以外の者が本サイト内に広告を掲載することがあります。</p>

    <p>第２１条（損害に関する免責）</p>
    <ol>
      <li>当社は、故意又は重過失により、本規約に違反して、お客様に損害を与えた場合、お客様に対し、その損害を賠償する責任を負うものとします。但し、当社は、法律上の請求の原因の種類を問わず、本サービスの利用若しくは利用不能又はその他本規約に規定する事項に関して生じる特別損害、付随的損害、間接的損害、派生的損害等の損害（逸失利益、機密情報、データ若しくはその他の情報の喪失、事業の中断、人身傷害及びプライバシーの侵害による損害を含みますが、これらに限定されません。）に関しては、一切の責任を負わないものとします。</li>
      <li>当社は、本サービス（本サービスと連携するサービスその他の本サービスに関連して第三者が提供するサービスを含みます。）の中断、停止、変更、追加、終了又はお客様データ（お客様が本サービスと連携するサービスの提供元その他の本サービスに関連するサービスの提供元等の第三者に対して提供したデータを含みます。）の喪失、流出等に関連してお客様が被った損害等について、一切の責任を負わないものとします。</li>
      <li>前各項に定めるほか、当社は、お客様が本サービスの利用に関連して被った損害又は損失等について、一切の責任を負わないものとします。</li>
    </ol>

    <p>第２２条（秘密保持）</p>
    <p>お客様は、本サービスに関する情報のうち公然と知られていないもの、当社から本サービスに関するサービス提供としてなされた回答及び当社又は委託先から得た当社又は委託先の機密に係る情報について秘密を保持するものとし、当社の事前の書面による承諾を得ることなくこれらを第三者に開示又は漏洩しないものとします。</p>

    <p>第２３条（第三者との紛争）</p>
    <p>お客様は、お客様と他のお客様を含む第三者との間で紛争が生じた場合には、自己の責任と費用をもってこれを処理解決するものとし、当該紛争に関連して当社が損害を被った場合には、その全ての損害（合理的な弁護士費用を含みますがこれに限られません。）を直ちに賠償する責任を負うものとします。なお、当社が当該紛争を処理解決した場合には、その処理解決に要した全ての費用（合理的な弁護士費用を含みますがこれに限られません。）は、お客様の負担とします。</p>

    <p>第２４条（契約期間）</p>
    <p>利用契約の有効期間は、お客様が利用契約を締結した個別のサービスに関する個別規約に基づく合意に従うものとします。</p>

    <p>第２５条（お客様による解約）</p>
    <p>お客様は、本サービスウェブサイトから、お客様が利用契約を締結した、契約の解約を申込むことができるものとします。この場合、当社がお客様から解約申込みを受け付けた後、当社の規定する解約手続きが終了したときに当該サービスの利用契約が解約されるものとします。なお、お客様が当社に対し債務を有している場合は利用契約解約後も当該債務は存在するものとし、当社への債務全額の支払義務が有効に存続します。また、当社は、利用契約に基づきお客様が当社に支払った料金の返金は行いません。</p>

    <p>第２６条（当社による利用停止・解約）</p>
    <ol>
      <li>当社は、お客様又は利用者が次の各号のいずれかに該当すると判断した場合、お客様への事前の通知若しくは催告を要することなく、当該お客様による本サービスの利用を停止し、又は利用契約の全部若しくは一部を解約することができるものとします。<br>
        <ol>
          <li>手形若しくは小切手が不渡りとなったとき又は支払停止となったとき</li>
          <li>差押え、仮差押え、仮処分若しくは競売の申立てがあったとき又は租税滞納処分を受けたとき</li>
          <li>破産手続開始、特定調停手続開始、会社更生手続開始若しくは民事再生手続開始その他これらに類似する倒産手続開始の申立て又は決定があったとき、又は清算に入ったとき</li>
          <li>死亡、解散又は事業の全部若しくは重要な一部を第三者に譲渡しようとしたとき</li>
          <li>後見開始又は保佐開始の申立てがあったとき</li>
          <li>監督官庁から事業の取消・停止処分等を受けたとき又は転廃業しようとしたとき</li>
          <li>本規約及び個別のサービスの利用規約に基づく支払債務を合意した支払方法にて履行しなかったとき</li>
          <li>前号のほか、お客様又は利用者が、本規約又は個別のサービスの利用規約に違反したとき</li>
          <li>信用状況が悪化し、又はそのおそれがあると認められる相当の事由があるとき</li>
          <li>当社が、お客様に対し、お客様の行為について不適当であると判断して中止を求めたにもかかわらず、お客様がこれに応じなかったとき</li>
        </ol>
      </li>
      <li>お客様は、前項による解約があった時点において、利用契約に関連してお客様の当社に対する未払いの債務がある場合には、直ちに当社が定める日までにこれを支払うものとします。また、当社は、利用契約に基づきお客様が当社に支払った料金の返金は行いません。</li>
      <li>第１項に基づく解約その他本利用規約等に基づく解約又は解除は、当社による損害賠償請求及び費用請求を妨げないものとします。</li>
    </ol>

    <p>第２７条（本サービスの停止・終了）</p>
    <ol>
      <li>当社は、以下各号の事由に起因する場合、お客様に対して事前に通知することにより、本サービスの全部又は一部を停止することができるものとします。但し、緊急の必要がある場合には、事前の通知を行うことなく、本サービスの全部又は一部を停止することができるものとします。<br>
        <ol>
          <li>本サービスを提供するために必要なコンピュータ、電気通信設備その他の機器、ソフトウェア若しくは電気通信回線の保守・点検を行うとき、又はこれらに障害が生じ、若しくは障害が生じるおそれがあるとき</li>
          <li>電気通信事業者又は国外の電気通信事業体が電気通信サービスの提供を停止すること等により本サービスの提供を行うことが不可能又は困難になったとき</li>
          <li>火災・停電、天災地変等の非常事態により本サービスの提供が不可能又は困難になったとき</li>
          <li>戦争、内乱、暴動、騒擾、労働争議等により、本サービスの提供が不可能又は困難になったとき</li>
          <li>第三者からの不正アクセス、コンピュータウイルスの感染等により、本サービスの提供が不可能又は困難になったとき</li>
          <li>法律、法令等に基づく措置により、本サービスの提供が不可能又は困難になったとき</li>
          <li>その他、客観的にやむを得ないと判断される場合</li>
        </ol>
      </li>
      <li>当社は、お客様に対して停止又は終了希望日の１ヶ月前までに通知することにより、お客様に対する本サービスの全部又は一部を停止又は終了することができるものとします。</li>
      <li>当社は、本条に基づき当社が行った措置によりお客様に生じた損害について一切の責任を負わないものとします。</li>
    </ol>

    <p>第２８条（本サービスの変更等）</p>
    <ol>
      <li>当社は、事前にお客様に通知することにより、本サービスの内容の変更又は追加を行うことができるものとします。但し、軽微な内容の変更を行う場合及び緊急の必要があるなど当社がやむを得ないと判断する場合には、事前の通知を行うことなく、本サービスの内容の変更又は追加を行うことができるものとします。</li>
      <li>当社は、前項に基づき本サービスを変更又は追加するに伴いお客様が被った損害その他の費用の賠償又は補償等につき、一切の責任を負わないものとします。</li>
    </ol>

    <p>第２９条（データの消去）</p>
    <ol>
      <li>当社は利用契約の全部又は一部が終了した場合又は本サービスの全部若しくは一部の提供を終了した場合、お客様の承諾を得ることなく、利用契約又は提供が終了した本サービスにかかるお客様データの全部又は一部を消去することができるものとします。</li>
      <li>お客様は前項に基づくお客様データの消去について一切異議を述べないものとし、当社は、前項に基づくお客様データの消去に関連してお客様が被った損害等について一切の責任を負わないものとします。</li>
    </ol>

    <p>第３０条（規約の変更）</p>
    <ol>
      <li>当社は、法令に違反しない範囲内で、当社の判断により、本規約及び個別のサービスの利用規約の内容の全部又は一部を変更することができるものとします。</li>
      <li>当社は、本規約又は個別のサービスの利用規約を変更する場合には、その効力発生日を定め、かつ、事前に、当該規約を変更する旨、当該変更内容及び当該変更の効力発生日を本サイトにおいて掲載し、また、お客様に通知して周知するものとします。<br>
          なお、変更の効力発生日以降に、お客様が本サービスを利用した場合又は上記期間内に利用契約の解約の手続きをとらなかった場合には、お客様は、当該規約の変更に同意したものとみなします。</li>
      <li>当社は、本条による本規約又は個別のサービスの利用規約の内容の全部又は一部の変更に伴いお客様が被った損害その他の費用の賠償又は補償等につき、一切の責任を負わないものとします。</li>
    </ol>

    <p>第３１条（反社会的勢力）</p>
    <ol>
      <li>お客様は、当社に対し、お客様自身並びにお客様の役員及び従業員が、以下の各号のいずれかの者に該当しないことを表明及び保証し、かつ将来にわたって該当しないことを確約します。<br>
        <ol>
          <li>暴力団</li>
          <li>暴力団員</li>
          <li>暴力団員でなくなった時から５年を経過しない者</li>
          <li>暴力団準構成員</li>
          <li>暴力団関係企業</li>
          <li>総会屋等</li>
          <li>社会運動等標ぼうゴロ</li>
          <li>特殊知能暴力集団等</li>
          <li>その他前各号に準ずる者</li>
          <li>前各号に該当する者（以下「暴力団員等」といいます。）が経営を支配していると認められる関係を有する者</li>
          <li>暴力団員等が経営に実質的に関与していると認められる関係を有する者</li>
          <li>自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有する者</li>
          <li>暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有する者</li>
          <li>役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有する者</li>
        </ol>
      </li>
      <li>お客様は、自ら又は第三者をして、以下の各号のいずれかに該当する行為を行わないものとします。<br>
        <ol>
          <li>暴力的な要求行為</li>
          <li>法的な責任を超えた不当な要求行為</li>
          <li>取引に関して、脅迫的な言動をし、又は暴力を用いる行為</li>
          <li>風説を流布し、偽計を用い又は威力を用いて当社の信用を毀損し、又は当社の業務を妨害する行為</li>
          <li>その他前各号に準ずる行為</li>
        </ol>
      </li>
    </ol>

    <p>第３２条（当社からの通知）</p>
    <ol>
      <li>当社は、本サイト上での掲示、電子メールの送信又は文書の送付その他当社が適当と判断する方法により、お客様に対し、随時必要な事項を通知するものとします。</li>
      <li>前項の通知は、当社が当該通知を本サイト上に掲示し、電子メールを発信し、文書を発送し、又はその他の方法により通知を発信した時点からその効力を生じるものとします。</li>
    </ol>

    <p>第３３条（準拠法・専属的合意管轄）</p>
    <ol>
      <li>本規約及び個別のサービスの利用規約は日本法に基づいて解釈されるものとします。</li>
      <li>本規約、個別のサービスの利用規約及び本サービスに関連又は起因して生じた、当社とお客様との間の紛争については、大阪地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
    </ol>

    <p>第３４条（協議解決）</p>
    <p>本規約に規定のない事項又は本規約の解釈について疑義が生じた事項については、お客様及び当社は、誠意をもって協議の上、解決にあたるものとします。</p>

    <p>第３５条（存続条項）</p>
    <p>利用契約終了後といえども、第５条（登録情報変更の届出）第２項、第７条（コンピュータ機器及び環境等）第３項、第８条（パスワード等）第５項、第９条（利用料金の算定・支払い）第３項、第４項及び第７項、第１０条（口座振替の手続き等）ないし第１５条（保証）、第１７条（損害賠償）、第１９条（禁止事項）、第２１条（損害に関する免責）ないし第２５条（お客様による解約）、第２６条（当社による利用停止・解約）第２項及び第３項、第２７条（本サービスの停止・終了）第３項、第２８条（本サービスの変更等）第２項、第２９条（データの消去）ないし第３４条（協議解決）、並びに本条の各規定は、なお有効に存続するものとします。</p>

    <p>第３６条（本サービスにおける計算根拠）</p>
    <p>本サービスが提供する数値は、第３条に記載されている通り、お客様が責任を持って調査や確認を行い、その結果に基づいて当社が提供する数値を利用するかどうかを判断して頂きます。本サービスにおける数値の算出根拠については、次の通りです。お客様はこれらの仕様を理解した上で、本サービスから計算される数値を参考にして選択して頂きますようお願いします。</p>
    <ol>
      <li>間口距離の計算方法<br>
        <ol>
          <li>間口距離は、指定した正面路線と接する部分の距離を間口距離とする仕様となっております。</li>
          <li>屈折路に面している場合の間口距離はその不整形地に係る想定整形地の間口に相当する距離と、屈折路に実際に面している距離とのいずれか短い距離をデフォルトの間口距離として設定する仕様となっております。</li>
          <li>角地などで角切がされている土地については、角切がなかったとした場合の土地の間口距離をオプションで選択することができます。この場合において、角切がなかったとした場合の設定については、間口を道路の延線上からとる方法と、隅切りから正面路線に向かう垂線上からとる方法が考えられますがが、本サービスにおいては、間口を道路の延線上からとる場合を採用しております。<br>
            <img src="/static/img/36-1-1.jpg" style="width:50%; height: auto;"><br>
            ※次のような間口が外側に広がった角切の土地の間口距離は、角切なかったものとした間口を選択する必要がありますが、本サービス上では対応しておりません。（自動でaを選択するようになっておりますのでご留意ください。）<br>
            <img src="/static/img/36-1-2.jpg" style="width:50%; height: auto;"><br>
            ※次のような一つの路線において一部路線に面していないような間口がある土地については、実際に道路に面している間口を選択する方法が正しい評価方法と考えられますが、本サービス上では対応しておりません。（自動でbを選択することになっております。）<br>
            <img src="/static/img/36-1-3.jpg" style="width:50%; height: auto;">
          </li>
        </ol>
      </li>
      <li>奥行距離の選択<br>
        <ol>
          <li>不整形地にかかる想定整形地の奥行距離を限度として、その不整形地の面積をその間口距離で除して得た数値としております。</li>
        </ol>
      </li>
      <li>正面路線の判定<br>
        <ol>
          <li>対象地の接する各路線の路線価に奥行価格補正率を乗じて計算した金額の高い方の路線を正面路線とする仕様となっておりますが、お客様においてその他の路線を正面路線として選択することができるような仕様となっておりますので、お客様の必要に応じて選択して頂きますようお願い致します。</li>
          <li>路線価に奥行価格補正率を乗じた後の金額が同額となる場合には、路線に接する距離の長い方の路線が正面路線になるように設定しておりますが、お客様においてその他の路線を正面路線として選択することができるような仕様となっておりますので、お客様の必要に応じて選択して頂きますようお願いします。<br>
            ※次のような地区の異なる2以上の路線に接する宅地の場合や複数の地区にまたがる場合の正面路線は、それぞれの路線の路線価に各路線の地区に適用される奥行価格補正率を乗じて計算した金額を基に判定が必要ですが、本サービスでは地区の異なる2以上の路線を同時に設定することができません。<br>
            <img src="/static/img/36-3-1.jpg" style="width:50%; height: auto;"><br>
            ※次のような一つの路線に異なる2以上の路線価に接する宅地の場合の正面路線の路線価は、それぞれの路線に接する距離により加重平均して正面路線を計算し、その正面路線価を基に奥行価格補正率を乗じて計算する方法が正しい評価方法と考えられますが、本サービスでは異なる2以上の路線価を同時に設定することができません。<br>
            <img src="/static/img/36-3-2.jpg" style="width:50%; height: auto;">
          </li>
        </ol>
      </li>
      <li>想定整形図のパターン出しについて<br>
        屈折路に面する不整形地に係る想定整形地は、いずれかの路線からの垂線によって又は路線に接する両端を結ぶ直線によって、評価しようとする宅地の全域を囲むく形又は正方形のうち最も面積の小さいものを想定整形地とする仕様がデフォルト設定となっておりますが、お客様においてその他の想定整形地を選択することができるような仕様となっておりますので、お客様の必要に応じて選択して頂きますようお願い致します。<br>
        ※内接する場合の屈折路については、外接する土地のように下記のような路線の両端を結ぶ直線による想定整形地については想定整形地の設定しておりません。もしお客様において下記のようなパターンを選択したい場合には、外接ボタンを選択して頂きますようお願いします。<br>
        <img src="/static/img/36-4-1.jpg" style="width:40%; height: auto;"><br>
        ※帯状部分を有するもの又はこれに類似する不整形地については、帯状部分とその他の部分に分割し、それぞれについて奥行価格補正等の画地調整を行って評価し、不整形地と評価しないことが正しい評価方法と考えられますが、本サービスにおいては対応しておらず、不整形地補正率が算出されることになりますが、お客様の判断において正しい数値をご利用ください。<br>
        <img src="/static/img/36-4-2.jpg" style="width:40%; height: auto;"><br>
        ※次のような準角地である屈折部の外側に位置する宅地については、(A-1)部分と(A-2)部分の台形状の土地並びにその合成体である多角形の土地について不整形地補正率は考慮しないで計算するところが正しい評価方法と考えられますが、本サービスにおいては対応しておらず、不整形地補正率が算出されることになりますが、お客様の判断において正しい数値をご利用ください。<br>
        <img src="/static/img/36-4-3.jpg" style="width:40%; height: auto;">
      </li>
      <li>Google Maps機能における面積や距離について<br>
        当社のシステムでは、Google Maps APIを利用して地図情報を表示しております。この地図情報はGoogle社が提供するサービスに準じて表示されております。Google Maps APIの利用に関しては、Google社のサービス利用規約(https://www.google.com/intl/ja_US/help/terms_maps/)にも準拠しておりますので、あわせてご確認いただけますようお願いいたします。
      </li>
      <li>簡易測量機械のドロガーの利用について<br>
        本サービスにおいては、ビズステーション株式会社(https://www.bizstation.jp/ja/drogger/)が提供するドロガーという簡易測量機械を利用して取得した緯度経度の座標を利用することができます。これらの座標情報はビズステーション株式会社が提供するサービスに準じて取得されており、本サービスにおいては、これらの座標情報が前項のgoogle社が提供する地図上にプロットされます。ビズステーション株式会社が提供するドロガーの利用に関しては、当該ドロガーを提供するビズステーション株式会社のサービス利用規約にも準拠しておりますので、あわせてご確認いただけますようお願いいたします。また、緯度経度の座標情報は正確な位置情報を提供することを目指しておりますが、万が一情報の誤りや欠損が生じる場合も考えられますので、利用に際しては適切な注意と判断をお願い致します。
      </li>
    </ol>

    <p>附則</p>

    <p>2022年&nbsp;11月1日&nbsp;制定・施行</p>

    <p>2023年&nbsp;8月3日&nbsp;最終改定</p>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
  props: {
    value: String,
    isTitle: {
      type: Boolean,
      default: true
    },
    mainId: String
  },
  data: () => ({
    edit: false
  }),
  computed: {
    localTitle: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    /* TODO: 必要な処理があれば記述 */
  }
}
</script>

<style scoped>

</style>
