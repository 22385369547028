<template>
  <b-overlay id="app" :show="loading" rounded="sm" :class="appClasses">
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand href="/">土地作図くん</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <template v-if="$store.getters['user/isLoggedIn']">
            <b-button
              class="d-flex flex-column justify-content-center align-items-center"
              href="https://forms.gle/aKn5U31wKDUDyss86"
              size="lg"
              target="_blank"
              variant="primary"
              v-if="this.$route.path.startsWith('/menu') === false"
              >
              <b-icon-exclamation-triangle />
              <span class="text-xs pt-1">バグ・改善報告</span>
            </b-button>

            <b-button
              class="d-flex flex-column justify-content-center align-items-center"
              href="/menu"
              size="lg"
              variant="primary"
              v-if="this.$route.path.startsWith('/menu') === false"
              >
              <b-icon-arrow-return-left />
              <span class="text-xs pt-1">メニューに戻る</span>
            </b-button>

            <b-button
              class="d-flex flex-column justify-content-center align-items-center"
              href="http://sakuzukun.com/blog/category/manual-154"
              size="lg"
              target="_blank"
              variant="primary"
              >
              <b-icon-book />
              <span class="text-xs pt-1">マニュアル</span>
            </b-button>

            <b-button
              class="d-flex flex-column justify-content-center align-items-center"
              size="lg"
              variant="primary"
              @click="logout"
              >
              <b-icon-box-arrow-right />
              <span class="text-xs pt-1">ログアウト</span>
            </b-button>
          </template>
          <template v-else>
            <b-button variant="primary" href="/login">ログイン</b-button>
            <b-button variant="dark" class="ml-2" href="/signup">新規登録</b-button>
          </template>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-alert :show="$store.getters['alert/showing']" dismissible :variant="$store.getters['alert/all'].variant"
             @dismissed="$store.dispatch('alert/close')">
      {{ $store.getters['alert/all'].message }}
    </b-alert>

    <router-view class="container-fluid" @loading="loading = $event" />

    <footer class="bg-secondary text-white">
      <div class="d-flex justify-content-end align-items-right">
        <div class="links pull-right">
          <a href="#" v-b-modal="'privacy-policy-footer'">プライバシーポリシー</a>
          <a href="#" v-b-modal="'terms-of-service-footer'">利用規約</a>
        </div>
        <b-modal id="privacy-policy-footer" class="modal-notice-text px-3" title="プライバシーポリシー" size="xl" ok-only>
          <privacy-policy v-model="title"></privacy-policy>
        </b-modal>
        <b-modal id="terms-of-service-footer" class="modal-notice-text px-3" title="利用規約" size="xl" ok-only>
          <terms-of-service v-model="title"></terms-of-service>
        </b-modal>
      </div>
    </footer>
  </b-overlay>
</template>

<script>
import TermsOfService from './components/TermsOfService'
import PrivacyPolicy from './components/PrivacyPolicy'
import colors from './colors'

export default {
  components: { TermsOfService, PrivacyPolicy },
  data: () => ({
    color: colors.color, // これを書いておかないと、colors 変数使っていないとのエラーになる
    loading: false
  }),
  computed: {
    appClasses () {
      const classes = []
      if (this.$route.path && this.$route.path.startsWith('/kagechi')) {
        classes.push('dark')
      }

      return classes
    }
  },
  mounted () {
    // ローカルストレージから取得した色指定をオブジェクトに保存
    colors.color = colors.getLocalStorageColorData()
  },
  methods: {
    logout () {
      this.$store.dispatch('user/logout')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/app";

#app > footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;

  .system-name {
    font-size: 20px;
  }

  .links {
    & > a {
      color: white;
      margin-left: .5rem;
      margin-right: .5rem;
    }
  }
}
</style>
